import React from "react";
import {BarDatum, BarTooltipProps, ResponsiveBar} from "@nivo/bar";
import {FormatUtils} from "../../utils/FormatUtils";
import {AxisProps} from "@nivo/axes";
import {Colors} from "../../styledcomponents/Styles";
import FlexBox from "../../styledcomponents/FlexBox";
import Text from "../../styledcomponents/Text";
import Box from "../../styledcomponents/Box";

/**
 * @author jaeho.lee104 on 2023. 04. 28..
 */
interface MarketTrendPriceChartProps {
    chartModels: Array<MarketTrendPriceChartModel>
    keys: MarketTrendPriceChartKey
}

export type MarketTrendPriceChartModel = {
    [key: string]: number | string;
}

export type MarketTrendPriceChartKey = {
    key1: string,
    key2: string
}

export const MarketTrendPriceChart: React.FC<MarketTrendPriceChartProps> = ({
                                                                                chartModels,
                                                                                keys
                                                                            }) => {

    return <ResponsiveBar
        data={chartModels}
        // @ts-ignore
        width={580}
        height={330}
        keys={[keys.key1, keys.key2]}
        indexBy="month"
        padding={0.3}
        margin={{top: 70, right: 10, bottom: 30, left: 50}}
        enableGridX={false}
        enableLabel={false}
        valueScale={{type: "linear"}}
        indexScale={{type: "band", round: true}}
        groupMode="grouped"
        colors={["#68C9CF", "#017DFD"]}
        tooltip={function (params: BarTooltipProps<BarDatum>) {
            return <FlexBox width={"fit-content"}
                            height={"fit-content"}
                            minWidth={'200px'}
                            padding={"20px"}
                            zIndex={500}
                            left={params.index <= 1 ? '80px' : params.index >= 10 ? '-80px' : '0px'}
                            flexDirection={"column"}
                            backgroundColor={Colors.WHITE}
                            borderRadius={"10px"}
                            alignItems={"flex-start"}
                            boxShadow={Colors.DEFAULT_BOX_SHADOW}
                            justifyContent={"center"}>
                <Text fontWeight={700}
                      fontSize={"18px"}>{params.data["month"]}</Text>
                <FlexBox
                    marginTop={"10px"}
                    alignItems={"center"}
                    justifyContent={"space-between"}>
                    <Box width={"16px"}
                         height={"16px"}
                         backgroundColor={"#68C9CF"}
                         borderRadius={"5px"}/>
                    <Text
                        fontSize={"18px"}
                        marginLeft={"10px"}>{keys.key1}</Text>
                </FlexBox>
                <Text
                    fontSize={"18px"}
                    fontWeight={700}
                    marginTop={"10px"}>US$ {params.data[keys.key1]}</Text>
                <FlexBox
                    alignItems={"center"}
                    marginTop={"10px"}
                    justifyContent={"space-between"}>
                    <Box width={"16px"}
                         height={"16px"}
                         backgroundColor={"#017DFD"}
                         borderRadius={"5px"}/>
                    <Text
                        fontSize={"18px"}
                        marginLeft={"10px"}>{keys.key2}</Text>
                </FlexBox>
                <Text
                    fontWeight={700}
                    fontSize={"18px"}
                    marginTop={"10px"}>US$ {params.data[keys.key2]}</Text>
            </FlexBox>
        }}
        valueFormat={(value) => {
            return `US$ ${FormatUtils.toCurrencyFormat(value.toString())}`
        }}
        borderRadius={2}
        axisTop={null}
        axisRight={null}
        innerPadding={2}
        axisBottom={{
            orient: "bottom",
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
        } as AxisProps}
        axisLeft={{
            // tickValues: [6000, 7000, 8000, 9000, 10000],
            orient: "left",
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            format: value => `US$ ${FormatUtils.toCurrencyFormat(Number(value).toString())}`,
        } as AxisProps}
        animate={true}
        legends={[
            {
                dataFrom: "keys",
                anchor: "top-right",
                direction: "row",
                justify: false,
                translateX: -30,
                translateY: -30,
                itemsSpacing: 40,
                itemDirection: "left-to-right",
                itemWidth: 70,
                itemHeight: 0,
                itemOpacity: 1,
                symbolSize: 14,
                symbolShape: "circle",
                itemTextColor: Colors.GRAY_800
            },
        ]}
        theme={{
            fontFamily: "Noto Sans",
            legends: {
                text: {
                    fontWeight: 400,
                    fontSize: 14,
                    color: Colors.GRAY_800
                }
            },
            crosshair: {
                line: {
                    stroke: Colors.GRAY_900,
                    strokeWidth: 1,
                    strokeOpacity: 1,
                    strokeDasharray: '3 4'
                }
            },
            axis: {
                ticks: {
                    text: {
                        fontWeight: 400,
                        fontSize: 10,
                        color: Colors.GRAY_800
                    }
                }
            },
            grid: {
                line: {
                    stroke: Colors.GRAY_300,
                    strokeWidth: 1,
                },
            },
        }}
    />
}


