/**
 * @author jaeho.lee104 on 2023. 04. 06..
 */
import React from "react";
import FlexBox from "../styledcomponents/FlexBox";
import {Colors} from "../styledcomponents/Styles";
import NewP2 from "../styledcomponents/text/NewP2";
import Image from "../styledcomponents/Image";
import NewH4 from "../styledcomponents/text/NewH4";
import {useTranslation} from "react-i18next";
import useTablet from "../uses/UseTablet";
import useMobile from "../uses/UseMobile";
import NewH1 from "../styledcomponents/text/NewH1";
import NewH2 from "../styledcomponents/text/NewH2";
import NewH6 from "../styledcomponents/text/NewH6";
import NewButton from "../styledcomponents/NewButton";
import HorizontalDivider from "../styledcomponents/HorizontalDivider";
import {useNavigate} from "react-router-dom";

const normalSection1Descriptions = [
    'user_intro_section_1_description_1',
    'user_intro_section_1_description_2',
    'user_intro_section_1_description_3'
]
const normalSection2Descriptions = [
    'user_intro_section_2_description_1',
    'user_intro_section_2_description_2',
    'user_intro_section_2_description_3',
    'user_intro_section_2_description_4',
    'user_intro_section_2_description_5'
]
const normalSection3Descriptions = [
    'user_intro_section_3_description_1',
    'user_intro_section_3_description_2'
]
const companySection1Description = [
    'user_intro_section_1_description_4',
    'user_intro_section_1_description_5',
    'user_intro_section_1_description_6',
    'user_intro_section_1_description_7'
]
const companySection3Description = [
    'user_intro_section_3_description_1',
    'user_intro_section_3_description_2',
    'user_intro_section_3_description_3',
    'user_intro_section_3_description_4',
    'user_intro_section_3_description_5',
    'user_intro_section_3_description_6',
    'user_intro_section_3_description_7',
    'user_intro_section_3_description_8',
]
const UserTypeIntroFragment = () => {
    const {t} = useTranslation()
    const isTablet = useTablet()
    const isMobile = useMobile()
    const navigate = useNavigate()

    return <FlexBox
        width={isMobile ? "100%" : isTablet ? "720px" : "880px"}
        flexDirection={"column"}
        margin={"0px auto"}
        alignItems={"center"}
        padding={"80px 0px"}>
        <NewH1
            color={Colors.GRAY_8}
            textAlign={"center"}>
            {t('intro_user_benefit')}
        </NewH1>
        <FlexBox
            height={isMobile ? "fit-content" : "1360px"}
            marginTop={"48px"}
            flexDirection={isMobile ? "column" : "row"}>
            <FlexBox
                border={`1px solid ${Colors.GRAY_2}`}
                width={"100%"}
                boxShadow={'0px 2px 40px 0px rgba(0, 0, 0, 0.05);'}
                borderRadius={"24px"}
                padding={"24px"}
                flexDirection={"column"}
                justifyContent={"space-between"}>
                <FlexBox
                    flexDirection={"column"}>
                    <Image
                        width={"80px"}
                        height={"80px"}
                        src={require("assets/images/normal-user-signup.svg").default}
                        alt="ic-normal-user"/>
                    <NewH2
                        color={Colors.GRAY_8}>
                        {t('normal_user')}
                    </NewH2>
                    <NewH6
                        color={Colors.GRAY_7}
                        marginTop={"8px"}>
                        {t('only_view_trading_data')}
                    </NewH6>
                    <NewButton
                        borderRadius={"12px"}
                        marginTop={"24px"}
                        width={"fit-content"}
                        height={"fit-content"}
                        padding={"14px 24px"}
                        onClick={() => {
                            navigate("/normal-user-signup")
                        }}>
                        {t('start_now')}
                    </NewButton>
                    <HorizontalDivider
                        backgroundColor={Colors.GRAY_3}
                        marginTop={"24px"}
                        height={"1px"}/>
                    <NewH4
                        color={Colors.GRAY_8}
                        marginTop={"24px"}>
                        {t('user_intro_section_1_title')}
                    </NewH4>
                    <FlexBox
                        backgroundColor={Colors.GRAY_0}
                        border={`1px solid ${Colors.GRAY_2}`}
                        borderRadius={"12px"}
                        flexDirection={"column"}
                        marginTop={"12px"}
                        padding={"12px 16px"}>
                        {normalSection1Descriptions.map((item, index) => {
                            return <FlexBox
                                alignItems={"center"}
                                marginTop={index === 0 ? "0px" : "12px"}>
                                <Image
                                    width={"16px"}
                                    height={"16px"}
                                    src={require("assets/images/ic-check-2.svg").default}
                                    alt="ic-check"/>
                                <NewP2
                                    marginLeft={"8px"}
                                    color={Colors.GRAY_7}>
                                    {t(item)}
                                </NewP2>
                            </FlexBox>
                        })}
                    </FlexBox>
                    <FlexBox
                        width={"100%"}
                        position={isMobile ? "relative" : "absolute"}
                        zIndex={1}
                        top={isMobile ? "0px" : "500px"}
                        flexDirection={"column"}>
                        <NewH4
                            textAlign={"left"}
                            color={Colors.GRAY_8}
                            marginTop={"57px"}>
                            {t('user_intro_section_2_title')}
                        </NewH4>
                        <FlexBox
                            backgroundColor={Colors.GRAY_0}
                            border={`1px solid ${Colors.GRAY_2}`}
                            borderRadius={"12px"}
                            flexDirection={"column"}
                            marginTop={"12px"}
                            padding={"12px 16px"}>
                            {normalSection2Descriptions.map((item, index) => {
                                return <FlexBox
                                    alignItems={"center"}
                                    marginTop={index === 0 ? "0px" : "12px"}>
                                    <Image
                                        width={"16px"}
                                        height={"16px"}
                                        src={require("assets/images/ic-check-2.svg").default}
                                        alt="ic-check"/>
                                    <NewP2
                                        marginLeft={"8px"}
                                        color={Colors.GRAY_7}>
                                        {t(item)}
                                    </NewP2>
                                </FlexBox>
                            })}
                        </FlexBox>
                    </FlexBox>
                    <FlexBox
                        position={isMobile ? "relative" : "absolute"}
                        zIndex={1}
                        width={"100%"}
                        top={isMobile ? "0px" : "790px"}
                        flexDirection={"column"}>
                        <NewH4
                            color={Colors.GRAY_8}
                            textAlign={"left"}
                            marginTop={"57px"}>
                            {t('user_intro_section_3_title')}
                        </NewH4>
                        <FlexBox
                            backgroundColor={Colors.GRAY_0}
                            border={`1px solid ${Colors.GRAY_2}`}
                            borderRadius={"12px"}
                            flexDirection={"column"}
                            marginTop={"12px"}
                            padding={"12px 16px"}>
                            {normalSection3Descriptions.map((item, index) => {
                                return <FlexBox
                                    alignItems={"center"}
                                    marginTop={index === 0 ? "0px" : "12px"}>
                                    <Image
                                        width={"16px"}
                                        height={"16px"}
                                        src={require("assets/images/ic-check-2.svg").default}
                                        alt="ic-check"/>
                                    <NewP2
                                        marginLeft={"8px"}
                                        color={Colors.GRAY_7}>
                                        {t(item)}
                                    </NewP2>
                                </FlexBox>
                            })}
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
            <FlexBox
                border={`1px solid ${Colors.GRAY_2}`}
                width={"100%"}
                marginTop={isMobile ? "24px" : "0px"}
                marginLeft={isMobile ? "0px" : isTablet ? "24px" : "40px"}
                boxShadow={'0px 2px 40px 0px rgba(0, 0, 0, 0.05);'}
                borderRadius={"24px"}
                padding={"24px"}
                flexDirection={"column"}
                justifyContent={"space-between"}>
                <FlexBox
                    flexDirection={"column"}>
                    <Image
                        width={"80px"}
                        height={"80px"}
                        src={require("assets/images/company-user-signup.svg").default}
                        alt="ic-normal-user"/>
                    <NewH2
                        color={Colors.GRAY_8}>
                        {t('company_user')}
                    </NewH2>
                    <NewH6
                        color={Colors.GRAY_7}
                        marginTop={"8px"}>
                        {t('view_trading_data_and_solution')}
                    </NewH6>
                    <NewButton
                        borderRadius={"12px"}
                        marginTop={"24px"}
                        width={"fit-content"}
                        height={"fit-content"}
                        padding={"14px 24px"}
                        onClick={() => {
                            navigate("/company-user-signup-1")
                        }}>
                        {t('start_now')}
                    </NewButton>
                    <HorizontalDivider
                        backgroundColor={Colors.GRAY_3}
                        marginTop={"24px"}
                        height={"1px"}/>
                    <NewH4
                        color={Colors.GRAY_8}
                        marginTop={"24px"}>
                        {t('user_intro_section_1_title')}
                    </NewH4>
                    <FlexBox
                        backgroundColor={Colors.GRAY_0}
                        border={`1px solid ${Colors.GRAY_2}`}
                        borderRadius={"12px"}
                        flexDirection={"column"}
                        marginTop={"12px"}
                        padding={"12px 16px"}>
                        {companySection1Description.map((item, index) => {
                            return <FlexBox
                                alignItems={"center"}
                                marginTop={index === 0 ? "0px" : "12px"}>
                                <Image
                                    width={"16px"}
                                    height={"16px"}
                                    src={require("assets/images/ic-check-2.svg").default}
                                    alt="ic-check"/>
                                <NewP2
                                    marginLeft={"8px"}
                                    color={Colors.GRAY_7}>
                                    {t(item)}
                                </NewP2>
                            </FlexBox>
                        })}
                    </FlexBox>
                    <FlexBox
                        width={"100%"}
                        position={isMobile ? "relative" : "absolute"}
                        zIndex={1}
                        top={isMobile ? "0px" : "500px"}
                        flexDirection={"column"}>
                        <NewH4
                            textAlign={"left"}
                            color={Colors.GRAY_8}
                            marginTop={"57px"}>
                            {t('user_intro_section_2_title')}
                        </NewH4>
                        <FlexBox
                            width={"100%"}
                            backgroundColor={Colors.GRAY_0}
                            border={`1px solid ${Colors.GRAY_2}`}
                            borderRadius={"12px"}
                            flexDirection={"column"}
                            marginTop={"12px"}
                            padding={"12px 16px"}>
                            {normalSection2Descriptions.map((item, index) => {
                                return <FlexBox
                                    alignItems={"center"}
                                    marginTop={index === 0 ? "0px" : "12px"}>
                                    <Image
                                        width={"16px"}
                                        height={"16px"}
                                        src={require("assets/images/ic-check-2.svg").default}
                                        alt="ic-check"/>
                                    <NewP2
                                        marginLeft={"8px"}
                                        color={Colors.GRAY_7}>
                                        {t(item)}
                                    </NewP2>
                                </FlexBox>
                            })}
                        </FlexBox>
                    </FlexBox>
                    <FlexBox
                        position={isMobile ? "relative" : "absolute"}
                        zIndex={1}
                        width={"100%"}
                        top={isMobile ? "0px" : "790px"}
                        flexDirection={"column"}>
                        <NewH4
                            color={Colors.GRAY_8}
                            textAlign={"left"}
                            marginTop={"57px"}>
                            {t('user_intro_section_3_title')}
                        </NewH4>
                        <FlexBox
                            backgroundColor={Colors.GRAY_0}
                            border={`1px solid ${Colors.GRAY_2}`}
                            borderRadius={"12px"}
                            flexDirection={"column"}
                            marginTop={"12px"}
                            padding={"12px 16px"}>
                            {companySection3Description.map((item, index) => {
                                return <FlexBox
                                    alignItems={"center"}
                                    marginTop={index === 0 ? "0px" : "12px"}>
                                    <Image
                                        width={"16px"}
                                        height={"16px"}
                                        src={require("assets/images/ic-check-2.svg").default}
                                        alt="ic-check"/>
                                    <NewP2
                                        marginLeft={"8px"}
                                        color={Colors.GRAY_7}>
                                        {t(item)}
                                    </NewP2>
                                </FlexBox>
                            })}
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </FlexBox>
    </FlexBox>
}
export default UserTypeIntroFragment;