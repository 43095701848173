import React, {memo} from "react";
import {Colors} from "../styledcomponents/Styles";
import Image from "../styledcomponents/Image";
import NewH6 from "../styledcomponents/text/NewH6";
import FlexBox from "../styledcomponents/FlexBox";
import {useTranslation} from "react-i18next";
import {useGoogleLogin} from "@react-oauth/google";
import {UsersAPI} from "../network/UsersAPI";
import ReactGA from "react-ga4";
import {ResponseCodes} from "../network/ResponseCodes";
import {LocalStorageManager} from "../LocalStorageManager";
import {Users} from "../modules/Users";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

export const GoogleLoginButton = memo(() => {

    const componentName = "GoogleLoginButton"
    const {t} = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleLoginButtonOnClick = useGoogleLogin({
        onSuccess: (response) => {
            signInWithGoogleOauth(response.code)
        },
        onError: (error) => {
            console.log(error);
        },
        flow: 'auth-code'
    });

    const signInWithGoogleOauth = (code: string) => {
        UsersAPI.signInWithGoogleOauth(code)
            .then(async (response) => {
                const responseCode = ResponseCodes.of(response.data.code);
                const result = response.data.data
                const accessToken = result?.accessToken
                const userId = result?.userId
                if (responseCode.isSuccess()) {
                    if (!userId || !accessToken) {
                        alert(t('alert_a_temporary_error_has_occurred'))
                        return
                    }
                    LocalStorageManager.setAccessToken(accessToken)
                    const user = await UsersAPI.getUserOrNull()
                    if (!user) {
                        alert(t('alert_a_temporary_error_has_occurred'))
                        return
                    }
                    dispatch(Users.loginSuccess(
                        componentName,
                        user,
                        accessToken
                    ))
                    navigate("/")
                } else if (responseCode.isNotMatchingPassword()) {
                    alert(t('alert_password_is_incorrect'))
                } else if (responseCode.isNotFoundUserId()) {
                    alert(t('alert_email_is_not_registered'))
                } else {
                    alert(t('alert_failed_to_log_in'))
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }
    return <FlexBox
        marginTop={"16px"}
        marginBottom={"12px"}
        height={"52px"}
        width={"100%"}
        border={`1px solid ${Colors.GRAY_4}`}
        backgroundColor={Colors.WHITE}
        borderRadius={"12px"}
        alignItems={"center"}
        justifyContent={"center"}
        cursor={"pointer"}
        onClick={() => {
            ReactGA.event({
                category: "login",
                action: `google_login`
            });
            handleLoginButtonOnClick()
        }}>
        <Image
            width={"24px"}
            height={"24px"}
            src={require("assets/images/icon-google.svg").default}
            alt="google-login-icon"/>
        <NewH6
            marginLeft={"8px"}
            color={Colors.GRAY_7}>{t('google_login')}</NewH6>
    </FlexBox>
})