/**
 * @author jaeho.lee104 on 2023. 08. 23..
 */
import React, {useEffect, useState} from "react";
import {Dialog} from "@mui/material";
import FlexBox from "../styledcomponents/FlexBox";
import NewH3 from "../styledcomponents/text/NewH3";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Colors} from "../styledcomponents/Styles";
import NewP2 from "../styledcomponents/text/NewP2";
import NewH4 from "styledcomponents/text/NewH4";
import Image from "../styledcomponents/Image";
import NewButton from "../styledcomponents/NewButton";
import useTablet from "../uses/UseTablet";
import {useTranslation} from "react-i18next";

export interface PlanIntroDialogProps {
    isOpen: boolean,
    price: string,
    premiumActivated: boolean,
    onClose: (positive: boolean) => void
}

const PlanIntroDialog: React.FC<{
    dialogProps: PlanIntroDialogProps
}> = ({dialogProps}) => {

    const [isOpen, setIsOpen] = useState(false);
    const isTablet = useTablet()
    const {t} = useTranslation()
    const theme = createTheme({
        components: {
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        minWidth: isTablet ? "720px" : "800px",
                        borderRadius: "24px"
                    }
                }
            }
        },
        palette: {
            background: {
                paper: '#fff',
            },
            text: {
                primary: '#000',
                secondary: '#46505A',
            },
            action: {
                active: '#001E3C',
            },
        },
    });

    useEffect(() => {
        setIsOpen(dialogProps.isOpen);
    }, [dialogProps.isOpen]);

    const handleOk = () => {
        if (!isOpen) {
            return;
        }
        dialogProps.onClose(true);
    };

    const handleCancel = () => {
        if (!isOpen) {
            return;
        }
        dialogProps.onClose(false);
    };

    return <>
        <ThemeProvider theme={theme}>
            <Dialog
                open={isOpen}
                onClose={handleCancel}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleOk();
                    }
                }}>
                <FlexBox
                    width={isTablet ? "720px" : "800px"}
                    height={"964px"}
                    padding={"16px 32px"}
                    flexDirection={"column"}>
                    <NewH3
                        color={Colors.GRAY_8}
                        marginTop={"16px"}
                        textAlign={"left"}>
                        {t('plan_intro_title')}
                    </NewH3>
                    <NewP2
                        color={Colors.GRAY_7}
                        marginTop={"16px"}
                        textAlign={"left"}>
                        {t('plan_intro_description')}
                    </NewP2>
                    <Image
                        cursor={"pointer"}
                        position={"absolute"}
                        right={"32px"}
                        top={"32px"}
                        width={"24px"}
                        height={"24px"}
                        src={require("assets/images/ic-dialog-close.svg").default}
                        onClick={() => {
                            dialogProps.onClose(false)
                        }}
                        alt="ic-dialog-close"/>
                    <FlexBox
                        marginTop={"32px"}>
                        <FlexBox
                            border={`1px solid ${Colors.GRAY_2}`}
                            width={"100%"}
                            height={"726px"}
                            borderRadius={"16px"}
                            marginBottom={"16px"}
                            padding={"24px"}
                            flexDirection={"column"}
                            justifyContent={"space-between"}>
                            <FlexBox
                                flexDirection={"column"}>
                                <NewH3
                                    color={Colors.GRAY_8}>
                                    Standard
                                </NewH3>
                                <NewP2
                                    color={Colors.GRAY_7}
                                    marginTop={"4px"}>
                                    {t('plan_standard_description')}
                                </NewP2>
                                <NewH4
                                    color={Colors.MAIN}
                                    marginTop={"24px"}>
                                    {t('free_plan')}
                                </NewH4>
                                <NewH4
                                    color={Colors.GRAY_8}
                                    marginTop={"24px"}>
                                    {t('plan_import_trends')}
                                </NewH4>
                                <FlexBox
                                    backgroundColor={Colors.GRAY_0}
                                    border={`1px solid ${Colors.GRAY_2}`}
                                    borderRadius={"12px"}
                                    flexDirection={"column"}
                                    marginTop={"12px"}
                                    padding={"12px 16px"}>
                                    <FlexBox
                                        alignItems={"center"}>
                                        <Image
                                            width={"16px"}
                                            height={"16px"}
                                            src={require("assets/images/ic-check-2.svg").default}
                                            alt="ic-check"/>
                                        <NewP2
                                            marginLeft={"8px"}
                                            color={Colors.GRAY_7}>
                                            {t('premium_trend_data_1')}
                                        </NewP2>
                                    </FlexBox>
                                    <FlexBox
                                        alignItems={"center"}
                                        marginTop={"12px"}>
                                        <Image
                                            width={"16px"}
                                            height={"16px"}
                                            src={require("assets/images/ic-check-2.svg").default}
                                            alt="ic-check"/>
                                        <NewP2
                                            marginLeft={"8px"}
                                            color={Colors.GRAY_7}>
                                            {t('premium_trend_data_2')}
                                        </NewP2>
                                    </FlexBox>
                                    <FlexBox
                                        alignItems={"center"}
                                        marginTop={"12px"}>
                                        <Image
                                            width={"16px"}
                                            height={"16px"}
                                            src={require("assets/images/ic-check-2.svg").default}
                                            alt="ic-check"/>
                                        <NewP2
                                            marginLeft={"8px"}
                                            color={Colors.GRAY_7}>
                                            {t('premium_trend_data_3')}
                                        </NewP2>
                                    </FlexBox>
                                    <FlexBox
                                        alignItems={"center"}
                                        marginTop={"12px"}>
                                        <Image
                                            width={"16px"}
                                            height={"16px"}
                                            src={require("assets/images/ic-check-2.svg").default}
                                            alt="ic-check"/>
                                        <NewP2
                                            marginLeft={"8px"}
                                            color={Colors.GRAY_7}>
                                            {t('premium_trend_data_4')}
                                        </NewP2>
                                    </FlexBox>
                                </FlexBox>
                            </FlexBox>
                            <NewButton
                                disabled={true}
                                bottom={"24px"}>
                                {t('current_plan')}
                            </NewButton>
                        </FlexBox>
                        <FlexBox
                            border={`1px solid ${Colors.GRAY_2}`}
                            width={"100%"}
                            height={"726px"}
                            marginLeft={"24px"}
                            borderRadius={"16px"}
                            padding={"24px"}
                            flexDirection={"column"}
                            justifyContent={"space-between"}>
                            <FlexBox
                                flexDirection={"column"}>
                                <FlexBox
                                    height={"32px"}
                                    alignItems={"flex-end"}>
                                    <NewH3
                                        color={Colors.GRAY_8}>
                                        Premium
                                    </NewH3>
                                    <Image
                                        alignSelf={"flex-start"}
                                        marginLeft={"4px"}
                                        width={"83px"}
                                        height={"20px"}
                                        src={require("assets/images/ic-most-popular-tag.svg").default}
                                        alt="ic-most-popular-tag"/>
                                </FlexBox>
                                <NewP2
                                    color={Colors.GRAY_7}
                                    marginTop={"4px"}>
                                    {t('plan_premium_description')}
                                </NewP2>
                                <FlexBox
                                    alignItems={"flex-end"}
                                    marginTop={"24px"}>
                                    <NewH4
                                        color={Colors.MAIN}>
                                        {`${t('en_krw_unit')}${dialogProps.price}${t('kr_krw_unit')}`}
                                    </NewH4>
                                    <NewP2
                                        color={Colors.GRAY_6}
                                        marginLeft={"4px"}>
                                        {`/${t('monthly')} ${t('additional_vat')}`}
                                    </NewP2>
                                </FlexBox>
                                <NewH4
                                    color={Colors.GRAY_8}
                                    marginTop={"24px"}>
                                    {t('plan_import_trends')}
                                </NewH4>
                                <FlexBox
                                    backgroundColor={Colors.GRAY_0}
                                    border={`1px solid ${Colors.GRAY_2}`}
                                    borderRadius={"12px"}
                                    flexDirection={"column"}
                                    marginTop={"12px"}
                                    padding={"12px 16px"}>
                                    <FlexBox
                                        alignItems={"center"}>
                                        <Image
                                            width={"16px"}
                                            height={"16px"}
                                            src={require("assets/images/ic-check-2.svg").default}
                                            alt="ic-check"/>
                                        <NewP2
                                            marginLeft={"8px"}
                                            color={Colors.GRAY_7}>
                                            {t('premium_trend_data_1')}
                                        </NewP2>
                                    </FlexBox>
                                    <FlexBox
                                        alignItems={"center"}
                                        marginTop={"12px"}>
                                        <Image
                                            width={"16px"}
                                            height={"16px"}
                                            src={require("assets/images/ic-check-2.svg").default}
                                            alt="ic-check"/>
                                        <NewP2
                                            marginLeft={"8px"}
                                            color={Colors.GRAY_7}>
                                            {t('premium_trend_data_2')}
                                        </NewP2>
                                    </FlexBox>
                                    <FlexBox
                                        alignItems={"center"}
                                        marginTop={"12px"}>
                                        <Image
                                            width={"16px"}
                                            height={"16px"}
                                            src={require("assets/images/ic-check-2.svg").default}
                                            alt="ic-check"/>
                                        <NewP2
                                            marginLeft={"8px"}
                                            color={Colors.GRAY_7}>
                                            {t('premium_trend_data_3')}
                                        </NewP2>
                                    </FlexBox>
                                    <FlexBox
                                        alignItems={"center"}
                                        marginTop={"12px"}>
                                        <Image
                                            width={"16px"}
                                            height={"16px"}
                                            src={require("assets/images/ic-check-2.svg").default}
                                            alt="ic-check"/>
                                        <NewP2
                                            marginLeft={"8px"}
                                            color={Colors.GRAY_7}>
                                            {t('premium_trend_data_4')}
                                        </NewP2>
                                    </FlexBox>
                                </FlexBox>
                                <NewH4
                                    color={Colors.GRAY_8}
                                    marginTop={"24px"}>
                                    {t('plan_scm_data')}
                                </NewH4>
                                <FlexBox
                                    backgroundColor={Colors.GRAY_0}
                                    border={`1px solid ${Colors.GRAY_2}`}
                                    borderRadius={"12px"}
                                    flexDirection={"column"}
                                    marginTop={"12px"}
                                    padding={"12px 16px"}>
                                    <FlexBox
                                        alignItems={"center"}>
                                        <Image
                                            width={"16px"}
                                            height={"16px"}
                                            src={require("assets/images/ic-check-2.svg").default}
                                            alt="ic-check"/>
                                        <NewP2
                                            marginLeft={"8px"}
                                            color={Colors.GRAY_7}>
                                            {t('premium_scm_data_1')}
                                        </NewP2>
                                    </FlexBox>
                                    <FlexBox
                                        alignItems={"center"}
                                        marginTop={"12px"}>
                                        <Image
                                            width={"16px"}
                                            height={"16px"}
                                            src={require("assets/images/ic-check-2.svg").default}
                                            alt="ic-check"/>
                                        <NewP2
                                            marginLeft={"8px"}
                                            color={Colors.GRAY_7}>
                                            {t('premium_scm_data_2')}
                                        </NewP2>
                                    </FlexBox>
                                    <FlexBox
                                        alignItems={"center"}
                                        marginTop={"12px"}>
                                        <Image
                                            width={"16px"}
                                            height={"16px"}
                                            src={require("assets/images/ic-check-2.svg").default}
                                            alt="ic-check"/>
                                        <NewP2
                                            marginLeft={"8px"}
                                            color={Colors.GRAY_7}>
                                            {t('premium_scm_data_3')}
                                        </NewP2>
                                    </FlexBox>
                                    <FlexBox
                                        alignItems={"center"}
                                        marginTop={"12px"}>
                                        <Image
                                            width={"16px"}
                                            height={"16px"}
                                            src={require("assets/images/ic-check-2.svg").default}
                                            alt="ic-check"/>
                                        <NewP2
                                            marginLeft={"8px"}
                                            color={Colors.GRAY_7}>
                                            {t('premium_scm_data_4')}
                                        </NewP2>
                                    </FlexBox>
                                    <FlexBox
                                        alignItems={"center"}
                                        marginTop={"12px"}>
                                        <Image
                                            width={"16px"}
                                            height={"16px"}
                                            src={require("assets/images/ic-check-2.svg").default}
                                            alt="ic-check"/>
                                        <NewP2
                                            marginLeft={"8px"}
                                            color={Colors.GRAY_7}>
                                            {t('premium_scm_data_5')}
                                        </NewP2>
                                    </FlexBox>
                                </FlexBox>
                            </FlexBox>
                            <NewButton
                                disabled={dialogProps.premiumActivated}
                                bottom={"24px"}
                                onClick={() => {
                                    handleOk()
                                }}>
                                {dialogProps.premiumActivated ? `${t('current_plan')}` : `${t('do_upgrade')}`}
                            </NewButton>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            </Dialog>
        </ThemeProvider>
    </>
}
export default PlanIntroDialog