/**
 * @author jaeho.lee104 on 2023. 08. 10..
 */
import FlexBox from "../styledcomponents/FlexBox";
import {Colors} from "../styledcomponents/Styles";
import NewH2 from "../styledcomponents/text/NewH2";
import NewP2 from "../styledcomponents/text/NewP2";
import Image from "../styledcomponents/Image";
import React, {useEffect, useRef, useState} from "react";
import TextField from "../components/TextField";
import {FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent} from "@mui/material";
import styled from "styled-components";
import Text from "../styledcomponents/Text";
import useMobile from "../uses/UseMobile";
import {CountryModel, DEFAULT_COUNTRIES} from "../model/CountryModel";
import {KeyboardUtils} from "../utils/KeyboardUtils";
import NewH5 from "../styledcomponents/text/NewH5";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {RootState} from "../modules";
import {UsersAPI} from "../network/UsersAPI";
import {UpdateUserInfoRequestBody} from "../model/UpdateUserInfoRequestBody";
import {ResponseCodes} from "../network/ResponseCodes";
import {Users} from "../modules/Users";
import useTablet from "../uses/UseTablet";
import {ExpandMoreOutlined} from "@mui/icons-material";
import NewPasswordChangeDialog from "../dialog/NewPasswordChangeDialog";
import PasswordChangeBottomSheet from "../dialog/PasswordChangeBottomSheet";
import SimpleDialog, {ButtonType} from "../dialog/SimpleDialog";
import NewButton from "../styledcomponents/NewButton";
import {CommonApi} from "../network/CommonApi";

// @ts-ignore

const Label = styled(Text)`
  margin-top: 24px;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: ${Colors.GRAY_7};
`

const UserFragment = () => {

    const {user} = useSelector(
        (state: RootState) => state.usersReducer, shallowEqual,
    );
    const {language} = useSelector((state: RootState) => state.configurationsReducer, shallowEqual);
    const [name, setName] = useState("")
    const [nameError, setNameError] = useState(false)
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(false)
    const [company, setCompany] = useState("")
    const [companyError, setCompanyError] = useState(false)
    const companyRef = useRef<HTMLInputElement>(null)
    const [companyCountryCode, setCompanyCountryCode] = useState("KR")
    const [countries, setCountries] = useState<Array<CountryModel>>(DEFAULT_COUNTRIES)

    const [phoneNumber, setPhoneNumber] = useState("")
    const phoneNumberRef = useRef<HTMLInputElement>(null)
    const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState("82")
    const [phoneNumberError, setPhoneNumberError] = useState(false)
    const [isPasswordChangeDialogOpen, setIsPasswordChangeDialogOpen] = useState(false)
    const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] = useState(false)

    const {t} = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch<any>()
    const isMobile = useMobile()
    const isTablet = useTablet()
    const countryLabel = t('country')

    useEffect(() => {
        if (user?.name) {
            setName(user?.name)
        }
        if (user?.userId) {
            setEmail(user?.userId)
        }
        if (user?.company) {
            setCompany(user?.company)
        }
        if (user?.phoneNumber) {
            setPhoneNumber(user?.phoneNumber)
        }
    }, [user])

    useEffect(() => {
        CommonApi.getCountries(language)
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                const countries = response.data.data
                if (responseCode.isSuccess() && countries && countries.length > 0) {
                    setCountries(countries)
                }
            })
    }, [language])

    function handleDeleteUser() {
        dispatch(Users.deleteUser())
        navigate("/")
    }

    async function handleSaveClicked() {
        if (!name) {
            setNameError(true)
            return
        }
        if (!company) {
            setCompanyError(true)
            return;
        }
        if (phoneNumber.length !== 11) {
            setPhoneNumberError(true)
            return;
        }
        const updateSucceed = await UsersAPI.updateUser(new UpdateUserInfoRequestBody(
            name,
            company,
            companyCountryCode,
            phoneNumber,
            phoneNumberCountryCode,
            ""
        )).then(res => {
            const responseCode = ResponseCodes.of(res.data.code)
            if (responseCode.isSuccess()) {
                alert("저장되었습니다.")
                return true
            } else {
                alert(t('alert_a_temporary_error_has_occurred'))
                return false
            }
        }).catch(e => {
            alert(t('alert_a_temporary_error_has_occurred'))
            return false
        })
        if (updateSucceed) {
            UsersAPI.getUserOrNull()
                .then(userOrNull => {
                    if (userOrNull) {
                        dispatch(Users.updateUser(userOrNull))
                    }
                })
        }
    }

    return <FlexBox
        flexDirection={"column"}
        width={"100%"}
        maxWidth={isTablet ? "100%" : "520px"}
        margin={"0px 24px"}
        padding={"16px 32px"}
        backgroundColor={Colors.WHITE}
        border={`1px solid ${Colors.GRAY_2}`}
        boxShadow={"0px 2px 40px 0px rgba(0, 0, 0, 0.05)"}
        borderRadius={"16px"}
        height={"100%"}
        onChange={(e) => {
            if (nameError) {
                setNameError(false)
            }
            if (phoneNumberError) {
                setPhoneNumberError(false)
            }
            if (companyError) {
                setCompanyError(false)
            }
        }}>

        <NewH2
            marginTop={"16px"}
            color={Colors.GRAY_8}>
            {t('user_account_info')}
        </NewH2>
        <NewP2
            marginTop={"12px"}
            marginBottom={"16px"}
            color={Colors.GRAY_7}>
            {t('user_info_safety')}
        </NewP2>

        <FlexBox
            alignSelf={"center"}
            justifyContent={"center"}
            width={"fit-content"}>
            <Image
                alignSelf={"center"}
                width={"120px"}
                height={"120px"}
                marginTop={"32px"}
                src={require("assets/images/profile-avatar.svg").default}
                alt="avatar"/>
            <Image
                width={"32px"}
                height={"32px"}
                position={"absolute"}
                right={"0px"}
                bottom={"0px"}
                cursor={"pointer"}
                onClick={() => {
                    alert(t('alert_coming_soon'))
                }}
                src={require("assets/images/ic-avatar-upload.svg").default}
                alt="avatar"/>
        </FlexBox>
        <TextField
            labelFontSize={"14px"}
            labelFontWight={500}
            labelGap={"8px"}
            labelColor={Colors.GRAY_7}
            width={"100%"}
            label={`${t('name')}`}
            error={nameError}
            errorText={`${t('alert_please_enter_your_name2')}`}
            marginTop={"24px"}
            value={name}
            onChange={(e) => {
                setName(e.target.value)
            }}
            type={"text"}
        />
        <TextField
            disabled={true}
            labelFontSize={"14px"}
            labelFontWight={500}
            labelGap={"8px"}
            labelColor={Colors.GRAY_7}
            width={"100%"}
            label={`${t('business_email')}`}
            marginTop={"24px"}
            value={email}
            onChange={(e) => {
                setEmail(e.target.value)
            }}
            type={"text"}
        />
        <Label>{t('company')}</Label>
        <FlexBox width={"100%"}
                 marginTop={"16px"}
                 alignItems={"center"}
                 flexDirection={isMobile ? "column" : "row"}>

            <FormControl sx={{
                minWidth: 180,
                width: isMobile ? "100%" : undefined,
                alignSelf: "flex-start"
            }}>
                <InputLabel
                    sx={{fontSize: "16px"}}
                    id="select-country-label-id">{countryLabel}</InputLabel>
                <Select
                    IconComponent={(props) => <ExpandMoreOutlined {...props}/>}
                    labelId={"select-country-label-id"}
                    input={<OutlinedInput
                        sx={{fontSize: "16px"}}
                        label={countryLabel}/>}
                    value={companyCountryCode}
                    onChange={(e: SelectChangeEvent<typeof companyCountryCode>) => {
                        setCompanyCountryCode(e.target.value)
                    }}
                    sx={{
                        fontSize: "16px",
                        borderRadius: "12px",
                        color: Colors.GRAY_8,
                        fontWeight: 500,
                        height: "52px",
                        "&& fieldset": {
                            border: `1px solid ${Colors.GRAY_4}`
                        },
                        "&:hover": {
                            "&& fieldset": {
                                border: `1px solid ${Colors.CTA_HOVER}`
                            }
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: `2px solid ${Colors.MAIN}`
                        },

                    }}>
                    {countries.map((country) => (
                        <MenuItem
                            sx={{fontSize: "16px"}}
                            value={country.countryCode}>{country.countryName}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                width={"100%"}
                error={companyError}
                errorText={`${t('alert_please_enter_the_company_name')}`}
                labelVisible={false}
                marginLeft={isMobile ? "0px" : "10px"}
                marginTop={isMobile ? "10px" : "0px"}
                value={company}
                onChange={(e) => {
                    setCompany(e.target.value)
                }}
                type={"text"}
            />
        </FlexBox>
        <Label>{t('phone')}</Label>
        <FlexBox width={"100%"}
                 marginTop={"16px"}
                 alignItems={"center"}
                 flexDirection={isMobile ? "column" : "row"}>

            <FormControl sx={{
                minWidth: 180,
                width: isMobile ? "100%" : undefined,
                alignSelf: "flex-start"
            }}>
                <InputLabel
                    sx={{fontSize: "16px"}}
                    id="select-country-number-label-id">{countryLabel}</InputLabel>
                <Select
                    IconComponent={(props) => <ExpandMoreOutlined {...props}/>}
                    labelId={"select-country-number-label-id"}
                    input={<OutlinedInput
                        sx={{fontSize: "16px"}}
                        label={countryLabel}/>}
                    value={phoneNumberCountryCode}
                    onChange={(e: SelectChangeEvent<typeof phoneNumberCountryCode>) => {
                        setPhoneNumberCountryCode(e.target.value)
                    }}
                    sx={{
                        alignItems: "center !important",
                        fontSize: "16px",
                        borderRadius: "12px",
                        color: Colors.GRAY_8,
                        fontWeight: 500,
                        height: "52px",
                        "&& fieldset": {
                            border: `1px solid ${Colors.GRAY_4}`,
                        },
                        "&:hover": {
                            "&& fieldset": {
                                border: `1px solid ${Colors.CTA_HOVER}`
                            }
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: `2px solid ${Colors.MAIN}`
                        },

                    }}>
                    {countries.map((country) => (
                        <MenuItem
                            sx={{
                                fontSize: "16px",
                                color: Colors.GRAY_8
                            }}
                            value={country.countryNumber}>{country.countryName}(+{country.countryNumber})</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                width={"100%"}
                error={phoneNumberError}
                errorText={`${t('alert_please_enter_your_phone_number2')}`}
                labelVisible={false}
                maxLength={11}
                marginLeft={isMobile ? "0px" : "10px"}
                marginTop={isMobile ? "10px" : "0px"}
                value={phoneNumber}
                onChange={(e) => {
                    setPhoneNumber(KeyboardUtils.clearNumber(e.target.value))
                }}
                type={"tel"}
            />
        </FlexBox>
        <NewButton
            colorType={0}
            marginTop={"32px"}
            width={"100%"}
            height={"52px"}
            fontSize={"16px"}
            lineHeight={"150%"}
            borderRadius={"12px"}
            onClick={() => {
                // noinspection JSIgnoredPromiseFromCall
                handleSaveClicked()
            }}>
            {t('save')}
        </NewButton>

        <FlexBox
            alignItems={"center"}
            marginTop={"32px"}
            cursor={"pointer"}
            onClick={() => {
                setIsPasswordChangeDialogOpen(true)
            }}>
            <NewH5
                color={Colors.MAIN}>{t('password_change')}</NewH5>
            <Image
                src={require("assets/images/ic-right-arrow-blue.svg").default}
                alt="avatar"/>
        </FlexBox>
        <NewP2
            color={Colors.GRAY_7}
            marginTop={"8px"}>
            {t('password_change_suggest')}
        </NewP2>
        <FlexBox
            alignItems={"center"}
            marginTop={"32px"}
            cursor={"pointer"}
            onClick={() => {
                setIsDeleteUserDialogOpen(true)
            }}>
            <NewH5
                color={Colors.GRAY_6}>
                {t('unregister_account')}
            </NewH5>
            <Image
                src={require("assets/images/ic-right-arrow-gray.svg").default}
                alt="ic-right-arrow-unregister"/>
        </FlexBox>
        <NewP2
            color={Colors.GRAY_7}
            marginBottom={"16px"}
            marginTop={"8px"}>
            {t('unregister_account_description')}
        </NewP2>
        {!isMobile && <NewPasswordChangeDialog dialogProps={{
            isOpen: isPasswordChangeDialogOpen,
            onClose: () => {
                setIsPasswordChangeDialogOpen(false)
            }
        }}/>}
        {isMobile && <PasswordChangeBottomSheet dialogProps={{
            isOpen: isPasswordChangeDialogOpen,
            onClose: () => {
                setIsPasswordChangeDialogOpen(false)
            }
        }}/>}
        {isDeleteUserDialogOpen && <SimpleDialog dialogProps={{
            isOpen: isDeleteUserDialogOpen,
            title: t('unregister_check'),
            description: "",
            positiveText: `${t('unregister')}`,
            positiveColorType: 3,
            buttonType: ButtonType.BOTH,
            onClose: (positive) => {
                setIsDeleteUserDialogOpen(false)
                if (positive) {
                    handleDeleteUser()
                }
            }
        }}/>}
    </FlexBox>
}

export default UserFragment;