/**
 * @author jaeho.lee104 on 2024. 01. 16..
 */
import React, {useEffect, useState} from "react";
import FlexBox from "../styledcomponents/FlexBox";
import NewH2 from "../styledcomponents/text/NewH2";
import NewH7 from "../styledcomponents/text/NewH7";
import {Colors} from "../styledcomponents/Styles";
import SelectBox from "../components/SelectBox";
import {CodeModel} from "../model/CodeModel";
import NTextField from "../components/NTextField";
import Image from "../styledcomponents/Image";
import NewP1 from "../styledcomponents/text/NewP1";
import NewH3 from "../styledcomponents/text/NewH3";
import NewP4 from "../styledcomponents/text/NewP4";
import NewH6 from "../styledcomponents/text/NewH6";
import Button from "../styledcomponents/Button";
import Text from "../styledcomponents/Text";
import NewH5 from "../styledcomponents/text/NewH5";
import NewButton from "../styledcomponents/NewButton";
import {useNavigate} from "react-router-dom";
import {SourcingListItem} from "../model/SourcingListItem";
import {SourcingAPI} from "../network/SourcingAPI";
import Flag from "react-flagkit";
import {TextUtils} from "../utils/TextUtils";
import {useTranslation} from "react-i18next";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../modules";

const SourcingFragment = () => {

    const searchTypes: Array<CodeModel> = [
        {
            name: "Products",
            code: "product"
        },
        {
            name: "HsCode",
            code: "hscode"
        }
    ]
    const {accessToken} = useSelector((state: RootState) => state.usersReducer, shallowEqual);
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [searchType, setSearchType] = useState("Products")
    const [searchInput, setSearchInput] = useState("")
    const [selectedCategoryListType, setSelectedCategoryListType] = useState(0)
    const categoryListTypes = [
        "ALL",
        "BEST TRADER",
        "MOST VIEWED",
        "TOP BRANDS"
    ]
    const [sourcingItems, setSourcingItems] = useState<Array<SourcingListItem>>([])
    const [selectedCategory, setSelectedCategory] = useState(0)
    const sampleCategories = [
        {
            index: 0,
            name: `${t('category_all')}`
        },
        {
            index: 1,
            name: `${t('category_meat')}`
        },
        {
            index: 2,
            name: `${t('category_fruits')}`
        },
        {
            index: 3,
            name: `${t('category_vegetables')}`
        },
        {
            index: 4,
            name: `${t('category_dairy')}`
        },
        {
            index: 5,
            name: `${t('category_grains')}`
        },
        {
            index: 6,
            name: `${t('category_plant_based_oil')}`
        },
        {
            index: 7,
            name: `${t('category_seafood')}`
        },
        {
            index: 8,
            name: `${t('category_processed_foods')}`
        }
    ]

    useEffect(() => {
        SourcingAPI.getSourcingItems()
            .then(response => {
                setSourcingItems(response.data.data || [])
            })
    }, [])

    if (!accessToken) {
        return <></>
    }
    return (
        <FlexBox
            width={"100%"}
            height={"100%"}>
            {/* TODO: navigation bar */}
            <FlexBox /* content area */
                margin={"40px auto"}
                width={"1200px"}
                flexDirection={"column"}>
                <FlexBox
                    background={"linear-gradient(88.09deg, #E0EDFF 0.99%, #409CE2 99.1%);"}
                    boxShadow={"0px 4px 8px 0px #00000014;"}
                    borderRadius={"16px"}
                    paddingLeft={"64px"}
                    flexDirection={"row"}

                    height={"340px"}>
                    <FlexBox
                        flexDirection={"column"}>
                        <NewH2
                            color={"#317EBA"}
                            marginTop={"80px"}>
                            {t('start_global_sourcing_title')}
                        </NewH2>
                        <NewH7
                            marginTop={"16px"}
                            color={Colors.GRAY_6}>
                            {t('start_global_sourcing_subtitle')}
                        </NewH7>
                        <FlexBox
                            marginTop={"48px"}>
                            <SelectBox items={searchTypes}
                                       width={"200px"}
                                       labelVisible={false}
                                       value={searchType}
                                       onChange={(e) => {
                                           setSearchType(e.target.value)
                                       }}
                            />
                            <NTextField
                                marginLeft={"12px"}
                                width={"420px"}
                                labelVisible={false}
                                value={searchInput}
                                hint={`${t('sourcing_search_hint')}`}
                                enabledEndAdornment={true}
                                endAdornment={<Image
                                    width={"24px"}
                                    height={"24px"}
                                    cursor={"pointer"}
                                    src={require("assets/images/ic-search-24.svg").default}
                                    alignSelf={"center"}
                                    alt="search-icon"/>}
                                onChange={(e) => {
                                    setSearchInput(e.target.value)
                                }}
                                type={"text"}
                            />
                        </FlexBox>
                    </FlexBox>
                    <Image
                        marginLeft={"56px"}
                        width={"411px"}
                        height={"290px"}
                        src={require("assets/images/image-sourcing-cart-411-290.png")}
                        alignSelf={"center"}
                        alt="cart-image"/>
                </FlexBox>
                <FlexBox
                    marginTop={"16px"}
                    height={"217px"}
                    justifyContent={"space-between"}>
                    <FlexBox
                        width={"389px"}
                        height={"100%"}
                        boxShadow={"0px 4px 8px 0px #00000014;"}
                        border={`1px solid ${Colors.GRAY_2}`}
                        borderRadius={"16px"}
                        padding={"24px 32px"}
                        justifyContent={"space-between"}
                        flexDirection={"row"}>
                        <FlexBox
                            flexDirection={"column"}
                            justifyContent={"space-between"}>
                            <FlexBox
                                flexDirection={"column"}>
                                <NewP1
                                    color={Colors.CTA_PRESSED}>
                                    Latest Update
                                </NewP1>
                                <NewH3
                                    marginTop={"6px"}
                                    color={Colors.GRAY_8}>
                                    {t('sourcing_income_trend')}
                                </NewH3>
                                <NewP4
                                    marginTop={"12px"}
                                    color={Colors.GRAY_7}>
                                    {t('sourcing_income_trend_subtitle')}
                                </NewP4>
                            </FlexBox>
                            <FlexBox
                                cursor={"pointer"}
                                alignItems={"center"}>
                                <NewP1
                                    color={Colors.MAIN}>
                                    {t('go_analyze')}
                                </NewP1>
                                <Image
                                    width={"24px"}
                                    height={"24px"}
                                    src={require("assets/images/ic-arrow-right-main-24.svg").default}
                                    alt="sourcing-income-trend-analytics-arrow"/>
                            </FlexBox>
                        </FlexBox>
                        <Image
                            marginBottom={"8px"}
                            width={"121px"}
                            height={"110px"}
                            src={require("assets/images/image-sourcing-analytics-1.png")}
                            alignSelf={"flex-end"}
                            alt="sourcing-income-trend-analytics"/>
                    </FlexBox>
                    <FlexBox
                        width={"389px"}
                        height={"100%"}
                        boxShadow={"0px 4px 8px 0px #00000014;"}
                        border={`1px solid ${Colors.GRAY_2}`}
                        borderRadius={"16px"}
                        padding={"24px 32px"}
                        justifyContent={"space-between"}
                        flexDirection={"row"}>
                        <FlexBox
                            flexDirection={"column"}
                            justifyContent={"space-between"}>
                            <FlexBox
                                flexDirection={"column"}>
                                <NewP1
                                    color={Colors.CTA_PRESSED}>
                                    Latest Update
                                </NewP1>
                                <NewH3
                                    marginTop={"6px"}
                                    color={Colors.GRAY_8}>
                                    {t('sourcing_scm_data')}
                                </NewH3>
                                <NewP4
                                    marginTop={"12px"}
                                    color={Colors.GRAY_7}>
                                    {t('sourcing_scm_data_subtitle')}
                                </NewP4>
                            </FlexBox>
                            <FlexBox
                                cursor={"pointer"}
                                alignItems={"center"}>
                                <NewP1
                                    color={Colors.MAIN}>
                                    {t('go_analyze')}
                                </NewP1>
                                <Image
                                    width={"24px"}
                                    height={"24px"}
                                    src={require("assets/images/ic-arrow-right-main-24.svg").default}
                                    alt="sourcing-company-analytics-arrow-2"/>
                            </FlexBox>
                        </FlexBox>
                        <Image
                            marginBottom={"8px"}
                            width={"140px"}
                            height={"112px"}
                            src={require("assets/images/image-sourcing-analytics-2.png")}
                            alignSelf={"flex-end"}
                            alt="sourcing-company-analytics"/>
                    </FlexBox>
                    <FlexBox
                        width={"389px"}
                        height={"100%"}
                        boxShadow={"0px 4px 8px 0px #00000014;"}
                        border={`1px solid ${Colors.GRAY_2}`}
                        borderRadius={"16px"}
                        padding={"24px 32px"}
                        justifyContent={"space-between"}
                        flexDirection={"row"}>
                        <FlexBox
                            flexDirection={"column"}
                            justifyContent={"space-between"}>
                            <FlexBox
                                flexDirection={"column"}>
                                <NewP1
                                    color={Colors.CTA_PRESSED}>
                                    Latest Update
                                </NewP1>
                                <NewH3
                                    marginTop={"6px"}
                                    color={Colors.GRAY_8}>
                                    {t('sourcing_trade_solution')}
                                </NewH3>
                                <NewP4
                                    marginTop={"12px"}
                                    color={Colors.GRAY_7}>
                                    {t('sourcing_trade_solution_subtitle')}
                                </NewP4>
                            </FlexBox>
                            <FlexBox
                                cursor={"pointer"}
                                alignItems={"center"}>
                                <NewP1
                                    color={Colors.MAIN}>
                                    {t('sourcing_start_solution')}
                                </NewP1>
                                <Image
                                    width={"24px"}
                                    height={"24px"}
                                    src={require("assets/images/ic-arrow-right-main-24.svg").default}
                                    alt="sourcing-solution-arrow"/>
                            </FlexBox>
                        </FlexBox>
                        <Image
                            marginBottom={"8px"}
                            width={"121px"}
                            height={"115px"}
                            src={require("assets/images/image-sourcing-analytics-3.png")}
                            alignSelf={"flex-end"}
                            alt="sourcing-income-trend-analytics"/>
                    </FlexBox>
                </FlexBox>
                <NewH3
                    color={Colors.GRAY_8}
                    marginTop={"80px"}>
                    {t('browse_products_by_category')}
                </NewH3>
                <FlexBox
                    marginTop={"24px"}>
                    {sampleCategories.map((item, index) => {
                        return <FlexBox
                            padding={"8px 16px"}
                            borderRadius={"8px"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            background={selectedCategory === index ? Colors.MAIN : Colors.WHITE}
                            marginRight={"6px"}
                            cursor={"pointer"}
                            border={`1px solid ${Colors.GRAY_4}`}
                            onClick={() => {
                                setSelectedCategory(index)
                            }}>
                            <NewH7
                                color={selectedCategory === index ? Colors.WHITE : Colors.GRAY_7}>
                                {`# ${item.name}`}
                            </NewH7>
                        </FlexBox>
                    })}
                </FlexBox>
                <FlexBox
                    marginTop={"24px"}>
                    {categoryListTypes.map((item, index) => {
                        return <FlexBox
                            padding={"4px 0px"}
                            marginRight={"24px"}
                            cursor={"pointer"}
                            borderBottom={selectedCategoryListType === index ? `2px solid ${Colors.MAIN}` : undefined}
                            onClick={() => {
                                setSelectedCategoryListType(index)
                            }}>
                            <NewH7
                                fontWeight={selectedCategoryListType === index ? 500 : 400}
                                color={selectedCategoryListType === index ? Colors.MAIN : Colors.GRAY_7}>
                                {item}
                            </NewH7>
                        </FlexBox>
                    })}
                </FlexBox>
                <FlexBox
                    width={"1200px"}
                    marginTop={"24px"}
                    flexWrap={"wrap"}>
                    {sourcingItems.map((item, index) => {
                        return <FlexBox
                            cursor={"pointer"}
                            onClick={() => {
                                window.open(`/sourcing-detail?itemId=${item.itemId}&companyId=${item.companyId}`)
                            }}
                            marginTop={index >= 4 ? "40px" : "0px"}
                            marginLeft={index % 4 === 0 ? "0px" : "16px"}
                            flexDirection={"column"}
                            width={"288px"}
                            height={"502px"}
                            overflow={"hidden"}
                            borderRadius={"16px"}
                            boxShadow={"0px 4px 8px 0px #00000014;"}
                            border={`1px solid ${Colors.GRAY_3}`}>
                            <FlexBox
                                minHeight={"288px"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                padding={"24px"}
                                background={Colors.GRAY_0}>
                                <Text
                                    position={"absolute"}
                                    top={"20px"}
                                    zIndex={10}
                                    left={"20px"}
                                    fontWeight={700}
                                    fontSize={"12px"}
                                    borderRadius={"4px"}
                                    padding={"2px 10px"}
                                    color={Colors.WHITE}
                                    backgroundColor={Colors.SECONDARY_01}>
                                    BEST
                                </Text>
                                <Image
                                    objectFit={"contain"}
                                    width={"100%"}
                                    height={"100%"}
                                    src={item.itemImageUrl}
                                    alignSelf={"center"}
                                    alt={`image-${item.itemImageId}`}/>
                            </FlexBox>
                            <FlexBox
                                padding={"20px"}
                                height={"100%"}
                                width={"100%"}
                                flexDirection={"column"}>
                                <FlexBox
                                    alignItems={"center"}>
                                    <Flag
                                        style={{
                                            borderRadius: "2px",
                                            clipPath: "border-box",
                                        }}
                                        country={item.originCode}
                                        size={24}/>
                                    <NewP4
                                        overflow={"hidden"}
                                        whiteSpace={"nowrap"}
                                        textOverflow={"ellipsis"}
                                        width={"100%"}
                                        marginLeft={'8px'}
                                        color={Colors.GRAY_6}>
                                        {item.originName}
                                    </NewP4>
                                </FlexBox>
                                <NewH7
                                    width={"100%"}
                                    marginTop={'8px'}
                                    overflow={"hidden"}
                                    whiteSpace={"nowrap"}
                                    textOverflow={"ellipsis"}
                                    color={Colors.GRAY_8}>
                                    {item.itemName}
                                </NewH7>
                                <FlexBox
                                    marginTop={"16px"}
                                    alignItems={"center"}>
                                    <NewH5
                                        color={Colors.MAIN}>
                                        US$ {item.itemPrice} ~
                                    </NewH5>
                                    <NewP1
                                        marginLeft={"8px"}
                                        color={Colors.GRAY_6}>
                                        per {TextUtils.packagingUnitCodeToUiText(item.itemPriceUnitCode)}
                                    </NewP1>
                                </FlexBox>
                                <NewButton
                                    border={`1px solid ${Colors.SECONDARY_PRESSED}`}
                                    colorType={5}
                                    width={'100%'}
                                    fontSize={"16px"}
                                    fontWeight={500}
                                    height={"44px"}
                                    marginTop={"16px"}>
                                    {t('get_quotation')}
                                </NewButton>
                            </FlexBox>
                        </FlexBox>
                    })}
                </FlexBox>
                <FlexBox
                    margin={"80px 0px"}
                    padding={"48px 80px"}
                    height={"256px"}
                    borderRadius={"16px"}
                    boxShadow={"0px 4px 8px 0px #00000014;"}
                    justifyContent={"space-between"}
                    background={"linear-gradient(89.02deg, #20D3FE 0.51%, #8E3BF0 88.34%, #FF3EEC 109.44%);"}>
                    <FlexBox
                        flexDirection={"column"}>
                        <NewH2
                            color={Colors.WHITE}>
                            {t('looking_for_import_products')}
                        </NewH2>
                        <NewH6
                            marginTop={"12px"}
                            color={Colors.WHITE}>
                            {t('check_product_and_get_a_quotation')}
                        </NewH6>
                        <Button
                            borderRadius={"12px"}
                            marginTop={"32px"}
                            width={"200px"}
                            border={`1px solid ${Colors.WHITE}`}
                            fontSize={"16px"}
                            padding={"14px 0px"}
                            color={Colors.WHITE}
                            backgroundColor={Colors.TRANSPARENT}>
                            {t('learn_more')}
                        </Button>
                    </FlexBox>
                    <Image
                        width={"143px"}
                        height={"154px"}
                        src={require("assets/images/image-big-search.png")}
                        alignSelf={"center"}
                        alt="big-search-image"/>
                </FlexBox>
            </FlexBox>
        </FlexBox>
    )
}
export default SourcingFragment;