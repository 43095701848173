/**
 * @author jaeho.lee104 on 2023. 04. 03..
 */

export class ResponseCode {
    code: string
    message: string

    constructor(code: string, message: string) {
        this.code = code;
        this.message = message;
    }

    isSuccess(): boolean {
        return this.code === ResponseCodes.SUCCESS.code
    }

    isRefreshTokenExpired(): boolean {
        return this.code === ResponseCodes.REFRESH_TOKEN_EXPIRED.code
    }

    isAccessTokenExpired(): boolean {
        return this.code === ResponseCodes.ACCESS_TOKEN_EXPIRED.code
    }

    isAccessTokenInvalid(): boolean {
        return this.code === ResponseCodes.ACCESS_TOKEN_IS_INVALID.code
    }

    isNeedLogin(): boolean {
        return this.code === ResponseCodes.NEED_LOGIN.code
    }

    isNotMatchingPassword(): boolean {
        return this.code === ResponseCodes.PASSWORD_NOT_MATCH.code
    }

    isNotMatchingNewPasswordCheck(): boolean {
        return this.code === ResponseCodes.NEW_PASSWORD_CHECK_ERROR.code
    }

    isNotFoundUserId(): boolean {
        return this.code === ResponseCodes.NOT_FOUND_USER_ID.code
    }

    isPasswordResetKeyExpired(): boolean {
        return this.code === ResponseCodes.PASSWORD_RESET_KEY_EXPIRED.code
    }

    isAlreadyRegisteredEmail(): boolean {
        return this.code === ResponseCodes.ALREADY_REGISTERED_EMAIL.code
    }

    isIncorrectInformation(): boolean {
        return this.code === ResponseCodes.INCORRECT_INFORMATION.code
    }

    isNotFoundInformation(): boolean {
        return this.code === ResponseCodes.NOT_FOUND_INFORMATION.code
    }

    toString() {
        return `code: ${this.code}, message: ${this.message}`
    }

}

export class ResponseCodes {
    static SUCCESS = new ResponseCode(
        "0000",
        "Success"
    )
    static EMAIL_CHECK_ERROR = new ResponseCode(
        "0001",
        "Email Check error."
    )
    static PASSWORD_NOT_MATCH = new ResponseCode(
        "0002",
        "Check password."
    ) //비밀번호 맞지 않음
    static NOT_CONFIRMED_EMAIL = new ResponseCode(
        "0003",
        "Not confirmed email check."
    ) //이메일 컨펌 안됨. (회원가입중)
    static NOT_FOUND_USER_ID = new ResponseCode(
        "0004",
        "Check User ID."
    ) //유저 ID를 찾을 수 없음
    static NEW_PASSWORD_CHECK_ERROR = new ResponseCode(
        "0005",
        "New password and Confirm new password are not the same."
    ) // 새비밀번호와 새비밀번호 확인이 다른 경우
    static PASSWORD_RESET_KEY_EXPIRED = new ResponseCode(
        "0006",
        "The key has already expired."
    ) // 비밀번호 찾기 KEY 만료시
    static ALREADY_REGISTERED_EMAIL = new ResponseCode(
        "0007",
        "This is an already registered email."
    ) // 이미 가입된 이메일
    static NO_CORRESPONDING_API_PERMISSIONS = new ResponseCode(
        "0095",
        "No corresponding API permissions."
    )
    static ACCESS_TOKEN_IS_INVALID = new ResponseCode(
        "0096",
        "Access Token is invalid."
    ) //쿠키 토큰 검증 실패
    static REFRESH_TOKEN_EXPIRED = new ResponseCode(
        "0097",
        "Refresh token expired."
    ) //refresh token 만료
    static ACCESS_TOKEN_EXPIRED = new ResponseCode(
        "0098",
        "Access token expired."
    ) //access token 만료
    static NEED_LOGIN = new ResponseCode(
        "0099",
        "Access denied. Need login"
    ) //로그인 필요
    static PAYMENT_DB_SAVE_ERROR = new ResponseCode(
        "1000",
        "Payment DB save Error"
    ) //결제 요청.
    static PAYMENT_IN_PROGRESS = new ResponseCode(
        "1002",
        "Payment in progress"
    )
    static PAYMENT_SUCCESS = new ResponseCode(
        "1003",
        "Payment success"
    )
    static PAYMENT_FAILED = new ResponseCode(
        "1004",
        "Payment failed"
    )
    static PAYMENT_CLOSE = new ResponseCode(
        "1005",
        "Payment close"
    )
    static PAYMENT_ALREADY_CANCELED = new ResponseCode(
        "1006",
        "Payment already canceled"
    )
    static IRREVOCABLE_PAYMENT = new ResponseCode(
        "1007",
        "irrevocable payment"
    )
    static INCORRECT_INFORMATION = new ResponseCode(
        "9995",
        "The information entered is incorrect."
    )
    static NOT_FOUND_INFORMATION = new ResponseCode(
        "9996",
        "Not found information."
    )
    static SERVER_ERROR = new ResponseCode(
        "9999",
        "Server error"
    ) //서버 에러 발생

    static of(code?: string): ResponseCode {
        return Object.values(ResponseCodes).find((it) => it.code === code) || new ResponseCode(code || "999999", "unspecified code")
    }

}