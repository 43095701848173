import React, {useEffect, useState} from "react";
import FlexBox from "../../styledcomponents/FlexBox";
import {Colors} from "../../styledcomponents/Styles";
import HorizontalDivider from "../../styledcomponents/HorizontalDivider";
import Box from "../../styledcomponents/Box";
import Text from "../../styledcomponents/Text";
import {RenderingByState} from "../RenderingByState";
import {FormatUtils} from "../../utils/FormatUtils";
import {Popover} from "antd";
import {Fade} from "react-awesome-reveal";
import useTablet from "../../uses/UseTablet";
import "./RankChart.css";
import {CommonProps} from "../../styledcomponents/CommonComponent";

interface RankChartProps extends CommonProps {
    title: string
    hsCodeStandard: string
    representColor: string
    unitDescription: string
    showInfo: boolean
    infoContent: string
    rankItems: Array<RankItem> | null
}

export type RankItem = {
    title: string
    value: number
    unit: string
}

const RankChart: React.FC<RankChartProps> = ({
                                                 title,
                                                 hsCodeStandard,
                                                 representColor,
                                                 unitDescription,
                                                 showInfo,
                                                 infoContent,
                                                 rankItems,
                                                 ...props
                                             }) => {

    const [total, setTotal] = useState(0)
    const isTablet = useTablet()
    useEffect(() => {
        if (rankItems) {
            const sum = rankItems.reduce<number>(
                (previousValue, currentValue) => {
                    return (Number(previousValue) + Number(currentValue.value))
                }, 0)
            setTotal(sum)
        }
    }, [rankItems])
    return (
        <FlexBox flexDirection={"column"}
                 border={`1px solid ${Colors.GREY_BORDER}`}
                 borderRadius={"10px"}
                 minHeight={"440px"}
                 width={"100%"}
                 paddingTop={"24px"}
                 paddingRight={"20px"}
                 paddingLeft={"20px"}
                 {...props}>
            <FlexBox alignItems={"flex-end"}
                     justifyContent={"space-between"}>
                <FlexBox alignItems={"center"}>
                    <Text
                        fontSize={isTablet ? "18px" : "20px"}
                        fontWeight={700}
                        lineHeight={"20px"}>
                        {title}
                    </Text>
                    {showInfo && <Popover
                        placement={"top"}
                        trigger="click"
                        content={
                            <FlexBox flexDirection={"column"}
                                     margin={"10px"}
                                     maxWidth={"280px"}>
                                <Text color={Colors.MAIN}
                                      fontSize={"14px"}>
                                    {infoContent}
                                </Text>
                            </FlexBox>}>
                        <Text cursor={"pointer"}
                              fontSize={"18px"}
                              lineHeight={"18px"}
                              marginLeft={"6px"}
                              marginTop={"2px"}>ⓘ</Text>
                    </Popover>}
                </FlexBox>
            </FlexBox>
            <HorizontalDivider
                paddingTop={"1px"}
                marginTop={"20px"}
                backgroundColor={Colors.KEY}/>
            <RenderingByState
                items={rankItems}>
                <Fade duration={300}
                      triggerOnce={true}>
                    <FlexBox flexDirection={"column"}
                             marginTop={"14px"}
                             marginBottom={"20px"}>
                        <FlexBox
                            justifyContent={"space-between"}
                            alignItems={isTablet ? "flex-start" : "center"}
                            flexDirection={isTablet ? "column" : "row"}>
                            <Text
                                fontSize={"13px"}
                                lineHeight={"13px"}
                                color={representColor}>
                                {hsCodeStandard}
                            </Text>
                            <Text
                                id={'unit_description_size'}
                                fontSize={"12px"}
                                lineHeight={"13px"}
                                marginTop={isTablet ? "10px" : "0px"}
                                color={Colors.SUB_TXT}>
                                {unitDescription}
                            </Text>
                        </FlexBox>
                        <Box marginTop={"6px"}/>
                        {rankItems && rankItems.map((item, index) => {
                            return <FlexBox flexDirection={"column"}
                                            marginTop={"22px"}
                                            key={`${item.value}${index}`}>
                                <Popover
                                    placement={"top"}
                                    trigger="hover"
                                    content={
                                        <FlexBox flexDirection={"column"}
                                                 margin={"10px"}
                                                 width={"fit-content"}
                                                 maxWidth={"320px"}>
                                            <Text fontSize={"14px"}
                                                  width={"fit-content"}
                                                  color={Colors.BLACK}>
                                                {item.title}
                                            </Text>
                                        </FlexBox>}>
                                    <Text
                                        width={"fit-content"}
                                        maxWidth={"320px"}
                                        fontSize={"14px"}
                                        lineHeight={"14px"}
                                        overflow={"hidden"}
                                        whiteSpace={"nowrap"}
                                        textOverflow={"ellipsis"}
                                        color={Colors.BLACK}>
                                        {item.title}
                                    </Text>
                                </Popover>
                                <FlexBox
                                    justifyContent={"space-between"}
                                    alignItems={"center"}>
                                    <FlexBox width={"100%"}
                                             marginRight={"12px"}
                                             height={"10px"}
                                             borderRadius={"20px"}
                                             backgroundColor={Colors.GREY_30}>

                                        <FlexBox
                                            backgroundColor={representColor}
                                            width={`calc(100%/${total}*${item.value})`}
                                            height={"10px"}
                                            borderRadius={"20px"}/>

                                    </FlexBox>
                                    <FlexBox
                                        alignItems={"flex-end"}>
                                        <Text
                                            fontWeight={700}
                                            fontSize={"20px"}
                                            color={Colors.GRAY_900}>
                                            {FormatUtils.toCurrencyFormat(item.value.toString())}
                                        </Text>
                                        <Text fontSize={"12px"}
                                              marginLeft={"5px"}
                                              marginBottom={"3px"}>
                                            {item.unit}
                                        </Text>
                                    </FlexBox>
                                </FlexBox>
                            </FlexBox>
                        })}
                    </FlexBox>
                </Fade>
            </RenderingByState>
        </FlexBox>
    )
}
export default RankChart;