/**
 * @author jaeho.lee104 on 2023. 08. 25..
 */

import React, {useEffect, useState} from "react";
import {BottomSheet} from "react-spring-bottom-sheet";
import {useTranslation} from "react-i18next";
import FlexBox from "../styledcomponents/FlexBox";
import Image from "../styledcomponents/Image";
import NewH5 from "../styledcomponents/text/NewH5";
import {Colors} from "../styledcomponents/Styles";
import NewH3 from "../styledcomponents/text/NewH3";
import NewP2 from "../styledcomponents/text/NewP2";
import NewH4 from "../styledcomponents/text/NewH4";
import NewH6 from "../styledcomponents/text/NewH6";
import {Checkbox} from "antd";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import NewP1 from "../styledcomponents/text/NewP1";
import {StringConstants} from "../constants/StringConstants";
import NewButton from "../styledcomponents/NewButton";

export interface PremiumPaymentBottomSheetProps {
    isOpen: boolean,
    price: string,
    backTitle: string,
    onClose: (positive: boolean) => void
}

const PremiumPaymentBottomSheet: React.FC<{
    dialogProps: PremiumPaymentBottomSheetProps
}> = ({dialogProps}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [termsOfServiceAgreeChecked, setTermsOfServiceAgreeChecked] = useState(false);
    const {t} = useTranslation()

    useEffect(() => {
        setIsOpen(dialogProps.isOpen);
    }, [dialogProps.isOpen]);

    const handleOk = () => {
        if (!isOpen) {
            return;
        }
        if (!termsOfServiceAgreeChecked) {
            alert(t('alert_please_agree_to_the_terms_of_service_and_privacy_policy'))
            return;
        }
        setTermsOfServiceAgreeChecked(false)
        dialogProps.onClose(true);
    };

    const handleCancel = () => {
        if (!isOpen) {
            return;
        }
        setTermsOfServiceAgreeChecked(false)
        dialogProps.onClose(false);
    };

    return <BottomSheet
        open={isOpen}
        onDismiss={handleCancel}
        defaultSnap={({maxHeight}) => maxHeight * 0.8}
        snapPoints={({maxHeight}) => [
            maxHeight - 2 * maxHeight / 10,
            maxHeight / 4,
            maxHeight * 0.6,
        ]}>
        <FlexBox
            width={"100%"}
            padding={"16px 20px"}
            flexDirection={"column"}>
            <FlexBox
                alignItems={"center"}
                justifyContent={"space-between"}>
                <FlexBox
                    alignItems={"center"}
                    cursor={"pointer"}
                    onClick={handleCancel}>
                    <Image
                        cursor={"pointer"}
                        width={"24px"}
                        height={"24px"}
                        src={require("assets/images/ic-back-arrow-24.svg").default}
                        alt="ic-dialog-close"/>
                    <NewH5
                        marginLeft={"8px"}
                        color={Colors.GRAY_6}>
                        {dialogProps.backTitle}
                    </NewH5>
                </FlexBox>
                <Image
                    cursor={"pointer"}
                    width={"24px"}
                    height={"24px"}
                    src={require("assets/images/ic-dialog-close.svg").default}
                    onClick={handleCancel}
                    alt="ic-dialog-close"/>
            </FlexBox>
            <FlexBox
                flexDirection={"column"}
                justifyContent={"space-between"}>
                <FlexBox
                    flexDirection={"column"}>
                    <FlexBox
                        marginTop={"28px"}
                        height={"28px"}
                        alignItems={"flex-end"}>
                        <NewH3
                            color={Colors.GRAY_8}>
                            Premium
                        </NewH3>
                        <Image
                            alignSelf={"flex-start"}
                            marginLeft={"4px"}
                            width={"83px"}
                            height={"20px"}
                            src={require("assets/images/ic-most-popular-tag.svg").default}
                            alt="ic-most-popular-tag"/>
                    </FlexBox>
                    <NewP2
                        color={Colors.GRAY_7}
                        marginTop={"4px"}>
                        {t('plan_description')}
                    </NewP2>
                    <NewH4
                        color={Colors.GRAY_8}
                        marginTop={"24px"}>
                        {t('plan_import_trends')}
                    </NewH4>
                    <FlexBox
                        backgroundColor={Colors.GRAY_0}
                        border={`1px solid ${Colors.GRAY_2}`}
                        borderRadius={"12px"}
                        flexDirection={"column"}
                        marginTop={"12px"}
                        padding={"12px 16px"}>
                        <FlexBox
                            alignItems={"center"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                {t('premium_trend_data_1')}
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                {t('premium_trend_data_2')}
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                {t('premium_trend_data_3')}
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                {t('premium_trend_data_4')}
                            </NewP2>
                        </FlexBox>
                    </FlexBox>
                    <NewH4
                        color={Colors.GRAY_8}
                        marginTop={"24px"}>
                        {t('plan_scm_data')}
                    </NewH4>
                    <FlexBox
                        backgroundColor={Colors.GRAY_0}
                        border={`1px solid ${Colors.GRAY_2}`}
                        borderRadius={"12px"}
                        flexDirection={"column"}
                        marginTop={"12px"}
                        padding={"12px 16px"}>
                        <FlexBox
                            alignItems={"center"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                {t('premium_scm_data_1')}
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                {t('premium_scm_data_2')}
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                {t('premium_scm_data_3')}
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                {t('premium_scm_data_4')}
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                {t('premium_scm_data_5')}
                            </NewP2>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            </FlexBox>

            <FlexBox
                flexDirection={"column"}
                marginTop={"24px"}
                justifyContent={"space-between"}>
                <FlexBox
                    flexDirection={"column"}>
                    <NewH6
                        color={Colors.GRAY_7}>
                        {t('plan_price')}
                    </NewH6>
                    <FlexBox
                        marginTop={"4px"}
                        alignItems={"flex-end"}>
                        <NewH3 color={Colors.MAIN}>{`${t('en_krw_unit')}${dialogProps.price}${t('kr_krw_unit')}`}</NewH3>
                        <NewP2 color={Colors.GRAY_6}
                               marginLeft={"4px"}>{`/${t('monthly')} ${t('additional_vat')}`}</NewP2>
                    </FlexBox>
                    {/*<NewP2 color={Colors.GRAY_7}*/}
                    {/*       marginTop={"4px"}>{`매월 정기 결제 ${t('additional_vat')}`}</NewP2>*/}
                    <FlexBox
                        marginTop={"16px"}
                        alignItems={"center"}>
                        <Image
                            width={"40px"}
                            height={"40px"}
                            src={require("assets/images/ic-card-brand-visa-40.svg").default}
                            alt="ic-card-visa"/>
                        <Image
                            width={"40px"}
                            height={"40px"}
                            marginLeft={"12px"}
                            src={require("assets/images/ic-card-brand-master-40.svg").default}
                            alt="ic-card-master"/>
                        <Image
                            width={"40px"}
                            height={"40px"}
                            marginLeft={"12px"}
                            src={require("assets/images/ic-card-brand-jcb-40.svg").default}
                            alt="ic-card-jcb"/>
                    </FlexBox>
                </FlexBox>
                <FlexBox
                    flexDirection={"column"}>
                    <FlexBox
                        marginTop={"24px"}>
                        <Checkbox
                            checked={termsOfServiceAgreeChecked}
                            onChange={(e: CheckboxChangeEvent) => {
                                setTermsOfServiceAgreeChecked(e.target.checked);
                            }}/>
                        <NewP1
                            color={Colors.GRAY_7}
                            marginLeft={"6px"}
                            fontSize={"14px"}
                            textAlign={"left"}>
                            {t('check_terms_of_service_and_privacy_policy_head')}{StringConstants.SPACE}
                            <a style={{fontWeight: "700", cursor: "pointer", borderBottom: "1px solid #000"}}
                               onClick={() => {
                                   window.open("/terms-of-service")
                               }}>{t('terms_of_service')}</a>{StringConstants.SPACE}{t('and')}{StringConstants.SPACE}
                            <a style={{fontWeight: "700", cursor: "pointer", borderBottom: "1px solid #000"}}
                               onClick={() => {
                                   window.open("/privacy-policy")
                               }}>{t('privacy_policy')}</a>
                            {t('check_terms_of_service_and_privacy_policy_tail')}</NewP1>
                    </FlexBox>
                    <NewButton
                        marginTop={"24px"}
                        onClick={handleOk}>
                        {t('do_pay')}
                    </NewButton>
                </FlexBox>
            </FlexBox>
        </FlexBox>
    </BottomSheet>
}

export default PremiumPaymentBottomSheet