import React from "react";
import FlexBox from "../styledcomponents/FlexBox";
import {Colors} from "../styledcomponents/Styles";
import NewH3 from "../styledcomponents/text/NewH3";
import NewH7 from "../styledcomponents/text/NewH7";
import NewH5 from "../styledcomponents/text/NewH5";
import Image from "../styledcomponents/Image";
import NewP2 from "../styledcomponents/text/NewP2";
import NewH6 from "../styledcomponents/text/NewH6";
import {ProductDetailItem} from "../model/ProductDetailItem";
import {TextUtils} from "../utils/TextUtils";
import {ImagePreview} from "../components/ImagePreview";

/**
 * @author jaeho.lee104 on 2024. 01. 25..
 */


interface ProductInfoFragmentProps {
    productDetailItem: ProductDetailItem | null;
}

const ProductInfoFragment = (props: ProductInfoFragmentProps) => {

    const {productDetailItem} = props;
    const productDescriptionTitleWidth = "160px"
    const packingInformationTitleWidth = "256px"

    const specifications = [
        {
            "title": "Product",
            "content": productDetailItem?.info?.itemName || "",
        },
        {
            "title": "Ingredients",
            "content": productDetailItem?.specification?.ingredients || ""
        },
        {
            "title": "Origin",
            "content": productDetailItem?.summary?.originName || ""
        },
        {
            "title": "Shelf life",
            "titleDescription": "",
            "content": productDetailItem?.specification?.shelfLife ? `${productDetailItem?.specification?.shelfLife} months` : ""
        },
        {
            "title": "Taste",
            "titleDescription": "",
            "content": productDetailItem?.specification?.taste || ""
        },
        {
            "title": "Appearance",
            "titleDescription": "",
            "content": productDetailItem?.specification?.appearance || ""
        },
        {
            "title": "Application",
            "titleDescription": "",
            "content": productDetailItem?.specification?.application || ""
        },
        {
            "title": "Allergen info",
            "titleDescription": "",
            "content": productDetailItem?.specification?.allergens || ""
        },
        {
            "title": "Specification",
            "titleDescription": "",
            "content": "Download",
            "contentColor": Colors.MAIN,
            "fontWeight": 700,
            "clickable": true,
        },
    ]

    const sourcingInformation = [
        {
            "title": "HS CODE",
            "content": productDetailItem?.info?.itemHsCode || ""
        },
        {
            "title": "Grade",
            "titleDescription": "",
            "content": TextUtils.productGradeCodeToUiText(productDetailItem?.sourcing?.productGradeCode)
        },
        {
            "title": "MOQ",
            "titleDescription": "MOQ : Minimum Order Quantity",
            "content": `${productDetailItem?.summary?.moq || ""} ${TextUtils.packagingUnitCodeToUiText(productDetailItem?.summary?.moqUnitCode)}`
        },
        {
            "title": "OEM",
            "titleDescription": "OEM : Original Equipment Manufacturing",
            "content": productDetailItem?.sourcing?.oem ? "Acceptable" : "Not Acceptable"
        },
        {
            "title": "ODM",
            "titleDescription": "ODM : Original Development Manufacturing",
            "content": productDetailItem?.sourcing?.odm ? "Acceptable" : "Not Acceptable"
        },
        {
            "title": "Korea Label",
            "titleDescription": "Korea Label : 한글표시사항으로 판매 및소비용으로 수입되는 식품 및 식기의 경우 필수사항입니다.",
            "content": `${productDetailItem?.sourcing?.koreanLabel ? "Acceptable" : "Not Acceptable"} 
            ${TextUtils.productBuyerUnitCodeToUiText(productDetailItem?.sourcing?.buyersCostCode)}`
        },
        {
            "title": "Certifications",
            "titleDescription": "",
            "content": productDetailItem?.certifications.map((item) => item.certificationName).join(",")
        },
        {
            "title": "Approved Exporter",
            "titleDescription": "",
            "content": productDetailItem?.sourcing?.approvedExporterId || ""
        },
        {
            "title": "Product Catalog",
            "titleDescription": "",
            "content": "Download",
            "contentColor": Colors.MAIN,
            "fontWeight": 700,
            "clickable": true,
        },
    ]
    const packingInformation = [
        {
            "title": "Product Packing",
            "content": `${productDetailItem?.packing?.productPacking || ""} per a ${TextUtils.packagingUnitCodeToUiText(productDetailItem?.packing?.packingUnitCode)}`
        },
        {
            "title": "Loading capacity",
            "content": `${productDetailItem?.packing?.loadingCapacity || ""} per ${TextUtils.productLoadingCapacityUnitCodeToUiText(productDetailItem?.packing?.loadingCapacityUnitCode)}`
        },
        {
            "title": "Lead time",
            "content": `${productDetailItem?.summary?.leadTime || ""}days after order confirmation`
        },
        {
            "title": "Load Port",
            "content": `${productDetailItem?.packing?.loadingPort || ""}, ${productDetailItem?.packing?.loadingPortCountryCode}`
        },
    ]
    return <FlexBox
        width={"1200px"}
        flexDirection={"column"}>
        <FlexBox
            backgroundColor={Colors.WHITE}
            border={`1px solid ${Colors.GRAY_3}`}
            padding={"40px 40px 48px 40px"}
            width={"100%"}
            borderRadius={"16px"}
            boxShadow={'0px 4px 8px 0px rgba(0, 0, 0, 0.08);'}
            flexDirection={"column"}
            minHeight={"400px"}>
            <NewH3
                color={Colors.GRAY_8}>
                Product Description
            </NewH3>
            <FlexBox
                marginTop={'32px'}
                justifyContent={"space-between"}>
                <FlexBox
                    flexDirection={"column"}>
                    <NewH7
                        color={Colors.GRAY_7}>
                        Specification
                    </NewH7>
                    <FlexBox
                        marginTop={"22px"}
                        flexDirection={"column"}
                        width={"552px"}
                        padding={"24px"}
                        backgroundColor={Colors.GRAY_0}
                        borderRadius={"12px"}
                        border={`1px solid ${Colors.GRAY_2}`}>
                        {specifications.map((item, index) => {
                            return <FlexBox
                                marginTop={index > 0 ? "16px" : "0px"}>
                                <NewH5
                                    textAlign={"left"}
                                    width={productDescriptionTitleWidth}
                                    color={Colors.GRAY_8}>
                                    {item.title}
                                </NewH5>
                                <FlexBox
                                    alignItems={"center"}
                                    cursor={item.clickable ? "pointer" : undefined}
                                    onClick={() => {
                                        if (!item.clickable) {
                                            return
                                        }
                                        let url = productDetailItem?.specificationImages[0]?.url
                                        if (url) {
                                            window.open(url)
                                        } else {
                                            alert("업로드 된 파일이 없습니다.")
                                        }
                                    }}>
                                    <Image
                                        marginTop={"2px"}
                                        visible={!!item.clickable}
                                        marginRight={"4px"}
                                        src={require("assets/images/ic-download-9x11.svg").default}
                                        alt={`download-${index}`}/>
                                    <NewH7
                                        textOverflow={"ellipsis"}
                                        whiteSpace={"nowrap"}
                                        overflow={"hidden"}
                                        fontWeight={item.fontWeight ? item.fontWeight : 400}
                                        color={item.contentColor ? item.contentColor : Colors.GRAY_7}>
                                        {item.content}
                                    </NewH7>
                                </FlexBox>
                            </FlexBox>
                        })}
                    </FlexBox>
                </FlexBox>
                <FlexBox
                    flexDirection={"column"}>
                    <NewH7
                        color={Colors.GRAY_7}>
                        Sourcing Information
                    </NewH7>
                    <FlexBox
                        marginTop={"22px"}
                        flexDirection={"column"}
                        width={"552px"}
                        padding={"24px"}
                        backgroundColor={Colors.GRAY_0}
                        borderRadius={"12px"}
                        border={`1px solid ${Colors.GRAY_2}`}>
                        {sourcingInformation.map((item, index) => {
                            return <FlexBox
                                marginTop={index > 0 ? "16px" : "0px"}>
                                <NewH5
                                    textAlign={"left"}
                                    width={productDescriptionTitleWidth}
                                    color={Colors.GRAY_8}>
                                    {item.title}
                                </NewH5>
                                <FlexBox
                                    alignItems={"center"}
                                    cursor={item.clickable ? "pointer" : undefined}
                                    onClick={() => {
                                        if (!item.clickable) {
                                            return
                                        }
                                        let url = productDetailItem?.catalogs[0]?.url
                                        if (url) {
                                            window.open(url)
                                        } else {
                                            alert("업로드 된 파일이 없습니다.")
                                        }
                                    }}>
                                    <Image
                                        marginTop={"2px"}
                                        visible={!!item.clickable}
                                        marginRight={"4px"}
                                        src={require("assets/images/ic-download-9x11.svg").default}
                                        alt={`download-${index}`}/>
                                    <NewH7
                                        textOverflow={"ellipsis"}
                                        whiteSpace={"nowrap"}
                                        overflow={"hidden"}
                                        fontWeight={item.fontWeight ? item.fontWeight : 400}
                                        color={item.contentColor ? item.contentColor : Colors.GRAY_7}>
                                        {item.content}
                                    </NewH7>
                                </FlexBox>
                            </FlexBox>
                        })}
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </FlexBox>
        <FlexBox
            backgroundColor={Colors.WHITE}
            border={`1px solid ${Colors.GRAY_3}`}
            padding={"40px 40px 48px 40px"}
            width={"100%"}
            marginTop={'40px'}
            borderRadius={"16px"}
            boxShadow={'0px 4px 8px 0px rgba(0, 0, 0, 0.08);'}
            flexDirection={"column"}
            minHeight={"400px"}>
            <NewH3
                color={Colors.GRAY_8}>
                Packing Information
            </NewH3>
            <FlexBox
                marginTop={'32px'}
                flexDirection={"column"}>
                <FlexBox
                    marginTop={"22px"}
                    flexDirection={"column"}
                    width={"100%"}
                    padding={"24px"}
                    backgroundColor={Colors.GRAY_0}
                    borderRadius={"12px"}
                    border={`1px solid ${Colors.GRAY_2}`}>
                    {packingInformation.map((item, index) => {
                        return <FlexBox
                            marginTop={index > 0 ? "16px" : "0px"}>
                            <NewH5
                                textAlign={"left"}
                                width={packingInformationTitleWidth}
                                color={Colors.GRAY_8}>
                                {item.title}
                            </NewH5>
                            <NewH7
                                textOverflow={"ellipsis"}
                                whiteSpace={"nowrap"}
                                overflow={"hidden"}
                                color={Colors.GRAY_7}>
                                {item.content}
                            </NewH7>
                        </FlexBox>
                    })}
                    <NewH5
                        marginTop={"16px"}
                        textAlign={"left"}
                        width={packingInformationTitleWidth}
                        color={Colors.GRAY_8}>
                        Picture
                    </NewH5>
                    <FlexBox
                        marginTop={"18px"}>
                        {productDetailItem?.packingPictures.map((item, index) => {
                            return <FlexBox
                                alignItems={"center"}
                                marginLeft={index > 0 ? "18px" : "0px"}
                                cursor={"pointer"}
                                onClick={() => {
                                    if (item.url) {
                                        ImagePreview.renderImage(item.url)
                                    }
                                }}
                                flexDirection={"column"}>
                                <Image
                                    border={`1px solid ${Colors.GRAY_3}`}
                                    width={"256px"}
                                    height={"180px"}
                                    borderRadius={"8px"}
                                    src={item.url}
                                    alt={`packing-image-${index}`}/>
                                <NewP2
                                    marginTop={"12px"}
                                    color={Colors.GRAY_7}>
                                    {item.name}
                                </NewP2>
                            </FlexBox>
                        })}
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </FlexBox>
        <FlexBox
            backgroundColor={Colors.WHITE}
            border={`1px solid ${Colors.GRAY_3}`}
            padding={"40px 40px 48px 40px"}
            width={"100%"}
            marginTop={'40px'}
            borderRadius={"16px"}
            boxShadow={'0px 4px 8px 0px rgba(0, 0, 0, 0.08);'}
            flexDirection={"column"}
            minHeight={"400px"}>
            <NewH3
                color={Colors.GRAY_8}>
                Certifications
            </NewH3>
            {productDetailItem?.certifications.map((item, index) => {
                return <FlexBox
                    marginTop={index > 0 ? "24px" : "34px"}
                    borderRadius={"16px"}
                    border={`1px solid ${Colors.GRAY_2}`}
                    backgroundColor={Colors.GRAY_0}
                    width={"100%"}
                    padding={"24px"}>
                    <FlexBox
                        alignItems={"center"}
                        justifyContent={"center"}>
                        <Image
                            border={`1px solid ${Colors.GRAY_3}`}
                            borderRadius={"12px"}
                            width={"100px"}
                            height={"100px"}
                            src={item.certificationFileUrl}
                            alt={`certification-image-${index}`}/>
                    </FlexBox>
                    <FlexBox
                        marginLeft={"24px"}
                        padding={"12px 0px"}
                        flexDirection={"column"}>
                        <NewH6
                            color={Colors.GRAY_8}>
                            {item.certificationName}
                        </NewH6>
                        <NewP2
                            marginTop={"8px"}
                            color={Colors.GRAY_6}>
                            {item.certificationDesc}
                        </NewP2>
                    </FlexBox>
                </FlexBox>
            })}
        </FlexBox>
    </FlexBox>
}
export default ProductInfoFragment;