export class ChangePasswordRequestBody {
    oldPass: string;
    newPass: string;
    newPassChk: string;

    constructor(oldPass: string, newPass: string, newPassChk: string) {
        this.oldPass = oldPass;
        this.newPass = newPass;
        this.newPassChk = newPassChk;
    }
}