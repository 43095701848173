// noinspection DuplicatedCode

/**
 * @author jaeho.lee104 on 2023. 05. 03..
 */
import React, {useEffect, useState} from "react";
import FlexBox from "../styledcomponents/FlexBox";
import {Colors} from "../styledcomponents/Styles";
import Button from "../styledcomponents/Button";
import {NewDashboardApi} from "../network/NewDashboardApi";
import {MarketAnalysisChart, MarketAnalysisChartModel} from "../components/chart/MarketAnalysisChart";
import {RenderingByState} from "../components/RenderingByState";
import Text from "../styledcomponents/Text";
import S1 from "../styledcomponents/text/S1";
import {Table, TablePaginationConfig} from "antd";
import ReactGA from "react-ga4";
import {ColumnsType} from "antd/es/table";
import "./SupplyAnalysisFragment.css";
import {Fade} from "react-awesome-reveal";
import {useTranslation} from "react-i18next";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../modules";
import useTablet from "../uses/UseTablet";


interface NewSupplyAnalysisFragmentProps {
    hsCode: string
}

type TradingTableItem = {
    key: string,
    companyId: string,
    country: string,
    rowNum: number,
    companyNm: string,
    importCnt: string,
    importCompanyCnt: string,
    marketShare: string,
    comparePreMonth: string
}

const SupplyAnalysisFragment: React.FC<NewSupplyAnalysisFragmentProps> = ({hsCode}) => {

    const [companyType, setCompanyType] = useState("exp")
    const [marketAnalysisItems, setMarketAnalysisItems] = useState<Array<MarketAnalysisChartModel> | null>(null)
    const [tradingTableItems, setTradingTableItems] = useState<Array<TradingTableItem> | null>(null)
    const {language} = useSelector((state: RootState) => state.configurationsReducer, shallowEqual);
    const {t} = useTranslation()

    const isTablet = useTablet()
    const tradingTableColumns: ColumnsType<TradingTableItem> = [
        {
            title: t('table_no'),
            dataIndex: "rowNum",
            align: "center",
            width: "80px"
        },
        {
            title: t('company_name'),
            dataIndex: "companyNm",
            align: "center",
            width: "320px",
            ellipsis: true
        },
        {
            title: t('country'),
            dataIndex: "country",
            align: "center",
            width: "180px",
            ellipsis: true,
        },
        {
            title: t('custom_records'),
            dataIndex: "importCnt",
            align: "center",
            width: "120px"
        },
        {
            title: t('number_of_related_companies'),
            dataIndex: "importCompanyCnt",
            align: "center",
            width: "120px"
        },
        {
            title: t('market_share') + "(%)",
            dataIndex: "marketShare",
            align: "center",
            width: "120px"
        },
        {
            title: t('comp_to_previous_month'),
            dataIndex: "comparePreMonth",
            align: "center",
            width: "120px",
            render: (value, record, index) => {
                const numberValue = Number(value)
                const isPositive = numberValue >= 0
                const isZero = numberValue === 0
                const pureValue = Math.abs(numberValue)
                return <FlexBox width={"100%"}
                                height={"100%"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                color={isZero ? Colors.BODY_TXT : isPositive ? Colors.RED : Colors.KEY}>
                    <Text>
                        {isZero ? "" : isPositive ? "+" : "-"}{pureValue}%
                    </Text>
                </FlexBox>
            },
        },
    ];

    useEffect(() => {
        setMarketAnalysisItems(null)
        setTradingTableItems(null)
    }, [hsCode])

    useEffect(() => {
        if (!hsCode) {
            return;
        }
        NewDashboardApi.getAnalysisMarket(hsCode, companyType, language)
            .then((response) => {
                const result = response.data.data
                if (!result || result.length === 0) {
                    setMarketAnalysisItems([]);
                    return;
                }
                let buildItems: Array<MarketAnalysisChartModel> = [];
                for (let i = 0; i < result.length; i++) {
                    let yearData = result[i];
                    if (!yearData || yearData.length === 0) {
                        continue;
                    }
                    let chartModel: MarketAnalysisChartModel = {
                        id: yearData[0].companyNm,
                        data: yearData.map((item) => {
                            return {
                                x: `${item.year}.${item.month}`,
                                y: parseInt(item.cnt),
                            }
                        })
                    };
                    buildItems.push(chartModel);
                }
                setMarketAnalysisItems(buildItems);
            })
            .catch((e) => {
                setMarketAnalysisItems([]);
            });
    }, [hsCode, companyType, language]);


    useEffect(() => {
        if (!hsCode) {
            return;
        }
        NewDashboardApi.getAnalysisTradingData(hsCode, companyType, language)
            .then((response) => {
                const tradingModels = response.data.data
                if (!tradingModels || tradingModels.length === 0) {
                    setTradingTableItems([])
                    return;
                }
                let tableItems = tradingModels.map((e) => {
                    return {
                        key: e.companyId,
                        companyId: e.companyId,
                        country: e.country,
                        rowNum: e.rowNum,
                        companyNm: e.companyNm,
                        importCnt: e.importCnt,
                        importCompanyCnt: e.importCompanyCnt,
                        marketShare: e.marketShare + "%",
                        comparePreMonth: e.comparePreMonth,
                    } as TradingTableItem;
                });
                setTradingTableItems(tableItems);
            })
            .catch((e) => {
                setTradingTableItems([])
            });
    }, [hsCode, companyType, language]);

    function handleCompanyClicked(tradingTableItem: TradingTableItem) {
        ReactGA.event({
            category: "supply_analysis",
            action: `click_company`
        });
        window.open(
            `company/${tradingTableItem.companyId}?hsCode=${hsCode}`,
            "_blank",
        );
    }


    function getSizeForScreenType(origin: string, fraction: number = 0.75): string {
        let value = Number(origin.replace("px", ""))
        return (isTablet ? value * fraction : value) + "px"
    }

    return (
        <FlexBox
            id={"supply-analysis-container"}
            flexDirection={"column"}
            width={"100%"}>
            <FlexBox
                width={"100%"}
                height={getSizeForScreenType("60px")}
                borderRadius={"10px"}
                marginTop={"30px"}
                padding={"5px"}
                alignSelf={"center"}
                background={Colors.GREY_TAB}
                alignItems={"center"}
                justifyContent={"center"}>

                <Button
                    height={"100%"}
                    width={"100%"}
                    fontSize={isTablet ? "14px" : "16px"}
                    borderRadius={"5px"}
                    fontWeight={companyType === "exp" ? 700 : 400}
                    backgroundColor={companyType === "exp" ? Colors.KEY : Colors.TRANSPARENT}
                    color={companyType === "exp" ? Colors.WHITE : Colors.SUB_TXT}
                    onClick={() => {
                        if (companyType !== "exp") {
                            ReactGA.event({
                                category: "supply_analysis",
                                action: `click_exp`
                            });
                            setMarketAnalysisItems(null)
                            setTradingTableItems(null)
                            setCompanyType("exp")
                        }
                    }}>
                    {t('overseas_supplier')}
                </Button>
                <Button
                    height={"100%"}
                    width={"100%"}
                    fontSize={isTablet ? "14px" : "16px"}
                    marginLeft={"5px"}
                    borderRadius={"5px"}
                    fontWeight={companyType === "imp" ? 700 : 400}
                    backgroundColor={companyType === "imp" ? Colors.KEY : Colors.TRANSPARENT}
                    color={companyType === "imp" ? Colors.WHITE : Colors.SUB_TXT}
                    onClick={() => {
                        if (companyType !== "imp") {
                            ReactGA.event({
                                category: "supply_analysis",
                                action: `click_imp`
                            });
                            setMarketAnalysisItems(null)
                            setTradingTableItems(null)
                            setCompanyType("imp")
                        }
                    }}>
                    {t('importers_in_kor')}
                </Button>

            </FlexBox>
            {/*------------------------------------- 시장 분석 차트 -------------------------------------*/}
            <FlexBox
                justifyContent={"space-between"}
                alignItems={isTablet ? "flex-start" : "flex-end"}
                marginTop={"40px"}
                flexDirection={isTablet ? "column" : "row"}>
                <Text
                    fontSize={isTablet ? "18px" : "24px"}
                    fontWeight={700}
                    lineHeight={isTablet ? "18px" : "24px"}
                    marginLeft={isTablet ? "10px" : "20px"}>
                    {t('market_analytics')}
                </Text>
                <Text
                    lineHeight={isTablet ? "20px" : "14px"}
                    fontSize={"14px"}
                    color={Colors.SUB_TXT}
                    marginRight={"20px"}
                    marginTop={isTablet ? "6px" : "0px"}
                    marginLeft={isTablet ? "10px" : "0px"}>
                    {`${t('records_unit')}, ${t('last_12_months')}`}
                </Text>
            </FlexBox>
            <FlexBox
                width={"100%"}
                height={isTablet ? "380px" : "418px"}
                border={`1px solid ${Colors.GREY_BORDER}`}
                borderRadius={"10px"}
                marginTop={"24px"}
                paddingTop={"26px"}
                paddingLeft={"30px"}
                paddingRight={"30px"}
                paddingBottom={"30px"}
                flexDirection={"column"}>
                <FlexBox
                    width={"100%"}
                    height={"100%"}
                    paddingBottom={"10px"}
                    overflowX={isTablet ? "scroll" : "hidden"}>
                    <RenderingByState items={marketAnalysisItems}>
                        {marketAnalysisItems &&
                            <Fade duration={500}
                                  triggerOnce={true}
                                  style={{
                                      width: "100%",
                                      height: "100%",
                                      minWidth: isTablet ? "840px" : "100%"
                                  }}>
                                <MarketAnalysisChart chartModels={marketAnalysisItems} colors={Colors.COLOR_SET}/>
                            </Fade>}
                    </RenderingByState>
                </FlexBox>
            </FlexBox>

            {/*------------------------------------- 무역 거래 데이터 테이블 -------------------------------------*/}
            <FlexBox
                id={"trading-table-container"}
                alignItems={isTablet ? "flex-start" : "flex-end"}
                width={"100%"}
                justifyContent={"space-between"}
                flexDirection={isTablet ? "column" : "row"}>
                <FlexBox
                    alignItems={isTablet ? "flex-start" : "flex-end"}
                    flexDirection={isTablet ? "column" : "row"}>
                    <Text
                        fontSize={isTablet ? "18px" : "24px"}
                        marginTop={getSizeForScreenType("84px")}
                        marginLeft={isTablet ? "10px" : "20px"}
                        fontWeight={700}
                        lineHeight={isTablet ? "18px" : "24px"}>
                        {t('trade_transaction_data')}
                    </Text>
                    {tradingTableItems &&
                        <Fade duration={300}
                              triggerOnce={true}>
                            <S1
                                marginBottom={"2px"}
                                marginTop={isTablet ? "6px" : "0px"}
                                marginLeft={isTablet ? "10px" : "12px"}
                                lineHeight={"14px"}
                                color={Colors.KEY}>
                                {t('search_result_companies_total', {count: tradingTableItems.length})}
                            </S1>
                        </Fade>}
                </FlexBox>
                <Text
                    marginTop={isTablet ? "6px" : "0px"}
                    marginLeft={isTablet ? "10px" : "0px"}
                    lineHeight={"14px"}
                    fontSize={"14px"}
                    color={Colors.SUB_TXT}
                    marginRight={"20px"}>
                    {`${t('records_unit')}, ${t('last_12_months')}`}
                </Text>
            </FlexBox>
            <FlexBox
                width={"100%"}
                height={"fit-content"}
                alignItems={"flex-start"}
                justifyContent={"center"}
                marginTop={"20px"}
                overflowX={isTablet ? "scroll" : "hidden"}>
                <FlexBox
                    width={"100%"}
                    height={"100%"}
                    paddingBottom={"10px"}
                    overflowX={isTablet ? "scroll" : "hidden"}>
                    <RenderingByState items={tradingTableItems}>
                        {tradingTableItems &&
                            <Fade duration={300}
                                  triggerOnce={true}
                                  style={{
                                      minWidth: isTablet ? "1080px" : undefined
                                  }}>
                                <Table
                                    style={{
                                        width: "100%",
                                    }}
                                    columns={tradingTableColumns}
                                    dataSource={tradingTableItems}
                                    bordered={false}
                                    pagination={{
                                        position: [(isTablet ? "bottomLeft" : "bottomCenter")],
                                        pageSize: isTablet ? 5 : 10,
                                        showSizeChanger: false,
                                    } as TablePaginationConfig}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: (event) => {
                                                handleCompanyClicked(record);
                                            },
                                        };
                                    }}
                                />
                            </Fade>}
                    </RenderingByState>
                </FlexBox>
            </FlexBox>
        </FlexBox>
    )
}
export default SupplyAnalysisFragment;