/**
 * @author jaeho.lee104 on 2023. 05. 11..
 */
import React from "react";
import {ResponsivePie} from "@nivo/pie";
import {Colors} from "../../styledcomponents/Styles";
import useTablet from "../../uses/UseTablet";


export type VolumePieChartModel = {
    id: string,
    label: string,
    value: number
}

interface VolumePieChartProps {
    items: Array<VolumePieChartModel>
}

export const VolumePieChart: React.FC<VolumePieChartProps> = ({
                                                                  items
                                                              }) => {

    const isTablet = useTablet()
    return <ResponsivePie
        data={items}
        motionConfig={"wobbly"}
        colors={Colors.COLOR_SET}
        margin={{top: 30, right: isTablet ? 80 : 0, bottom: 46, left: isTablet ? 80 : 0}}
        innerRadius={0.5}
        padAngle={2}
        cornerRadius={8}
        activeInnerRadiusOffset={24}
        borderWidth={0}
        arcLabelsSkipAngle={20}
        arcLinkLabelsSkipAngle={20}
        arcLinkLabelsThickness={1}
        arcLinkLabelsOffset={0}
        enableArcLabels={false}
        arcLinkLabelsDiagonalLength={isTablet ? 10 : 14}
        arcLinkLabelsStraightLength={isTablet ? 10 : 40}
        arcLinkLabelsColor={{
            from: 'color'
        }}
        // arcLabelsComponent={(d) => {
        //     console.log("label", d, d.style.transform.get())
        //     return (
        //         <g>
        //             {d.label.split(" ")
        //                 .map((segment, i) => (
        //                     <text
        //                         key={d.datum.id + segment}
        //                         transform={d.style.transform.get()}
        //                         dominantBaseline="central"
        //                         style={{
        //                             font: "Noto Sans",
        //                             fontWeight: 700,
        //                             fontSize: "20px",
        //                             fill: Colors.KEY
        //                         }}
        //                     >
        //                         {segment}%
        //                     </text>
        //                 ))}
        //         </g>
        //     );
        // }}
        arcLinkLabelComponent={(d) => {
            return (
                <g opacity={d.style.opacity.get()}>
                    <path
                        fill="none"
                        stroke={d.style.linkColor.get()}
                        strokeWidth={1}
                        d={d.style.path.get()}
                    />
                    <g transform={d.style.textPosition.get()}>
                        <text
                            key={d.datum.id}
                            textAnchor={d.style.textAnchor.get()}
                            dominantBaseline="central"
                            style={{
                                font: "Noto Sans",
                                fontWeight: 500,
                                fontSize: "14px",
                                fill: Colors.BLACK,
                            }}
                        >
                            {d.datum.label}
                        </text>
                        <text
                            key={d.datum.id + d.datum.formattedValue}
                            textAnchor={d.style.textAnchor.get()}
                            transform={`translate(${d.style.textAnchor.get() === 'start' ? '4' : '-4'},20)`}
                            dominantBaseline="central"
                            style={{
                                font: "Noto Sans",
                                fontWeight: 500,
                                fontSize: "16px",
                                fill: Colors.KEY,
                            }}
                        >
                            {d.datum.formattedValue}%
                        </text>
                    </g>
                </g>
            );
        }}
        fit={true}
        theme={{

            fontFamily: "Noto Sans",
            legends: {
                text: {
                    fontWeight: 400,
                    fontSize: 14,
                    color: Colors.GRAY_800
                }
            },
            crosshair: {
                line: {
                    stroke: Colors.GRAY_900,
                    strokeWidth: 1,
                    strokeOpacity: 1,
                    strokeDasharray: '3 4'
                }
            },
            axis: {
                ticks: {
                    text: {
                        fontWeight: 400,
                        fontSize: 10,
                        color: Colors.GRAY_800
                    }
                }
            },
            grid: {
                line: {
                    stroke: Colors.GRAY_300,
                    strokeWidth: 1,
                },
            },
        }}
    />
}