/**
 * @author jaeho.lee104 on 2023. 07. 14..
 */
import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {PaypleAPI} from "../network/PaypleAPI";
import {CircularProgress} from "@mui/material";
import {Colors} from "../styledcomponents/Styles";
import FlexBox from "../styledcomponents/FlexBox";
import {useDispatch} from "react-redux";
import * as Auths from "../modules/auths";
import {useTranslation} from "react-i18next";
import NewH1 from "../styledcomponents/text/NewH1";

const PaymentResultFragment = () => {

    const [searchParams, setSearchParams] = useSearchParams()
    const oid = searchParams.get('oid')
    const type = searchParams.get('type')
    const [tryCount, setTryCount] = useState(0)
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const delay = (ms: number) => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    useEffect(() => {
        if (!oid && type === 'card') {
            alert("카드가 등록되었습니다.")
            dispatch(Auths.refreshPaymentState())
            window.close()
            return;
        }
        if (!oid || !type) {
            return
        }
        PaypleAPI.getPaymentResult(oid)
            .then(async (res) => {
                const code = res.data.code
                const message = res.data.message
                if (code === '1002') {
                    await delay(2000)
                    setTryCount(tryCount + 1)
                } else if (code === '1100') {
                    if (type === 'card') {
                        alert("카드가 등록되었습니다.")
                    } else {
                        alert(`결제가 완료되었습니다.`)
                    }
                    dispatch(Auths.refreshPaymentState())
                    window.close()
                } else {
                    alert(`[${code}] ${message}`)
                    dispatch(Auths.refreshPaymentState())
                    window.close()
                }
            })
            .catch((e) => {
                alert(t('alert_a_temporary_error_has_occurred'))
            })

    }, [oid, tryCount, type])

    return (
        <FlexBox
            flexDirection={"column"}
            width={"100%"}
            alignItems={"center"}
            minHeight={"600px"}
            height={"600px"}>
            <NewH1
                marginTop={"100px"}
                fontWeight={700}
                fontSize={"24px"}>
                {type === 'card' ? "카드 등록중입니다.\n\n잠시만 기다려주세요..." : "상품 결제중입니다.\n\n잠시만 기다려주세요..."}
            </NewH1>
            <FlexBox
                marginTop={"80px"}
                width={"60px"}
                height={"60px"}
                alignItems={"center"}
                justifyContent={"center"}>
                <CircularProgress
                    sx={{
                        color: Colors.BLUE_20
                    }}/>
            </FlexBox>
        </FlexBox>
    )
}
export default PaymentResultFragment;