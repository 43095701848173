import React, {RefObject, useRef, useState} from "react";
import {KeyboardUtils} from "../utils/KeyboardUtils";
import Text from "../styledcomponents/Text";
import {Colors} from "../styledcomponents/Styles";
import FlexBox from "../styledcomponents/FlexBox";
import Input from "../styledcomponents/Input";
import styled from "styled-components";
import Button from "../styledcomponents/Button";
import {CardInfo} from "../model/CardInfo";
import {Radio} from "antd";

const InfoPlainText = styled(Text)`
  font-size: 16px;
  margin-top: 6px;
`;
const InputErrorText = styled(Text)`
  margin-top: 4px;
  font-size: 12px;
  text-align: left;
  color: ${Colors.INPUT_ERROR};
`
const COMMON_INPUT_BETWEEN_MARGIN = "20px";

export enum CardFormMode {
    NEW,
    EDIT,
    ADDED
}

export namespace CardFormMode {
    export function isEdit(mode: CardFormMode): boolean {
        return mode === CardFormMode.EDIT;
    }

    export function isAdded(mode: CardFormMode): boolean {
        return mode === CardFormMode.ADDED;
    }

    export function isNew(mode: CardFormMode): boolean {
        return mode === CardFormMode.NEW;
    }
}

export type CardInputSnapshot = {
    firstName: string,
    lastName: string,
    identityNumber: string,
    card1: string,
    card2: string,
    card3: string,
    card4: string,
    expirationMonth: string,
    expirationYear: string,
    cvc: string,
    password: string
}

const CardInputForm: React.FC<{
    cardInfo: CardInfo,
    cardFormMode: CardFormMode,
    onSubmitClick: (snapshot: CardInputSnapshot) => void,
    onEditClick: () => void,
    onCancelClick: () => void
}> = ({
          cardInfo,
          cardFormMode,
          onSubmitClick,
          onEditClick,
          onCancelClick
      }) => {

    const [lastName, setLastName] = useState<string>("");
    const [lastNameError, setLastNameError] = useState<boolean>(false);
    const lastNameRef = useRef<HTMLInputElement>(null);

    const [firstName, setFirstName] = useState<string>("");
    const [firstNameError, setFirstNameError] = useState<boolean>(false);
    const firstNameRef = useRef<HTMLInputElement>(null);

    const [identityNumber, setIdentityNumber] = useState<string>("");
    const [identityNumberError, setIdentityNumberError] = useState<boolean>(false);
    const identityNumberRef = useRef<HTMLInputElement>(null);

    const [card1, setCard1] = useState<string>("");
    const [card1Error, setCard1Error] = useState<boolean>(false);
    const card1Ref = useRef<HTMLInputElement>(null);

    const [card2, setCard2] = useState<string>("");
    const [card2Error, setCard2Error] = useState<boolean>(false);
    const card2Ref = useRef<HTMLInputElement>(null);

    const [card3, setCard3] = useState<string>("");
    const [card3Error, setCard3Error] = useState<boolean>(false);
    const card3Ref = useRef<HTMLInputElement>(null);

    const [card4, setCard4] = useState<string>("");
    const [card4Error, setCard4Error] = useState<boolean>(false);
    const card4Ref = useRef<HTMLInputElement>(null);

    const [expiredDate, setExpiredDate] = useState<string>("");
    const [expiredDateError, setExpiredDateError] = useState<boolean>(false);
    const expiredDateRef = useRef<HTMLInputElement>(null);

    const [cvc, setCvc] = useState<string>("");
    const [cvcError, setCvcError] = useState<boolean>(false);
    const cvcRef = useRef<HTMLInputElement>(null);

    const [password, setPassword] = useState<string>("");
    const [passwordError, setPasswordError] = useState<boolean>(false);
    const passwordRef = useRef<HTMLInputElement>(null);

    const CARD_PERSON_TYPE = 1
    const CARD_BUSINESS_TYPE = 2
    const [cardType, setCardType] = useState(CARD_PERSON_TYPE)

    function takeFormSnapshot(): CardInputSnapshot {
        const split = expiredDate.split("/")
        return {
            firstName: firstName,
            lastName: lastName,
            identityNumber: identityNumber,
            card1: card1,
            card2: card2,
            card3: card3,
            card4: card4,
            expirationMonth: split.length > 0 ? split[0] : "",
            expirationYear: split.length > 1 ? split[1] : "",
            cvc: cvc,
            password: password,
        };
    }

    function handleCardInputOnChange(
        e: React.ChangeEvent<HTMLInputElement>,
        prevRef: RefObject<HTMLInputElement> | null,
        nextRef: RefObject<HTMLInputElement>,
        state: string,
        setter: ((newState: string) => void)) {

        let value = KeyboardUtils.clearNumber(e.target.value);
        if (state.length < 4 && value.length === 4) {
            nextRef.current?.focus();
        } else if (state.length > 0 && value.length === 0) {
            prevRef?.current?.focus();
        }
        setter(value);
    }

    function handleExpiredDateInputOnChange(
        e: React.ChangeEvent<HTMLInputElement>,
        prevRef: RefObject<HTMLInputElement> | null,
        nextRef: RefObject<HTMLInputElement> | null,
        setter: ((newState: string) => void)) {
        let value = KeyboardUtils.clearNumber(e.target.value);
        if (value.length >= 3) {
            let formatted = `${value.slice(0, 2)}/${value.slice(2, 4)}`;
            setter(formatted);
            if (expiredDate.length < 5 && formatted.length === 5) {
                nextRef?.current?.focus();
            }
        } else {
            setter(value);
        }
    }


    function handleCVCInputOnChange(
        e: React.ChangeEvent<HTMLInputElement>,
        prevRef: RefObject<HTMLInputElement> | null,
        nextRef: RefObject<HTMLInputElement> | null,
        setter: ((newState: string) => void)) {
        let value = KeyboardUtils.clearNumber(e.target.value);
        setter(value);
        if (cvc.length < 3 && value.length === 3) {
            nextRef?.current?.focus();
        }
    }

    function handlePasswordInputOnChange(
        e: React.ChangeEvent<HTMLInputElement>,
        prevRef: RefObject<HTMLInputElement> | null,
        nextRef: RefObject<HTMLInputElement> | null,
        setter: ((newState: string) => void)) {
        let value = KeyboardUtils.clearNumber(e.target.value);
        setter(value);
        if (password.length < 2 && value.length === 2) {
            nextRef?.current?.focus();
        }
    }

    function checkCardFormValid(snapshot: CardInputSnapshot): boolean {
        if (snapshot.lastName.length <= 0) {
            setLastNameError(true)
            lastNameRef?.current?.focus()
            return false
        }
        if (snapshot.firstName.length <= 0) {
            setFirstNameError(true)
            firstNameRef?.current?.focus()
            return false
        }
        const identityNumberValidLength = cardType === CARD_PERSON_TYPE ? 6 : 10
        if (snapshot.identityNumber.length !== identityNumberValidLength) {
            setIdentityNumberError(true)
            identityNumberRef?.current?.focus()
            return false
        }
        if (snapshot.card1.length !== 4) {
            setCard1Error(true)
            card1Ref?.current?.focus()
            return false
        }
        if (snapshot.card2.length !== 4) {
            setCard2Error(true)
            card2Ref?.current?.focus()
            return false
        }
        if (snapshot.card3.length !== 4) {
            setCard3Error(true)
            card3Ref?.current?.focus()
            return false
        }
        if (snapshot.card4.length !== 4) {
            setCard4Error(true)
            card4Ref?.current?.focus()
            return false
        }
        if (snapshot.expirationYear.length !== 2 || snapshot.expirationMonth.length !== 2) {
            setExpiredDateError(true)
            expiredDateRef?.current?.focus()
            return false
        }
        if (snapshot.cvc.length !== 3) {
            setCvcError(true)
            cvcRef?.current?.focus()
            return false
        }
        if (snapshot.password.length !== 2) {
            setPasswordError(true)
            passwordRef?.current?.focus()
            return false
        }
        return true
    }

    function onHandleSubmitClick() {
        const snapshot = takeFormSnapshot()
        if (checkCardFormValid(snapshot)) {
            onSubmitClick(snapshot);
        }
    }

    function clearInput() {
        setCard1("");
        setCard1Error(false)
        setCard2("");
        setCard2Error(false)
        setCard3("");
        setCard3Error(false)
        setCard4("");
        setCard4Error(false)
        setCvc("");
        setCvcError(false)
        setExpiredDate("");
        setExpiredDateError(false)
        setPassword("");
        setPasswordError(false)
        setIdentityNumber("");
        setIdentityNumberError(false)
        setFirstName("");
        setFirstNameError(false)
        setLastName("");
        setLastNameError(false)
        setCardType(CARD_PERSON_TYPE)
    }

    return (
        <FlexBox
            flexDirection={"column"}
            alignItems={"flex-start"}
            border={`1px solid ${Colors.GRAY_350}`}
            width={"fit-content"}
            paddingLeft={"36px"}
            paddingRight={"36px"}
            paddingBottom={"36px"}
            paddingTop={"36px"}
            borderRadius={"10px"}>
            {!CardFormMode.isNew(cardFormMode)
                && <FlexBox flexGrow={1}
                            flexDirection={"row"}
                            width={"100%"}
                            alignItems={"center"}>
                    <img
                        width={"22px"}
                        src={require("assets/images/ic-payment-card.svg").default}
                        alt={"ic-payment-card"}
                    />

                    <Text alignSelf={"flex-start"}
                          marginLeft={"10px"}
                          color={Colors.GRAY_900}
                          fontWeight={700}
                          fontSize={"18px"}>신용카드 또는 직불카드</Text>

                    <Text
                        fontSize={"16px"}
                        fontWeight={700}
                        color={Colors.MAIN}
                        cursor={"pointer"}
                        marginLeft={"78px"}
                        onClick={() => {
                            if (CardFormMode.isEdit(cardFormMode)) {
                                onHandleSubmitClick()
                            } else {
                                clearInput();
                                onEditClick();
                            }
                        }}>
                        {CardFormMode.isEdit(cardFormMode) ? "저장" : "편집"}
                    </Text>
                    {CardFormMode.isEdit(cardFormMode)
                        && <Text
                            fontSize={"16px"}
                            marginLeft={"30px"}
                            alignContent={"flex-end"}
                            fontWeight={700}
                            color={Colors.MAIN}
                            cursor={"pointer"}
                            onClick={() => {
                                onCancelClick();
                            }}>
                            {"취소"}
                        </Text>}
                </FlexBox>}

            {CardFormMode.isEdit(cardFormMode) &&
                <Radio.Group
                    style={{marginTop: "20px"}}
                    onChange={(e) => {
                        setCardType(e.target.value)
                    }}
                    value={cardType}>
                    <Radio
                        value={CARD_PERSON_TYPE}
                        style={{
                            fontSize: "16px",
                            fontWeight: 500
                        }}>개인카드</Radio>
                    <Radio value={CARD_BUSINESS_TYPE}
                           style={{
                               fontSize: "16px",
                               fontWeight: 500
                           }}>법인카드</Radio>
                </Radio.Group>}

            {CardFormMode.isEdit(cardFormMode)
                && <FlexBox flexDirection={"column"}>
                    <FlexBox marginTop={COMMON_INPUT_BETWEEN_MARGIN}>
                        <FlexBox flexDirection={"column"}
                                 alignItems={"flex-start"}>
                            <Text
                                fontSize={"16px"}
                                fontWeight={700}
                                color={Colors.GRAY_900}>성</Text>
                            <Input placeholder="홍"
                                   marginTop={"10px"}
                                   type="text"
                                   name="last name"
                                   ref={lastNameRef}
                                   value={lastName}
                                   error={lastNameError}
                                   onChange={(e) => {
                                       if (lastNameError) {
                                           setLastNameError(false)
                                       }
                                       setLastName(e.target.value);
                                   }}
                                   width={"190px"}/>

                            {lastNameError && <InputErrorText>성을 입력해주세요.</InputErrorText>}
                        </FlexBox>
                        <FlexBox flexDirection={"column"}
                                 alignItems={"flex-start"}
                                 marginLeft={"20px"}>
                            <Text
                                fontSize={"16px"}
                                fontWeight={700}
                                color={Colors.GRAY_900}>{"이름"}</Text>
                            <Input placeholder="길동"
                                   marginTop={"10px"}
                                   type="text"
                                   name="first name"
                                   width={"190px"}
                                   ref={firstNameRef}
                                   error={firstNameError}
                                   value={firstName}
                                   onChange={(e) => {
                                       if (firstNameError) {
                                           setFirstNameError(false)
                                       }
                                       setFirstName(e.target.value);
                                   }}/>
                            {firstNameError && <InputErrorText>이름을 입력해주세요.</InputErrorText>}
                        </FlexBox>
                    </FlexBox>
                    <FlexBox flexDirection={"column"}
                             alignItems={"flex-start"}
                             marginTop={COMMON_INPUT_BETWEEN_MARGIN}>
                        <Text
                            fontSize={"16px"}
                            fontWeight={700}
                            color={Colors.GRAY_900}>{cardType === CARD_PERSON_TYPE ? "생년월일(6자리)" : "사업자등록번호(10자리)"}</Text>
                        <Input placeholder={cardType === CARD_PERSON_TYPE ? "YYMMDD" : ""}
                               marginTop={"10px"}
                               maxLength={cardType === CARD_PERSON_TYPE ? 6 : 10}
                               type="text"
                               name="identityNumber"
                               ref={identityNumberRef}
                               error={identityNumberError}
                               value={identityNumber}
                               onChange={(e) => {
                                   if (identityNumberError) {
                                       setIdentityNumberError(false)
                                   }
                                   setIdentityNumber(e.target.value);
                               }}
                               width={"190px"}/>
                        {identityNumberError &&
                            <InputErrorText>{cardType === CARD_PERSON_TYPE ? "생년월일 6자리를 입력해주세요." : "사업자등록번호 10자리를 입력해주세요"}</InputErrorText>}
                    </FlexBox>
                    <Text
                        fontSize={"16px"}
                        marginTop={COMMON_INPUT_BETWEEN_MARGIN}
                        fontWeight={700}
                        alignSelf={"flex-start"}
                        color={Colors.GRAY_900}>
                        카드 번호
                    </Text>
                    <FlexBox marginTop={"10px"}>
                        <Input placeholder="0000"
                               maxLength={4}
                               type="tel"
                               name="card number1"
                               width={"88px"}
                               ref={card1Ref}
                               error={card1Error}
                               value={card1}
                               onChange={(e) => {
                                   if (card1Error) {
                                       setCard1Error(false)
                                   }
                                   handleCardInputOnChange(e, null, card2Ref, card1, setCard1);
                               }}
                        />
                        <Input placeholder="0000"
                               marginLeft={"16px"}
                               maxLength={4}
                               type="tel"
                               name="card number2"
                               ref={card2Ref}
                               error={card2Error}
                               value={card2}
                               width={"88px"}
                               onChange={(e) => {
                                   if (card2Error) {
                                       setCard2Error(false)
                                   }
                                   handleCardInputOnChange(e, card1Ref, card3Ref, card2, setCard2);
                               }}/>
                        <Input placeholder="0000"
                               marginLeft={"16px"}
                               maxLength={4}
                               type="tel"
                               name="card number3"
                               width={"88px"}
                               ref={card3Ref}
                               error={card3Error}
                               value={card3}
                               onChange={(e) => {
                                   if (card3Error) {
                                       setCard3Error(false)
                                   }
                                   handleCardInputOnChange(e, card2Ref, card4Ref, card3, setCard3);
                               }}/>
                        <Input placeholder="0000"
                               marginLeft={"16px"}
                               maxLength={4}
                               type="tel"
                               name="card number4"
                               width={"88px"}
                               ref={card4Ref}
                               error={card4Error}
                               value={card4}
                               onChange={(e) => {
                                   if (card4Error) {
                                       setCard4Error(false)
                                   }
                                   handleCardInputOnChange(e, card3Ref, expiredDateRef, card4, setCard4);
                               }}/>
                    </FlexBox>
                    {(card1Error || card2Error || card3Error || card4Error) && <InputErrorText>카드번호 4자리를 입력해주세요.</InputErrorText>}
                    <FlexBox marginTop={COMMON_INPUT_BETWEEN_MARGIN}>
                        <FlexBox flexDirection={"column"}
                                 alignItems={"flex-start"}>
                            <Text
                                fontSize={"16px"}
                                fontWeight={700}
                                color={Colors.GRAY_900}>유효기간</Text>
                            <Input placeholder="MM/YY"
                                   marginTop={"10px"}
                                   maxLength={5}
                                   type="text"
                                   name="expiry date"
                                   ref={expiredDateRef}
                                   error={expiredDateError}
                                   value={expiredDate}
                                   onChange={(e) => {
                                       if (expiredDateError) {
                                           setExpiredDateError(false)
                                       }
                                       handleExpiredDateInputOnChange(e, card4Ref, cvcRef, setExpiredDate);
                                   }}
                                   width={"190px"}/>
                            {expiredDateError && <InputErrorText>유효기간을 입력해주세요.</InputErrorText>}
                        </FlexBox>
                        <FlexBox flexDirection={"column"}
                                 alignItems={"flex-start"}
                                 marginLeft={"20px"}>
                            <Text
                                fontSize={"16px"}
                                fontWeight={700}
                                color={Colors.GRAY_900}>CVC</Text>
                            <Input placeholder="카드 뒷면 3자리 숫자"
                                   marginTop={"10px"}
                                   type="password"
                                   name="CVC"
                                   maxLength={3}
                                   width={"190px"}
                                   value={cvc}
                                   ref={cvcRef}
                                   error={cvcError}
                                   onChange={(e) => {
                                       if (cvcError) {
                                           setCvcError(false)
                                       }
                                       handleCVCInputOnChange(e, expiredDateRef, passwordRef, setCvc);
                                   }}/>
                            {cvcError && <InputErrorText>cvc 3자리를 입력해주세요.</InputErrorText>}
                        </FlexBox>
                    </FlexBox>
                    <FlexBox flexDirection={"column"}
                             alignItems={"flex-start"}
                             marginTop={COMMON_INPUT_BETWEEN_MARGIN}>
                        <Text
                            fontSize={"16px"}
                            fontWeight={700}
                            color={Colors.GRAY_900}>카드 비밀번호(앞 2자리)</Text>
                        <FlexBox marginTop={"10px"} alignItems={"center"}>
                            <img src={require("assets/images/ic-card-password.svg").default}
                                 alt={"ic-card-password"}
                                 style={{
                                     position: "absolute",
                                     right: "12px",
                                 }}/>
                            <Input placeholder="**"
                                   type="password"
                                   name="card password"
                                   value={password}
                                   ref={passwordRef}
                                   error={passwordError}
                                   maxLength={2}
                                   width={"190px"}
                                   onChange={(e) => {
                                       if (passwordError) {
                                           setPasswordError(false)
                                       }
                                       handlePasswordInputOnChange(e, cvcRef, null, setPassword);
                                   }}/>
                            {passwordError && <InputErrorText>비밀번호 앞 2자리를 입력해주세요.</InputErrorText>}
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            }
            {CardFormMode.isAdded(cardFormMode)
                && <FlexBox flexDirection={"column"}>
                    <FlexBox flexDirection={"column"}
                             alignItems={"flex-start"}
                             marginTop={COMMON_INPUT_BETWEEN_MARGIN}>
                        <Text
                            fontSize={"16px"}
                            fontWeight={700}
                            color={Colors.GRAY_900}>소유자 이름</Text>
                        <InfoPlainText>{cardInfo.userName}</InfoPlainText>

                    </FlexBox>
                    <FlexBox flexDirection={"column"}
                             alignItems={"flex-start"}
                             marginTop={COMMON_INPUT_BETWEEN_MARGIN}>
                        <Text
                            fontSize={"16px"}
                            fontWeight={700}
                            color={Colors.GRAY_900}>카드 번호</Text>
                        <InfoPlainText>{cardInfo.cardNumber}</InfoPlainText>

                    </FlexBox>
                </FlexBox>}
            {CardFormMode.isNew(cardFormMode)
                && <FlexBox
                    flexDirection={"column"}
                    height={"276px"}
                    borderRadius={"10px"}
                    alignSelf={"center"}
                    alignItems={"center"}
                    justifyContent={"center"}>
                    <Text fontSize={"16px"}>현재 등록된 결제 방법이 없습니다.</Text>
                    <Button width={"88px"}
                            height={"40px"}
                            fontSize={"14px"}
                            marginTop={"18px"}
                            alignSelf={"center"}
                            onClick={() => {
                                clearInput();
                                onEditClick();
                            }}>
                        등록하기
                    </Button>
                </FlexBox>}
        </FlexBox>
    );
};

export default CardInputForm;