/**
 * @author jaeho.lee104 on 2023. 10. 03..
 */
import React, {useEffect} from "react";

const AnugaFragment = () => {
    useEffect(() => {
        window.location.href = "https://promotion.thirdworks.co.kr/"
    }, [])
    return (
        <></>
    )
}
export default AnugaFragment