export class ImagePreview {
    static renderImage(src: string) {
        const imageUrl = src;
        const newWindow = window.open("", "_blank");
        newWindow?.document.write(`
            <!DOCTYPE html>
            <html>
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>preview</title>
            </head>
            <body>
                <img src="${imageUrl}" alt="product-image">
            </body>
            </html>
        `);
    }
}