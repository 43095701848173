import {ContentContainer} from "../styledcomponents/Containers";
import React from "react";
import FlexBox from "../styledcomponents/FlexBox";
import NewH3 from "../styledcomponents/text/NewH3";
import {useTranslation} from "react-i18next";

const CompanyPreparingFragment = () => {
    const {t} = useTranslation()
    return (
        <ContentContainer>
            <FlexBox
                alignItems={"center"}
                flexDirection={"column"}>
                <NewH3
                    marginTop={"200px"}>
                    {t('company_info_temp_draft')}
                </NewH3>
            </FlexBox>
        </ContentContainer>
    )
}
export default CompanyPreparingFragment;