import styled from "styled-components";
import Text from "../Text";
import {reactiveValueInContent} from "../../utils/StyleUtils";

const H2 = styled(Text)`
  font-size: ${({reactive}) => reactive ? reactiveValueInContent("54px", "12px") : "54px"};
  //font-size: min(calc(100vw * 0.045), 5.4rem);
  font-weight: 700;
`;

export default H2;