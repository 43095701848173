import {Button, Result} from "antd";
import {useNavigate} from "react-router-dom";
import React from "react";

const PaymentSuccess: React.FC = () => {

    const navigate = useNavigate();

    return (<Result
            style={{marginTop: "160px"}}
            status="success"
            title="결제 완료"
            subTitle="프리미엄 서비스를 이용하실 수 있습니다."
            extra={[
                <Button type="primary" key="supply" onClick={() => {
                    navigate("/dashboard");
                }}>
                    공급망 분석 이용하기
                </Button>,
            ]}
        />
    );
};

export default PaymentSuccess;