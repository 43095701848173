import React, {useEffect, useRef, useState} from "react";
import {ContentContainer} from "../styledcomponents/Containers";
import Text from "../styledcomponents/Text";
import HorizontalDivider from "../styledcomponents/HorizontalDivider";
import TextField from "../components/TextField";
import styled from "styled-components";
import {Colors} from "../styledcomponents/Styles";
import Input from "../styledcomponents/Input";
import FlexBox from "../styledcomponents/FlexBox";
import useMobile from "../uses/UseMobile";
import Button from "../styledcomponents/Button";
import {RegexUtils} from "../constants/RegexUtils";
import {KeyboardUtils} from "../utils/KeyboardUtils";
import {useNavigate} from "react-router-dom";
import {UsersAPI} from "../network/UsersAPI";
import {RequestEmailCodeRequestBody} from "../model/RequestEmailCodeRequestBody";
import {ResponseCodes} from "../network/ResponseCodes";
import {VerifyEmailCodeRequestBody} from "../model/VerifyEmailCodeRequestBody";
import {CountryModel} from "../model/CountryModel";
import {CommonApi} from "../network/CommonApi";
import {useTranslation} from "react-i18next";
import NewH2 from "../styledcomponents/text/NewH2";
import NewP2 from "../styledcomponents/text/NewP2";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../modules";
import NewH5 from "../styledcomponents/text/NewH5";
import {CodeModel} from "../model/CodeModel";
import SingleAutoComplete from "../components/SingleAutoComplete";

const InputErrorText = styled(Text)`
    margin-top: 4px;
    font-size: 12px;
    text-align: left;
    color: ${Colors.INPUT_ERROR};
`

const Label = styled(NewH5)`
    margin-top: 34px;
    text-align: left;
    color: ${Colors.GRAY_7};
`

const CompanyUserSignUpFirstFragment = () => {

    const {language} = useSelector((state: RootState) => state.configurationsReducer, shallowEqual);

    const [userName, setUserName] = useState("")
    const [userNameError, setUserNameError] = useState(false)

    const [emailCode, setEmailCode] = useState("")
    const [emailCodeError, setEmailCodeError] = useState(false)
    const emailCodeRef = useRef<HTMLInputElement>(null)
    const [emailCodeConfirmed, setEmailCodeConfirmed] = useState(false)
    const [emailCodeRequested, setEmailCodeRequested] = useState(false)

    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(false)
    const emailRef = useRef<HTMLInputElement>(null)

    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState(false)

    const [passwordCheck, setPasswordCheck] = useState("")
    const [passwordCheckError, setPasswordCheckError] = useState(false)

    const [countryNumberCode, setCountryNumberCode] = useState<CodeModel | null>(null)
    const [phoneNumber, setPhoneNumber] = useState("")
    const [phoneNumberError, setPhoneNumberError] = useState(false)
    const phoneNumberRef = useRef<HTMLInputElement>(null)

    const [countries, setCountries] = useState<Array<CountryModel>>([])
    const [codeCountries, setCodeCountries] = useState<Array<CodeModel>>([])

    const navigate = useNavigate()
    const isMobile = useMobile()

    const {t} = useTranslation()
    const countryLabel = t('country')


    useEffect(() => {
        CommonApi.getCountries(language)
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                const countries = response.data.data
                if (responseCode.isSuccess() && countries && countries.length > 0) {
                    setCountries(countries)
                    setCodeCountries(countries.map(item => {
                        return {
                            code: item.countryCode,
                            name: `${item.countryName} +${item.countryNumber}`
                        }
                    }))
                }
            })
    }, [language])

    function handleNextClick() {
        if (userName.length === 0) {
            setUserNameError(true)
            return
        }
        if (!RegexUtils.checkEmail(email)) {
            setEmailError(true)
            return;
        }
        if (!emailCodeConfirmed) {
            setEmailCodeError(true)
            emailCodeRef?.current?.focus()
            return;
        }
        if (!RegexUtils.checkPassword(password)) {
            setPasswordError(true)
            return;
        }
        if (password !== passwordCheck) {
            setPasswordCheckError(true)
            return;
        }
        if (phoneNumber.length !== 11) {
            setPhoneNumberError(true)
            phoneNumberRef?.current?.focus()
            return;
        }
        navigate("/company-user-signup-2", {
            state: {
                userId: email,
                password: password,
                userName: userName,
                phoneNumber: phoneNumber,
                countryNumber: countryNumberCode !== null ? countryNumberCode.code : ""
            }
        })
    }

    function handleRequestEmailCode() {
        if (email === "") {
            setEmailError(true)
            return;
        }
        if (!RegexUtils.checkEmail(email)) {
            setEmailError(true)
            return;
        }
        UsersAPI.requestEmailCode(new RequestEmailCodeRequestBody(email))
            .then((response) => response.data)
            .then((data) => {
                const responseCode = ResponseCodes.of(data.code)
                if (responseCode.isSuccess()) {
                    setEmailCodeRequested(true)
                    alert(t('alert_verification_code_has_been_sent'))
                } else if (responseCode.isAlreadyRegisteredEmail()) {
                    alert(t('alert_email_has_already_been_registered'))
                } else {
                    alert(t('alert_failed_to_send_the_verification_code'))
                }
            })
            .catch((e) => {
                alert(t('alert_failed_to_send_the_verification_code'))
            })
    }

    function handleVerifyEmailCode() {
        if (emailCode.length === 0) {
            setEmailCodeError(true)
            emailCodeRef?.current?.focus()
            return
        }
        UsersAPI.verifyEmailCode(new VerifyEmailCodeRequestBody(email, emailCode))
            .then((response) => response.data)
            .then((data) => {
                const responseCode = ResponseCodes.of(data.code);
                if (responseCode.isSuccess()) {
                    setEmailCodeConfirmed(true)
                    alert(t('alert_verified'))
                } else {
                    setEmailCodeError(true)
                    emailCodeRef?.current?.focus()
                }
            })
            .catch((e) => {
                alert(t('alert_a_temporary_error_has_occurred'))
            })
    }

    return (
        <ContentContainer width={"100%"}
                          padding={"0px 20px"}
                          alignItems={"center"}>
            <FlexBox
                maxWidth={"480px"}
                width={"100%"}
                flexDirection={"column"}>
                <NewH2 marginTop={"64px"}
                       color={Colors.GRAY_8}>
                    {t('company_user_auth')}
                </NewH2>
                <FlexBox
                    marginTop={"40px"}
                    alignItems={"center"}
                    width={"100%"}
                    justifyContent={"space-between"}>
                    <FlexBox
                        minWidth={"64px"}
                        minHeight={"64px"}
                        backgroundColor={Colors.MAIN}
                        borderRadius={"100px"}
                        justifyContent={"center"}
                        alignItems={"center"}>
                        <NewH2
                            color={Colors.WHITE}>
                            1
                        </NewH2>
                    </FlexBox>
                    <HorizontalDivider
                        width={"100%"}
                        margin={"0px 16px"}
                        height={"1px"}
                        color={Colors.GRAY_3}/>
                    <FlexBox
                        minWidth={"64px"}
                        minHeight={"64px"}
                        backgroundColor={Colors.SECONDARY_HOVER}
                        borderRadius={"100px"}
                        justifyContent={"center"}
                        alignItems={"center"}>
                        <NewH2
                            color={Colors.GRAY_7}>
                            2
                        </NewH2>
                    </FlexBox>
                    <HorizontalDivider
                        width={"100%"}
                        margin={"0px 16px"}
                        height={"1px"}
                        color={Colors.GRAY_3}/>
                    <FlexBox
                        minWidth={"64px"}
                        minHeight={"64px"}
                        backgroundColor={Colors.SECONDARY_HOVER}
                        borderRadius={"100px"}
                        justifyContent={"center"}
                        alignItems={"center"}>
                        <NewH2
                            color={Colors.GRAY_7}>
                            3
                        </NewH2>
                    </FlexBox>
                </FlexBox>
                <FlexBox
                    marginTop={"12px"}
                    alignItems={"center"}
                    width={"100%"}
                    justifyContent={"space-between"}>
                    <NewH5
                        width={"64px"}
                        color={Colors.GRAY_7}>
                        {t('user_info')}
                    </NewH5>
                    <NewH5
                        width={"64px"}
                        color={Colors.GRAY_7}>
                        {t('company_info')}
                    </NewH5>
                    <NewH5
                        width={"64px"}
                        color={Colors.GRAY_7}>
                        {t('register_finish')}
                    </NewH5>
                </FlexBox>
                <TextField
                    width={"100%"}
                    label={`${t('name')}`}
                    labelFontWight={700}
                    labelFontSize={"16px"}
                    marginTop={"34px"}
                    error={userNameError}
                    errorText={`${t('alert_please_enter_your_name2')}`}
                    value={userName}
                    onChange={(e) => {
                        if (userNameError) {
                            setUserNameError(false)
                        }
                        setUserName(e.target.value)
                    }}
                    type={"text"}
                />
                <Label>{t('business_email')}</Label>
                <FlexBox width={"100%"}
                         marginTop={"20px"}
                         alignItems={"center"}
                         flexDirection={isMobile ? "column" : "row"}>
                    <Input
                        autoComplete={"off"}
                        ref={emailRef}
                        width={"100%"}
                        alignSelf={"center"}
                        error={emailError}
                        disabled={emailCodeConfirmed}
                        onChange={(e) => {
                            if (emailError) {
                                setEmailError(false)
                            }
                            setEmail(e.target.value)
                        }}
                        type={"email"}
                        value={email}
                    />
                    <Button
                        width={isMobile ? "100%" : undefined}
                        minWidth={"90px"}
                        fontSize={"16px"}
                        paddingLeft={"10px"}
                        paddingRight={"10px"}
                        backgroundColor={Colors.SECONDARY_HOVER}
                        border={emailCodeConfirmed ? `1px solid ${Colors.GRAY_4}` : `1px solid ${Colors.SECONDARY_PRESSED}`}
                        backgroundOnDisabled={Colors.GRAY_1}
                        colorOnDisabled={Colors.GRAY_5}
                        disabled={emailCodeConfirmed}
                        fontWeight={500}
                        color={Colors.MAIN}
                        marginTop={isMobile ? "10px" : "0px"}
                        alignSelf={"flex-start"}
                        marginLeft={isMobile ? "0px" : "10px"}
                        onClick={() => {
                            if (!emailCodeConfirmed) {
                                handleRequestEmailCode()
                            }
                        }}>
                        {t('get_verification_code')}
                    </Button>
                </FlexBox>
                {emailError && <InputErrorText>{t('invalid_email_format')}</InputErrorText>}
                {emailCodeRequested && <Label>{t('business_email_code')}</Label>}
                {emailCodeRequested && <FlexBox width={"100%"}
                                                marginTop={"20px"}
                                                alignItems={"center"}
                                                flexDirection={isMobile ? "column" : "row"}>
                    <Input
                        autoComplete={"off"}
                        ref={emailCodeRef}
                        width={"100%"}
                        alignSelf={"center"}
                        error={emailCodeError}
                        disabled={emailCodeConfirmed}
                        onChange={(e) => {
                            if (emailCodeError) {
                                setEmailCodeError(false)
                            }
                            setEmailCode(e.target.value)
                        }}
                        type={"text"}
                        value={emailCode}
                    />
                    <Button
                        minWidth={"80px"}
                        maxWidth={"80px"}
                        fontSize={"14px"}
                        paddingLeft={"10px"}
                        paddingRight={"10px"}
                        backgroundColor={Colors.SECONDARY_HOVER}
                        color={Colors.MAIN}
                        border={emailCodeConfirmed ? `1px solid ${Colors.GRAY_4}` : `1px solid ${Colors.SECONDARY_PRESSED}`}
                        backgroundOnDisabled={Colors.GRAY_1}
                        colorOnDisabled={Colors.GRAY_5}
                        disabled={emailCodeConfirmed}
                        marginTop={isMobile ? "10px" : "0px"}
                        alignSelf={"flex-start"}
                        marginLeft={isMobile ? "0px" : "10px"}
                        onClick={() => {
                            if (!emailCodeConfirmed) {
                                handleVerifyEmailCode()
                            }
                        }}>
                        {emailCodeConfirmed ? `${t('code_confirmed')}` : `${t('code_confirm')}`}
                    </Button>
                </FlexBox>}
                {emailCodeError && <InputErrorText>{t('please_check_verification_code')}</InputErrorText>}
                {emailCodeConfirmed && <Text
                    marginTop={"4px"}
                    fontSize={"12px"}
                    textAlign={"left"}>{t('code_verified')}</Text>}

                <TextField
                    width={"100%"}
                    label={`${t('password')}`}
                    labelFontWight={700}
                    labelFontSize={"16px"}
                    marginTop={"34px"}
                    error={passwordError}
                    value={password}
                    onChange={(e) => {
                        if (passwordError) {
                            setPasswordError(false)
                        }
                        setPassword(e.target.value)
                    }}
                    type={"password"}
                />
                <NewP2
                    color={passwordError ? Colors.INPUT_ERROR : Colors.GRAY_6}
                    marginTop={"8px"}
                    textAlign={"left"}>
                    {t('invalid_password_format')}</NewP2>
                <TextField
                    width={"100%"}
                    label={`${t('password_confirm')}`}
                    labelFontSize={"16px"}
                    labelFontWight={700}
                    marginTop={"34px"}
                    error={passwordCheckError}
                    errorText={`${t('password_check_not_equals')}`}
                    value={passwordCheck}
                    onChange={(e) => {
                        if (passwordCheckError) {
                            setPasswordCheckError(false)
                        }
                        setPasswordCheck(e.target.value)
                    }}
                    type={"password"}
                />
                <Label>{t('phone')}</Label>
                <FlexBox width={"100%"}
                         marginTop={"20px"}
                         alignItems={"center"}
                         flexDirection={"column"}>

                    <SingleAutoComplete
                        hint={countryLabel}
                        enabledStartAdornment={false}
                        width={"100%"}
                        labelFontWight={500}
                        labelFontSize={"16px"}
                        labelVisible={false}
                        label={countryLabel}
                        items={codeCountries}
                        value={countryNumberCode}
                        onChangedValues={(value) => {
                            setCountryNumberCode(value)
                        }}
                    />
                    <Input
                        autoComplete={"off"}
                        ref={phoneNumberRef}
                        width={"100%"}
                        alignSelf={"center"}
                        marginTop={"10px"}
                        error={phoneNumberError}
                        maxLength={11}
                        onChange={(e) => {
                            if (phoneNumberError) {
                                setPhoneNumberError(false)
                            }
                            setPhoneNumber(KeyboardUtils.clearNumber(e.target.value))
                        }}
                        type={"tel"}
                        value={phoneNumber}
                    />
                </FlexBox>
                {phoneNumberError && <InputErrorText>{t('alert_please_enter_your_phone_number2')}</InputErrorText>}

                <Button
                    alignSelf={"center"}
                    marginTop={"34px"}
                    marginBottom={"64px"}
                    width={"100%"}
                    onClick={() => handleNextClick()}>{t('next')}</Button>
            </FlexBox>
        </ContentContainer>
    )
}
export default CompanyUserSignUpFirstFragment;