/**
 * @author jaeho.lee104 on 2023. 08. 11..
 */
import FlexBox from "../styledcomponents/FlexBox";
import NewH2 from "../styledcomponents/text/NewH2";
import {Colors, Sizes} from "../styledcomponents/Styles";
import NewP2 from "../styledcomponents/text/NewP2";
import NewP4 from "../styledcomponents/text/NewP4";
import NewH3 from "../styledcomponents/text/NewH3";
import NewH4 from "../styledcomponents/text/NewH4";
import Image from "../styledcomponents/Image";
import React, {useEffect, useState} from "react";
import Button from "../styledcomponents/Button";
import NewP1 from "../styledcomponents/text/NewP1";
import NewH5 from "../styledcomponents/text/NewH5";
import Box from "../styledcomponents/Box";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {RootState} from "../modules";
import {useTranslation} from "react-i18next";
import {BillingAPI} from "../network/BillingAPI";
import {ResponseUtils} from "../utils/ResponseUtils";
import {FormatUtils} from "../utils/FormatUtils";
import {DatePicker, Popover, TimeRangePickerProps} from "antd";
import {ResponseCodes} from "../network/ResponseCodes";
import {UsersAPI} from "../network/UsersAPI";
import {UsingServiceResult} from "../model/UsingServiceResult";
import {DateFormatUtils} from "../utils/DateFormatUtils";
import {CardUtils} from "../utils/CardUtils";
import {CardInfo} from "../model/CardInfo";
import type {Dayjs} from 'dayjs';
import dayjs from 'dayjs';
import "./PaymentManagerFragment.css";

import {FormControl, MenuItem, OutlinedInput, Select, SelectChangeEvent} from "@mui/material";
import {ExpandMoreOutlined} from "@mui/icons-material";
import useMobile from "../uses/UseMobile";
import {CommonUtils} from "../utils/CommonUtils";
import 'react-spring-bottom-sheet/dist/style.css'
import NewP3 from "../styledcomponents/text/NewP3";
import PremiumPaymentDialog from "../dialog/PremiumPaymentDialog";
import PremiumPaymentBottomSheet from "../dialog/PremiumPaymentBottomSheet";
import PlanIntroDialog from "../dialog/PlanIntroDialog";
import PlanIntroBottomSheet from "../dialog/PlanIntroBottomSheet";
import PremiumCancelDialog from "../dialog/PremiumCancelDialog";
import PremiumCancelBottomSheet from "../dialog/PremiumCancelBottomSheet";
import {AxiosResponse} from "axios";
import {BillingService} from "../model/BillingService";
import {PaypleAPI} from "../network/PaypleAPI";
import * as Auths from "../modules/auths";
import SimpleDialog, {ButtonType} from "../dialog/SimpleDialog";
import LoadingDialog from "../dialog/LoadingDialog";
import {useMediaQuery} from "react-responsive";

const {RangePicker} = DatePicker;


const paymentKeyColumnWidth = "152px"
const paymentDateColumnWidth = "120px"
const paymentUsagePeriodColumnWidth = "120px"
const paymentStateColumnWidth = "76px"
const paymentPriceColumnWidth = "152px"
const paymentBillColumnWidth = "116px"
const paymentMoreColumnWidth = "56px"

const paymentApprovedPath = require(`assets/images/ic-payment-approved.svg`).default
const paymentCanceledPath = require(`assets/images/ic-payment-canceled.svg`).default

const creditCardEtcPath = require(`assets/images/ic-credit-card-etc.svg`).default
const creditCardEmptyPath = require(`assets/images/ic-credit-card-empty.svg`).default

const dateFormat = 'YYYY-MM-DD';

const rangePresets: TimeRangePickerProps['presets'] = [
    {label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()]},
    {label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()]},
    {label: 'Last 1 Year', value: [dayjs().add(-365, 'd'), dayjs()]},
];

const PaymentManagerFragment = () => {

    const {accessToken, user} = useSelector((state: RootState) => state.usersReducer, shallowEqual);
    const {premiumActivated, paymentState} = useSelector((state: RootState) => state.auths, shallowEqual);
    const {language} = useSelector((state: RootState) => state.configurationsReducer, shallowEqual);
    const [refreshCount, setRefreshCount] = useState(0)
    const [searchTrigger, setSearchTrigger] = useState(0)
    const [billingHistories, setBillingHistories] = useState<Array<BillingHistory> | null>(null);
    const {t} = useTranslation()
    const [usingServiceResult, setUsingServiceResult] = useState<UsingServiceResult | null>(null)
    const [cardInfo, setCardInfo] = useState<CardInfo | null>(null)
    const [paymentHistoriesStartDate, setPaymentHistoriesStartDate] = useState(() => {
        const currentDate = new Date()
        currentDate.setFullYear(currentDate.getFullYear() - 1);
        return currentDate
    });
    const [paymentHistoriesEndDate, setPaymentHistoriesEndDate] = useState(new Date());
    const [paymentHistoriesFilterOption, setPaymentHistoriesFilterOption] = useState(0)
    const [filteredBillingHistories, setFilteredBillingHistories] = useState<Array<BillingHistory> | null>(null);
    const [billingServices, setBillingServices] = useState<Array<BillingService>>([])
    const [pendingResubscribe, setPendingResubscribe] = useState(false)

    const [isPlanDialogOpen, setIsPlanDialogOpen] = useState(false)
    const [isPremiumPaymentDialogOpen, setIsPremiumPaymentDialogOpen] = useState(false)
    const [isPremiumCancelDialogOpen, setIsPremiumCancelDialogOpen] = useState(false)
    const [isRemoveCardDialogOpen, setIsRemoveCardDialogOpen] = useState(false)
    const [isResubscribeDialogOpen, setIsResubscribeDialogOpen] = useState(false)
    const [isLoadingDialogOpen, setIsLoadingDialogOpen] = useState(false)

    const isTablet = useMediaQuery({
        query: Sizes.MEDIA_QUERY_1200,
    })
    const isMobile = useMobile()
    const dispatch = useDispatch()

    const EmptyPaymentHistories = () => {
        return <FlexBox
            width={"100%"}
            height={"200px"}
            justifyContent={"center"}
            alignItems={"center"}>
            <Image
                width={"24px"}
                height={"24px"}
                src={require("assets/images/ic-payment-history.svg").default}
                alt="ic-check"/>
            <NewP3
                marginLeft={"8px"}
                color={Colors.GRAY_6}>{t('empty_billing_history')}</NewP3>
        </FlexBox>
    }

    const paymentHistoriesFilters = [
        {
            id: 0,
            name: `${t('all')}`
        }, {
            id: 1,
            name: `${t('approved')}`
        }, {
            id: 2,
            name: `${t('canceled')}`
        }, {
            id: 3,
            name: `${t('error')}`
        }]

    const premiumPlanDescriptions = [
        `${t('premium_plan_description_1')}`,
        `${t('premium_plan_description_2')}`,
        `${t('premium_plan_description_3')}`
    ]
    const freePlanDescriptions = [
        `${t('standard_plan_description_1')}`,
        `${t('standard_plan_description_2')}`,
        `${t('standard_plan_description_3')}`
    ]

    const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates) {
            setPaymentHistoriesStartDate(new Date(dateStrings[0]))
            setPaymentHistoriesEndDate(new Date(dateStrings[1]))
        }
    };

    useEffect(() => {
        if (cardInfo && pendingResubscribe) {
            setPendingResubscribe(false)
            resubscribe()
        }
    }, [cardInfo, pendingResubscribe])

    useEffect(() => {
        const filtered: Array<BillingHistory> = billingHistories ? billingHistories.filter((value, index, array) => {
            return paymentHistoriesFilterOption === 0
                || (paymentHistoriesFilterOption === 1 && value.status === "SUCCESS")
                || (paymentHistoriesFilterOption === 2 && value.status === "CANCELED")
                || (paymentHistoriesFilterOption === 3 && value.status === "FAIL")
        }) : []
        setFilteredBillingHistories(filtered)
    }, [paymentHistoriesFilterOption])

    useEffect(() => {
        const filtered: Array<BillingHistory> = billingHistories ? billingHistories.filter((value, index, array) => {
            return paymentHistoriesFilterOption === 0
                || (paymentHistoriesFilterOption === 1 && value.status === "SUCCESS")
                || (paymentHistoriesFilterOption === 2 && value.status === "CANCELED")
                || (paymentHistoriesFilterOption === 3 && value.status === "FAIL")
        }) : []
        setFilteredBillingHistories(filtered)
    }, [billingHistories])

    useEffect(() => {
        if (!accessToken) {
            return;
        }
        BillingAPI.getCardInfo()
            .then((response) => {
                let cardInfo = response.data.data;
                if (cardInfo && CardUtils.isCardInfoValid(cardInfo)) {
                    setCardInfo(cardInfo)
                } else {
                    setCardInfo(null);
                }
            })
            .catch((error) => {
                setCardInfo(null);
            });
    }, [accessToken, refreshCount, paymentState]);

    useEffect(() => {
        if (!accessToken) {
            return;
        }
        UsersAPI.getUsingService()
            .then((response) => {
                let usingServiceResult = response.data?.data;
                setUsingServiceResult(usingServiceResult)
            })
    }, [accessToken, refreshCount, paymentState]);

    useEffect(() => {
        if (!accessToken) {
            return;
        }
        BillingAPI.getBillingHistories(
            DateFormatUtils.toYYYYMMDD(paymentHistoriesStartDate.toDateString()),
            DateFormatUtils.toYYYYMMDD(paymentHistoriesEndDate.toDateString()))
            .then((response) => {
                if (ResponseUtils.isSuccess(response)) {
                    let histories = response.data.data || [];
                    setBillingHistories(histories);
                }
            })
            .catch(e => {
                setBillingHistories([])
            })
    }, [accessToken, refreshCount, searchTrigger, paymentState]);

    useEffect(() => {
        if (!accessToken) {
            return;
        }
        BillingAPI.getBillingServices()
            .then((res: AxiosResponse<BaseResponse<Array<BillingService>>>) => {
                const services = res.data.data || []
                setBillingServices(services)
            })

    }, [accessToken])

    async function cancelPayment(historyKey: string, forceCancel: boolean = false) {
        setIsLoadingDialogOpen(true)
        await BillingAPI.cancelBilling(historyKey, "", forceCancel)
            .then(res => {
                const responseCode = ResponseCodes.of(res.data.code)
                if (responseCode.isSuccess()) {
                    dispatch(Auths.refreshPaymentState())
                } else {
                    alert(`결제취소 실패: [${res.data.code}] ${res.data.message}`)
                }
            })
            .catch(e => {
                alert(t('alert_a_temporary_error_has_occurred'))
            })
        setIsLoadingDialogOpen(false)
    }

    const doAddCard = () => {
        if (billingServices.length === 0) {
            alert(t('no_found_service'))
            return
        }
        PaypleAPI.getPaypleOrderId(billingServices[0].itemId, "card")
            .then((res) => {
                const result = res.data.data
                const oid = result?.payOID
                const payerNo = result?.payerNo
                if (!payerNo) {
                    alert(t('no_found_payer_no'))
                    return
                }
                const options = 'top=10, left=10, width=380, height=1080, ' +
                    'status=no, ' +
                    'menubar=no,' +
                    ' toolbar=no,' +
                    ' resizable=no, scrollbar=yes';
                window.open(`/payment/window?language=kr&type=AUTH&payerNo=${payerNo}&orderId=${oid}`, "pay-window", options)
            })
            .catch((e) => {
                alert(t('alert_a_temporary_error_has_occurred'))
            })
    }

    const doSubscribe = (itemId: string,
                         itemName: string,
                         itemPrice: number) => {
        if (itemId.length === 0) {
            alert(t('no_found_service'))
            return
        }
        if (cardInfo?.cardId) {
            BillingAPI.doSubscribe(itemId)
                .then((res) => {
                    const responseCode = ResponseCodes.of(res.data.code)
                    if (responseCode.isSuccess()) {
                        alert(t('payment_completed'))
                        dispatch(Auths.refreshPaymentState())
                    } else {
                        alert(`${t('payment_failed')}: [${res.data.code}] ${res.data.message}`)
                    }
                })
                .catch((e) => {
                    alert(t('alert_a_temporary_error_has_occurred'))
                })
        } else {
            PaypleAPI.getPaypleOrderId(itemId, "payment")
                .then((res) => {
                    const result = res.data.data
                    const orderId = result?.payOID
                    const payerNo = result?.payerNo
                    if (!orderId) {
                        alert(t('no_order_id'))
                        return
                    }
                    if (!payerNo) {
                        alert(t('no_found_payer_no'))
                        return
                    }
                    const options = 'top=10, left=10, width=380, height=1080, ' +
                        'status=no, ' +
                        'menubar=no,' +
                        ' toolbar=no,' +
                        ' resizable=no, scrollbar=yes';
                    window.open(`/payment/window?orderId=${orderId}&language=kr&type=PAY&payerNo=${payerNo}&product=${itemName}&price=${itemPrice}`, "pay-window", options)
                })
                .catch((e) => {
                    alert(t('alert_a_temporary_error_has_occurred'))
                })
        }
    }

    function cancelSubscribe() {
        if (billingServices.length === 0) {
            alert(t('no_found_service'))
            return
        }
        const itemId = billingServices[0].itemId
        if (!usingServiceResult) {
            alert(t('no_found_using_plan'))
            return;
        }
        BillingAPI.cancelSubscribe(itemId)
            .then((res) => {
                const responseCode = ResponseCodes.of(res.data.code)
                if (responseCode.isSuccess()) {
                    alert(t('cancel_subscribe_completed'))
                    dispatch(Auths.refreshPaymentState())
                } else {
                    alert(`${t('cancel_subscribe_failed')}: [${res.data.code}] ${res.data.message}`)
                }
            })
            .catch((e) => {
                alert(t('alert_a_temporary_error_has_occurred'))
            })
    }

    function resubscribe() {
        if (!cardInfo) {
            setPendingResubscribe(true)
            doAddCard()
            return
        }
        if (!usingServiceResult) {
            alert(t('no_found_using_plan'))
            return;
        }
        doSubscribe(
            usingServiceResult.itemId,
            usingServiceResult.itemName,
            Number(usingServiceResult.itemPrice) + Number(usingServiceResult.itemPriceVat)
        )
    }

    function removeAddedCard() {
        if (!cardInfo) {
            alert(t('no_found_added_card'))
            return
        }
        BillingAPI.removeCard(cardInfo.cardId)
            .then((res) => {
                const responseCode = ResponseCodes.of(res.data.code)
                if (responseCode.isSuccess()) {
                    alert(t('remove_card_completed'))
                    dispatch(Auths.refreshPaymentState())
                } else {
                    alert(`${t('remove_card_failed')}: [${res.data.code}] ${res.data.message}`)
                }
            })
            .catch((e) => {
                alert(t('alert_a_temporary_error_has_occurred'))
            })
    }

    function getServicePrice(): string {
        return billingServices.length > 0 ? billingServices[0].itemPrice : "199000"
    }

    return <FlexBox
        id={"payment-manager-fragment-container"}
        width={"100%"}
        padding={isTablet ? "0px 24px" : undefined}
        flexDirection={"column"}>
        <NewH2
            color={Colors.GRAY_8}>
            {t('payment_manage')}
        </NewH2>
        <NewP2
            marginTop={"13px"}
            color={Colors.GRAY_7}
        >{t('check_payment_info')}</NewP2>
        <NewP4 color={Colors.GRAY_6}
               marginTop={"13px"}>
            {t('not_collect_payment_info')}
        </NewP4>
        <FlexBox
            justifyContent={"space-between"}
            height={"fit-content"}
            marginTop={"40px"}
            flexDirection={isTablet ? "column" : "row"}>
            <FlexBox
                borderRadius={"16px"}
                boxShadow={"0px 2px 40px 0px rgba(0, 0, 0, 0.05)"}
                border={`1px solid ${Colors.GRAY_2}`}
                justifyContent={"space-between"}
                backgroundColor={Colors.WHITE}
                flexDirection={"column"}
                width={"100%"}
                maxWidth={isTablet ? undefined : "452px"}
                padding={"16px 32px"}>
                <FlexBox
                    width={"100%"}
                    flexDirection={"column"}>
                    <NewH3
                        color={Colors.GRAY_8}
                        marginTop={"16px"}>{t('using_plan')}</NewH3>
                    <NewP2
                        color={Colors.GRAY_7}
                        marginTop={"8px"}>{t('plan_description')}</NewP2>
                </FlexBox>
                <FlexBox
                    flexDirection={"column"}
                    width={"100%"}>
                    <FlexBox
                        padding={"12px 16px"}
                        height={!isMobile ? "80px" : undefined}
                        visible={usingServiceResult !== null}
                        borderRadius={"12px"}
                        backgroundColor={Colors.GRAY_0}
                        marginTop={"32px"}
                        width={"100%"}
                        justifyContent={"space-around"}
                        flexDirection={isMobile ? "column" : "row"}
                        border={`1px solid ${Colors.GRAY_2}`}>
                        <FlexBox
                            width={"100%"}
                            flexDirection={"column"}>
                            <NewP2
                                color={Colors.GRAY_7}>{t('plan')}</NewP2>
                            <FlexBox
                                alignItems={"center"}
                                minWidth={"170px"}
                                marginTop={"8px"}>
                                <NewH4
                                    textAlign={"left"}
                                    color={Colors.GRAY_7}>{premiumActivated ? "Premium Plan" : "Standard Plan"}</NewH4>
                                <Popover
                                    id={"plan-help"}
                                    placement={"bottom"}
                                    trigger="hover"
                                    content={
                                        <FlexBox flexDirection={"column"}
                                                 width={"fit-content"}>
                                            <FlexBox
                                                alignItems={"center"}>
                                                <Image
                                                    width={"16px"}
                                                    height={"16px"}
                                                    src={require("assets/images/ic-check-2.svg").default}
                                                    alt="ic-check"/>
                                                <NewP2
                                                    marginLeft={"8px"}
                                                    color={Colors.GRAY_2}>
                                                    {premiumActivated ? premiumPlanDescriptions[0] : freePlanDescriptions[0]}
                                                </NewP2>
                                            </FlexBox>
                                            <FlexBox
                                                marginTop={"8px"}
                                                alignItems={"center"}>
                                                <Image
                                                    cursor={"pointer"}
                                                    width={"16px"}
                                                    height={"16px"}
                                                    src={require("assets/images/ic-check-2.svg").default}
                                                    alt="ic-check"/>
                                                <NewP2
                                                    marginLeft={"8px"}
                                                    color={Colors.GRAY_2}>
                                                    {premiumActivated ? premiumPlanDescriptions[1] : freePlanDescriptions[1]}
                                                </NewP2>
                                            </FlexBox>
                                            <FlexBox
                                                marginTop={"8px"}
                                                alignItems={"center"}>
                                                <Image
                                                    cursor={"pointer"}
                                                    width={"16px"}
                                                    height={"16px"}
                                                    src={require("assets/images/ic-check-2.svg").default}
                                                    alt="ic-check"/>
                                                <NewP2
                                                    marginLeft={"8px"}
                                                    color={Colors.GRAY_2}>
                                                    {premiumActivated ? premiumPlanDescriptions[2] : freePlanDescriptions[2]}
                                                </NewP2>
                                            </FlexBox>
                                        </FlexBox>}>
                                    <Image
                                        cursor={"pointer"}
                                        width={"24px"}
                                        height={"24px"}
                                        marginLeft={"8px"}
                                        src={require("assets/images/ic-help.svg").default}
                                        alt="ic-help"/>
                                </Popover>
                            </FlexBox>
                        </FlexBox>
                        <FlexBox
                            width={"100%"}
                            flexDirection={"column"}
                            marginLeft={isMobile ? undefined : "8px"}
                            marginTop={isMobile ? "16px" : undefined}>
                            <FlexBox alignItems={"center"}>
                                <NewP2 color={Colors.GRAY_7}>{t('plan_price')}</NewP2>
                                <NewP2 color={Colors.GRAY_6} marginLeft={"4px"}>{t('additional_vat')}</NewP2>
                            </FlexBox>
                            <FlexBox
                                marginTop={"8px"}
                                alignItems={"flex-end"}>
                                <NewH4
                                    color={Colors.GRAY_7}>{usingServiceResult ? `${FormatUtils.toCurrencyFormat(String(usingServiceResult.itemPrice))}` : '0'}{t('KRW')}</NewH4>
                                {usingServiceResult && <NewP2 color={Colors.GRAY_6}
                                                              marginLeft={"4px"}>{`/${t('monthly')}`}</NewP2>}
                            </FlexBox>
                        </FlexBox>

                    </FlexBox>
                    <Button
                        disabled={premiumActivated}
                        backgroundOnDisabled={Colors.WHITE}
                        colorOnDisabled={Colors.CTA_DISABLED}
                        height={"44px"}
                        minHeight={"44px"}
                        marginTop={"24px"}
                        marginBottom={"16px"}
                        width={"100%"}
                        fontSize={"16px"}
                        fontWeight={500}
                        lineHeight={"150%"}
                        color={Colors.MAIN}
                        border={`1px solid ${premiumActivated ? Colors.CTA_DISABLED : Colors.SECONDARY_PRESSED}`}
                        onClick={() => {
                            if (premiumActivated) {
                                alert(t('already_using_premium_plan'))
                            } else {
                                setIsPlanDialogOpen(true)
                            }
                        }}
                        backgroundColor={Colors.SECONDARY_HOVER}>
                        {t('upgrade_plan')}
                    </Button>
                </FlexBox>
            </FlexBox>
            <FlexBox
                justifyContent={"space-between"}
                borderRadius={"16px"}
                marginTop={isTablet ? "40px" : undefined}
                border={`1px solid ${Colors.GRAY_2}`}
                boxShadow={"0px 2px 40px 0px rgba(0, 0, 0, 0.05)"}
                backgroundColor={Colors.WHITE}
                flexDirection={"column"}
                width={"100%"}
                maxWidth={isTablet ? undefined : "452px"}
                padding={"16px 32px"}>
                <FlexBox
                    width={"100%"}
                    flexDirection={"column"}>
                    <NewH3
                        color={Colors.GRAY_8}
                        marginTop={"16px"}>{t('payment_method')}</NewH3>
                    <Box
                        visible={!premiumActivated}
                        marginTop={"8px"}
                        width={"100%"}
                        height={"21px"}/>
                    <FlexBox
                        visible={premiumActivated}
                        marginTop={"8px"}

                        flexDirection={isMobile ? "column" : "row"}>
                        <NewP2 color={Colors.GRAY_7}>
                            {usingServiceResult?.subscribe ?
                                `${t('using_plan_billing_info', {endDate: DateFormatUtils.toYYYY_MM_DD_unit(usingServiceResult?.endDate, language)})}`
                                : `${t('using_plan_finish_info', {endDate: DateFormatUtils.toYYYY_MM_DD_unit(usingServiceResult?.endDate, language)})}`}</NewP2>
                        <NewP1
                            marginLeft={isMobile ? undefined : '8px'}
                            marginTop={isMobile ? "8px" : undefined}
                            cursor={"pointer"}
                            onClick={() => {
                                if (usingServiceResult?.subscribe) {
                                    setIsPremiumCancelDialogOpen(true)
                                } else {
                                    setIsResubscribeDialogOpen(true)
                                }
                            }}
                            color={Colors.MAIN}>
                            {usingServiceResult?.subscribe ? `${t('cancel_subscribe')}` : `${t('resubscribe')}`}
                        </NewP1>
                    </FlexBox>
                </FlexBox>
                <FlexBox
                    width={"100%"}
                    flexDirection={"column"}>
                    <FlexBox
                        padding={"12px 16px"}
                        height={"80px"}
                        borderRadius={"12px"}
                        backgroundColor={Colors.GRAY_0}
                        marginTop={"32px"}
                        width={"100%"}
                        border={`1px solid ${Colors.GRAY_2}`}>
                        <FlexBox
                            backgroundColor={Colors.GRAY_3}
                            width={"48px"}
                            height={"48px"}
                            borderRadius={"100px"}
                            alignItems={"center"}
                            justifyContent={"center"}>
                            <Image
                                width={"24px"}
                                height={"24px"}
                                src={cardInfo ? creditCardEtcPath : creditCardEmptyPath}
                                alt="ic-card"/>
                        </FlexBox>
                        <FlexBox
                            marginLeft={"24px"}
                            flexDirection={"column"}>
                            <NewH5
                                color={Colors.GRAY_7}>{cardInfo ? cardInfo.cardNumber : `${t('no_added_card')}`}</NewH5>
                            <NewP2
                                color={Colors.GRAY_7}>{cardInfo ? cardInfo.userName : `${t('no_card_info')}`}</NewP2>
                        </FlexBox>
                        {cardInfo && <Popover
                            placement={"top"}
                            trigger="click"
                            content={
                                <FlexBox flexDirection={"column"}
                                         width={"fit-content"}
                                         cursor={"pointer"}
                                         onClick={() => {
                                             setIsRemoveCardDialogOpen(true)
                                         }}>
                                    <NewP2 color={Colors.DESTRUCTIVE_PRIMARY}>
                                        {t('delete_card')}
                                    </NewP2>
                                </FlexBox>}>
                            <Image
                                alignSelf={"center"}
                                cursor={"pointer"}
                                position={"absolute"}
                                right={"16px"}
                                width={"24px"}
                                height={"24px"}
                                src={require("assets/images/ic-more-button.svg").default}
                                alt="ic-card-more"/>
                        </Popover>}
                    </FlexBox>
                    <Button
                        marginTop={"24px"}
                        marginBottom={"16px"}
                        minHeight={"44px"}
                        width={"100%"}
                        fontSize={"16px"}
                        fontWeight={500}
                        height={"44px"}
                        lineHeight={"150%"}
                        color={Colors.MAIN}
                        border={`1px solid ${Colors.SECONDARY_PRESSED}`}
                        backgroundColor={Colors.SECONDARY_HOVER}
                        onClick={() => {
                            doAddCard()
                        }}>
                        {cardInfo ? `${t('change_card')}` : `${t('add_card')}`}
                    </Button>
                </FlexBox>
            </FlexBox>
        </FlexBox>

        {filteredBillingHistories !== null && <FlexBox
            flexDirection={"column"}
            marginTop={"40px"}
            boxShadow={"0px 2px 40px 0px rgba(0, 0, 0, 0.05)"}
            backgroundColor={Colors.WHITE}
            border={`1px solid ${Colors.GRAY_2}`}
            borderRadius={"16px"}
            paddingTop={"32px"}
            paddingBottom={"32px"}>
            <FlexBox
                flexDirection={"column"}>
                <FlexBox
                    flexDirection={"column"}
                    paddingLeft={"32px"}
                    paddingRight={"32px"}>
                    <NewH3>
                        {t('payment_history')}
                    </NewH3>
                    <NewP2
                        marginTop={"8px"}>
                        {t('payment_history_description')}
                    </NewP2>

                    <FlexBox
                        alignItems={"center"}
                        flexDirection={isMobile ? "column" : "row"}
                        marginTop={"24px"}>
                        <FormControl sx={{
                            minWidth: isMobile ? "100%" : isTablet ? "120px" : "140px",
                            alignSelf: "flex-start"
                        }}>
                            <Select
                                IconComponent={(props) => <ExpandMoreOutlined {...props}/>}
                                input={<OutlinedInput
                                    sx={{fontSize: "16px"}}
                                />}
                                value={paymentHistoriesFilterOption}
                                onChange={(e: SelectChangeEvent<number>) => {
                                    setPaymentHistoriesFilterOption(Number(e.target.value))
                                }}
                                sx={{
                                    fontSize: "14px",
                                    borderRadius: "8px",
                                    color: Colors.GRAY_7,
                                    fontWeight: 500,
                                    height: "44px",
                                    "&& fieldset": {
                                        border: `1px solid ${Colors.GRAY_4}`
                                    },
                                    "&:hover": {
                                        "&& fieldset": {
                                            border: `1px solid ${Colors.CTA_HOVER}`
                                        }
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        border: `2px solid ${Colors.MAIN}`
                                    },

                                }}>
                                {paymentHistoriesFilters.map((item) => (
                                    <MenuItem
                                        sx={{fontSize: "16px"}}
                                        value={item.id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <RangePicker
                            separator={"~"}
                            defaultValue={[dayjs(paymentHistoriesStartDate), dayjs(paymentHistoriesEndDate)]}
                            presets={rangePresets}
                            style={{
                                marginTop: isMobile ? "16px" : undefined,
                                textAlign: "center",
                                width: isMobile ? "100%" : isTablet ? "312px" : "335px",
                                height: "44px",
                                marginLeft: isMobile ? undefined : "16px"
                            }}
                            format={dateFormat}
                            onChange={onRangeChange}
                            size={"large"}/>
                        <Button
                            marginLeft={isMobile ? undefined : "16px"}
                            width={isMobile ? "100%" : "80px"}
                            height={"44px"}
                            borderRadius={"8px"}
                            border={`1px solid ${Colors.GRAY_4}`}
                            backgroundColor={Colors.WHITE}
                            color={Colors.GRAY_7}
                            fontSize={"16px"}
                            fontWeight={500}
                            lineHeight={"150%"}
                            marginTop={isMobile ? "16px" : undefined}
                            backgroundOnHover={Colors.SECONDARY_HOVER}
                            backgroundOnActive={Colors.SECONDARY_PRESSED}
                            onClick={() => {
                                setSearchTrigger(searchTrigger + 1)
                            }}>
                            {t('Search')}
                        </Button>
                    </FlexBox>
                </FlexBox>
                <FlexBox
                    flexDirection={"column"}
                    width={"0px"}
                    minWidth={"100%"}
                    overflow={"auto"}>
                    <FlexBox
                        borderTop={`1px solid ${Colors.GRAY_3}`}
                        borderBottom={`1px solid ${Colors.GRAY_3}`}
                        alignItems={"center"}
                        marginTop={"24px"}
                        paddingLeft={"32px"}
                        paddingRight={"32px"}
                        justifyContent={"space-around"}
                        minWidth={isTablet ? "840px" : undefined}
                        height={"48px"}>
                        <NewP2
                            maxWidth={paymentKeyColumnWidth}
                            width={"100%"}
                            color={Colors.GRAY_6}
                            paddingLeft={"16px"}
                            paddingRight={"16px"}>{t('payment_id')}</NewP2>
                        <NewP2
                            maxWidth={paymentDateColumnWidth}
                            width={"100%"}
                            color={Colors.GRAY_6}
                            paddingLeft={"16px"}
                            paddingRight={"16px"}>{t('payment_date')}</NewP2>
                        <NewP2
                            maxWidth={paymentUsagePeriodColumnWidth}
                            width={"100%"}
                            color={Colors.GRAY_6}
                            paddingLeft={"16px"}
                            paddingRight={"16px"}>{t('available_date')}</NewP2>
                        <NewP2
                            maxWidth={paymentStateColumnWidth}
                            width={"100%"}
                            color={Colors.GRAY_6}
                            paddingLeft={"16px"}
                            paddingRight={"16px"}>{t('payment_state')}</NewP2>
                        <NewP2
                            maxWidth={paymentPriceColumnWidth}
                            textAlign={"right"}
                            width={"100%"}
                            color={Colors.GRAY_6}
                            paddingLeft={"16px"}
                            paddingRight={"16px"}>{t('price')}</NewP2>
                        <NewP2
                            textAlign={"center"}
                            maxWidth={paymentBillColumnWidth}
                            width={"100%"}
                            color={Colors.GRAY_6}
                            paddingLeft={"16px"}
                            paddingRight={"16px"}>{t('billing_paper')}</NewP2>
                        <Box
                            height={"100%"}
                            maxWidth={paymentMoreColumnWidth}
                            width={paymentMoreColumnWidth}
                        />
                    </FlexBox>
                    <FlexBox
                        width={"100%"}
                        minWidth={isTablet ? "840px" : undefined}
                        visible={filteredBillingHistories.length === 0}
                        flexDirection={"column"}>
                        <EmptyPaymentHistories/>
                    </FlexBox>
                    <FlexBox
                        width={"100%"}
                        minWidth={isTablet ? "840px" : undefined}
                        visible={filteredBillingHistories.length > 0}
                        flexDirection={"column"}>
                        {filteredBillingHistories.map((value, index, array) => {
                            return <FlexBox
                                borderRadius={"8px"}
                                alignItems={"center"}
                                marginLeft={"32px"}
                                marginRight={"32px"}
                                marginTop={"8px"}
                                backgroundColor={index % 2 === 0 ? Colors.GRAY_0 : Colors.WHITE}
                                justifyContent={"space-around"}
                                height={"48px"}>
                                <NewP1
                                    textAlign={"left"}
                                    maxWidth={paymentKeyColumnWidth}
                                    width={"100%"}
                                    color={Colors.GRAY_7}
                                    paddingLeft={"16px"}
                                    paddingRight={"16px"}>#{value.historyKey.substring(0, 12)}</NewP1>
                                <NewP1
                                    textAlign={"left"}
                                    maxWidth={paymentDateColumnWidth}
                                    width={"100%"}
                                    color={Colors.GRAY_7}
                                    paddingLeft={"16px"}
                                    paddingRight={"16px"}>{value.paymentDate}</NewP1>
                                <NewP1
                                    textAlign={"left"}
                                    maxWidth={paymentUsagePeriodColumnWidth}
                                    width={"100%"}
                                    color={Colors.GRAY_7}
                                    paddingLeft={"16px"}
                                    paddingRight={"16px"}>{value.expirationDate}</NewP1>
                                <FlexBox
                                    paddingLeft={"16px"}
                                    paddingRight={"16px"}
                                    maxWidth={paymentStateColumnWidth}
                                    alignItems={"center"}
                                    width={"100%"}>
                                    <Image
                                        maxWidth={"10px"}
                                        width={"10px"}
                                        src={value.status === "SUCCESS" ? paymentApprovedPath : paymentCanceledPath}
                                        alt="payment-status"/>
                                    <NewP1
                                        textAlign={"left"}
                                        marginLeft={"8px"}
                                        color={Colors.GRAY_8}>{value.status === "SUCCESS" ?
                                        `${t('payment_state_success')}` : value.status === "CANCELED" ?
                                            `${t('payment_state_canceled')}` : `${t('payment_state_error')}`}</NewP1>
                                </FlexBox>
                                <NewP1
                                    maxWidth={paymentPriceColumnWidth}
                                    textAlign={"right"}
                                    width={"100%"}
                                    color={Colors.GRAY_7}
                                    paddingLeft={"16px"}
                                    paddingRight={"16px"}>{value.monetaryUnit} {FormatUtils.toCurrencyFormat(value.amount)}</NewP1>
                                <NewP1
                                    textAlign={"center"}
                                    maxWidth={paymentBillColumnWidth}
                                    width={"100%"}
                                    cursor={"pointer"}
                                    color={Colors.MAIN}
                                    paddingLeft={"16px"}
                                    paddingRight={"16px"}
                                    onClick={() => {
                                        window.open(value.receiptUrl);
                                    }}>{t('download')}</NewP1>
                                <FlexBox
                                    width={paymentMoreColumnWidth}>
                                    <Popover
                                        placement={"top"}
                                        trigger="click"
                                        content={
                                            <FlexBox flexDirection={"column"}
                                                     width={"fit-content"}
                                                     cursor={"pointer"}>
                                                <NewP2 color={value.availableCancel ? Colors.DESTRUCTIVE_PRIMARY : Colors.GRAY_4}
                                                       onClick={() => {
                                                           if (value.availableCancel) {
                                                               cancelPayment(value.historyKey)
                                                           } else {
                                                               alert(t('not_cancelable_state'))
                                                           }
                                                       }}>
                                                    {t('cancel_payment')}
                                                </NewP2>
                                                {CommonUtils.isAdmin(user?.userId || "")
                                                    && <NewP2 color={Colors.DESTRUCTIVE_PRIMARY}
                                                              marginTop={"8px"}
                                                              onClick={() => {
                                                                  cancelPayment(value.historyKey, true)
                                                              }}>
                                                        [관리자계정] 강제 결제 취소하기
                                                    </NewP2>}
                                            </FlexBox>}>
                                        <Image
                                            cursor={"pointer"}
                                            width={"24px"}
                                            height={"24px"}
                                            src={require("assets/images/ic-more-button.svg").default}
                                            alt="ic-payment-more"/>
                                    </Popover>
                                </FlexBox>
                            </FlexBox>
                        })}
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </FlexBox>
        }
        {!isMobile && <PlanIntroDialog dialogProps={{
            isOpen: isPlanDialogOpen,
            price: FormatUtils.toCurrencyFormat(getServicePrice()),
            premiumActivated: premiumActivated,
            onClose: (positive) => {
                setIsPlanDialogOpen(false)
                if (positive) {
                    setIsPremiumPaymentDialogOpen(true)
                }
            }
        }}
        />}
        {isMobile && <PlanIntroBottomSheet dialogProps={{
            isOpen: isPlanDialogOpen,
            premiumActivated: premiumActivated,
            price: FormatUtils.toCurrencyFormat(getServicePrice()),
            onClose: (positive) => {
                setIsPlanDialogOpen(false)
                if (positive) {
                    setIsPremiumPaymentDialogOpen(true)
                }
            }
        }}/>}
        {!isMobile && <PremiumPaymentDialog dialogProps={{
            isOpen: isPremiumPaymentDialogOpen,
            price: FormatUtils.toCurrencyFormat(getServicePrice()),
            backTitle: `${t('back_plan_intro')}`,
            onClose: (positive) => {
                setIsPremiumPaymentDialogOpen(false)
                if (positive) {
                    const service = billingServices[0]
                    if (!service) {
                        alert(t('no_found_service'))
                        return
                    }
                    doSubscribe(
                        service.itemId,
                        service.itemName,
                        Number(service.itemPrice) + Number(service.itemPriceVat)
                    )
                }
            }
        }}
        />}
        {isMobile && <PremiumPaymentBottomSheet dialogProps={{
            isOpen: isPremiumPaymentDialogOpen,
            price: FormatUtils.toCurrencyFormat(getServicePrice()),
            backTitle: `${t('back_plan_intro')}`,
            onClose: (positive) => {
                setIsPremiumPaymentDialogOpen(false)
                if (positive) {
                    const service = billingServices[0]
                    if (!service) {
                        alert(t('no_found_service'))
                        return
                    }
                    doSubscribe(
                        service.itemId,
                        service.itemName,
                        Number(service.itemPrice) + Number(service.itemPriceVat)
                    )
                }
            }
        }}/>}
        {!isMobile && <PremiumCancelDialog dialogProps={{
            isOpen: isPremiumCancelDialogOpen,
            price: FormatUtils.toCurrencyFormat(getServicePrice()),
            backTitle: `${t('dialog_back')}`,
            onClose: (positive) => {
                setIsPremiumCancelDialogOpen(false)
                if (positive) {
                    cancelSubscribe()
                }
            }
        }}/>}
        {isMobile && <PremiumCancelBottomSheet dialogProps={{
            isOpen: isPremiumCancelDialogOpen,
            price: FormatUtils.toCurrencyFormat(getServicePrice()),
            backTitle: `${t('dialog_back')}`,
            onClose: (positive) => {
                setIsPremiumCancelDialogOpen(false)
                if (positive) {
                    cancelSubscribe()
                }
            }
        }}/>}
        {isRemoveCardDialogOpen && <SimpleDialog dialogProps={{
            isOpen: isRemoveCardDialogOpen,
            title: `${t('really_delete_card')}`,
            description: usingServiceResult?.subscribe ? `${t('delete_card_and_available_plan_info',
                {endDate: DateFormatUtils.toYYYY_MM_DD_unit(usingServiceResult.endDate, language)})}` : "",
            positiveText: `${t('delete')}`,
            positiveColorType: 3,
            buttonType: ButtonType.BOTH,
            onClose: (positive) => {
                setIsRemoveCardDialogOpen(false)
                if (positive) {
                    removeAddedCard()
                }
            }
        }}/>}
        {isResubscribeDialogOpen && <SimpleDialog dialogProps={{
            isOpen: isResubscribeDialogOpen,
            title: `${t('check_resubscribe')}`,
            description: `${t('available_plan_end_date_info',
                {endDate: DateFormatUtils.toYYYY_MM_DD_unit(usingServiceResult?.endDate, language)})}`,
            positiveText: `${t('subscribe')}`,
            positiveColorType: 0,
            buttonType: ButtonType.BOTH,
            onClose: (positive) => {
                setIsResubscribeDialogOpen(false)
                if (positive) {
                    resubscribe()
                }
            }
        }}/>}
        {isLoadingDialogOpen && <LoadingDialog dialogProps={{
            isOpen: isLoadingDialogOpen,
            title: `${t('payment_cancel_loading')}`,
            onClose: (positive) => {
                setIsLoadingDialogOpen(false)
            }
        }}/>}
    </FlexBox>
}

export default PaymentManagerFragment