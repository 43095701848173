import {ContentContainer} from "../styledcomponents/Containers";
import React from "react";
import Image from "../styledcomponents/Image";
import {Colors} from "../styledcomponents/Styles";
import {useLocation, useNavigate} from "react-router-dom";
import NewButton from "../styledcomponents/NewButton";
import FlexBox from "../styledcomponents/FlexBox";
import NewH3 from "../styledcomponents/text/NewH3";
import {useTranslation} from "react-i18next";
import NewP2 from "../styledcomponents/text/NewP2";

/**
 * @author jaeho.lee104 on 2023. 04. 07..
 */
const SignUpDoneFragment = () => {
    const {state} = useLocation();
    const navigate = useNavigate()
    const {t} = useTranslation()
    return <ContentContainer
        alignItems={"center"}>
        <FlexBox
            width={"480px"}
            marginBottom={"64px"}
            marginTop={"88px"}
            alignItems={"center"}
            border={`1px solid ${Colors.GRAY_2}`}
            borderRadius={"12px"}
            boxShadow={'0px 4px 16px 0px rgba(0, 0, 0, 0.08);'}
            padding={"16px 32px"}
            flexDirection={"column"}>
            <Image
                width={"192px"}
                height={"148px"}
                src={require("assets/images/image-signup-done.png")}
                alt="sign-up-done"
                marginTop={"32px"}/>
            <NewH3
                marginTop={"40px"}
                color={Colors.GRAY_9}>
                {t('signup_done')}
            </NewH3>
            <NewP2
                marginTop={"16px"}
                color={Colors.GRAY_9}>
                {t('welcome_signup')}
            </NewP2>
            <NewButton
                width={"100%"}
                marginBottom={"16px"}
                marginTop={"32px"}
                fontSize={"16px"}
                padding={"14px 16px"}
                onClick={() => {
                    navigate("/login")
                }}>
                {t('return_to_login_page')}
            </NewButton>
        </FlexBox>
    </ContentContainer>

}
export default SignUpDoneFragment;