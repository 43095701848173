/**
 * @author jaeho.lee104 on 2023. 08. 24..
 */

import React, {useEffect, useRef, useState} from "react";
import {Dialog} from "@mui/material";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import FlexBox from "../styledcomponents/FlexBox";
import NewH3 from "../styledcomponents/text/NewH3";
import Image from "../styledcomponents/Image";
import {Colors} from "../styledcomponents/Styles";
import NewP2 from "../styledcomponents/text/NewP2";
import NewButton from "../styledcomponents/NewButton";
import NewP1 from "../styledcomponents/text/NewP1";
import Input from "../styledcomponents/Input";
import styled from "styled-components";
import Text from "../styledcomponents/Text";
import {RegexUtils} from "../constants/RegexUtils";
import {UsersAPI} from "../network/UsersAPI";
import {ChangePasswordRequestBody} from "../model/ChangePasswordRequestBody";
import {ResponseCodes} from "../network/ResponseCodes";
import {useTranslation} from "react-i18next";

const iconCheckDefault = require(`assets/images/ic-check-gray-16.svg`).default
const iconCheckValid = require(`assets/images/ic-check-green-16.svg`).default
const iconCheckError = require(`assets/images/ic-check-red-16.svg`).default
const lockClosePath = require(`assets/images/ic-lock-close.svg`).default
const lockOpenPath = require(`assets/images/ic-lock-open.svg`).default

const InputErrorText = styled(Text)`
  margin-top: 12px;
  font-size: 14px;
  text-align: left;
  font-weight: 400;
  color: ${Colors.INPUT_ERROR};
`

const theme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    minWidth: "480px",
                    borderRadius: "16px"
                }
            }
        }
    },
    palette: {
        background: {
            paper: '#fff',
        },
        text: {
            primary: '#000',
            secondary: '#46505A',
        },
        action: {
            active: '#001E3C',
        },
    },
});

export interface NewPasswordChangeDialogProps {
    isOpen: boolean,
    onClose: (positive: boolean) => void
}

const NewPasswordChangeDialog: React.FC<{
    dialogProps: NewPasswordChangeDialogProps
}> = ({dialogProps}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [newConfirmPassword, setNewConfirmPassword] = useState("")

    const [currentPasswordLockOn, setCurrentPasswordLockOn] = useState(true)
    const [currentPasswordError, setCurrentPasswordError] = useState(false)
    const currentPasswordRef = useRef<HTMLInputElement>(null)

    const [newPasswordLockOn, setNewPasswordLockOn] = useState(true)
    const [newPasswordError, setNewPasswordError] = useState(false)
    const newPasswordRef = useRef<HTMLInputElement>(null)

    const [newConfirmPasswordLockOn, setNewConfirmPasswordLockOn] = useState(true)
    const [newConfirmPasswordError, setNewConfirmPasswordError] = useState(false)
    const newConfirmPasswordRef = useRef<HTMLInputElement>(null)

    const [newPasswordLengthState, setNewPasswordLengthState] = useState(0) // 0: default, 1: success, 2: error
    const [newPasswordWordState, setNewPasswordWordState] = useState(0)
    const {t} = useTranslation()

    useEffect(() => {
        setIsOpen(dialogProps.isOpen);
    }, [dialogProps.isOpen]);

    const handleOk = () => {
        if (!isOpen) {
            return;
        }
        if (currentPassword.length < 1) {
            setCurrentPasswordError(true)
            currentPasswordRef.current?.focus()
            return;
        }
        if (newPassword.length < 8 || newPassword.length > 20) {
            setNewPasswordError(true)
            setNewPasswordLengthState(2)
            setNewPasswordWordState(2)
            newPasswordRef.current?.focus()
            return;
        }
        if (newPassword !== newConfirmPassword) {
            setNewConfirmPasswordError(true)
            newConfirmPasswordRef.current?.focus()
            return;
        }
        UsersAPI.changePassword(new ChangePasswordRequestBody(
            currentPassword,
            newPassword,
            newConfirmPassword,
        )).then(response => {
            const responseCode = ResponseCodes.of(response.data.code)
            if (responseCode.isSuccess()) {
                alert(t('alert_password_has_been_changed'));
                handleCancel()
            } else if (responseCode.isNotMatchingPassword()) {
                setCurrentPasswordError(true)
                currentPasswordRef.current?.focus()
            } else if (responseCode.isNotMatchingNewPasswordCheck()) {
                setNewConfirmPasswordError(true)
                newConfirmPasswordRef.current?.focus()
            } else if (responseCode.isNotFoundUserId()) {
                alert(t('alert_login_is_required'));
                handleCancel()
            } else {
                alert(t('alert_a_temporary_error_has_occurred'))
            }
        }).catch((e) => {
            alert(t('alert_a_temporary_error_has_occurred'))
        })
    };

    const handleCancel = () => {
        if (!isOpen) {
            return;
        }
        setCurrentPassword("")
        setNewPassword("")
        setNewConfirmPassword("")
        setCurrentPasswordError(false)
        setNewPasswordError(false)
        setNewConfirmPasswordError(false)
        setNewPasswordWordState(0)
        setNewPasswordLengthState(0)
        dialogProps.onClose(false);
    };

    function handleOnChangedNewPassword(changedText: string) {
        setNewPasswordLengthState(changedText.length >= 8 && changedText.length <= 20 ? 1 : 0)
        setNewPasswordWordState(RegexUtils.checkPasswordWithoutLength(changedText) ? 1 : 0)
    }

    return <ThemeProvider theme={theme}>
        <Dialog
            open={isOpen}
            onClose={handleCancel}
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    handleOk();
                }
            }}>
            <FlexBox
                padding={"32px"}
                flexDirection={"column"}>
                <FlexBox
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}>
                    <NewH3
                        color={Colors.GRAY_9}>
                        {t('change_password')}
                    </NewH3>
                    <Image
                        cursor={"pointer"}
                        width={"24px"}
                        height={"24px"}
                        src={require("assets/images/ic-dialog-close.svg").default}
                        onClick={handleCancel}
                        alt="ic-dialog-close"/>
                </FlexBox>
                <NewP2
                    marginTop={"16px"}
                    color={Colors.GRAY_7}>
                    {t('enter_new_password')}
                </NewP2>
                <NewP1
                    color={Colors.GRAY_7}
                    marginTop={"32px"}>{t('password')}</NewP1>
                <FlexBox
                    width={"100%"}
                    alignSelf={"center"}
                    marginTop={"8px"}>
                    <Input
                        paddingRight={"48px"}
                        width={"100%"}
                        maxLength={20}
                        fontWeight={700}
                        lineHeight={"150%"}
                        ref={currentPasswordRef}
                        error={currentPasswordError}
                        value={currentPassword}
                        type={currentPasswordLockOn ? "password" : "text"}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleOk();
                            }
                        }}
                        onChange={(e) => {
                            if (currentPasswordError) {
                                setCurrentPasswordError(false)
                            }
                            setCurrentPassword(e.target.value)
                        }}
                    />
                    <Image
                        maxWidth={"24px"}
                        width={"24px"}
                        maxHeight={"24px"}
                        height={"24px"}
                        position={"absolute"}
                        right={"16px"}
                        alignSelf={"center"}
                        cursor={"pointer"}
                        src={currentPasswordLockOn ? lockClosePath : lockOpenPath}
                        onClick={() => {
                            setCurrentPasswordLockOn(!currentPasswordLockOn)
                        }}
                        alt="current-password-lock-icon"/>
                </FlexBox>
                {currentPasswordError && <InputErrorText>{t('incorrect_current_password')}</InputErrorText>}
                <NewP1
                    color={Colors.GRAY_7}
                    marginTop={"24px"}>{t('new_password')}</NewP1>
                <FlexBox
                    width={"100%"}
                    alignSelf={"center"}
                    marginTop={"8px"}>
                    <Input
                        paddingRight={"48px"}
                        width={"100%"}
                        maxLength={20}
                        fontWeight={700}
                        lineHeight={"150%"}
                        ref={newPasswordRef}
                        error={newPasswordError}
                        value={newPassword}
                        type={newPasswordLockOn ? "password" : "text"}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleOk();
                            }
                        }}
                        onChange={(e) => {
                            if (newPasswordError) {
                                setNewPasswordError(false)
                                setNewPasswordWordState(0)
                                setNewPasswordLengthState(0)
                            }
                            setNewPassword(e.target.value)
                            handleOnChangedNewPassword(e.target.value)
                        }}
                    />
                    <Image
                        maxWidth={"24px"}
                        width={"24px"}
                        maxHeight={"24px"}
                        height={"24px"}
                        position={"absolute"}
                        right={"16px"}
                        alignSelf={"center"}
                        cursor={"pointer"}
                        src={newPasswordLockOn ? lockClosePath : lockOpenPath}
                        onClick={() => {
                            setNewPasswordLockOn(!newPasswordLockOn)
                        }}
                        alt="new-password-lock-icon"/>
                </FlexBox>
                <FlexBox
                    marginTop={"8px"}
                    alignItems={"center"}>
                    <Image
                        width={"16px"}
                        height={"16px"}
                        src={newPasswordLengthState === 0 ? iconCheckDefault : newPasswordLengthState === 1 ? iconCheckValid : iconCheckError}
                        alt="ic-check-1"/>
                    <NewP2
                        marginLeft={"8px"}
                        color={newPasswordError ? Colors.INPUT_ERROR : Colors.GRAY_7}>
                        {t('password_condition_1')}
                    </NewP2>
                </FlexBox>
                <FlexBox
                    marginTop={"8px"}
                    alignItems={"center"}>
                    <Image
                        width={"16px"}
                        height={"16px"}
                        src={newPasswordWordState === 0 ? iconCheckDefault : newPasswordWordState === 1 ? iconCheckValid : iconCheckError}
                        alt="ic-check-1"/>
                    <NewP2
                        marginLeft={"8px"}
                        color={newPasswordError ? Colors.INPUT_ERROR : Colors.GRAY_7}>
                        {t('password_condition_2')}
                    </NewP2>
                </FlexBox>
                <NewP1
                    color={Colors.GRAY_7}
                    marginTop={"24px"}>{t('confirm_new_password')}</NewP1>
                <FlexBox
                    width={"100%"}
                    alignSelf={"center"}
                    marginTop={"8px"}>
                    <Input
                        paddingRight={"48px"}
                        width={"100%"}
                        maxLength={20}
                        fontWeight={700}
                        lineHeight={"150%"}
                        ref={newConfirmPasswordRef}
                        error={newConfirmPasswordError}
                        value={newConfirmPassword}
                        type={newConfirmPasswordLockOn ? "password" : "text"}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleOk();
                            }
                        }}
                        onChange={(e) => {
                            if (newConfirmPasswordError) {
                                setNewConfirmPasswordError(false)
                            }
                            setNewConfirmPassword(e.target.value)
                        }}
                    />
                    <Image
                        maxWidth={"24px"}
                        width={"24px"}
                        maxHeight={"24px"}
                        height={"24px"}
                        position={"absolute"}
                        right={"16px"}
                        alignSelf={"center"}
                        cursor={"pointer"}
                        src={newConfirmPasswordLockOn ? lockClosePath : lockOpenPath}
                        onClick={() => {
                            setNewConfirmPasswordLockOn(!newConfirmPasswordLockOn)
                        }}
                        alt="new-confirm-password-lock-icon"/>
                </FlexBox>
                {newConfirmPasswordError && <InputErrorText>{t('new_password_not_match')}</InputErrorText>}
                <NewButton
                    height={"52px"}
                    borderRadius={"12px"}
                    marginBottom={"16px"}
                    marginTop={"32px"}
                    onClick={handleOk}>
                    {t('change')}
                </NewButton>
            </FlexBox>
        </Dialog>
    </ThemeProvider>
}

export default NewPasswordChangeDialog;