/**
 * @author jaeho.lee104 on 2023. 05. 03..
 */
import {ChartXYData} from "../../model/ChartXYData";
import React from "react";
import {ResponsiveLine} from "@nivo/line";
import {AxisProps} from "@nivo/axes";
import {Colors} from "../../styledcomponents/Styles";
import {FormatUtils} from "../../utils/FormatUtils";
import {Datum} from "@nivo/legends/dist/types/types";

interface MarketAnalysisChartProps {
    chartModels: Array<MarketAnalysisChartModel>,
    colors: Array<string>
}

export type MarketAnalysisChartModel = {
    id: string,
    data: Array<ChartXYData>;
}

export const MarketAnalysisChart: React.FC<MarketAnalysisChartProps> = ({
                                                                            chartModels,
                                                                            colors
                                                                        }) => {

    function renderLegendText(text: string): string {
        if (text.length < 18) {
            return text
        }
        return text.substring(0, 17) + "..."
    }

    return (
        <ResponsiveLine
            data={chartModels}
            colors={colors}
            margin={{top: 70, right: 20, bottom: 24, left: 40}}
            xScale={{type: "point"}}
            yScale={{
                type: "linear",
                stacked: false,
                reverse: false,
            }}
            enableSlices="x"
            enableGridX={false}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                orient: "bottom",
                tickSize: 0,
                tickPadding: 10,
                tickRotation: 0
            } as AxisProps}
            axisLeft={{
                orient: "left",
                tickSize: 0,
                tickPadding: 20,
                tickRotation: 0,
                format: (value) => {
                    return `${FormatUtils.toCurrencyFormat(value.toString())}`
                }
            } as AxisProps}
            enablePoints={false}
            enablePointLabel={false}
            legends={[
                {
                    data: chartModels.map((item, index) => {
                        return {
                            id: item.id,
                            label: renderLegendText(item.id),
                            fill: colors[index % 5]
                        } as Datum
                    }),
                    toggleSerie: false,
                    anchor: "top-left",
                    direction: "row",
                    justify: false,
                    translateX: -30,
                    translateY: -72,
                    itemTextColor: Colors.BODY_TXT,
                    itemDirection: "left-to-right",
                    itemWidth: 200,
                    itemHeight: 20,
                    itemsSpacing: 0,
                    itemOpacity: 1,
                    symbolSize: 14,
                    // @ts-ignore
                    symbolShape: "circle",
                    effects: [
                        {
                            on: "hover",
                            style: {
                                symbolSize: 16
                            },
                        },
                    ],
                },
            ]}
            theme={{
                fontFamily: "Noto Sans",
                legends: {
                    text: {
                        fontWeight: 400,
                        fontSize: 14,
                        color: Colors.GRAY_800
                    }
                },
                crosshair: {
                    line: {
                        stroke: Colors.GRAY_900,
                        strokeWidth: 1,
                        strokeOpacity: 1,
                        strokeDasharray: '3 4'
                    }
                },
                axis: {
                    ticks: {
                        text: {
                            fontWeight: 400,
                            fontSize: 10,
                            color: Colors.GRAY_800
                        }
                    }
                },
                grid: {
                    line: {
                        stroke: Colors.GRAY_300,
                        strokeWidth: 1,
                    },
                },
            }}

        />
    )
}