import {NetworkCommon} from "./NetworkCommon";
import {AxiosResponse} from "axios";
import {ImportApplyListItem} from "../model/ImportApplyListItem";
import {ImportApplyDetailItem} from "../model/ImportApplyDetailItem";
import {ImportNotice} from "../model/ImportNotice";
import {Comment} from "../model/Comment";

export class ImportApplyAPI {

    static instance = NetworkCommon.createDefaultAxiosInstance()

    static getPosts(postSubject: string | null = null, pageIndex: number, countPerPage: number): Promise<AxiosResponse<BaseResponse<Array<ImportApplyListItem>>>> {
        return ImportApplyAPI.instance.get("/importApply/posts", {
            params: {
                postSubject: postSubject,
                pageIndex: pageIndex,
                countPerPage: countPerPage
            },
        });
    }

    static getPost(postSequence: string, password: string): Promise<AxiosResponse<BaseResponse<ImportApplyDetailItem>>> {
        return ImportApplyAPI.instance.post("/importApply/posts/validPassword", {
            postSequence: postSequence,
            password: password
        });
    }

    static addPost(subject: string,
                   companyName: string,
                   writer: string,
                   email: string,
                   phone: string,
                   password: string,
                   contents: string): Promise<AxiosResponse<BaseResponse<null>>> {
        let formData = new FormData()
        const importApply = {
            companyName: companyName,
            subject: subject,
            writer: writer,
            email: email,
            phone: phone,
            password: password,
            contents: contents
        }
        formData.append("importApply", new Blob([JSON.stringify(importApply)], {
            type: "application/json"
        }))
        return ImportApplyAPI.instance.post("/importApply/post", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    }

    static modifyPost(postSequence: string,
                      subject: string,
                      companyName: string,
                      writer: string,
                      email: string,
                      phone: string,
                      password: string,
                      contents: string): Promise<AxiosResponse<BaseResponse<null>>> {
        let formData = new FormData()
        const importApply = {
            companyName: companyName,
            subject: subject,
            writer: writer,
            email: email,
            phone: phone,
            password: password,
            contents: contents
        }
        formData.append("importApply", new Blob([JSON.stringify(importApply)], {
            type: "application/json"
        }))
        return ImportApplyAPI.instance.put(`/importApply/post/${postSequence}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    }

    static deletePost(postSequence: string, password: string): Promise<AxiosResponse<BaseResponse<null>>> {
        return ImportApplyAPI.instance.delete(`/importApply/post`, {
            data: {
                postSequence: postSequence,
                password: password
            }
        });
    }

    static getNotice(postSequence: string): Promise<AxiosResponse<BaseResponse<ImportNotice>>> {
        return ImportApplyAPI.instance.get(`/importApply/notice/${postSequence}`)
    }

    static getComments(postSequence: string, commentKey: string): Promise<AxiosResponse<BaseResponse<Array<Comment>>>> {
        return ImportApplyAPI.instance.get(`/importApply/post/${postSequence}/comments`, {
            params: {
                commentKey: commentKey
            }
        })
    }

    static addComment(postSequence: string, commentKey: string, comment: string): Promise<AxiosResponse<BaseResponse<null>>> {
        return ImportApplyAPI.instance.post(`/importApply/post/${postSequence}/comments`, {
            comment: comment,
            commentKey: commentKey

        })
    }

    static deleteComment(postSequence: string, commentKey: string, commentSequence: number): Promise<AxiosResponse<BaseResponse<null>>> {
        return ImportApplyAPI.instance.delete(`/importApply/post/${postSequence}/comments/${commentSequence}`, {
            params: {
                commentKey: commentKey
            }
        })
    }
}