/**
 * @author jaeho.lee104 on 2023. 11. 03..
 */
import React from "react";
import FlexBox from "../styledcomponents/FlexBox";
import NewH5 from "../styledcomponents/text/NewH5";
import {Colors} from "../styledcomponents/Styles";
import Image from "../styledcomponents/Image";
import {CommonProps} from "../styledcomponents/CommonComponent";

export interface RadioItemProps extends CommonProps {
    title: string,
    checked: boolean,
    onClick: () => void
    width?: string,
    height?: string
}

export const NewRadioItem: React.FC<RadioItemProps> = ({
                                                           title,
                                                           checked,
                                                           width = "100%",
                                                           height = "52px",
                                                           ...props
                                                       }) => {

    const itemChecked = require(`assets/images/radio-item-checked.svg`).default
    const itemUnchecked = require(`assets/images/radio-item-unchecked.svg`).default

    return <FlexBox
        borderRadius={"12px"}
        border={checked ? `2px solid ${Colors.MAIN}` : `1px solid ${Colors.GRAY_4}`}
        backgroundColor={Colors.WHITE}
        height={height}
        width={width}
        cursor={"pointer"}
        padding={"14px 16px"}
        alignItems={"center"}
        {...props}>
        <Image
            width={"24px"}
            height={"24px"}
            src={checked ? itemChecked : itemUnchecked}
            alt="ic-radio-item"/>
        <NewH5
            marginLeft={"16px"}
            color={Colors.GRAY_8}>
            {title}
        </NewH5>
    </FlexBox>
}