/**
 * @author jaeho.lee104 on 2023. 04. 06..
 */
import {useGoogleLogin} from "@react-oauth/google";
import React from "react";
import FlexBox from "../styledcomponents/FlexBox";
import NewH1 from "../styledcomponents/text/NewH1";
import {UsersAPI} from "../network/UsersAPI";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../modules";
import NewButton from "../styledcomponents/NewButton";

const GoogleAuthTestFragment = () => {

    const clientId = "985325558274-60qkcbhcns7vqegfbt72ll3getlkokir.apps.googleusercontent.com"
    const {language} = useSelector((state: RootState) => state.configurationsReducer, shallowEqual);

    const googleLogin = useGoogleLogin({
        onSuccess: res => {
            console.log(res)
            handleLogin(res.code)
        },
        onError: res => console.log(res),
        flow: 'auth-code', //auth_code
    });

    function handleLogin(token: string) {
        UsersAPI.signInWithGoogleOauth(token)
            .then((res) => {
                console.log(res)
            })
            .catch((e) => {
                console.log(e)
            })
    }

    return <>
        <FlexBox
            alignItems={"center"}
            flexDirection={"column"}
            justifyContent={"center"}
            width={"1400px"}
            margin={"0 auto"}>
            <NewH1
                marginBottom={"40px"}
                marginTop={"40px"}>
                Google Auth TEST
            </NewH1>
            <NewButton
                width={"400px"}
                onClick={() => googleLogin()}>
                Google Login
            </NewButton>
        </FlexBox>
    </>
}
export default GoogleAuthTestFragment;