/**
 * @author jaeho.lee104 on 2023. 08. 25..
 */
import React, {useEffect, useState} from "react";
import {CircularProgress, Dialog} from "@mui/material";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import useMobile from "../uses/UseMobile";
import {BottomSheet} from "react-spring-bottom-sheet";
import FlexBox from "../styledcomponents/FlexBox";
import NewH3 from "../styledcomponents/text/NewH3";
import {Colors} from "../styledcomponents/Styles";

const theme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    minWidth: "480px",
                    borderRadius: "16px"
                }
            }
        }
    },
    palette: {
        background: {
            paper: '#fff',
        },
        text: {
            primary: '#000',
            secondary: '#46505A',
        },
        action: {
            active: '#001E3C',
        },
    },
});

export interface LoadingDialogProps {
    isOpen: boolean,
    title: string,
    onClose: (positive: boolean) => void
}

const LoadingDialog: React.FC<{
    dialogProps: LoadingDialogProps
}> = ({dialogProps}) => {

    const [isOpen, setIsOpen] = useState(false);
    const isMobile = useMobile()

    useEffect(() => {
        setIsOpen(dialogProps.isOpen);
    }, [dialogProps.isOpen]);

    return <>{!isMobile &&
        <ThemeProvider theme={theme}>
            <Dialog
                open={isOpen}>
                <FlexBox
                    flexDirection={"column"}
                    padding={"32px"}
                    alignItems={"center"}>
                    <NewH3>
                        {dialogProps.title}
                    </NewH3>
                    <CircularProgress
                        sx={{
                            color: Colors.MAIN,
                            marginTop: "32px",
                            marginBottom: "16px"
                        }}/>
                </FlexBox>
            </Dialog>
        </ThemeProvider>}
        {isMobile &&
            <BottomSheet
                open={isOpen}
                defaultSnap={({maxHeight}) => maxHeight - 8 * maxHeight / 10}
                snapPoints={({maxHeight}) => [
                    maxHeight - 8 * maxHeight / 10
                ]}>
                <FlexBox
                    flexDirection={"column"}
                    padding={"32px"}
                    alignItems={"center"}>
                    <NewH3>
                        {dialogProps.title}
                    </NewH3>
                    <CircularProgress
                        sx={{
                            color: Colors.MAIN,
                            marginTop: "32px",
                            marginBottom: "16px"
                        }}/>
                </FlexBox>
            </BottomSheet>}
    </>
}

export default LoadingDialog