import React, {ChangeEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import FlexBox from "../styledcomponents/FlexBox";
import HorizontalDivider from "../styledcomponents/HorizontalDivider";
import {Colors} from "../styledcomponents/Styles";
import Input from "../styledcomponents/Input";
import styled from "styled-components";
import emailjs from '@emailjs/browser';
import {Dialog} from "@mui/material";
import Button from "../styledcomponents/Button";
import {StringConstants} from "../constants/StringConstants";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {Checkbox} from "antd";
import {Textarea} from "evergreen-ui";
import useMobile from "../uses/UseMobile";
import "./SampleRequestDialog.css";
import NewH5 from "../styledcomponents/text/NewH5";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import NewH2 from "../styledcomponents/text/NewH2";
import NewH4 from "../styledcomponents/text/NewH4";
import NewP2 from "../styledcomponents/text/NewP2";
import {BottomSheet} from "react-spring-bottom-sheet";
import Image from "../styledcomponents/Image";

const FieldLayout = styled(FlexBox)`
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
`
const FieldHeader = styled(NewH5)`
  width: 200px;
  text-align: start;
`
const TextField = styled(Input)`
  width: 100%;
  background-color: white;
`


const theme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    minWidth: "720px",
                    borderRadius: "16px"
                }
            }
        }
    },
    palette: {
        background: {
            paper: '#fff',
        },
        text: {
            primary: '#000',
            secondary: '#46505A',
        },
        action: {
            active: '#001E3C',
        },
    },
});

export interface SampleRequestDialogProps {
    isOpen: boolean
    onClosed: () => void
}

const SampleRequestDialog: React.FC<SampleRequestDialogProps> = ({isOpen, onClosed}) => {

    const [isShown, setIsShown] = React.useState(false)
    const [termsOfServiceAgreeChecked, setTermsOfServiceAgreeChecked] = useState(false);
    const [company, setCompany] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [message, setMessage] = useState("")
    const {t} = useTranslation()
    const isMobile = useMobile()
    const handleClose = () => {
        setIsShown(false);
        onClosed()
    };

    useEffect(() => {
        setIsShown(isOpen)
    }, [isOpen])

    function handleSubmit() {
        const params = {
            company_name: company,
            user_name: name,
            phone_number: phoneNumber,
            email: email,
            message: message
        }
        if (!company) {
            alert(t('alert_please_enter_the_company_name'))
            return
        }
        if (!name) {
            alert(t('alert_please_enter_your_name'))
            return
        }
        if (!phoneNumber) {
            alert(t('alert_please_enter_your_phone_number'))
            return
        }
        if (!email) {
            alert(t('alert_please_enter_your_email_address'))
            return
        }
        if (!message) {
            alert(t('alert_please_enter_your_inquiry'))
            return;
        }
        if (!termsOfServiceAgreeChecked) {
            alert(t('alert_please_agree_to_the_privacy_policy'))
            return;
        }
        emailjs.send('service_476mxkw', 'template_tczqcvh', params, 'aWur-HWDDnLCPvv6l')
            .then((result) => {
                alert(t('alert_your_inquiry_has_been_received'))
                handleClose()
            }, (error) => {
                alert(t('alert_failed_to_submit_your_inquiry'))
            });
    }

    return <>
        {!isMobile && <ThemeProvider theme={theme}>
            <Dialog
                open={isShown}
                onClose={() => {
                    handleClose()
                }}>
                <FlexBox
                    padding={"32px"}
                    flexDirection={"column"}>
                    <FlexBox
                        alignItems={"center"}
                        justifyContent={"space-between"}>
                        <NewH2>
                            {t('request_sample_report')}
                        </NewH2>
                        <Image
                            cursor={"pointer"}
                            onClick={handleClose}
                            width={"20px"}
                            height={"20px"}
                            src={require("assets/images/ic-close-20.svg").default}
                            alt="ic-close"/>
                    </FlexBox>
                    <FlexBox
                        justifyContent={"space-between"}
                        alignItems={"flex-end"}
                        marginTop={"32px"}>
                        <NewH4>
                            {t('customer_information')}
                        </NewH4>
                        <NewP2><span style={{color: Colors.RED}}>*</span> {t('please_fill_information')}</NewP2>
                    </FlexBox>
                    <HorizontalDivider
                        marginTop={"20px"}
                        marginBottom={"20px"}/>
                    <FieldLayout>
                        <FieldHeader>
                <span style={{
                    color: Colors.RED,
                    marginRight: "2px"
                }}>*</span>{t('company')}
                        </FieldHeader>
                        <TextField
                            value={company}
                            onChange={(e) => {
                                setCompany(e.target.value)
                            }}
                            autoFocus={true}/>
                    </FieldLayout>
                    <FieldLayout>
                        <FieldHeader>
                <span style={{
                    color: Colors.RED,
                    marginRight: "2px"
                }}>*</span>{t('name')}
                        </FieldHeader>
                        <TextField
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                        />
                    </FieldLayout>
                    <FieldLayout>
                        <FieldHeader>
                <span style={{
                    color: Colors.RED,
                    marginRight: "2px"
                }}>*</span>{t('phone')}
                        </FieldHeader>
                        <TextField
                            placeholder={`010-0000-0000`}
                            value={phoneNumber}
                            onChange={(e) => {
                                setPhoneNumber(e.target.value)
                            }}
                        />
                    </FieldLayout>
                    <FieldLayout>
                        <FieldHeader>
                <span style={{
                    color: Colors.RED,
                    marginRight: "2px"
                }}>*</span>E-Mail
                        </FieldHeader>
                        <TextField
                            placeholder={`abc@thirdworks.co.kr`}
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                        />
                    </FieldLayout>
                    <NewH4
                        marginTop={"36px"}>
                        {t('inquiry_content')}
                    </NewH4>
                    <HorizontalDivider
                        marginTop={"20px"}
                        marginBottom={"16px"}/>
                    <Textarea name="request-body"
                              className={"input-class"}
                              required={true}
                              rows={10}
                              style={{
                                  fontFamily: "Noto Sans",
                                  lineHeight: "24px",
                                  fontSize: "16px",
                                  marginTop: "8px",
                                  resize: "none"
                              }}
                              value={message}
                              placeholder={`※ ${t('inquiry_example')}\n\n1. ${t('inquiry_example_1')}\n2. ${t('inquiry_example_2')}\n3. ${t('inquiry_example_3')}`}
                              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                                  setMessage(e.target.value);
                              }}/>
                    <NewP2
                        marginTop={"16px"}
                        color={Colors.GRAY_6}>
                        ※ {t('inquiry_notice')}
                    </NewP2>
                    <FlexBox
                        marginTop={"16px"}
                        alignItems={"center"}>
                        <Checkbox
                            checked={termsOfServiceAgreeChecked}
                            onChange={(e: CheckboxChangeEvent) => {
                                setTermsOfServiceAgreeChecked(e.target.checked);
                            }}/>
                        <NewP2
                            marginLeft={"8px"}>
                            {t('check_terms_of_service_and_privacy_policy_head')}{StringConstants.SPACE}
                            <a style={{fontWeight: "700", cursor: "pointer", borderBottom: "1px solid #000"}}
                               onClick={() => {
                                   window.open("/terms-of-service")
                               }}>{t('terms_of_service')}</a>{StringConstants.SPACE}{t('and')}{StringConstants.SPACE}
                            <a style={{fontWeight: "700", cursor: "pointer", borderBottom: "1px solid #000"}}
                               onClick={() => {
                                   window.open("/privacy-policy")
                               }}>{t('privacy_policy')}</a>
                            {t('check_terms_of_service_and_privacy_policy_tail')}</NewP2>
                    </FlexBox>
                    <FlexBox
                        marginTop={"16px"}
                        alignItems={"center"}
                        alignSelf={"flex-end"}>
                        <Button marginLeft={"24px"}
                                width={"120px"}
                                fontSize={"16px"}
                                padding={"16px 20px"}
                                backgroundColor={Colors.WHITE}
                                border={`1px solid ${Colors.GRAY_6}`}
                                color={Colors.GRAY_6}
                                onClick={() => {
                                    handleClose()
                                }}>{t('cancel')}</Button>
                        <Button marginLeft={"8px"}
                                padding={"16px 20px"}
                                fontSize={"16px"}
                                width={"120px"}
                                onClick={() => {
                                    handleSubmit()
                                }}>{t('request')}</Button>
                    </FlexBox>
                </FlexBox>
            </Dialog>
        </ThemeProvider>}
        {isMobile &&
            <BottomSheet
                open={isOpen}
                onDismiss={handleClose}
                defaultSnap={({maxHeight}) => maxHeight * 0.8}
                snapPoints={({maxHeight}) => [
                    maxHeight * 0.4,
                    maxHeight * 0.8,
                ]}>
                <FlexBox
                    padding={"16px 20px"}
                    flexDirection={"column"}>
                    <FlexBox
                        alignItems={"center"}
                        justifyContent={"space-between"}>
                        <NewH2>
                            {t('request_sample_report')}
                        </NewH2>
                        <Image
                            cursor={"pointer"}
                            onClick={handleClose}
                            width={"20px"}
                            height={"20px"}
                            src={require("assets/images/ic-close-20.svg").default}
                            alt="ic-close"/>
                    </FlexBox>
                    <FlexBox
                        flexDirection={"column"}
                        marginTop={"32px"}>
                        <NewH4>
                            {t('customer_information')}
                        </NewH4>
                        <NewP2 marginTop={"8px"}><span style={{color: Colors.RED}}>*</span> {t('please_fill_information')}</NewP2>
                    </FlexBox>
                    <HorizontalDivider
                        marginTop={"20px"}
                        marginBottom={"20px"}/>
                    <FieldLayout>
                        <FieldHeader>
                <span style={{
                    color: Colors.RED,
                    marginRight: "2px"
                }}>*</span>{t('company')}
                        </FieldHeader>
                        <TextField
                            value={company}
                            onChange={(e) => {
                                setCompany(e.target.value)
                            }}
                            autoFocus={true}/>
                    </FieldLayout>
                    <FieldLayout>
                        <FieldHeader>
                <span style={{
                    color: Colors.RED,
                    marginRight: "2px"
                }}>*</span>{t('name')}
                        </FieldHeader>
                        <TextField
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                        />
                    </FieldLayout>
                    <FieldLayout>
                        <FieldHeader>
                <span style={{
                    color: Colors.RED,
                    marginRight: "2px"
                }}>*</span>{t('phone')}
                        </FieldHeader>
                        <TextField
                            placeholder={`010-0000-0000`}
                            value={phoneNumber}
                            onChange={(e) => {
                                setPhoneNumber(e.target.value)
                            }}
                        />
                    </FieldLayout>
                    <FieldLayout>
                        <FieldHeader>
                <span style={{
                    color: Colors.RED,
                    marginRight: "2px"
                }}>*</span>E-Mail
                        </FieldHeader>
                        <TextField
                            placeholder={`abc@thirdworks.co.kr`}
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                        />
                    </FieldLayout>
                    <NewH4
                        marginTop={"36px"}>
                        {t('inquiry_content')}
                    </NewH4>
                    <HorizontalDivider
                        marginTop={"20px"}
                        marginBottom={"16px"}/>
                    <Textarea name="request-body"
                              className={"input-class"}
                              required={true}
                              rows={10}
                              style={{
                                  fontFamily: "Noto Sans",
                                  lineHeight: "24px",
                                  fontSize: "16px",
                                  marginTop: "8px"
                              }}
                              value={message}
                              placeholder={`※ ${t('inquiry_example')}\n\n1. ${t('inquiry_example_1')}\n2. ${t('inquiry_example_2')}\n3. ${t('inquiry_example_3')}`}
                              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                                  setMessage(e.target.value);
                              }}/>
                    <NewP2
                        marginTop={"16px"}
                        color={Colors.GRAY_6}>
                        ※ {t('inquiry_notice')}
                    </NewP2>
                    <FlexBox
                        marginTop={"16px"}
                        alignItems={"center"}>
                        <Checkbox
                            checked={termsOfServiceAgreeChecked}
                            onChange={(e: CheckboxChangeEvent) => {
                                setTermsOfServiceAgreeChecked(e.target.checked);
                            }}/>
                        <NewP2
                            marginLeft={"8px"}>
                            {t('check_terms_of_service_and_privacy_policy_head')}{StringConstants.SPACE}
                            <a style={{fontWeight: "700", cursor: "pointer", borderBottom: "1px solid #000"}}
                               onClick={() => {
                                   window.open("/terms-of-service")
                               }}>{t('terms_of_service')}</a>{StringConstants.SPACE}{t('and')}{StringConstants.SPACE}
                            <a style={{fontWeight: "700", cursor: "pointer", borderBottom: "1px solid #000"}}
                               onClick={() => {
                                   window.open("/privacy-policy")
                               }}>{t('privacy_policy')}</a>
                            {t('check_terms_of_service_and_privacy_policy_tail')}</NewP2>
                    </FlexBox>
                    <FlexBox
                        marginTop={"32px"}
                        alignItems={"center"}
                        alignSelf={"flex-end"}>
                        <Button marginLeft={"24px"}
                                width={"120px"}
                                fontSize={"16px"}
                                padding={"16px 20px"}
                                backgroundColor={Colors.WHITE}
                                border={`1px solid ${Colors.GRAY_6}`}
                                color={Colors.GRAY_6}
                                onClick={() => {
                                    handleClose()
                                }}>{t('cancel')}</Button>
                        <Button marginLeft={"8px"}
                                padding={"16px 20px"}
                                fontSize={"16px"}
                                width={"120px"}
                                onClick={() => {
                                    handleSubmit()
                                }}>{t('request')}</Button>
                    </FlexBox>
                </FlexBox>
            </BottomSheet>
        }
    </>
}

export default SampleRequestDialog;