import styled from "styled-components";
import Text from "../Text";
import {reactiveValueInContent} from "../../utils/StyleUtils";

const S1 = styled(Text)`
  font-size: ${({reactive}) => reactive ? reactiveValueInContent("14px", "12px") : "14px"};
  //font-size: min(calc(100vw * 0.013), 1.4rem);
  font-weight: normal;
`;

export default S1;