/**
 * @author jaeho.lee104 on 2023. 05. 11..
 */
import React from "react";
import FlexBox from "../../styledcomponents/FlexBox";
import Text from "../../styledcomponents/Text";
import styled from "styled-components";
import {Colors} from "../../styledcomponents/Styles";
import {Popover} from "antd";
import {DateFormatUtils} from "../../utils/DateFormatUtils";
import {useTranslation} from "react-i18next";


const HeaderItem = styled(Text)`
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  text-align: center;
  color: ${Colors.SUB_TXT};
`

const GraphItemContainer = styled(FlexBox)`
  height: 50px;
  border: 1px solid ${Colors.GREY_BORDER};
  margin-left: 10px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background-color: ${({backgroundColor}) => backgroundColor || Colors.WHITE};
`

const GraphItemText = styled(Text)`
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 16px;
  padding-right: 16px;
  color: ${({color}) => color || Colors.BLACK};
`

export type SupplyAnalysisGraphModel = {
    companyNm: string,
    importerNm: string,
    itemNm: string,
    productNm: string,
    avgCnt: string,
    processingDt: string,
    textColor: string,
    backgroundColor: string
}

interface SupplyAnalysisGraphProps {
    items: Array<Array<Array<SupplyAnalysisGraphModel>>>
}

export const SupplyAnalysisGraph: React.FC<SupplyAnalysisGraphProps> = ({
                                                                            items
                                                                        }) => {

    const itemWidth = "200px"
    const importerWidth = "232px"
    const productWidth = "446px"
    const avgCntWidth = "120px"
    const processingDateWidth = "150px"

    const {t} = useTranslation()


    return <FlexBox flexDirection={"column"}
                    width={"1200px"}
                    minWidth={"1200px"}
                    height={"100%"}
                    marginBottom={"30px"}>
        <FlexBox
            marginTop={"20px"}
            marginBottom={"20px"}
            alignItems={"center"}>
            <HeaderItem
                minWidth={itemWidth}
                width={itemWidth}>{t('product_type')}</HeaderItem>
            <HeaderItem
                minWidth={importerWidth}
                width={importerWidth}
                marginLeft={"10px"}>{t('trade_company')}</HeaderItem>
            <HeaderItem
                marginLeft={"10px"}
                minWidth={productWidth}
                width={productWidth}>{t('product_name')}</HeaderItem>
            <HeaderItem
                minWidth={avgCntWidth}
                width={avgCntWidth}
                marginLeft={"10px"}>{t('average_of_customs_record_per_month')}</HeaderItem>
            <HeaderItem
                minWidth={processingDateWidth}
                width={processingDateWidth}
                marginLeft={"10px"}>{t('latest_date_of_customs_record')}</HeaderItem>
        </FlexBox>
        <FlexBox
            width={"100%"}
            height={"100%"}
            flexDirection={"column"}>
            {items.map((groupByItemName, index) => {
                return <FlexBox
                    key={groupByItemName[0][0].itemNm + index}
                    width={"100%"}
                    height={"fit-content"}
                    paddingBottom={"40px"}
                    alignItems={"flex-start"}>
                    <Popover
                        placement={"top"}
                        trigger="click"
                        content={<Text>{groupByItemName[0][0].itemNm}</Text>}>
                        <FlexBox
                            alignItems={"center"}
                            style={{
                                position: "sticky",
                                top: "0px",
                                cursor: "pointer",
                                marginBottom: "10px"
                            }}>
                            <GraphItemContainer width={itemWidth}
                                                minWidth={itemWidth}
                                                backgroundColor={groupByItemName[0][0].textColor}
                                                style={{
                                                    border: Colors.TRANSPARENT,
                                                    marginLeft: "0px"
                                                }}>
                                <GraphItemText
                                    color={Colors.WHITE}
                                    style={{
                                        fontWeight: 700,
                                        fontSize: "16px",
                                    }}
                                >{groupByItemName[0][0].itemNm}</GraphItemText>
                            </GraphItemContainer>

                            <div
                                style={{
                                    width: "0",
                                    height: "0",
                                    alignSelf: "center",
                                    borderTop: "5px solid transparent",
                                    borderBottom: "5px solid transparent",
                                    borderLeft: `5px solid ${groupByItemName[0][0].textColor}`,
                                }}
                            />
                        </FlexBox>
                    </Popover>
                    <FlexBox
                        flexDirection={"column"}>
                        {groupByItemName.map((groupByImporterName, index) => {
                            return <FlexBox
                                key={groupByImporterName[0].importerNm + index}>
                                <Popover
                                    placement={"top"}
                                    trigger="click"
                                    content={<Text>{groupByImporterName[0].importerNm}</Text>}>
                                    <GraphItemContainer
                                        style={{
                                            position: "sticky",
                                            top: "0px",
                                            cursor: "pointer",
                                            marginBottom: "10px"
                                        }}
                                        backgroundColor={groupByImporterName[0].backgroundColor}
                                        minWidth={importerWidth}
                                        width={importerWidth}>
                                        <GraphItemText
                                            color={groupByImporterName[0].textColor}>{groupByImporterName[0].importerNm}</GraphItemText>
                                    </GraphItemContainer>
                                </Popover>
                                <FlexBox flexDirection={"column"}>
                                    {groupByImporterName.map((item, index) => {
                                        return <FlexBox
                                            key={item.productNm + index}
                                            style={{
                                                marginBottom: "10px"
                                            }}>
                                            <Popover
                                                placement={"top"}
                                                trigger="click"
                                                content={<Text>{item.productNm}</Text>}>
                                                <GraphItemContainer width={productWidth}
                                                                    minWidth={productWidth}
                                                                    backgroundColor={Colors.BG_GREY}
                                                                    style={{
                                                                        cursor: "pointer"
                                                                    }}>
                                                    <GraphItemText>{item.productNm}</GraphItemText>
                                                </GraphItemContainer>
                                            </Popover>
                                            <GraphItemContainer width={avgCntWidth}
                                                                minWidth={avgCntWidth}
                                                                color={Colors.SUB_TXT}>
                                                <GraphItemText
                                                    color={item.textColor}
                                                >{item.avgCnt}{t('item_case_unit')}</GraphItemText>
                                            </GraphItemContainer>
                                            <GraphItemContainer width={processingDateWidth}
                                                                minWidth={processingDateWidth}>
                                                <GraphItemText
                                                    color={Colors.SUB_TXT}>{DateFormatUtils.toYYYY_MM_DD_slash(item.processingDt)}</GraphItemText>
                                            </GraphItemContainer>
                                        </FlexBox>
                                    })}
                                </FlexBox>
                            </FlexBox>
                        })}
                    </FlexBox>
                </FlexBox>
            })}
        </FlexBox>
    </FlexBox>
}