/**
 * @author jaeho.lee104 on 2023. 11. 23..
 */
import {Autocomplete, Chip, InputAdornment, Shadows, TextField} from "@mui/material";
import React, {FC} from "react";
import {CommonProps} from "../styledcomponents/CommonComponent";
import {Colors} from "../styledcomponents/Styles";
import FlexBox from "../styledcomponents/FlexBox";
import Text from "../styledcomponents/Text";
import {CodeModel} from "../model/CodeModel";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {ExpandMoreOutlined} from "@mui/icons-material";
import Image from "../styledcomponents/Image";

const theme = createTheme({
    shadows: Array(25).fill("0px 4px 16px 0px rgba(0, 0, 0, 0.08);") as Shadows,
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                option: {
                    borderRadius: "8px",
                    margin: "4px",
                    color: Colors.GRAY_8
                },
                inputRoot: {
                    paddingLeft: "16px", //검색아이콘 앞 패딩
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    padding: "20px",
                }
            },
            defaultProps: {
                sx: {
                    borderRadius: "12px",
                    height: "52px",
                    "&& fieldset": {
                        border: `1px solid ${Colors.GRAY_4}`
                    },
                    "&:hover": {
                        "&& fieldset": {
                            border: `1px solid ${Colors.CTA_HOVER}`
                        },
                    },
                    "&.Mui-focused": {
                        "&:hover": {
                            "&& fieldset": {
                                border: `2px solid ${Colors.MAIN}`
                            },
                        },
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `2px solid ${Colors.MAIN}`
                    },
                }
            },
        },
        MuiPaper: {
            defaultProps: {
                sx: {
                    padding: "4px",
                    background: Colors.WHITE,
                    marginTop: "4px",
                    borderRadius: "12px",
                    boxShadow: "0px 2px 40px 0px rgba(0, 0, 0, 0.05);",
                }
            }
        },
        MuiList: {
            defaultProps: {
                sx: {
                    padding: "0px",
                }
            }
        },
        MuiChip: {
            defaultProps: {
                sx: {
                    borderRadius: "6px",
                    background: Colors.GRAY_1,
                    border: `1px solid ${Colors.GRAY_3}`,
                    color: Colors.GRAY_7,
                    fontSize: "14px",
                    fontWeight: 500,
                    '&+&': {
                        marginLeft: "8px"
                    }
                }
            }
        }
    },
    palette: {
        primary: {
            main: Colors.MAIN
        },
    }
});

export interface NAutoCompleteProps extends CommonProps {
    items: Array<CodeModel>
    hint?: string;
    label?: string;
    labelGap?: string;
    labelFontSize?: string;
    labelColor?: string;
    labelFontWight?: number | "normal";
    labelVisible?: boolean;
    maxLength?: number;
    width?: string
    limitTags?: number
    enabledStartAdornment?: boolean
    value: CodeModel | null
    onChangedValues?: (value: CodeModel | null) => void;
}

const SingleAutoComplete: FC<NAutoCompleteProps> = ({
                                                        value = null,
                                                        enabledStartAdornment = true,
                                                        limitTags = undefined,
                                                        items = [],
                                                        maxLength = undefined,
                                                        labelVisible = true,
                                                        label = "",
                                                        labelGap = "8px",
                                                        labelFontSize = "14px",
                                                        labelColor = Colors.GRAY_7,
                                                        labelFontWight = 500,
                                                        hint = "",
                                                        width = "100%",
                                                        ...props
                                                    }) => {

    return <FlexBox
        flexDirection={"column"}
        alignItems={"flex-start"}
        width={width}
        {...props}>
        {labelVisible && <Text fontSize={labelFontSize}
                               color={labelColor}
                               lineHeight={"150%"}
                               marginBottom={labelGap}
                               fontWeight={labelFontWight}>{label}</Text>}
        <ThemeProvider theme={theme}>
            <Autocomplete
                popupIcon={<ExpandMoreOutlined/>}
                sx={{
                    width: width,
                    overflow: "hidden",
                    maxLines: 1,
                }}
                limitTags={limitTags}
                noOptionsText={'No Options'}
                freeSolo={false}
                multiple={false}
                id="tags-outlined"
                value={value}
                isOptionEqualToValue={((option, value) => {
                    return option.code === value.code
                })}
                options={items}
                getOptionLabel={(item) => item.name}
                onChange={(event, value, reason, details) => {
                    if (props.onChangedValues) {
                        props.onChangedValues(value);
                    }
                }}
                renderTags={(value: readonly CodeModel[], getTagProps) =>
                    value.map((option: CodeModel, index: number) => {
                        if (value.length > 3) {
                            return <Chip variant="outlined"
                                         label={`${option.name.substring(0, value.length > 4 ? 1 : 2)}..`} {...getTagProps({index})} />
                        } else {
                            return <Chip variant="outlined" label={option.name} {...getTagProps({index})} />
                        }
                    })
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth={true}
                        maxRows={1}
                        sx={{
                            overflow: "hidden"
                        }}
                        placeholder={hint}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <>
                                    {enabledStartAdornment && <InputAdornment position="start">
                                        <Image
                                            alignSelf={"center"}
                                            width={"24px"}
                                            height={'24px'}
                                            src={require("assets/images/ic-search-24px.svg").default}
                                            alt="search-icon"/>
                                    </InputAdornment>}
                                    {params.InputProps.startAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
        </ThemeProvider>
    </FlexBox>;
}

export default SingleAutoComplete