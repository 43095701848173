import React, {useEffect, useState} from "react";
import FlexBox from "../styledcomponents/FlexBox";
import {ColumnsType} from "antd/es/table";
import {Table, TablePaginationConfig, Tag} from "antd";
import NewH3 from "../styledcomponents/text/NewH3";
import Text from "../styledcomponents/Text";
import {Colors} from "../styledcomponents/Styles";
import "./InquiryListFragment.css";
import LockIcon from "@mui/icons-material/Lock";
import NewButton from "../styledcomponents/NewButton";
import {ImportApplyAPI} from "../network/ImportApplyAPI";
import {ResponseCodes} from "../network/ResponseCodes";
import {ImportApplyListItem} from "../model/ImportApplyListItem";
import {useTranslation} from "react-i18next";
import useTablet from "../uses/UseTablet";
import useMobile from "../uses/UseMobile";
import NewH5 from "../styledcomponents/text/NewH5";
import NewP1 from "../styledcomponents/text/NewP1";


const InquiryListFragment = () => {


    const tableColumns: ColumnsType<ImportApplyListItem> = [
        {
            title: 'No',
            dataIndex: "idx",
            align: "center",
            width: "60px",
            render: (value, record, index) => {
                return <FlexBox width={"100%"}
                                height={"100%"}
                                alignItems={"center"}
                                justifyContent={"center"}>
                    {record.contentType === 'POST' ?
                        <Text
                            marginLeft={"8px"}
                            fontWeight={record.contentType === 'POST' ? 400 : 700}>
                            {record.contentType === 'POST' ? record.idx : 'Notice'}
                        </Text>
                        : <Tag color={Colors.GRAY_7} key={'notice'}>
                            {'Notice'}
                        </Tag>}
                </FlexBox>
            },
        },
        {
            title: 'Subject',
            dataIndex: "subject",
            align: "center",
            width: "320px",
            ellipsis: true,
            render: (value, record, index) => {
                return <FlexBox width={"100%"}
                                height={"100%"}
                                alignItems={"center"}>
                    {record.contentType === 'POST' ?
                        <FlexBox
                            alignItems={"center"}>
                            <LockIcon fontSize={"small"}/>
                            <Text
                                marginLeft={"8px"}
                                fontWeight={400}>
                                {record.subject}
                            </Text>
                        </FlexBox>
                        : <Text
                            fontWeight={700}>
                            {record.subject}
                        </Text>}
                </FlexBox>
            },
        },
        {
            title: 'Writer',
            dataIndex: "writer",
            align: "center",
            width: "48px",
            ellipsis: true,
            render: (value, record, index) => {
                return <FlexBox width={"100%"}
                                height={"100%"}
                                alignItems={"center"}
                                justifyContent={"center"}>
                    <Text
                        fontWeight={record.contentType === 'POST' ? 400 : 700}>
                        {record.writer}
                    </Text>
                </FlexBox>
            },
        },
        {
            title: 'Views',
            dataIndex: "views",
            align: "center",
            width: "48px",
        },
        {
            title: 'Date',
            dataIndex: "createDate",
            align: "center",
            width: "80px",
        }
    ];

    const {t} = useTranslation()
    const [items, setItems] = useState<Array<ImportApplyListItem>>([])
    const isTablet = useTablet()
    const isMobile = useMobile()

    useEffect(() => {
        ImportApplyAPI.getPosts(null, 0, 10)
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                if (responseCode.isSuccess()) {
                    setItems(response.data.data || [])
                }
            })
    }, []);

    function handleItemClicked(item: ImportApplyListItem) {
        window.open(
            `sourcing-inquiry/${item.contentType.toLowerCase()}/${item.postSequence}`,
            "_blank",
        );
    }

    return <FlexBox
        width={"100%"}>
        <FlexBox
            width={"100%"}
            justifyContent={"center"}
            margin={"0 auto"}
            height={"100%"}>
            <FlexBox
                width={"100%"}
                id={'table-container'}
                flexDirection={"column"}
                padding={"20px"}
                alignItems={"center"}
                marginBottom={"120px"}>
                <FlexBox
                    marginTop={"60px"}
                    width={"100%"}
                    maxWidth={"1120px"}
                    flexDirection={isMobile ? "column" : "row"}
                    alignItems={isMobile ? "flex-start" : "center"}
                    justifyContent={"space-between"}>
                    <NewH3>
                        {t('sourcing_inquiry')}
                    </NewH3>
                    <NewButton
                        marginTop={isMobile ? "20px" : "0px"}
                        width={isMobile ? "100%" : "120px"}
                        colorType={6}
                        onClick={() => {
                            window.open(
                                `sourcing-inquiry/post`,
                                "_blank",
                            );
                        }}>
                        POST
                    </NewButton>
                </FlexBox>
                <Table
                    style={{
                        display: isMobile ? "none" : "block",
                        marginTop: "40px",
                        width: "1120px"
                    }}
                    columns={tableColumns}
                    dataSource={items}
                    bordered={false}
                    scroll={{x: 1080}}
                    pagination={{
                        position: ["bottomCenter"],
                        pageSize: 10,
                        showSizeChanger: false,
                    } as TablePaginationConfig}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => {
                                handleItemClicked(record);
                            },
                        };
                    }}
                />
                {isMobile && items.map((item, index) => {
                    return <FlexBox
                        marginTop={"20px"}
                        boxShadow={"0px 4px 8px 0px #00000014;"}
                        border={`1px solid ${Colors.GRAY_3}`}
                        height={"140px"}
                        padding={"20px"}
                        borderRadius={"16px"}
                        width={"100%"}
                        flexDirection={"column"}
                        alignItems={"flex-start"}
                        cursor={"pointer"}
                        borderOnHover={`1px solid ${Colors.GRAY_7}`}
                        onClick={() => {
                            handleItemClicked(item);
                        }}>
                        <FlexBox
                            width={"100%"}
                            alignItems={"center"}
                            justifyContent={"space-between"}>
                            <NewP1>
                                No.{item.idx}&nbsp;&nbsp;<span style={{
                                color: Colors.GRAY_3
                            }}>|</span>&nbsp;&nbsp;{item.writer}
                            </NewP1>
                            <NewP1>
                                {item.createDate}
                            </NewP1>
                        </FlexBox>
                        <FlexBox
                            marginTop={"24px"}
                            alignItems={"flex-start"}>
                            {item.contentType === 'POST' && <LockIcon
                                sx={{
                                    marginTop: "4px",
                                    marginRight: "6px"
                                }}
                                fontSize={"small"}/>}
                            <NewH5
                                textAlign={"left"}
                                maxLines={2}
                                textOverflow={"ellipsis"}
                                overflow={"hidden"}
                                overflowWrap={"anywhere"}
                                width={"100%"}>
                                {item.subject}
                            </NewH5>
                        </FlexBox>
                    </FlexBox>
                })}
            </FlexBox>
        </FlexBox>
    </FlexBox>
}
export default InquiryListFragment;