import React, {useRef, useState} from "react";
import FlexBox from "../styledcomponents/FlexBox";
import NewH3 from "../styledcomponents/text/NewH3";
import TextField from "../components/TextField";
import NewButton from "../styledcomponents/NewButton";
import {Checkbox} from "antd";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import NewP2 from "../styledcomponents/text/NewP2";
import {Colors} from "../styledcomponents/Styles";
import {StringConstants} from "../constants/StringConstants";
import {useTranslation} from "react-i18next";
import TextArea from "antd/lib/input/TextArea";
import {ImportApplyAPI} from "../network/ImportApplyAPI";
import {ResponseCodes} from "../network/ResponseCodes";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {RegexUtils} from "../constants/RegexUtils";
import Image from "../styledcomponents/Image";
import HorizontalDivider from "../styledcomponents/HorizontalDivider";
import CheckIcon from "@mui/icons-material/Check";
import NewH4 from "../styledcomponents/text/NewH4";
import useTablet from "../uses/UseTablet";
import useMobile from "../uses/UseMobile";
import styled from "styled-components";
import TextEditor from "../components/TextEditor";
import {Editor} from "@toast-ui/react-editor";

const PostTextArea = styled(TextArea)`
    .ant-input::placeholder {
        color: ${Colors.GRAY_6};
    }
`;

const InquiryModifyFragment = () => {

    const {postSequence} = useParams();
    const {state} = useLocation();

    const [companyName, setCompanyName] = useState(state.companyName)
    const [companyNameError, setCompanyNameError] = useState(false)

    const [subject, setSubject] = useState(state.subject)
    const [subjectError, setSubjectError] = useState(false)

    const [writer, setWriter] = useState(state.writer)
    const [writerError, setWriterError] = useState(false)

    const [email, setEmail] = useState(state.email)
    const [emailError, setEmailError] = useState(false)

    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState(false)

    const [phoneNumber, setPhoneNumber] = useState(state.phone)
    const [phoneNumberError, setPhoneNumberError] = useState(false)

    const [termsOfServiceAgreeChecked, setTermsOfServiceAgreeChecked] = useState(false);
    const {t} = useTranslation()
    const navigate = useNavigate()
    const isTablet = useTablet()
    const isMobile = useMobile()
    const editorRef = useRef<Editor>(null)

    function handleSaveClicked() {
        if (!postSequence) {
            alert(t('must_retry'))
            return;
        }
        if (!subject) {
            setSubjectError(true)
            return
        }
        if (!companyName) {
            setCompanyNameError(true)
            return;
        }
        if (!email || !RegexUtils.checkEmail(email)) {
            setEmailError(true)
            return;
        }
        if (!writer || writer.length < 2) {
            setWriterError(true)
            return;
        }
        if (!phoneNumber) {
            setPhoneNumberError(true)
            return;
        }
        if (!password) {
            setPasswordError(true)
            return;
        }
        if (!termsOfServiceAgreeChecked) {
            alert(t('alert_please_agree_to_the_terms_of_service_and_privacy_policy'))
            return
        }
        ImportApplyAPI.modifyPost(
            postSequence,
            subject,
            companyName,
            writer,
            email,
            phoneNumber,
            password,
            editorRef.current?.getInstance().getHTML() || ""
        ).then((response) => {
            const responseCode = ResponseCodes.of(response.data.code)
            if (responseCode.isSuccess()) {
                navigate(-1)
            } else {
                alert(t('alert_a_temporary_error_has_occurred'))
            }
        })
    }

    return <FlexBox
        width={"100%"}
        minWidth={isTablet ? "100%" : "1200px"}
        justifyContent={"center"}
        backgroundColor={Colors.BG_PAGE}
        height={"100%"}>
        <FlexBox
            backgroundColor={Colors.WHITE}
            width={"640px"}
            border={`1px solid ${Colors.GRAY_3}`}
            borderRadius={"12px"}
            padding={isMobile ? "24px" : "40px"}
            margin={"40px 20px 120px 20px"}
            flexDirection={"column"}
            alignItems={"center"}>
            <Image
                visible={!isTablet}
                width={"132px"}
                position={"absolute"}
                top={"24px"}
                right={"24px"}
                opacity={0.8}
                src={require("assets/images/app-bar-logo.svg").default}
                alignSelf={"center"}
                alt="form-logo"/>
            <FlexBox
                marginTop={isTablet ? "0px" : "20px"}
                alignItems={"center"}
                justifyContent={"space-between"}>
                <NewH3>
                    {t('import_apply_form')}
                </NewH3>
            </FlexBox>
            <TextField
                width={"100%"}
                isRequired={true}
                label={`${t('inquiry_subject')}`}
                labelFontWight={700}
                labelFontSize={"16px"}
                marginTop={"34px"}
                error={subjectError}
                value={subject}
                onChange={(e) => {
                    if (subjectError) {
                        setSubjectError(false)
                    }
                    setSubject(e.target.value)
                }}
                type={"text"}
            />
            <TextField
                width={"100%"}
                isRequired={true}
                label={`${t('company')}`}
                labelFontWight={700}
                labelFontSize={"16px"}
                marginTop={"34px"}
                error={companyNameError}
                errorText={`${t('alert_please_enter_the_company_name')}`}
                value={companyName}
                onChange={(e) => {
                    if (companyNameError) {
                        setCompanyNameError(false)
                    }
                    setCompanyName(e.target.value)
                }}
                type={"text"}
            />
            <FlexBox
                marginTop={isTablet ? "0px" : "24px"}
                width={'100%'}
                flexDirection={isTablet ? "column" : "row"}>
                <TextField
                    width={"100%"}
                    marginTop={isTablet ? "24px" : "0px"}
                    isRequired={true}
                    label={`${t('email')}`}
                    labelFontWight={700}
                    labelFontSize={"16px"}
                    error={emailError}
                    errorText={`${t('invalid_email_format')}`}
                    value={email}
                    onChange={(e) => {
                        if (emailError) {
                            setEmailError(false)
                        }
                        setEmail(e.target.value)
                    }}
                    type={"text"}
                />
                <TextField
                    marginLeft={isTablet ? "0px" : "20px"}
                    marginTop={isTablet ? "24px" : "0px"}
                    width={"100%"}
                    label={`${t('writer')}`}
                    isRequired={true}
                    labelFontWight={700}
                    labelFontSize={"16px"}
                    error={writerError}
                    value={writer}
                    onChange={(e) => {
                        if (writerError) {
                            setWriterError(false)
                        }
                        setWriter(e.target.value)
                    }}
                    type={"text"}
                />
            </FlexBox>
            <FlexBox
                alignItems={"center"}
                marginTop={isTablet ? "0px" : "24px"}
                width={'100%'}
                flexDirection={isTablet ? "column" : "row"}>
                <TextField
                    width={"100%"}
                    isRequired={true}
                    label={`${t('phone')}`}
                    marginTop={isTablet ? "24px" : "0px"}
                    labelFontWight={700}
                    labelFontSize={"16px"}
                    error={phoneNumberError}
                    value={phoneNumber}
                    errorText={`${t('alert_please_enter_your_phone_number2')}`}
                    onChange={(e) => {
                        if (phoneNumberError) {
                            setPhoneNumberError(false)
                        }
                        setPhoneNumber(e.target.value)
                    }}
                    type={"text"}
                />
                <TextField
                    width={"100%"}
                    marginLeft={isTablet ? "0px" : "20px"}
                    marginTop={isTablet ? "24px" : "0px"}
                    label={`${t('password')}`}
                    isRequired={true}
                    labelFontWight={700}
                    labelFontSize={"16px"}
                    error={passwordError}
                    value={password}
                    onChange={(e) => {
                        if (password) {
                            setPasswordError(false)
                        }
                        setPassword(e.target.value)
                    }}
                    type={"password"}
                />
            </FlexBox>
            <HorizontalDivider margin={"34px"}/>
            <FlexBox
                alignSelf={"flex-start"}
                alignItems={"center"}>
                <CheckIcon sx={{color: Colors.GRAY_7}}/>
                <NewH4 fontSize={'16px'}
                       marginLeft={"8px"}
                       color={Colors.GRAY_7}
                       lineHeight={"150%"}
                       alignSelf={"flex-start"}
                       fontWeight={700}>{t('sourcing_content')}</NewH4>
            </FlexBox>
            <FlexBox
                width={"100%"}
                marginTop={"20px"}>
                <TextEditor editorRef={editorRef} content={`${t('import_apply_form_content_placeholder')}`}/>
            </FlexBox>
            <FlexBox
                alignSelf={"flex-end"}
                marginTop={"34px"}>
                <Checkbox
                    checked={termsOfServiceAgreeChecked}
                    onChange={(e: CheckboxChangeEvent) => {
                        setTermsOfServiceAgreeChecked(e.target.checked);
                    }}/>
                <NewP2
                    color={Colors.GRAY_7}
                    marginLeft={"6px"}
                    fontSize={"14px"}>
                    {t('check_terms_of_service_and_privacy_policy_head')}{StringConstants.SPACE}
                    <a style={{fontWeight: "700", cursor: "pointer", borderBottom: "1px solid #000"}}
                       onClick={() => {
                           window.open("/terms-of-service")
                       }}>{t('terms_of_service')}</a>{StringConstants.SPACE}{t('and')}{StringConstants.SPACE}
                    <a style={{fontWeight: "700", cursor: "pointer", borderBottom: "1px solid #000"}}
                       onClick={() => {
                           window.open("/privacy-policy")
                       }}>{t('privacy_policy')}</a>
                    {t('check_terms_of_service_and_privacy_policy_tail')}</NewP2>
            </FlexBox>
            <NewButton
                alignSelf={"flex-end"}
                marginTop={"40px"}
                width={isMobile ? "100%" : "200px"}
                colorType={6}
                onClick={() => {
                    handleSaveClicked()
                }}>
                {t('save')}
            </NewButton>
        </FlexBox>
    </FlexBox>;
}
export default InquiryModifyFragment;