import React, {useEffect, useState} from "react";
import FlexBox from "../styledcomponents/FlexBox";
import {Colors} from "../styledcomponents/Styles";
import {FormatUtils} from "../utils/FormatUtils";
import HorizontalDivider from "../styledcomponents/HorizontalDivider";
import Text from "../styledcomponents/Text";
import {NewDashboardApi} from "../network/NewDashboardApi";
import RankChart, {RankItem} from "../components/chart/RankChart";
import {RenderingByState} from "../components/RenderingByState";
import {IncomeTrendWgtAreaChart, IncomeTrendWgtChartModel} from "../components/chart/IncomeTrendWgtAreaChart";
import {ChartXYData} from "../model/ChartXYData";
import CountryRankChart, {CountryRankItem} from "../components/CountryRankChart";
import {ReGeoMapChart} from 'regeo-map-chart';
import TreemapChart, {TreemapDataItem} from "../components/chart/TreemapChart";
import Button from "../styledcomponents/Button";
import S1 from "../styledcomponents/text/S1";
import {Popover} from "antd";
import {MarketTrendPriceChart, MarketTrendPriceChartKey, MarketTrendPriceChartModel} from "../components/chart/MarketTrendPriceChart";
import {Fade} from "react-awesome-reveal";
import "./IncomeTrendFragment.css";
import {useTranslation} from "react-i18next";
import {LocalStorageManager} from "../LocalStorageManager";
import {Carousel} from "react-bootstrap";
import NewP2 from "../styledcomponents/text/NewP2";
import Image from "../styledcomponents/Image";
import useTablet from "../uses/UseTablet";
import {useNavigate} from "react-router-dom";


/**
 * @author jaeho.lee104 on 2023. 04. 21..
 */
interface IncomeTrendFragmentProps {
    hsCode: string,
    onCompanyClick: (companyId: string) => void
}

const IncomeTrendFragment: React.FC<IncomeTrendFragmentProps> = ({hsCode, onCompanyClick}) => {
    const [incomeTrendWgtChartModels, setIncomeTrendWgtChartModels] = useState<Array<IncomeTrendWgtChartModel> | null>(null);
    const [hsCodeRankItems, setHsCodeRankItems] = useState<Array<RankItem> | null>(null)
    const [categoryRankItems, setCategoryRankItems] = useState<Array<RankItem> | null>(null)
    const [productRankItems, setProductRankItems] = useState<Array<RankItem> | null>(null)
    const [countryRankItems, setCountryRankItems] = useState<Array<CountryRankItem> | null>(null)
    const [mapData, setMapData] = useState<Array<any> | null>(null)
    const [companyItems, setCompanyItems] = useState<Array<TreemapDataItem> | null>(null)
    const [companyType, setCompanyType] = useState("exp")
    const [marketTrendPriceChartModels, setMarketTrendPriceChartModels] = useState<Array<MarketTrendPriceChartModel> | null>(null)
    const [marketTrendPriceChartKey, setMarketTrendPriceChartKey] = useState<MarketTrendPriceChartKey | null>(null)
    const {t, i18n} = useTranslation()
    const [language, setLanguage] = useState<string>(LocalStorageManager.getLanguage)
    const isTablet = useTablet()
    const navigate = useNavigate()

    i18n.on("languageChanged", (lng) => {
        setLanguage(lng)
    })

    useEffect(() => {
        setIncomeTrendWgtChartModels(null)
        setHsCodeRankItems(null)
        setCategoryRankItems(null)
        setProductRankItems(null)
        setCountryRankItems(null)
        setMapData(null)
        setCompanyItems(null)
        setMarketTrendPriceChartModels(null)
    }, [hsCode])

    useEffect(() => {
        setHsCodeRankItems(null)
        setCategoryRankItems(null)
        setProductRankItems(null)
        setCountryRankItems(null)
        setMapData(null)
    }, [language])

    {/*------------------------------------- 수입 트렌드 차트 -------------------------------------*/
    }
    useEffect(() => {
        if (!hsCode) {
            return
        }
        NewDashboardApi.getIncomeTrendWgt(hsCode, 2)
            .then((response) => response.data)
            .then((data) => {
                const years = data.data
                if (!years || years.length === 0) {
                    setIncomeTrendWgtChartModels([]);
                    return;
                }
                let isValid = false
                years.forEach((yearData) => {
                    yearData.forEach((groupByYear) => {
                        isValid ||= Number(groupByYear.wgt) > 0
                    })
                })
                if (!isValid) {
                    setIncomeTrendWgtChartModels([])
                    return;
                }
                let incomeTrendWgtArray: Array<IncomeTrendWgtChartModel> = [];
                for (let i = 0; i < years.length; i++) {
                    const groupByYear = years[i]
                    let trendData = groupByYear.map((incomeTrendModel, index) => {
                        let value = Number(incomeTrendModel.wgt)
                        const month = parseInt(incomeTrendModel.month)
                        return {
                            x: t('month_' + month) + (language === "en" ? `(${month})` : ""),
                            y: value / 1000,
                        } as ChartXYData;
                    });
                    let firstIndexYear = groupByYear[0].year;
                    let lastIndexYear = groupByYear[groupByYear.length - 1].year;
                    let id = firstIndexYear;
                    if (firstIndexYear !== lastIndexYear) {
                        id += `-${lastIndexYear}`;
                    }
                    incomeTrendWgtArray.push({
                        id: id,
                        data: trendData,
                    });
                }
                setIncomeTrendWgtChartModels(incomeTrendWgtArray.reverse());
            })
            .catch((e) => {
                setIncomeTrendWgtChartModels([]);
            })
    }, [hsCode, language])

    {/*------------------------------------- 수입 트렌드 순위 차트 -------------------------------------*/
    }
    useEffect(() => {
        if (!hsCode) {
            return
        }
        NewDashboardApi.getIncomeTrendRank(hsCode, language)
            .then((response) => {
                const result = response.data.data
                const hsCodeRank = result?.hscodeRank
                const categoryRank = result?.itemRank
                const productRank = result?.productRank
                if (!hsCodeRank || hsCodeRank.length === 0) {
                    setHsCodeRankItems([])
                } else {
                    const mapped = hsCodeRank.slice(0, 5).map((value) => {
                        const formattedValue = parseFloat(value.cnt) > 1000 ? Math.floor(parseFloat(value.cnt) / 1000)
                            : (parseFloat(value.cnt) / 1000.0).toFixed(3)
                        return {
                            title: `${FormatUtils.toHsCodeFormat(value.code, 10)} ${value.name}`,
                            value: formattedValue,
                            unit: t('item_ton_unit')
                        } as RankItem
                    })
                    setHsCodeRankItems(mapped)
                }
                if (!categoryRank || categoryRank.length === 0) {
                    setCategoryRankItems([])
                } else {
                    const mapped = categoryRank.slice(0, 5).map((value) => {
                        return {
                            title: value.name,
                            value: Number(value.cnt),
                            unit: t('item_case_unit')
                        } as RankItem
                    })
                    setCategoryRankItems(mapped)
                }
                if (!productRank || productRank.length === 0) {
                    setProductRankItems([])
                } else {
                    const mapped = productRank.slice(0, 5).map((value) => {
                        return {
                            title: value.name,
                            value: Number(value.cnt),
                            unit: t('item_case_unit')
                        } as RankItem
                    })
                    setProductRankItems(mapped)
                }
            })
            .catch((e) => {
                setHsCodeRankItems([])
                setCategoryRankItems([])
                setProductRankItems([])
            })

    }, [hsCode, language])

    {/*------------------------------------- 국가 순위 차트 -------------------------------------*/
    }
    useEffect(() => {
        if (!hsCode) {
            return
        }
        NewDashboardApi.getMarketTrendCountry(hsCode, language)
            .then((response) => {
                const countryItems = response.data.data
                if (!countryItems || countryItems.length === 0) {
                    setCountryRankItems([])
                    setMapData([])
                    return
                }
                const countryRankItems = countryItems.slice(0, 5).map((item) => {
                    return {
                        countryName: item.countryNm,
                        countryCode: item.countryCd,
                        percent: item.percent,
                    } as CountryRankItem
                })
                setCountryRankItems(countryRankItems)

                let mapData = [];
                mapData.push(["Region", "weight(k/wt)"]);
                countryItems.slice(0, 5).forEach((element) => {
                    if (Number(element.weight) > 0) {
                        mapData.push([element.countryCd.toLowerCase(), Math.round(parseInt(element.weight) / 1000)]);
                    }
                });
                if (mapData.length === 1) {
                    setMapData([]);
                } else {
                    setMapData(mapData);
                }
            })
            .catch((e) => {
                setCountryRankItems([])
                setMapData([])
            })

    }, [hsCode, language])

    {/*------------------------------------- 업체별 점유율 차트 -------------------------------------*/
    }
    useEffect(() => {
        if (!hsCode) {
            return
        }
        NewDashboardApi.getMarketTrendCompany(hsCode, companyType)
            .then((response) => {
                const companyItems = response.data.data
                if (!companyItems || companyItems.length === 0) {
                    setCompanyItems([])
                    return
                }
                const treemapItems = companyItems!.slice(0, 5).map((item, index) => {
                    return {
                        id: item.companyId,
                        what: item.companyNm,
                        value: Number(item.percent),
                        color: Colors.COLOR_SET[index % 5],
                    }
                })
                setCompanyItems(treemapItems)
            })
            .catch((e) => {
                setCompanyItems([])
            })

    }, [hsCode, companyType])

    {/*------------------------------------- 평균 통관 가격 차트 -------------------------------------*/
    }
    useEffect(() => {
        if (!hsCode) {
            return;
        }
        NewDashboardApi.getMarketTrendPrice(hsCode)
            .then((response) => {
                const originPriceData = response.data.data
                if (!originPriceData || originPriceData.length === 0) {
                    setMarketTrendPriceChartModels([])
                    return;
                }
                let priceArray = [];
                for (let i = 0; i < originPriceData.length; i++) {
                    if (!originPriceData[i] || originPriceData[i].length === 0) {
                        continue;
                    }
                    const length = originPriceData[i].length
                    let firstItemYear = originPriceData[i][0].year;
                    let lastItemYear = originPriceData[i][length - 1].year;
                    let id = firstItemYear;
                    if (firstItemYear !== lastItemYear) {
                        id += `-${lastItemYear}`;
                    }
                    let priceModel = originPriceData[i].map((trendData) => {
                        return {
                            id: id,
                            price: trendData.price,
                            month: t('month_' + parseInt(trendData.month))
                        };
                    });
                    priceArray.push(priceModel);
                }
                let groupedByMonthData = [];
                for (let i = 0; i < priceArray[0].length; i++) {
                    let groupedByMonth: MarketTrendPriceChartModel = {}
                    groupedByMonth[priceArray[0][i].id] = parseFloat(priceArray[0][i].price);
                    groupedByMonth["month"] = priceArray[0][i].month;
                    groupedByMonth[priceArray[1][i].id] = parseFloat(priceArray[1][i].price);
                    groupedByMonthData.push(groupedByMonth);
                }
                setMarketTrendPriceChartModels(groupedByMonthData);
                setMarketTrendPriceChartKey({
                    key1: priceArray[0][0].id,
                    key2: priceArray[1][0].id
                })
            })
            .catch((e) => {
                setMarketTrendPriceChartModels([])
                setMarketTrendPriceChartKey(null)
            })
    }, [hsCode, language]);

    function getSizeForScreenType(origin: string, fraction: number = 0.75): string {
        let value = Number(origin.replace("px", ""))
        return (isTablet ? value * fraction : value) + "px"
    }

    return (
        <FlexBox
            id={"income-trend-container"}
            flexDirection={"column"}
            width={"100%"}>
            {/*------------------------------------- 수입 트렌드 차트 -------------------------------------*/}
            <Text
                id={'income_trend_title'}
                fontSize={isTablet ? "20px" : "24px"}
                fontWeight={700}
                marginTop={getSizeForScreenType("40px")}
                marginLeft={getSizeForScreenType("20px")}>
                {t('import_trends')}
            </Text>
            <FlexBox
                width={"100%"}
                height={isTablet ? "440px" : "418px"}
                border={`1px solid ${Colors.GREY_BORDER}`}
                borderRadius={"10px"}
                marginTop={"24px"}
                paddingTop={"26px"}
                paddingLeft={isTablet ? "20px" : "30px"}
                paddingRight={isTablet ? "20px" : "30px"}
                paddingBottom={isTablet ? "20px" : "30px"}
                flexDirection={"column"}>
                <FlexBox
                    alignItems={isTablet ? "flex-start" : "flex-end"}
                    justifyContent={"space-between"}
                    flexDirection={isTablet ? "column" : "row"}>
                    <Text
                        fontSize={isTablet ? "18px" : "20px"}
                        fontWeight={700}>
                        {t('import_trends_chart_title', {hsCode: FormatUtils.toHsCodeFormat(hsCode)})}
                    </Text>
                    <FlexBox
                        alignItems={"flex-start"}
                        marginTop={isTablet ? "4px" : "0px"}>
                        <Text
                            color={Colors.KEY}
                            fontSize={"14px"}>
                            {t('hs_code_standard')}
                        </Text>
                        <Text
                            color={Colors.SUB_TXT}
                            fontSize={"14px"}
                            marginLeft={"6px"}>
                            {t('ton_unit')}
                        </Text>
                    </FlexBox>
                </FlexBox>
                <HorizontalDivider
                    paddingTop={"1px"}
                    marginTop={"20px"}
                    backgroundColor={Colors.KEY}/>
                <FlexBox
                    width={"100%"}
                    height={"100%"}
                    overflowX={isTablet ? "scroll" : "hidden"}
                    paddingBottom={"10px"}>
                    <RenderingByState items={incomeTrendWgtChartModels}>
                        {incomeTrendWgtChartModels &&
                            <Fade duration={500}
                                  triggerOnce={true}
                                  style={{
                                      width: "100%",
                                      height: "100%",
                                      minWidth: isTablet ? "840px" : "100%"
                                  }}>
                                <IncomeTrendWgtAreaChart
                                    chartModels={incomeTrendWgtChartModels}
                                />
                            </Fade>}
                    </RenderingByState>
                </FlexBox>
            </FlexBox>
            {/*------------------------------------- 품목별/유형별/제품별 순위 차트 -------------------------------------*/}
            {!isTablet && <FlexBox
                width={"100%"}
                marginTop={"24px"}
                justifyContent={"space-between"}>
                <RankChart title={t('import_volume_rate_by_item')}
                           key={"hsCodeRank"}
                           hsCodeStandard={t('hs_code_10_unit_standard')}
                           representColor={Colors.PREMIUM}
                           unitDescription={`${t(`ton_unit`)}, ${t('last_12_months')}`}
                           showInfo={false}
                           infoContent={""}
                           rankItems={hsCodeRankItems}/>
                <RankChart title={t('import_volume_rate_by_type')}
                           key={"categoryRank"}
                           hsCodeStandard={t('hs_code_standard')}
                           representColor={Colors.KEY}
                           unitDescription={`${t(`case_unit`)}, ${t('last_12_months')}`}
                           showInfo={false}
                           infoContent={""}
                           marginLeft={"20px"}
                           rankItems={categoryRankItems}/>
                <RankChart title={t('import_volume_by_product')}
                           key={"productRank"}
                           hsCodeStandard={t('hs_code_standard')}
                           representColor={Colors.STANDARD}
                           unitDescription={`${t(`case_unit`)}, ${t('last_12_months')}`}
                           showInfo={true}
                           marginLeft={"20px"}
                           infoContent={t('import_volume_by_product_pop_over')}
                           rankItems={productRankItems}/>
            </FlexBox>}
            {isTablet &&
                <Carousel
                    style={{
                        marginTop: "30px"
                    }}
                    interval={null}>
                    <Carousel.Item>
                        <RankChart title={t('import_volume_rate_by_item')}
                                   key={"hsCodeRank"}
                                   hsCodeStandard={t('hs_code_10_unit_standard')}
                                   representColor={Colors.PREMIUM}
                                   unitDescription={`${t(`ton_unit`)}, ${t('last_12_months')}`}
                                   showInfo={false}
                                   infoContent={""}
                                   rankItems={hsCodeRankItems}/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <RankChart title={t('import_volume_rate_by_type')}
                                   key={"categoryRank"}
                                   hsCodeStandard={t('hs_code_standard')}
                                   representColor={Colors.KEY}
                                   unitDescription={`${t(`case_unit`)}, ${t('last_12_months')}`}
                                   showInfo={false}
                                   infoContent={""}
                                   rankItems={categoryRankItems}/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <RankChart title={t('import_volume_by_product')}
                                   key={"productRank"}
                                   hsCodeStandard={t('hs_code_standard')}
                                   representColor={Colors.STANDARD}
                                   unitDescription={`${t(`case_unit`)}, ${t('last_12_months')}`}
                                   showInfo={true}
                                   infoContent={t('import_volume_by_product_pop_over')}
                                   rankItems={productRankItems}/>
                    </Carousel.Item>
                </Carousel>
            }

            {/*------------------------------------- 마켓 트렌드 차트 -------------------------------------*/}
            <Text
                id={"market-trend-title"}
                fontSize={isTablet ? "20px" : "24px"}
                fontWeight={700}
                marginTop={getSizeForScreenType("80px")}
                marginLeft={"20px"}>
                {t('market_trends')}
            </Text>
            <FlexBox
                width={"100%"}
                border={`1px solid ${Colors.GREY_BORDER}`}
                borderRadius={"10px"}
                marginTop={"24px"}
                height={"fit-content"}
                flexDirection={"column"}>
                <FlexBox
                    flexDirection={isTablet ? "column" : "row"}>
                    <CountryRankChart title={t('import_rate_by_countries')}
                                      unitDescription={t('ton_unit')}
                                      rankItems={countryRankItems}/>

                    <FlexBox
                        width={"100%"}
                        height={"100%"}
                        alignSelf={"center"}
                        alignItems={"center"}
                        justifyContent={"center"}>
                        <RenderingByState items={mapData}>
                            {mapData &&
                                <Fade duration={500}
                                      style={{
                                          width: isTablet ? "100%" : undefined,
                                          paddingLeft: isTablet ? "20px" : undefined,
                                          paddingRight: isTablet ? "20px" : undefined,
                                          paddingBottom: isTablet ? "20px" : undefined
                                      }}
                                      triggerOnce={true}>
                                    <ReGeoMapChart
                                        data={mapData}
                                        width={isTablet ? "100%" : 580}
                                        datalessRegionColor="#EFEFEF"
                                        datafulRegionColor={Colors.KEY}
                                        backgroundColor="#fff"
                                        strokeColor="#BBBCBC"
                                        tooltipBackgroundColor="#fff"
                                    />
                                </Fade>}
                        </RenderingByState>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
            <FlexBox
                height={isTablet ? "900px" : "430px"}
                marginTop={"24px"}
                flexDirection={isTablet ? "column" : "row"}>
                <FlexBox
                    width={isTablet ? "100%" : "400px"}
                    height={isTablet ? "fit-content" : "100%"}
                    border={`1px solid ${Colors.GREY_BORDER}`}
                    borderRadius={"10px"}
                    flexDirection={"column"}
                    paddingTop={isTablet ? "20px" : "25px"}
                    paddingLeft={isTablet ? "20px" : "30px"}
                    paddingRight={isTablet ? "20px" : "30px"}
                    paddingBottom={"40px"}>
                    <Text lineHeight={"20px"}
                          fontWeight={700}
                          fontSize={isTablet ? "18px" : "20px"}>
                        {t('market_share_by_companies')}
                    </Text>
                    <HorizontalDivider
                        paddingTop={"1px"}
                        marginTop={getSizeForScreenType("20px")}
                        backgroundColor={Colors.KEY}/>
                    <FlexBox
                        visible={companyItems !== null && companyItems.length > 0}
                        marginTop={"8px"}
                        alignSelf={"flex-end"}
                        alignItems={"center"}
                        cursor={"pointer"}
                        onClick={() => {
                            navigate("/dashboard?index=1")
                        }}>
                        <NewP2 color={Colors.GRAY_6}>
                            {t('more_view')}
                        </NewP2>
                        <Image
                            src={require("assets/images/ic-right-arrow-gray.svg").default}
                            alt="ic-right-arrow-companies-more"/>
                    </FlexBox>
                    <FlexBox
                        width={isTablet ? "100%" : "280px"}
                        height={isTablet ? "36px" : "40px"}
                        borderRadius={isTablet ? "6px" : "10px"}
                        marginTop={companyItems ? getSizeForScreenType("8px") : getSizeForScreenType("30px")}
                        marginBottom={getSizeForScreenType("30px")}
                        alignSelf={"center"}
                        background={Colors.GREY_TAB}
                        alignItems={"center"}
                        justifyContent={"center"}>
                        <Button
                            height={isTablet ? "28px" : "32px"}
                            width={isTablet ? "100%" : "136px"}
                            fontSize={isTablet ? "12px" : "14px"}
                            marginLeft={isTablet ? "4px" : "0px"}
                            borderRadius={"6px"}
                            fontWeight={companyType === "exp" ? 700 : 400}
                            backgroundColor={companyType === "exp" ? Colors.WHITE : Colors.TRANSPARENT}
                            color={companyType === "exp" ? Colors.KEY : Colors.SUB_TXT}
                            border={companyType === "exp" ? `1px solid ${Colors.KEY}` : undefined}
                            onClick={() => {
                                if (companyType !== "exp") {
                                    setCompanyItems(null)
                                    setCompanyType("exp")
                                }
                            }}>
                            {t('overseas_supplier')}
                        </Button>
                        <Button
                            height={isTablet ? "28px" : "32px"}
                            width={isTablet ? "100%" : "136px"}
                            fontSize={isTablet ? "12px" : "14px"}
                            marginRight={isTablet ? "4px" : "0px"}
                            borderRadius={"6px"}
                            fontWeight={companyType === "imp" ? 700 : 400}
                            backgroundColor={companyType === "imp" ? Colors.WHITE : Colors.TRANSPARENT}
                            color={companyType === "imp" ? Colors.KEY : Colors.SUB_TXT}
                            border={companyType === "imp" ? `1px solid ${Colors.KEY}` : undefined}
                            onClick={() => {
                                if (companyType !== "imp") {
                                    setCompanyItems(null)
                                    setCompanyType("imp")
                                }
                            }}>
                            {t('importers_in_kor')}
                        </Button>

                    </FlexBox>
                    <FlexBox width={isTablet ? "100%" : "340px"}
                             height={isTablet ? "fit-content" : "220px"}
                             alignSelf={"center"}
                             alignItems={"center"}
                             justifyContent={"center"}>
                        <RenderingByState items={companyItems}>
                            {companyItems && <TreemapChart
                                items={companyItems}
                                onItemClick={(item) => {
                                    onCompanyClick(item.id)
                                }}/>}
                        </RenderingByState>
                    </FlexBox>
                </FlexBox>

                <FlexBox
                    width={"100%"}
                    overflow={"hidden"}
                    marginLeft={isTablet ? "0px" : "14px"}
                    height={isTablet ? "480px" : "100%"}
                    border={`1px solid ${Colors.GREY_BORDER}`}
                    borderRadius={"10px"}
                    marginTop={isTablet ? "20px" : "0px"}
                    paddingTop={"25px"}
                    paddingLeft={"30px"}
                    paddingRight={"30px"}
                    paddingBottom={"30px"}
                    flexDirection={"column"}>
                    <FlexBox
                        alignItems={isTablet ? "flex-start" : "flex-end"}
                        justifyContent={"space-between"}
                        flexDirection={isTablet ? "column" : "row"}>
                        <FlexBox>
                            <Text
                                fontSize={"18px"}
                                fontWeight={700}
                                lineHeight={"20px"}>
                                {t('average_price_on_customs_clearance')}
                            </Text>
                            {<Popover
                                placement={"top"}
                                trigger="click"
                                content={
                                    <FlexBox flexDirection={"column"}
                                             margin={"10px"}
                                             maxWidth={"280px"}>
                                        <Text color={Colors.MAIN}
                                              fontSize={"14px"}>
                                            {t('price_by_quantity')}
                                        </Text>
                                    </FlexBox>}>
                                <Text cursor={"pointer"}
                                      fontSize={"18px"}
                                      lineHeight={"18px"}
                                      marginLeft={"6px"}
                                      marginTop={"3px"}>ⓘ</Text>
                            </Popover>}
                        </FlexBox>
                        <S1
                            marginTop={isTablet ? "10px" : "0px"}
                            lineHeight={"14px"}
                            color={Colors.SUB_TXT}>
                            {`${t('kg_unit')}, ${t('last_12_months')}`}
                        </S1>
                    </FlexBox>
                    <HorizontalDivider
                        paddingTop={"1px"}
                        marginTop={"20px"}
                        backgroundColor={Colors.KEY}/>
                    <FlexBox
                        width={"100%"}
                        height={"100%"}
                        overflowX={isTablet ? "scroll" : "hidden"}>
                        <RenderingByState items={marketTrendPriceChartModels}>
                            {marketTrendPriceChartModels && marketTrendPriceChartKey &&
                                <MarketTrendPriceChart chartModels={marketTrendPriceChartModels}
                                                       keys={marketTrendPriceChartKey}/>}
                        </RenderingByState>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </FlexBox>
    )
}
export default IncomeTrendFragment;