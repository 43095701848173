import {KeyboardEvent, MouseEvent} from "react";
import {RegexUtils} from "../constants/RegexUtils";

export class KeyboardUtils {
    static filterOnlyNumber(e: KeyboardEvent<HTMLInputElement>): boolean {
        return RegexUtils.checkOnlyNumber(e.key) || e.key === "Backspace";
    }

    static clearNumber(value: string): string {
        return value.replace(/\D+/g, "");
    }

    static isNewWindowTriggerKey(event: MouseEvent<any>): boolean {
        return event.ctrlKey || event.metaKey
    }
}