import styled from "styled-components";

export interface CommonProps {
    disabled?: boolean;
    visible?: boolean;
    margin?: string;
    marginLeft?: string;
    marginRight?: string;
    marginTop?: string;
    marginBottom?: string;
    padding?: string;
    paddingLeft?: string
    paddingRight?: string
    paddingTop?: string
    paddingBottom?: string
    textAlign?: "left" | "right" | "center"
    overflow?: "hidden" | "auto" | "scroll" | "visible"
    overflowY?: "hidden" | "auto" | "scroll" | "visible"
    overflowX?: "hidden" | "auto" | "scroll" | "visible"
    width?: string | "fit-content" | "inherit";
    height?: string | "fit-content";
    minWidth?: string | "fit-content";
    minHeight?: string | "fit-content";
    maxWidth?: string | "fit-content";
    maxHeight?: string | "fit-content";
    border?: string | undefined;
    borderRight?: string;
    borderLeft?: string;
    borderTop?: string;
    borderBottom?: string;
    position?: string | "relative" | "absolute" | "undefined" | "fixed" | "sticky" | "static";
    float?: "none" | "right" | "top" | "left" | "bottom";
    color?: string;
    fontSize?: string;
    fontWeight?: number | "normal";
    alignContent?: "flex-start" | "flex-end" | "center" |
        "space-between" | "space-around" | "stretch";
    alignItems?: "stretch" | "flex-start" | "flex-end" | "center" | "baseline";
    flexDirection?: "row" | "row-reverse" | "column" | "column-reverse";
    flexFlow?: string,
    flexWrap?: "nowrap" | "wrap" | "wrap-reverse";
    justifyContent?:
        "flex-start" | "flex-end" | "center" |
        "space-between" | "space-around" | "space-evenly";
    alignSelf?: "auto" | "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
    borderRadius?: string
    display?: "block" | "inline-block" | "flex"
    flexGrow?: number;
    backgroundColor?: string | undefined;
    background?: string | undefined;
    cursor?: "pointer" | "text" | undefined;
    left?: string
    right?: string
    top?: string
    bottom?: string
    zIndex?: number | undefined;
    opacity?: number | undefined;
    boxShadow?: string | undefined;
    lineHeight?: string | undefined;
    transform?: string | undefined;
    aspectRatio?: number | undefined;
    borderOnHover?: string | undefined;
    colorOnHover?: string
    backgroundOnHover?: string
    borderOnActive?: string | undefined;
    colorOnActive?: string
    backgroundOnActive?: string
    backgroundOnDisabled?: string
    colorOnDisabled?: string
}

const CommonComponent = styled.div<CommonProps>`
    overflow: ${({overflow}) => overflow || undefined};
    overflow-y: ${({overflowY}) => overflowY || undefined};
    overflow-x: ${({overflowX}) => overflowX || undefined};
    box-shadow: ${({boxShadow}) => boxShadow || undefined};
    opacity: ${({opacity}) => opacity || undefined};
    flex-grow: ${({flexGrow}) => flexGrow || undefined};
    position: ${({position}) => position || undefined};
    border-radius: ${({borderRadius}) => borderRadius || undefined};
    float: ${({float}) => float || undefined};
    margin: ${({margin}) => margin || undefined};
    margin-left: ${({marginLeft, margin}) => (!margin && marginLeft) || undefined};
    margin-right: ${({marginRight, margin}) => (!margin && marginRight) || undefined};
    margin-bottom: ${({marginBottom, margin}) => (!margin && marginBottom) || undefined};
    margin-top: ${({marginTop, margin}) => (!margin && marginTop) || undefined};
    padding: ${({padding}) => padding || undefined};
    padding-left: ${({paddingLeft, padding}) => (!padding && paddingLeft) || undefined};
    padding-right: ${({paddingRight, padding}) => (!padding && paddingRight) || undefined};
    padding-bottom: ${({paddingBottom, padding}) => (!padding && paddingBottom) || undefined};
    padding-top: ${({paddingTop, padding}) => (!padding && paddingTop) || undefined};
    width: ${({width}) => width || undefined};
    height: ${({height}) => height || undefined};
    min-width: ${({minWidth}) => minWidth || undefined};
    min-height: ${({minHeight}) => minHeight || undefined};
    max-width: ${({maxWidth}) => maxWidth || undefined};
    max-height: ${({maxHeight}) => maxHeight || undefined};
    border: ${({border}) => border || undefined};
    border-right: ${({borderRight}) => borderRight || undefined};
    border-left: ${({borderLeft}) => borderLeft || undefined};
    border-top: ${({borderTop}) => borderTop || undefined};
    border-bottom: ${({borderBottom}) => borderBottom || undefined};
    color: ${({color, disabled, colorOnDisabled}) => disabled ? colorOnDisabled : color};
    font-size: ${({fontSize}) => fontSize || undefined};
    font-weight: ${({fontWeight}) => fontWeight || undefined};
    display: ${({display, visible}) => visible ? (display || undefined) : "none"};
    align-content: ${({alignContent}) => alignContent || undefined};
    align-items: ${({alignItems}) => alignItems || undefined};
    flex-direction: ${({flexDirection}) => flexDirection || undefined};
    flex-wrap: ${({flexWrap}) => flexWrap || undefined};
    justify-content: ${({justifyContent}) => justifyContent || undefined};
    align-self: ${({alignSelf}) => alignSelf || undefined};
    background-color: ${({disabled, backgroundColor, backgroundOnDisabled}) => disabled ? backgroundOnDisabled : backgroundColor};
    background: ${({background}) => background || undefined};
    cursor: ${({cursor}) => cursor || undefined};
    right: ${({right}) => right || undefined};
    left: ${({left}) => left || undefined};
    top: ${({top}) => top || undefined};
    bottom: ${({bottom}) => bottom || undefined};
    z-index: ${({zIndex}) => zIndex || undefined};
    line-height: ${({lineHeight}) => lineHeight || undefined};
    text-align: ${({textAlign}) => textAlign || undefined};
    aspect-ratio: ${({aspectRatio}) => aspectRatio || undefined};

    &:hover {
        border: ${({borderOnHover}) => borderOnHover};
        color: ${({colorOnHover}) => colorOnHover};
        background-color: ${({backgroundOnHover}) => backgroundOnHover};
    }

    &:active {
        border: ${({borderOnActive}) => borderOnActive};
        color: ${({colorOnActive}) => colorOnActive};
        background-color: ${({backgroundOnActive}) => backgroundOnActive};
    }


`;

CommonComponent.defaultProps = {
    visible: true
}

export default CommonComponent;
