import {NetworkCommon} from "network/NetworkCommon";
import {AxiosResponse} from "axios";
import {HsCodeItem} from "../model/HsItem";
import {IncomeTrendWgtModel} from "../model/IncomeTrendWgtModel";
import {IncomeTrendRankResult} from "../model/IncomeTrendRankResult";
import {MarketCountryModel} from "../model/MarketCountryModel";
import {MarketTrendCompanyModel} from "../model/MarketTrendCompanyModel";
import {MarketTrendPriceModel} from "../model/MarketTrendPriceModel";
import {AnalysisMarketModel} from "../model/AnalysisMarketModel";
import {AnalysisTradingModel} from "../model/AnalysisTradingModel";
import {CompanyModel} from "../model/CompanyModel";
import {CompanyMarketModel} from "../model/CompanyMarketModel";
import {IncomePatternModel} from "../model/IncomePatternModel";

export class NewDashboardApi {

    static instance = NetworkCommon.createDefaultAxiosInstance()

    static searchByHsCode(hsCode: string, language: string): Promise<AxiosResponse<BaseResponse<Array<HsCodeItem>>>> {
        return NewDashboardApi.instance.get("/common/search/hscode", {
            params: {
                hsCode: hsCode,
                pageNum: 100,
                langCode: language
            },
        });
    }

    static searchByCondition(condition: string, language: string): Promise<AxiosResponse<BaseResponse<Array<HsCodeItem>>>> {
        return NewDashboardApi.instance.get("/common/search/condition", {
            params: {
                condition: condition,
                pageNum: 100,
                langCode: language
            },
        });
    }

    static getIncomeTrendWgt(hsCode: string, count: number): Promise<AxiosResponse<BaseResponse<Array<Array<IncomeTrendWgtModel>>>>> {
        return NewDashboardApi.instance.get("/dashboard/impTrend/wgt", {
            params: {
                hsCode: hsCode,
                cnt: count,
            },
        });
    }

    static getIncomeTrendRank(hsCode: string, langCode: string): Promise<AxiosResponse<BaseResponse<IncomeTrendRankResult>>> {
        return NewDashboardApi.instance.get("/dashboard/impTrend/rank", {
            params: {
                hsCode: hsCode,
                langCode: langCode
            },
        });
    }

    static getMarketTrendCountry(hsCode: string, langCode: string): Promise<AxiosResponse<BaseResponse<Array<MarketCountryModel>>>> {
        return NewDashboardApi.instance.get("/dashboard/marketTrend/country", {
            params: {
                hsCode: hsCode,
                langCode: langCode
            },
        });
    }

    static getMarketTrendCompany(hsCode: string, companyType: string): Promise<AxiosResponse<BaseResponse<Array<MarketTrendCompanyModel>>>> {
        return NewDashboardApi.instance.get("/dashboard/marketTrend/company", {
            params: {
                hsCode: hsCode,
                type: companyType,
            },
        });
    }


    static getMarketTrendPrice(hsCode: string): Promise<AxiosResponse<BaseResponse<Array<Array<MarketTrendPriceModel>>>>> {
        return NewDashboardApi.instance.get("/dashboard/marketTrend/price", {
            params: {
                hsCode: hsCode,
                cnt: 2,
            },
        });
    }


    static getAnalysisMarket(hsCode: string, companyType: string, langCode: string): Promise<AxiosResponse<BaseResponse<Array<Array<AnalysisMarketModel>>>>> {
        return NewDashboardApi.instance.get("/analysis/market", {
            params: {
                type: companyType,
                hsCode: hsCode,
                langCode: langCode,
                rank: 5,
            },
        });
    }

    static getAnalysisTradingData(hsCode: string, companyType: string, langCode: string): Promise<AxiosResponse<BaseResponse<Array<AnalysisTradingModel>>>> {
        return NewDashboardApi.instance.get("/analysis/tradingData", {
            params: {
                hsCode: hsCode,
                type: companyType,
                langCode: langCode
            },
        });
    }

    static getCompany(companyId: string, hsCode: string | null, langCode: string): Promise<AxiosResponse<BaseResponse<CompanyModel>>> {
        return NewDashboardApi.instance.get("/analysis/company", {
            params: {
                companyId: companyId,
                hsCode: hsCode,
                langCode: langCode
            },
        });
    }

    static getCompanyMarket(companyId: string, tradeType: string, hsCode: string | null, langCode: string): Promise<AxiosResponse<BaseResponse<Array<CompanyMarketModel>>>> {
        return NewDashboardApi.instance.get("/analysis/company/market", {
            params: {
                companyId: companyId,
                tradeType: tradeType,
                hsCode: hsCode,
                langCode: langCode
            },
        });
    }

    static getCompanyPattern(companyId: string, tradeType: string, hsCode: string | null, langCode: string): Promise<AxiosResponse<BaseResponse<Array<Array<IncomePatternModel>>>>> {
        return NewDashboardApi.instance.get("/analysis/company/pattern", {
            params: {
                companyId: companyId,
                tradeType: tradeType,
                hsCode: hsCode,
                langCode: langCode
            },
        });
    }

    static getCompanySupply(companyId: string, tradeType: string, hsCode: string | null, langCode: string): Promise<AxiosResponse<BaseResponse<Array<CompanySupplyModel>>>> {
        return NewDashboardApi.instance.get("/analysis/company/supply", {
            params: {
                companyId: companyId,
                tradeType: tradeType,
                month: 12,
                hsCode: hsCode,
                langCode: langCode
            },
        });
    }

}