import {ProductStatus} from "../model/ProductStatus";
import {ProductBuyerTypes, ProductCapacityTypes, ProductGradeTypes, ProductLoadingCapacityUnit, ProductUnitTypes} from "../model/CodeModel";

export class TextUtils {
    static itemStatusToUiText(itemStatus: string): string {
        switch (itemStatus) {
            case ProductStatus.TEMP_DRAFT:
                return "임시저장"
            case ProductStatus.PRODUCT_DISPLAY:
                return "상품게시중"
            case ProductStatus.APPROVAL_APPROVED:
                return "상품승인"
            case ProductStatus.APPROVAL_PENDING:
                return "승인요청"
            case ProductStatus.APPROVAL_REJECTED:
                return "승인거절"
            case ProductStatus.PUBLICATION_SUSPENDED:
                return "게시중단"
            default:
                return "알수없음"
        }
    }

    static packagingUnitCodeToUiText(unitCode?: string): string {
        if (!unitCode) {
            return ""
        }
        return ProductUnitTypes.find(item => item.code === unitCode)?.name || unitCode
    }

    static productLoadingCapacityUnitCodeToUiText(unitCode?: string): string {
        if (!unitCode) {
            return ""
        }
        return ProductLoadingCapacityUnit.find(item => item.code === unitCode)?.name || unitCode
    }

    static productBuyerUnitCodeToUiText(unitCode?: string): string {
        if (!unitCode) {
            return ""
        }
        return ProductBuyerTypes.find(item => item.code === unitCode)?.name || unitCode
    }

    static productGradeCodeToUiText(unitCode?: string | null): string {
        if (!unitCode) {
            return ""
        }
        return ProductGradeTypes.find(item => item.code === unitCode)?.name || unitCode
    }

    static productCapacityUnitCodeToUiText(unitCode?: string): string {
        if (!unitCode) {
            return ""
        }
        return ProductCapacityTypes.find(item => item.code === unitCode)?.name || unitCode
    }
}