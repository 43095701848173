import React, {useEffect, useState} from "react";
import Text from "../styledcomponents/Text";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {RootState} from "../modules";
import {ContentContainer} from "../styledcomponents/Containers";
import FlexBox from "../styledcomponents/FlexBox";
import {Colors, Fonts} from "../styledcomponents/Styles";
import Button from "../styledcomponents/Button";
import {CardFormMode,} from "../components/CardInputForm";
import {BillingAPI} from "../network/BillingAPI";
import {CardUtils} from "../utils/CardUtils";
import {CardInfo} from "../model/CardInfo";
import {useNavigate} from "react-router-dom";
import HorizontalDivider from "../styledcomponents/HorizontalDivider";
import Image from "../styledcomponents/Image";
import VerticalDivider from "../styledcomponents/VerticalDivider";
import {PaypleAPI} from "../network/PaypleAPI";
import {AxiosResponse} from "axios";
import {BillingService} from "../model/BillingService";
import {useTranslation} from "react-i18next";

const premiumFeatureList = [
    "업체의 월별 수입신고건수",
    "업체별 거래 파트너 리스트",
    "업체의 최근 수입신고일자",
    "전체 시장 대비 업체의 시장점유율",
    "업체의 품목간 거래비중",
    "업체의 수입공급망 데이터",
    "업체의 월별 수입신고 패턴",
];

const DevPaymentFragment = () => {

    const emptyCardInfo: CardInfo = {
        cardId: "",
        cardNumber: "",
        cardExpirationMonth: "",
        cardExpirationYear: "",
        userName: "",
    };

    const {signedIn} = useSelector(
        (state: RootState) => state.usersReducer, shallowEqual,
    );
    const {premiumActivated} = useSelector(
        (state: RootState) => state.auths, shallowEqual,
    );
    const navigate = useNavigate();
    const [cardInfoRefresher, setCardInfoRefresher] = useState(0);
    const [cardInfo, setCardInfo] = useState<CardInfo | null>(null);
    const [cardFormMode, setCardFormMode] = useState(CardFormMode.NEW)
    const [premiumServiceId, setPremiumServiceId] = useState("");


    const [termsOfServiceAgreeChecked, setTermsOfServiceAgreeChecked] = useState(false);

    const {language} = useSelector((state: RootState) => state.configurationsReducer, shallowEqual);
    const dispatch = useDispatch();
    const {t} = useTranslation()

    useEffect(() => {
        if (!signedIn) {
            return;
        }
        BillingAPI.getBillingServices()
            .then((response) => {
                let services = response.data.data;
                if (services === null) {
                    return;
                }
                if (services?.length > 0) {
                    setPremiumServiceId(services[0].itemId);
                }
            });

    }, [signedIn]);

    useEffect(() => {
        if (!signedIn) {
            return;
        }
        BillingAPI.getCardInfo()
            .then((response) => {
                let cardInfo = response.data.data;
                if (cardInfo && CardUtils.isCardInfoValid(cardInfo)) {
                    setCardInfo({
                        cardId: cardInfo.cardId,
                        cardNumber: cardInfo.cardNumber,
                        cardExpirationMonth: cardInfo.cardExpirationMonth,
                        cardExpirationYear: cardInfo.cardExpirationYear,
                        userName: cardInfo.userName,
                    });
                    setCardFormMode(CardFormMode.ADDED);
                } else {
                    setCardInfo(emptyCardInfo);
                }
            })
            .catch((error) => {
                setCardInfo(emptyCardInfo);
            });
    }, [signedIn, cardInfoRefresher]);

    async function getPaypleOrderKey(type: string): Promise<string> {
        const serviceId = await BillingAPI.getBillingServices()
            .then((res: AxiosResponse<BaseResponse<Array<BillingService>>>) => {
                const services = res.data.data
                const atFirst = services ? services[0].itemId : null
                return atFirst || ""
            })
            .catch(e => {
                return ""
            })

        const orderKey = await PaypleAPI.getPaypleOrderId(serviceId, type)
            .then((res) => {
                return res.data.data?.payOID || ""
            })
            .catch(e => {
                return ""
            })
        return orderKey
    }

    const handleKrPayClick = (type: string) => {
        if (!signedIn) {
            alert(t('alert_login_is_required'))
            return
        }
        BillingAPI.getBillingServices()
            .then((res: AxiosResponse<BaseResponse<Array<BillingService>>>) => {
                const services = res.data.data
                const service = services ? services[0] : null
                const itemId = services ? services[0].itemId : null
                if (itemId !== null) {
                    PaypleAPI.getPaypleOrderId(itemId, type === 'AUTH' ? 'card' : 'payment')
                        .then((res) => {
                            const result = res.data.data
                            const orderId = result?.payOID
                            const payerNo = result?.payerNo
                            if (!orderId) {
                                alert("order id 를 확인해주세요")
                                return
                            }
                            if (!payerNo) {
                                alert("payer no 를 확인해주세요")
                                return
                            }

                            const options = 'top=10, left=10, width=380, height=1080, ' +
                                'status=no, ' +
                                'menubar=no,' +
                                ' toolbar=no,' +
                                ' resizable=no, scrollbar=yes';
                            window.open(`/payment/window?orderId=${orderId}&language=kr&type=${type}&payerNo=${payerNo}&product=${service?.itemName}&price=${Number(service?.itemPrice) + Number(service?.itemPriceVat)}`, "pay-window", options)
                        })
                }
            })
    }

    const handleEnPayClick = async (type: string = "") => {
        if (!signedIn) {
            alert(t('alert_login_is_required'))
            return
        }
        let [accessToken, payCls] = await PaypleAPI.getAuthKey()
            .then((res) => {
                const result = res.data.data
                return [result?.accessToken, result?.payCls]
            })
            .catch(e => {
                return ["", ""]
            })
        const orderId = await getPaypleOrderKey(type === "CARDCHANGE" ? "card" : "payment")
            .then(key => {
                return key
            })
        if (!orderId) {
            alert("order id 를 확인해주세요")
            return
        }
        const options = 'top=10, left=10, width=380, height=1000, ' +
            'status=no, ' +
            'menubar=no,' +
            ' toolbar=no,' +
            ' resizable=no, scrollbar=yes';
        window.open(`/payment/window?orderId=${orderId}&language=en&accessToken=${accessToken}&payCls=${payCls}&type=${type}`, "pay-window", options)
    }

    return (
        <FlexBox width={"100%"}
                 height={"100%"}
                 backgroundColor={Colors.GREY_20}>
            <ContentContainer alignItems={"center"}>
                <FlexBox
                    width={"1060px"}
                    height={"640px"}
                    marginTop={"200px"}
                    marginBottom={"200px"}
                    backgroundColor={Colors.WHITE}
                    border={`1px solid ${Colors.GRAY_350}`}>
                    <FlexBox marginTop={"60px"}>
                        <FlexBox marginLeft={"60px"}
                                 flexGrow={1}
                                 flexDirection={"column"}>
                            <FlexBox flexDirection={"column"}
                                     width={"402px"}
                                     alignItems={"center"}
                                     borderRadius={"10px"}>
                                <Text
                                    alignSelf={"flex-start"}
                                    marginLeft={"10px"}
                                    fontSize={"24px"}
                                    fontWeight={500}>PREMIUM PLAN</Text>
                                <HorizontalDivider
                                    marginTop={"40px"}
                                    marginBottom={"24px"}
                                    width={"100%"}
                                    style={{
                                        opacity: 0.7,
                                        backgroundColor: "#d2d2d2",
                                        height: "1px"
                                    }}/>
                                {premiumFeatureList.map((item: string) =>
                                    <Text marginTop={"16px"}
                                          alignSelf={"flex-start"}
                                          fontWeight={500}
                                          fontSize={"16px"}
                                          textAlign={"left"}>
                                        <span style={{color: Colors.BLUE_30}}>✓</span>&nbsp;&nbsp;&nbsp;&nbsp;{item}
                                    </Text>,
                                )}
                            </FlexBox>
                        </FlexBox>
                        <VerticalDivider
                            height={"90%"}
                            backgroundColor={Colors.GRAY_350}
                            marginLeft={"80px"}/>
                        <FlexBox flexDirection={"column"}
                                 width={"440px"}
                                 marginLeft={"40px"}
                                 alignItems={"flex-start"}>
                            <FlexBox
                                width={"100%"}
                                alignSelf={"flex-start"}
                                alignItems={"center"}
                                justifyContent={"space-between"}>
                                <Text
                                    fontSize={"18px"}
                                    marginLeft={"40px"}>연간 요금</Text>
                            </FlexBox>
                            <FlexBox
                                marginTop={"20px"}
                                width={"100%"}
                                alignSelf={"flex-start"}
                                alignItems={"center"}
                                justifyContent={"space-between"}>
                                <Text
                                    fontSize={"20px"}
                                    marginLeft={"40px"}>총액 <span style={{color: Colors.GREY_60, fontSize: "16px"}}>(VAT 포함)</span></Text>
                                <Text
                                    fontSize={"24px"}
                                    color={Colors.BLACK}
                                    marginRight={"40px"}
                                    fontWeight={700}>KRW548,900원</Text>
                            </FlexBox>
                            <FlexBox
                                marginTop={"40px"}
                                marginLeft={"36px"}>
                                <Image
                                    width={"56px"}
                                    src={require("assets/images/visa.png")}/>
                                <Image
                                    marginLeft={"10px"}
                                    width={"56px"}
                                    src={require("assets/images/mastercard.svg").default}/>
                            </FlexBox>
                            <Button width={"360px"}
                                    height={"55px"}
                                    borderRadius={"10px"}
                                    fontSize={"20px"}
                                    fontWeight={Fonts.WEIGHT_700}
                                    color={Colors.WHITE}
                                    backgroundColor={Colors.MAIN}
                                    marginTop={"40px"}
                                    alignSelf={"center"}
                                    onClick={() => {
                                        handleKrPayClick("PAY")
                                    }}>
                                PAY NOW (KR)
                            </Button>
                            <Button width={"360px"}
                                    height={"55px"}
                                    borderRadius={"10px"}
                                    fontSize={"20px"}
                                    fontWeight={Fonts.WEIGHT_700}
                                    color={Colors.WHITE}
                                    backgroundColor={Colors.MAIN}
                                    marginTop={"20px"}
                                    alignSelf={"center"}
                                    onClick={() => {
                                        handleKrPayClick("AUTH")
                                    }}>
                                ADD CARD (KR)
                            </Button>
                            <Button width={"360px"}
                                    height={"55px"}
                                    borderRadius={"10px"}
                                    fontSize={"20px"}
                                    fontWeight={Fonts.WEIGHT_700}
                                    color={Colors.WHITE}
                                    backgroundColor={Colors.MAIN}
                                    marginTop={"20px"}
                                    alignSelf={"center"}
                                    onClick={() => {
                                        handleEnPayClick()
                                    }}>
                                PAY NOW (EN)
                            </Button>
                            <Button width={"360px"}
                                    height={"55px"}
                                    borderRadius={"10px"}
                                    fontSize={"20px"}
                                    fontWeight={Fonts.WEIGHT_700}
                                    color={Colors.WHITE}
                                    backgroundColor={Colors.MAIN}
                                    marginTop={"20px"}
                                    marginBottom={"40px"}
                                    alignSelf={"center"}
                                    onClick={() => {
                                        handleEnPayClick("CARDCHANGE")
                                    }}>
                                ADD CARD (EN)
                            </Button>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            </ContentContainer>
        </FlexBox>
    );
};

export default DevPaymentFragment;

