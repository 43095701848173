import React from "react";
import FlexBox from "../styledcomponents/FlexBox";
import {Colors} from "../styledcomponents/Styles";
import useMobile from "../uses/UseMobile";
import {Accordion} from "react-bootstrap";
import NewH2 from "../styledcomponents/text/NewH2";
import "./FaqFragment.css"

const FaqFragment = () => {
    const isMobile = useMobile()
    return <FlexBox
        id={'faq-container'}
        backgroundColor={Colors.GRAY_0}
        justifyContent={"center"}>
        <FlexBox
            backgroundColor={Colors.WHITE}
            margin={isMobile ? "20px" : "80px"}
            maxWidth={"1440px"}
            boxShadow={"0px 2px 40px 0px rgba(0, 0, 0, 0.05)"}
            borderRadius={"12px"}
            padding={isMobile ? "20px" : "40px"}
            flexDirection={"column"}
            alignItems={"center"}
            width={"100%"}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <NewH2
                color={Colors.BLACK}
                marginTop={"20px"}
                marginBottom={"20px"}>FAQ</NewH2>
            <Accordion className="w-100 p-3" defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>1. Who can use THIRDWORKS ?</Accordion.Header>
                    <Accordion.Body>
                        We can provide the market report for customs prices, guides and market share by
                        competitors you need for
                        exporting to south korea. And we can recommend Korean partner and buyer companies
                        can match your
                        business in the future
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>2. How much data is available in the dashboard ?</Accordion.Header>
                    <Accordion.Body>
                        We provide imported food products from HS CODE 01 to 24.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>3. When is the data update cycle ?</Accordion.Header>
                    <Accordion.Body>
                        All data is updated in the 1st week of every month.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>4. Where does trade and customs data come from ?</Accordion.Header>
                    <Accordion.Body>
                        Trade data (monthly for products at the 6-digit HS code level) is provided by UN
                        Comtrade, Korean
                        customs offices, and the statistical office.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>
                        5. What types of information can I get from the market intelligence
                        ?</Accordion.Header>
                    <Accordion.Body>
                        Our customers have been asking us about the current market trends in south korea and
                        any similar
                        products have been imported before and what would be the amount of it and what’s the
                        customs price,
                        customs procedure. We can provide you with all information based on customs data.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>6. What information is included in the market analysis report
                        ?</Accordion.Header>
                    <Accordion.Body>
                        The report’s contents are Korea Customs procedures and market trends, competitive
                        company analysis
                        according to the HS Code you required. We can provide you exact competitor names,
                        their market share and
                        contract patterns, what products are deal with and they are making the business with
                        whom.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>7. Do you take market research or trade inquiries ?</Accordion.Header>
                    <Accordion.Body>
                        Yes, We support customized market analysis reports including requesting quotes,
                        negotiating prices, and
                        preparing customs documents.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header>8. What is the process for the partner matching service
                        ?</Accordion.Header>
                    <Accordion.Body>
                        In buyer matching service, we can provide you 3 buyers information that interested
                        in your
                        products. <br/>For checking the company
                        credit, we are checking
                        the 3 different
                        standards.
                        <FlexBox
                            flexDirection={"column"}
                            alignItems={"center"}>
                            <ul>
                                <li>Whether the buyer have an experience for the import or export based on
                                    Customs data
                                </li>
                                <li>Does membership is registered in public company like trade association and
                                    import organization
                                </li>
                                <li>Do they have appropriate market share in South Korea.</li>
                            </ul>
                        </FlexBox>
                        If you need some assistance, you can send us the your company details and your
                        product information by
                        email. <br/>we will introduce you the 3 buyer companies details that match your
                        business in 2 weeks ~ 2
                        months.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

        </FlexBox>
    </FlexBox>
}
export default FaqFragment;