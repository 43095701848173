import FlexBox from "../styledcomponents/FlexBox";
import {CircularProgress} from "@mui/material";
import {Colors} from "../styledcomponents/Styles";
import {Empty} from "antd";
import React from "react";

export class UiUtils {
    static Loading = () => {
        return <FlexBox
            width={"100%"}
            height={"100%"}
            alignItems={"center"}
            justifyContent={"center"}>
            <CircularProgress
                sx={{
                    color: Colors.BLUE_20
                }}/>
        </FlexBox>
    }

    static EmptyLayout = () => {
        return <FlexBox
            width={"100%"}
            height={"100%"}
            alignItems={"center"}
            justifyContent={"center"}>
            <Empty/>
        </FlexBox>
    }

    static renderingByState(data: Array<any> | null, node: React.ReactElement | null): React.ReactElement | null {
        if (data == null) {
            return this.Loading()
        } else if (data.length === 0) {
            return this.EmptyLayout()
        } else {
            return node
        }
    }
}