import React, {useState} from 'react';
import {Editor} from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import "./TextEditor.css"

type Props = {
    editorRef: React.RefObject<Editor> | null;
    imageHandler?: (blob: File, callback: typeof Function) => void;
    content?: string;
    placeholder?: string;
};

const TextEditor = ({content, placeholder, editorRef, imageHandler}: Props) => {

    const [toolbar, setToolbar] = useState([
        ['heading', 'bold', 'italic', 'strike'],
        ['hr', 'quote'],
        ['ul', 'ol'],
        ['table', 'link']
    ])

    return (
        <div
            className={"editor-container"}>
            <Editor
                initialValue={content || ' '}
                initialEditType='wysiwyg'
                autofocus={false}
                ref={editorRef}
                toolbarItems={toolbar}
                hideModeSwitch
                useCommandShortcut={true}
                height='540px'
            />
        </div>
    );
}

export default TextEditor