import React, {useEffect, useState} from "react";
import {linearGradientDef} from "@nivo/core";
import {AxisProps} from "@nivo/axes";
import {Colors} from "../../styledcomponents/Styles";
import {ResponsiveLine} from "@nivo/line";
import {ChartXYData} from "../../model/ChartXYData";
import {FormatUtils} from "../../utils/FormatUtils";

interface IncomeTrendWgtAreaChartProps {
    chartModels: Array<IncomeTrendWgtChartModel>
}

export type IncomeTrendWgtChartModel = {
    id: string,
    data: Array<ChartXYData>
}

export const IncomeTrendWgtAreaChart: React.FC<IncomeTrendWgtAreaChartProps> = ({
                                                                                    chartModels
                                                                                }) => {

    const [minValue, setMinValue] = useState(Number.MAX_VALUE)
    const [maxValue, setMaxValue] = useState(0)
    const [leftMargin, setLeftMargin] = useState(10)

    useEffect(() => {
        if (maxValue > 1) {
            setLeftMargin((Math.floor(maxValue).toString().length + 3) * 10)
        } else {
            setLeftMargin((maxValue.toString().length + 3) * 10)
        }

    }, [maxValue])

    useEffect(() => {
        if (chartModels) {
            let min = Number.MAX_VALUE
            let max = 0
            chartModels.forEach((year) => {
                year.data.forEach((chartData) => {
                    min = Math.min(min, chartData.y)
                    max = Math.max(max, chartData.y)
                })
            })
            setMinValue(min)
            setMaxValue(max)
        }
    }, [chartModels])

    return (
        <ResponsiveLine
            data={chartModels}
            margin={{top: 70, right: 14, bottom: 30, left: leftMargin}}
            enableGridX={false}
            xScale={{type: "point"}}
            yScale={{
                type: "linear",
                stacked: false,
                reverse: false,
                min: minValue,
                max: maxValue,
                clamp: true,
                nice: true
            }}
            // gridYValues={[6000, 7000, 8000, 9000, 10000]}
            pointSize={4}
            pointBorderWidth={8}
            pointBorderColor={{
                from: "color",
                modifiers: [["opacity", 0.3]],
            }}
            enableArea={true}
            colors={["#017DFD", "#68C9CF"]}
            areaOpacity={0.5}
            defs={[
                linearGradientDef("gradientA", [
                    {offset: 0, color: "#017DFD"},
                    {offset: 100, color: "rgba(1, 125, 253, 0.4)", opacity: 0.5},
                ]),
                linearGradientDef("gradientB", [
                    {offset: 0, color: "#68C9CF"},
                    {offset: 100, color: "#68C9CF", opacity: 0.5},
                ])
            ]}
            fill={[{
                match: {
                    id: chartModels[0].id
                }, id: "gradientA"
            }, {
                match: {
                    id: chartModels[1].id
                }, id: "gradientB"
            }]}
            yFormat={(value) => {
                return `${FormatUtils.toCurrencyFormat(Number(value).toString())} M/T`
            }}
            crosshairType="cross"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                orient: "bottom",
                tickSize: 0,
                tickPadding: 10,
                tickRotation: 0,
            } as AxisProps}
            enableSlices="x"
            axisLeft={{
                // tickValues: [6000, 7000, 8000, 9000, 10000],
                orient: "left",
                tickSize: 0,
                tickPadding: 10,
                tickRotation: 0,
                format: value => `${FormatUtils.toCurrencyFormat(Number(value).toString())} M/T`,
            } as AxisProps}

            pointLabelYOffset={-12}
            useMesh={true}
            lineWidth={0.5}
            legends={[
                {
                    anchor: "top-right",
                    direction: "row",
                    justify: false,
                    translateX: -30,
                    translateY: -30,
                    itemsSpacing: 40,
                    itemDirection: "left-to-right",
                    itemWidth: 70,
                    itemHeight: 0,
                    itemOpacity: 1,
                    symbolSize: 14,
                    symbolShape: "circle",
                    itemTextColor: Colors.GRAY_800
                },
            ]}
            theme={{
                fontFamily: "Noto Sans",
                legends: {
                    text: {
                        fontWeight: 400,
                        fontSize: 14,
                        color: Colors.GRAY_800
                    }
                },
                crosshair: {
                    line: {
                        stroke: Colors.GRAY_900,
                        strokeWidth: 1,
                        strokeOpacity: 1,
                        strokeDasharray: '3 4'
                    }
                },
                axis: {
                    ticks: {
                        text: {
                            fontWeight: 400,
                            fontSize: 10,
                            color: Colors.GRAY_800
                        }
                    }
                },
                grid: {
                    line: {
                        stroke: Colors.GRAY_300,
                        strokeWidth: 1,
                    },
                },
            }}
        />)
}