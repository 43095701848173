/**
 * @author jaeho.lee104 on 2023. 05. 09..
 */
import {ChartXYData} from "../../model/ChartXYData";
import React, {useState} from "react";
import {ResponsiveLine} from "@nivo/line";
import {Colors} from "../../styledcomponents/Styles";
import FlexBox from "../../styledcomponents/FlexBox";
import {FormatUtils} from "../../utils/FormatUtils";
import {AxisProps} from "@nivo/axes";
import NewP2 from "../../styledcomponents/text/NewP2";

interface IncomePatternChartProps {
    chartModels: Array<IncomePatterChartModel>,
    colors: Array<string>
}

export type IncomePatterChartModel = {
    id: string,
    data: Array<ChartXYData>;
}

export const IncomePatternChart: React.FC<IncomePatternChartProps> = ({
                                                                          chartModels,
                                                                          colors
                                                                      }) => {

    const [highlightedSeries, setHighlightedSeries] = useState<string | null>(null);
    const getLineColor = (line: IncomePatterChartModel, index: number) => {
        if (!highlightedSeries) {
            return colors[index % (colors.length)]
        }
        return line.id === highlightedSeries ? colors[index % (colors.length)] : Colors.TRANSPARENT;
    };

    return <FlexBox
        width={"100%"}
        height={"100%"}
        flexDirection={"column"}>
        <FlexBox
            height={"400px"}>
            <ResponsiveLine
                data={chartModels.map(((series, index) => ({
                    ...series,
                    color: getLineColor(series, index)
                })))}
                colors={({id, color}) => color}
                margin={{top: 80, right: 20, bottom: 26, left: 40}}
                xScale={{type: "point"}}
                yScale={{
                    type: "linear",
                    stacked: false,
                    reverse: false,
                }}
                enableSlices={'x'}
                enableGridX={false}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    orient: "bottom",
                    tickSize: 0,
                    tickPadding: 10,
                    tickRotation: 0
                } as AxisProps}
                axisLeft={{
                    orient: "left",
                    tickSize: 0,
                    tickPadding: 20,
                    tickRotation: 0,
                    format: (value) => {
                        return `${FormatUtils.toCurrencyFormat(value.toString())}`
                    }
                } as AxisProps}
                useMesh={true}
                enableArea={!!highlightedSeries}
                areaOpacity={highlightedSeries ? 0.3 : 0}
                enablePoints={true}
                enablePointLabel={false}
                theme={{
                    fontFamily: "Noto Sans",
                    crosshair: {
                        line: {
                            stroke: Colors.GRAY_900,
                            strokeWidth: 1,
                            strokeOpacity: 1,
                            strokeDasharray: '3 4'
                        }
                    },
                    axis: {
                        ticks: {
                            text: {
                                fontWeight: 400,
                                fontSize: 10,
                                color: Colors.GRAY_800
                            }
                        }
                    },
                    grid: {
                        line: {
                            stroke: Colors.GRAY_300,
                            strokeWidth: 1,
                        },
                    },
                }}
            />
        </FlexBox>
        <FlexBox
            position={"absolute"}
            width={"100%"}
            overflow={"auto"}>
            {chartModels.map((d, index) => (
                <div
                    style={{
                        marginLeft: '20px',
                        height: '60px',
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '5px',
                        cursor: 'pointer',
                        background: d.id === highlightedSeries ? '#f0f0f0' : 'white'
                    }}
                    onMouseEnter={() => setHighlightedSeries(d.id)}
                    onMouseLeave={() => setHighlightedSeries(null)}
                >
                            <span
                                style={{
                                    alignSelf: 'center',
                                    backgroundColor: colors[index % colors.length],
                                    display: 'inline-block',
                                    minWidth: '12px',
                                    minHeight: '12px',
                                    borderRadius: '4px',
                                    marginRight: '5px'
                                }}
                            ></span>
                    <NewP2
                        fontWeight={d.id === highlightedSeries ? 700 : 400}
                        whiteSpace={"nowrap"}>
                        {d.id}
                    </NewP2>
                </div>
            ))}
        </FlexBox>
    </FlexBox>
}

