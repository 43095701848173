import {Viewer} from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor-viewer.css';

type Props = {
    content: string;
    styleClass?: string;
};

function TextEditorViewer({content, styleClass}: Props) {
    return (
        <section className={styleClass}
                 style={{
                     textAlign: "left"
                 }}>
            <Viewer initialValue={content}/>
        </section>
    );
}

export default TextEditorViewer;