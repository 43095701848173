import styled from "styled-components";
import CommonComponent, {CommonProps} from "./CommonComponent";

export interface TextProps extends CommonProps {
    reactive?: boolean
    whiteSpace?: "normal" | "nowrap" | "pre-wrap" | "pre-line"
    overflowWrap?: "normal" | "break-word" | "anywhere";
    textOverflow?: "ellipsis" | "fade"
    strokeWidth?: string
    strokeColor?: string
    maxLines?: number
}

const Text = styled(CommonComponent)<TextProps>`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${({maxLines}) => maxLines || undefined};
    overflow-wrap: ${({overflowWrap}) => overflowWrap || undefined};
    -webkit-text-stroke-width: ${({strokeWidth}) => strokeWidth || undefined};
    -webkit-text-stroke-color: ${({strokeColor}) => strokeColor || undefined};
    width: ${({width}) => width || "fit-content"};
    text-align: ${({textAlign}) => textAlign || "left"};
    line-height: ${({lineHeight}) => lineHeight || "normal"};
    height: ${({height}) => height || "fit-content"};
    font-size: ${({fontSize}) => fontSize || "16px"};
    font-weight: ${({fontWeight}) => fontWeight || "normal"};
    white-space: ${({whiteSpace}) => whiteSpace || "pre-line"};
    text-overflow: ${({textOverflow}) => textOverflow || undefined};
`;

export default Text;