import styled from "styled-components";
import Text from "../Text";
import {reactiveValueInContent} from "../../utils/StyleUtils";

const B2 = styled(Text)`
  font-size: ${({reactive}) => reactive ? reactiveValueInContent("28px", "12px") : "28px"};
  //font-size: min(calc(100vw * 0.0233), 2.8rem);
  font-weight: 500;
`;

export default B2;