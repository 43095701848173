import {NetworkCommon} from "network/NetworkCommon";
import {AxiosResponse} from "axios";
import {CardInfo} from "../model/CardInfo";
import {BillingResponse} from "../model/BillingResponse";
import {BillingService} from "../model/BillingService";
import {CardSaveRequestBody} from "../model/CardSaveRequestBody";

const instance = NetworkCommon.createDefaultAxiosInstance()

export class BillingAPI {
    static getCardInfo(): Promise<AxiosResponse<BaseResponse<CardInfo>>> {
        return instance.get("/payment/cardInfo");
    }

    static saveCardInfo(cardSaveRequestBody: CardSaveRequestBody): Promise<AxiosResponse<BaseResponse<BillingResponse>>> {
        return instance.post("/payment/cardInfo", cardSaveRequestBody);
    }

    static doSubscribe(serviceId: string): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.post(`/payment/subscribe/${serviceId}`, null);
    }

    static cancelSubscribe(serviceId: string): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.delete(`/payment/subscribe/${serviceId}`);
    }

    static removeCard(cardId: string): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.delete(`/payment/billing/${cardId}`);
    }

    static getBillingServices(): Promise<AxiosResponse<BaseResponse<Array<BillingService>>>> {
        return instance.get(`/payment/billing/service`);
    }

    static getBillingHistories(dateFrom: string, dateTo: string): Promise<AxiosResponse<BaseResponse<Array<BillingHistory>>>> {
        return instance.get("/payment/billing/history", {
            params: {
                dateFrom: dateFrom,
                dateTo: dateTo
            },
        });
    }

    static cancelBilling(historyKey: string,
                         cancelReason: string = "TEST",
                         forceCancel: boolean = false): Promise<AxiosResponse<BaseResponse<string>>> {
        return instance.post(`/payment/billing/cancel`, {
            historyKey: historyKey,
            cancelReason: cancelReason,
            forceCancel: forceCancel
        })
    }
}