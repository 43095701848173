import {useMediaQuery} from "react-responsive";

/**
 * @author jaeho.lee104 on 2023. 04. 08..
 */
const useMaxWidth = (maxWidth: string) => {
    return useMediaQuery({
        query: `(max-width: ${maxWidth})`,
    });
}
export default useMaxWidth