import {UsersAPI} from "../network/UsersAPI";
import {ResponseCodes} from "../network/ResponseCodes";
import {LocalStorageManager} from "../LocalStorageManager";
import {Dispatch} from "redux";
import {Logger} from "../log/Logger";
import {User} from "../model/User";
import {NavigateFunction} from "react-router/dist/lib/hooks";

/**
 * @author jaeho.lee104 on 2023. 05. 08..
 */
enum UserActionType {
    LOGIN = "users/LOGIN",
    LOGOUT = "users/LOGOUT",
    DELETE = "users/DELETE",
    REFRESH_TOKEN = "users/REFRESH_TOKEN",
    UPDATE_USER = "users/UPDATE_USER"
}

interface UserUpdateInfoAction {
    type: UserActionType.UPDATE_USER,
    user: User
}

interface UserRefreshTokenAction {
    type: UserActionType.REFRESH_TOKEN,
    signedIn: boolean
    user: User | null
    accessToken: string
}

interface UserLogoutAction {
    type: UserActionType.LOGOUT,
    signedIn: boolean
    user: User | null
    accessToken: string
}

interface UserDeleteAction {
    type: UserActionType.DELETE,
    signedIn: boolean
    user: User | null
    accessToken: string
}

interface UserLoginAction {
    type: UserActionType.LOGIN
    signedIn: boolean
    user: User
    accessToken: string
}

type UserActions = UserRefreshTokenAction
    | UserLogoutAction
    | UserLoginAction
    | UserUpdateInfoAction
    | UserDeleteAction

type UserState = {
    signedIn: boolean,
    user: User | null,
    accessToken: string
}

const initialState: UserState = {
    signedIn: (LocalStorageManager.getUserId()?.length || 0) > 0,
    user: User.getEmptyUser(),
    accessToken: LocalStorageManager.getAccessToken() || ""
};

export class Users {

    static isRefreshing = false

    static updateUser = (user: User): UserUpdateInfoAction => {
        return {
            type: UserActionType.UPDATE_USER,
            user: user
        }
    }

    static loginSuccess = (
        componentName: string,
        user: User,
        accessToken: string
    ): UserLoginAction => {
        LocalStorageManager.saveUserId(user.userId)
        Logger.setUserId(user.userId)
        Logger.info(componentName, `login success. userId: ${user.userId}`)
        return {
            type: UserActionType.LOGIN,
            signedIn: true,
            user: user,
            accessToken: accessToken
        };
    }

    static logout = () => {
        return async (dispatch: Dispatch) => {
            UsersAPI.signOut()
                .then(response => {
                    let responseCode = ResponseCodes.of(response.data.code)
                    if (responseCode.isSuccess()
                        || responseCode.isNeedLogin()) {
                        LocalStorageManager.removeUserId()
                        LocalStorageManager.removeAccessToken()
                        // noinspection JSIgnoredPromiseFromCall
                        Logger.setUserId(null)
                        dispatch({
                            type: UserActionType.LOGOUT,
                            signedIn: false,
                            user: null,
                            accessToken: ""
                        });
                    }
                })
        }
    };

    static deleteUser = () => {
        // noinspection JSIgnoredPromiseFromCall
        return async (dispatch: Dispatch) => {
            UsersAPI.deleteUser()
                .then(response => {
                    let responseCode = ResponseCodes.of(response.data.code)
                    if (responseCode.isSuccess()) {
                        LocalStorageManager.removeUserId()
                        LocalStorageManager.removeAccessToken()
                        dispatch({
                            type: UserActionType.DELETE,
                            signedIn: false,
                            user: null,
                            accessToken: ""
                        })
                    } else {
                        alert("Failed to delete user")
                    }
                })
                .catch(e => {
                    alert("delete error")
                })
        }
    };

    static refreshToken = (navigate: NavigateFunction) => {
        if (Users.isRefreshing) {
            console.log("already refreshing!!!")
            return
        }
        return async (dispatch: Dispatch) => {
            Users.isRefreshing = true
            return UsersAPI.refreshToken()
                .then(async (response) => {
                    const responseCode = ResponseCodes.of(response.data.code);
                    Users.isRefreshing = false
                    if (responseCode.isSuccess()) {
                        const data = response.data.data
                        const accessToken = data?.accessToken
                        if (!accessToken) {
                            LocalStorageManager.removeUserId()
                            LocalStorageManager.removeAccessToken()
                            dispatch({
                                type: UserActionType.REFRESH_TOKEN,
                                signedIn: false,
                                user: null,
                                accessToken: ""
                            })
                            navigate("/")
                            return
                        }
                        LocalStorageManager.setAccessToken(accessToken)
                        let user = await UsersAPI.getUserOrNull()
                        if (!user) {
                            LocalStorageManager.removeUserId()
                            LocalStorageManager.removeAccessToken()
                            dispatch({
                                type: UserActionType.REFRESH_TOKEN,
                                signedIn: false,
                                user: null,
                                accessToken: "",
                                isBusinessUser: false
                            })
                            navigate("/")
                            return
                        }
                        LocalStorageManager.saveUserId(user.userId)
                        dispatch({
                            type: UserActionType.REFRESH_TOKEN,
                            signedIn: true,
                            user: user,
                            accessToken: accessToken,
                        })
                    } else {
                        LocalStorageManager.removeUserId()
                        LocalStorageManager.removeAccessToken()
                        dispatch({
                            type: UserActionType.REFRESH_TOKEN,
                            signedIn: false,
                            user: null,
                            accessToken: ""
                        })
                        if (responseCode.isRefreshTokenExpired()) {
                            Logger.captureEvent({
                                level: "error",
                                message: `web refresh token expired.`,
                            })
                            navigate("/login")
                        }
                    }
                })
                .catch((e) => {
                    Users.isRefreshing = false
                    dispatch({
                        type: UserActionType.REFRESH_TOKEN,
                        signedIn: false,
                        user: null,
                        accessToken: ""
                    })
                    navigate("/")
                })
        };
    };
}

function usersReducer(state: UserState = initialState, action: UserActions): UserState {
    switch (action.type) {
        case UserActionType.LOGIN:
            return {
                signedIn: action.signedIn,
                user: action.user,
                accessToken: action.accessToken
            }
        case UserActionType.LOGOUT:
            return {
                signedIn: action.signedIn,
                user: action.user,
                accessToken: action.accessToken
            }
        case UserActionType.REFRESH_TOKEN:
            return {
                signedIn: action.signedIn,
                user: action.user,
                accessToken: action.accessToken
            }
        case UserActionType.UPDATE_USER:
            return {
                ...state,
                user: action.user
            }
        case UserActionType.DELETE:
            return {
                signedIn: action.signedIn,
                user: action.user,
                accessToken: action.accessToken
            }
        default:
            return state;
    }
}

export default usersReducer;
