/**
 * @author jaeho.lee104 on 2023. 09. 19..
 */
import React, {useEffect, useState} from "react";
import useMobile from "../uses/UseMobile";
import {useTranslation} from "react-i18next";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Dialog} from "@mui/material";
import FlexBox from "../styledcomponents/FlexBox";
import Image from "../styledcomponents/Image";
import NewH3 from "../styledcomponents/text/NewH3";
import {Colors} from "../styledcomponents/Styles";
import NewH4 from "../styledcomponents/text/NewH4";
import NewP2 from "../styledcomponents/text/NewP2";
import {BottomSheet} from "react-spring-bottom-sheet";
import NewP4 from "../styledcomponents/text/NewP4";
import HorizontalDivider from "../styledcomponents/HorizontalDivider";
import Input from "../styledcomponents/Input";
import TextArea from "antd/lib/input/TextArea";
import {StringConstants} from "../constants/StringConstants";
import {KeyboardUtils} from "../utils/KeyboardUtils";
import {Checkbox} from "antd";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import emailjs from "@emailjs/browser";
import NewButton from "../styledcomponents/NewButton";

export interface AnugaPromotionRequestDialogProps {
    isOpen: boolean,
    onClose: (positive: boolean) => void
}

const AnugaPromotionRequestDialog: React.FC<{
    dialogProps: AnugaPromotionRequestDialogProps
}> = ({dialogProps}) => {

    const [isOpen, setIsOpen] = useState(false);
    const isMobile = useMobile()
    const {t} = useTranslation()
    const [name, setName] = useState("")
    const [company, setCompany] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [message, setMessage] = useState("")
    const [termsOfServiceAgreeChecked, setTermsOfServiceAgreeChecked] = useState(false);

    const theme = createTheme({
        components: {
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        minWidth: "600px",
                        borderRadius: "16px"
                    }
                }
            }
        },
        palette: {
            background: {
                paper: '#fff',
            },
            text: {
                primary: '#000',
                secondary: '#46505A',
            },
            action: {
                active: '#001E3C',
            },
        },
    });

    useEffect(() => {
        setIsOpen(dialogProps.isOpen);
    }, [dialogProps.isOpen]);

    const handleOk = () => {
        if (!isOpen) {
            return;
        }
        const params = {
            company_name: company,
            user_name: name,
            phone_number: phoneNumber,
            email: email,
            message: message
        }
        if (!company) {
            alert(t('alert_please_enter_the_company_name'))
            return
        }
        if (!name) {
            alert(t('alert_please_enter_your_name'))
            return
        }
        if (!phoneNumber) {
            alert(t('alert_please_enter_your_phone_number'))
            return
        }
        if (!email) {
            alert(t('alert_please_enter_your_email_address'))
            return
        }
        if (!message) {
            alert(t('alert_please_enter_your_inquiry'))
            return;
        }
        if (!termsOfServiceAgreeChecked) {
            alert(t('alert_please_agree_to_the_privacy_policy'))
            return;
        }
        emailjs.send('service_476mxkw', 'template_v7222p4', params, 'aWur-HWDDnLCPvv6l')
            .then((result) => {
                alert(t('alert_request_promotion_has_been_received'))
                dialogProps.onClose(true);
            }, (error) => {
                alert(t('alert_failed_to_request_promotion'))
            });
    };

    const handleCancel = () => {
        if (!isOpen) {
            return;
        }
        dialogProps.onClose(false);
    };
    return <>
        {!isMobile && <ThemeProvider theme={theme}>
            <Dialog
                open={isOpen}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleOk();
                    }
                }}>
                <FlexBox
                    width={"100%"}
                    padding={"16px 32px"}
                    flexDirection={"column"}>
                    <FlexBox
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        marginTop={"16px"}>
                        <NewH3
                            color={Colors.GRAY_9}>
                            {t('apply_for_promotion')} 🎉
                        </NewH3>
                        <Image
                            cursor={"pointer"}
                            width={"24px"}
                            height={"24px"}
                            zIndex={10}
                            src={require("assets/images/ic-dialog-close.svg").default}
                            onClick={handleCancel}
                            alt="ic-dialog-close"/>
                    </FlexBox>
                    <FlexBox
                        width={"100%"}
                        backgroundColor={Colors.GRAY_0}
                        border={`1px solid ${Colors.GRAY_2}`}
                        borderRadius={"12px"}
                        flexDirection={"column"}
                        marginTop={"16px"}
                        padding={"12px 16px"}>
                        <FlexBox
                            alignItems={"center"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                Free 1 month data subscription
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                Free 3 multiple quotation
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                1 Target Market Analysis Report
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                Guide for Korea Import Procedure
                            </NewP2>
                        </FlexBox>
                    </FlexBox>
                    <FlexBox
                        marginTop={"40px"}
                        justifyContent={"space-between"}
                        alignItems={"center"}>
                        <NewH4>
                            {t('customer_information')}
                        </NewH4>
                        <NewP4
                            color={Colors.GRAY_6}>
                            <span style={{
                                color: Colors.DESTRUCTIVE_HOVER
                            }}>*</span> {t('please_fill_information')}
                        </NewP4>
                    </FlexBox>
                    <HorizontalDivider
                        marginTop={"16px"}
                        backgroundColor={Colors.GRAY_3}/>
                    <FlexBox
                        marginTop={"24px"}
                        alignItems={"center"}>
                        <FlexBox
                            width={"100%"}
                            flexDirection={"column"}
                            justifyContent={"flex-start"}>
                            <NewP2
                                color={Colors.GRAY_7}>
                                {t('name')}<span style={{color: Colors.DESTRUCTIVE_HOVER}}>*</span>
                            </NewP2>
                            <Input
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                                hintFontWeight={400}
                                fontWeight={400}
                                color={Colors.GRAY_8}
                                hintColor={Colors.GRAY_5}
                                marginTop={"8px"}/>
                        </FlexBox>
                        <FlexBox
                            width={"100%"}
                            marginLeft={"16px"}
                            flexDirection={"column"}
                            justifyContent={"flex-start"}>
                            <NewP2
                                color={Colors.GRAY_7}>
                                {t('company')}<span style={{color: Colors.DESTRUCTIVE_HOVER}}>*</span>
                            </NewP2>
                            <Input
                                value={company}
                                onChange={(e) => {
                                    setCompany(e.target.value)
                                }}
                                hintFontWeight={400}
                                fontWeight={400}
                                color={Colors.GRAY_8}
                                hintColor={Colors.GRAY_5}
                                marginTop={"8px"}/>
                        </FlexBox>
                    </FlexBox>
                    <FlexBox
                        marginTop={"24px"}
                        alignItems={"center"}>
                        <FlexBox
                            width={"100%"}
                            flexDirection={"column"}
                            justifyContent={"flex-start"}>
                            <NewP2
                                color={Colors.GRAY_7}>
                                E-Mail<span style={{color: Colors.DESTRUCTIVE_HOVER}}>*</span>
                            </NewP2>
                            <Input
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                hintFontWeight={400}
                                fontWeight={400}
                                color={Colors.GRAY_8}
                                hintColor={Colors.GRAY_5}
                                marginTop={"8px"}/>
                        </FlexBox>
                        <FlexBox
                            width={"100%"}
                            marginLeft={"16px"}
                            flexDirection={"column"}
                            justifyContent={"flex-start"}>
                            <NewP2
                                color={Colors.GRAY_7}>
                                {t('phone')}<span style={{color: Colors.DESTRUCTIVE_HOVER}}>*</span>
                            </NewP2>
                            <Input
                                value={phoneNumber}
                                onChange={(e) => {
                                    setPhoneNumber(KeyboardUtils.clearNumber(e.target.value))
                                }}
                                hintFontWeight={400}
                                fontWeight={400}
                                color={Colors.GRAY_8}
                                hintColor={Colors.GRAY_5}
                                marginTop={"8px"}/>
                        </FlexBox>
                    </FlexBox>
                    <NewP2
                        marginTop={"24px"}
                        color={Colors.GRAY_7}>
                        {t('promotions_of_interest')}<span style={{color: Colors.MAIN}}>({t('optional')})</span>
                    </NewP2>
                    <TextArea
                        value={message}
                        onChange={(e) => {
                            setMessage(e.target.value)
                        }}
                        showCount
                        maxLength={400}
                        style={{
                            height: 190,
                            marginTop: "8px",
                            resize: 'none',
                            padding: "8px",
                        }}
                        placeholder={`※ ${t('inquiry_example')}\n\n1. ${t('inquiry_example_1')}\n2. ${t('inquiry_example_2')}\n3. ${t('inquiry_example_3')}`}
                    />
                    <FlexBox
                        marginTop={"24px"}
                        alignItems={"center"}>
                        <Checkbox
                            checked={termsOfServiceAgreeChecked}
                            onChange={(e: CheckboxChangeEvent) => {
                                setTermsOfServiceAgreeChecked(e.target.checked);
                            }}/>
                        <NewP2
                            fontWeight={400}
                            marginLeft={"8px"}>
                            {t('check_terms_of_service_and_privacy_policy_head')}{StringConstants.SPACE}
                            <a style={{fontWeight: "700", cursor: "pointer", borderBottom: "1px solid #000"}}
                               onClick={() => {
                                   window.open("/terms-of-service")
                               }}>{t('terms_of_service')}</a>{StringConstants.SPACE}{t('and')}{StringConstants.SPACE}
                            <a style={{fontWeight: "700", cursor: "pointer", borderBottom: "1px solid #000"}}
                               onClick={() => {
                                   window.open("/privacy-policy")
                               }}>{t('privacy_policy')}</a>
                            {t('check_terms_of_service_and_privacy_policy_tail')}</NewP2>
                    </FlexBox>
                    <FlexBox
                        alignItems={"center"}
                        marginTop={"16px"}
                        padding={"16px 0px"}>
                        <NewButton
                            colorType={2}
                            height={"52px"}
                            width={"100%"}
                            fontSize={"16px"}
                            fontWeight={500}
                            borderRadius={"12px"}
                            onClick={handleCancel}>
                            {t('cancel')}
                        </NewButton>
                        <NewButton
                            colorType={0}
                            height={"52px"}
                            width={"100%"}
                            marginLeft={"16px"}
                            fontSize={"16px"}
                            fontWeight={700}
                            onClick={handleOk}
                            borderRadius={"12px"}>
                            {t('promotion_apply')}
                        </NewButton>
                    </FlexBox>
                </FlexBox>
            </Dialog>
        </ThemeProvider>}
        {isMobile && <BottomSheet
            open={isOpen}
            blocking={true}
            defaultSnap={({maxHeight}) => maxHeight * 0.8}
            snapPoints={({maxHeight}) => [
                maxHeight * 0.8,
                maxHeight * 0.4,
            ]}>
            <FlexBox
                width={"100%"}
                padding={"16px 32px"}
                flexDirection={"column"}>
                <FlexBox
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    marginTop={"16px"}>
                    <NewH3
                        color={Colors.GRAY_9}>
                        프로모션 신청하기 🎉
                    </NewH3>
                    <Image
                        cursor={"pointer"}
                        width={"24px"}
                        height={"24px"}
                        zIndex={10}
                        src={require("assets/images/ic-dialog-close.svg").default}
                        onClick={handleCancel}
                        alt="ic-dialog-close"/>
                </FlexBox>
                <FlexBox
                    width={"100%"}
                    backgroundColor={Colors.GRAY_0}
                    border={`1px solid ${Colors.GRAY_2}`}
                    borderRadius={"12px"}
                    flexDirection={"column"}
                    marginTop={"16px"}
                    padding={"12px 16px"}>
                    <FlexBox
                        alignItems={"center"}>
                        <Image
                            width={"16px"}
                            height={"16px"}
                            src={require("assets/images/ic-check-2.svg").default}
                            alt="ic-check"/>
                        <NewP2
                            marginLeft={"8px"}
                            color={Colors.GRAY_7}>
                            Free 1 month data subscription
                        </NewP2>
                    </FlexBox>
                    <FlexBox
                        alignItems={"center"}
                        marginTop={"12px"}>
                        <Image
                            width={"16px"}
                            height={"16px"}
                            src={require("assets/images/ic-check-2.svg").default}
                            alt="ic-check"/>
                        <NewP2
                            marginLeft={"8px"}
                            color={Colors.GRAY_7}>
                            Free 3 multiple quotation
                        </NewP2>
                    </FlexBox>
                    <FlexBox
                        alignItems={"center"}
                        marginTop={"12px"}>
                        <Image
                            width={"16px"}
                            height={"16px"}
                            src={require("assets/images/ic-check-2.svg").default}
                            alt="ic-check"/>
                        <NewP2
                            marginLeft={"8px"}
                            color={Colors.GRAY_7}>
                            1 Target Market Analysis Report
                        </NewP2>
                    </FlexBox>
                    <FlexBox
                        alignItems={"center"}
                        marginTop={"12px"}>
                        <Image
                            width={"16px"}
                            height={"16px"}
                            src={require("assets/images/ic-check-2.svg").default}
                            alt="ic-check"/>
                        <NewP2
                            marginLeft={"8px"}
                            color={Colors.GRAY_7}>
                            Guide for Korea Import Procedure
                        </NewP2>
                    </FlexBox>
                </FlexBox>
                <FlexBox
                    marginTop={"40px"}
                    justifyContent={"space-between"}
                    alignItems={"center"}>
                    <NewH4>
                        기본 정보 입력
                    </NewH4>
                    <NewP4
                        color={Colors.GRAY_6}>
                            <span style={{
                                color: Colors.DESTRUCTIVE_HOVER
                            }}>*</span> 표시된 부분은 필수 입력 항목입니다.
                    </NewP4>
                </FlexBox>
                <HorizontalDivider
                    marginTop={"16px"}
                    backgroundColor={Colors.GRAY_3}/>
                <FlexBox
                    width={"100%"}
                    marginTop={"24px"}
                    flexDirection={"column"}
                    justifyContent={"flex-start"}>
                    <NewP2
                        color={Colors.GRAY_7}>
                        {t('name')}<span style={{color: Colors.DESTRUCTIVE_HOVER}}>*</span>
                    </NewP2>
                    <Input
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value)
                        }}
                        hintFontWeight={400}
                        fontWeight={400}
                        color={Colors.GRAY_8}
                        hintColor={Colors.GRAY_5}
                        marginTop={"8px"}/>
                </FlexBox>
                <FlexBox
                    marginTop={"12px"}
                    width={"100%"}
                    flexDirection={"column"}
                    justifyContent={"flex-start"}>
                    <NewP2
                        color={Colors.GRAY_7}>
                        {t('company')}<span style={{color: Colors.DESTRUCTIVE_HOVER}}>*</span>
                    </NewP2>
                    <Input
                        value={company}
                        onChange={(e) => {
                            setCompany(e.target.value)
                        }}
                        hintFontWeight={400}
                        fontWeight={400}
                        color={Colors.GRAY_8}
                        hintColor={Colors.GRAY_5}
                        marginTop={"8px"}/>
                </FlexBox>
                <FlexBox
                    marginTop={"12px"}
                    width={"100%"}
                    flexDirection={"column"}
                    justifyContent={"flex-start"}>
                    <NewP2
                        color={Colors.GRAY_7}>
                        E-Mail<span style={{color: Colors.DESTRUCTIVE_HOVER}}>*</span>
                    </NewP2>
                    <Input
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                        hintFontWeight={400}
                        fontWeight={400}
                        color={Colors.GRAY_8}
                        hintColor={Colors.GRAY_5}
                        marginTop={"8px"}/>
                </FlexBox>
                <FlexBox
                    marginTop={"12px"}
                    width={"100%"}
                    flexDirection={"column"}
                    justifyContent={"flex-start"}>
                    <NewP2
                        color={Colors.GRAY_7}>
                        {t('phone')}<span style={{color: Colors.DESTRUCTIVE_HOVER}}>*</span>
                    </NewP2>
                    <Input
                        value={phoneNumber}
                        onChange={(e) => {
                            setPhoneNumber(KeyboardUtils.clearNumber(e.target.value))
                        }}
                        hintFontWeight={400}
                        fontWeight={400}
                        color={Colors.GRAY_8}
                        hintColor={Colors.GRAY_5}
                        marginTop={"8px"}/>
                </FlexBox>
                <NewP2
                    marginTop={"12px"}
                    color={Colors.GRAY_7}>
                    관심있는 프로모션<span style={{color: Colors.MAIN}}>(선택)</span>
                </NewP2>
                <TextArea
                    value={message}
                    onChange={(e) => {
                        setMessage(e.target.value)
                    }}
                    showCount
                    maxLength={400}
                    style={{
                        height: 190,
                        marginTop: "8px",
                        resize: 'none',
                        padding: "8px",
                    }}
                    placeholder={`※ ${t('inquiry_example')}\n\n1. ${t('inquiry_example_1')}\n2. ${t('inquiry_example_2')}\n3. ${t('inquiry_example_3')}`}
                />
                <FlexBox
                    marginTop={"24px"}
                    alignItems={"center"}>
                    <Checkbox
                        checked={termsOfServiceAgreeChecked}
                        onChange={(e: CheckboxChangeEvent) => {
                            setTermsOfServiceAgreeChecked(e.target.checked);
                        }}/>
                    <NewP2
                        fontWeight={400}
                        marginLeft={"8px"}>
                        {t('check_terms_of_service_and_privacy_policy_head')}{StringConstants.SPACE}
                        <a style={{fontWeight: "700", cursor: "pointer", borderBottom: "1px solid #000"}}
                           onClick={() => {
                               window.open("/terms-of-service")
                           }}>{t('terms_of_service')}</a>{StringConstants.SPACE}{t('and')}{StringConstants.SPACE}
                        <a style={{fontWeight: "700", cursor: "pointer", borderBottom: "1px solid #000"}}
                           onClick={() => {
                               window.open("/privacy-policy")
                           }}>{t('privacy_policy')}</a>
                        {t('check_terms_of_service_and_privacy_policy_tail')}</NewP2>
                </FlexBox>
                <FlexBox
                    alignItems={"center"}
                    marginTop={"16px"}
                    padding={"16px 0px"}>
                    <NewButton
                        colorType={2}
                        height={"52px"}
                        width={"100%"}
                        fontSize={"16px"}
                        fontWeight={500}
                        borderRadius={"12px"}
                        onClick={handleCancel}>
                        {t('cancel')}
                    </NewButton>
                    <NewButton
                        colorType={0}
                        height={"52px"}
                        width={"100%"}
                        marginLeft={"16px"}
                        fontSize={"16px"}
                        fontWeight={700}
                        onClick={handleOk}
                        borderRadius={"12px"}>
                        {t('promotion_apply')}
                    </NewButton>
                </FlexBox>
            </FlexBox>
        </BottomSheet>}
    </>
}
export default AnugaPromotionRequestDialog;