import auths from "modules/auths";
import configurationsReducer from "./Configurations";
import {applyMiddleware, combineReducers, createStore} from "redux";
import {createStateSyncMiddleware, initMessageListener, initStateWithPrevTab} from "redux-state-sync";
import {composeWithDevTools} from "redux-devtools-extension";
import {createLogger} from "redux-logger";
import thunk from "redux-thunk";
import usersReducer from "./Users";

const rootReducer = combineReducers({
    usersReducer,
    auths,
    configurationsReducer

});
const logger = createLogger();
const reduxStateSyncConfig = {};
export const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk, createStateSyncMiddleware(reduxStateSyncConfig))),
    // composeWithDevTools(applyMiddleware(logger, thunk)),
);
initMessageListener(store);
initStateWithPrevTab(store)
export type RootState = ReturnType<typeof store.getState>
