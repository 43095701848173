/**
 * @author jaeho.lee104 on 2023. 08. 22..
 */

const adminUsers = [
    "mjkim@thirdworks.co.kr",
    "heehyun@thirdworks.co.kr",
    "jaeholee@thirdworks.co.kr",
    "hyeon@thirdworks.co.kr"
]

export class CommonUtils {
    static isAdmin(userId: string): boolean {
        return adminUsers.includes(userId)
    }
}