import React, {useEffect, useState} from "react";
import FlexBox from "../styledcomponents/FlexBox";
import NewP2 from "../styledcomponents/text/NewP2";
import {Colors} from "../styledcomponents/Styles";
import Image from "../styledcomponents/Image";
import NewH5 from "../styledcomponents/text/NewH5";
import NewH3 from "../styledcomponents/text/NewH3";
import NewH2 from "../styledcomponents/text/NewH2";
import NewButton from "../styledcomponents/NewButton";
import NewH6 from "../styledcomponents/text/NewH6";
import Button from "../styledcomponents/Button";
import NewH7 from "../styledcomponents/text/NewH7";
import ProductInfoFragment from "./ProductInfoFragment";
import Text from "../styledcomponents/Text";
import NewP4 from "../styledcomponents/text/NewP4";
import NewP1 from "../styledcomponents/text/NewP1";
import CompanyInfoFragment from "./CompanyInfoFragment";
import {ProductDetailItem} from "../model/ProductDetailItem";
import {SourcingAPI} from "../network/SourcingAPI";
import {useSearchParams} from "react-router-dom";
import Flag from "react-flagkit";
import {TextUtils} from "../utils/TextUtils";
import {CompanyDetailItem} from "../model/CompanyDetailItem";
import {useTranslation} from "react-i18next";
import {ImagePreview} from "../components/ImagePreview";
import CompanyPreparingFragment from "./CompanyPreparingFragment";


const SourcingDetailFragment = () => {
    const sampleProducts = [
        {
            id: 0,
            name: "MANDOR BIO ORGANIC ALMOND DRINK",
            source: "POLI.COM SRL",
            price: "US$ 2.50 ~",
            per: "per EA",
            imagePath: require("assets/images/image-product-sample.png")
        },
        {
            id: 1,
            name: "MANDOR BIO ORGANIC ALMOND DRINK",
            source: "POLI.COM SRL",
            price: "US$ 2.50 ~",
            per: "per EA",
            imagePath: require("assets/images/image-product-sample.png")
        },
        {
            id: 2,
            name: "MANDOR BIO ORGANIC ALMOND DRINK",
            source: "POLI.COM SRL",
            price: "US$ 2.50 ~",
            per: "per EA",
            imagePath: require("assets/images/image-product-sample.png")
        },
        {
            id: 3,
            name: "MANDOR BIO ORGANIC ALMOND DRINK",
            source: "POLI.COM SRL",
            price: "US$ 2.50 ~",
            per: "per EA",
            imagePath: require("assets/images/image-product-sample.png")
        }
    ]
    const [selectedTabMenuIndex, setSelectedTabMenuIndex] = useState(0)
    const [searchParams, setSearchParams] = useSearchParams()
    const [productDetailItem, setProductDetailItem] = useState<ProductDetailItem | null>(null)
    const [companyDetailItem, setCompanyDetailItem] = useState<CompanyDetailItem | null>(null)
    const companyId = searchParams.get('companyId') || ""
    const itemId = searchParams.get('itemId') || ""
    const {t} = useTranslation()
    const tabMenus = [
        `${t('sourcing_product_info')}`,
        `${t('sourcing_company_info')}`
    ]

    const [selectedProductImage, setSelectedProductImage] = useState<string | null>(null)

    useEffect(() => {
        if (productDetailItem && productDetailItem.itemImages.length > 0) {
            setSelectedProductImage(productDetailItem.itemImages[0].url)
        }
    }, [productDetailItem])

    useEffect(() => {
        if (!itemId) {
            return
        }
        SourcingAPI.getProductDetail(itemId)
            .then((response) => {
                setProductDetailItem(response.data?.data || null)
            })
    }, [itemId])

    useEffect(() => {
        if (!companyId) {
            return
        }
        SourcingAPI.getCompanyDetail(companyId)
            .then((response) => {
                setCompanyDetailItem(response.data?.data || null)
            })
    }, [companyId])

    function getContentByTabMenu(): React.ReactNode {
        if (selectedTabMenuIndex === 0) {
            return <ProductInfoFragment productDetailItem={productDetailItem}/>
        } else if (selectedTabMenuIndex === 1) {
            return companyDetailItem ? <CompanyInfoFragment companyDetailItem={companyDetailItem}/> : <CompanyPreparingFragment/>
        } else {
            return <ProductInfoFragment productDetailItem={productDetailItem}/>
        }
    }

    return <FlexBox
        width={"100%"}
        height={"100%"}
        flexDirection={"column"}>
        <FlexBox /* content area */
            margin={"40px auto"}
            width={"1200px"}
            flexDirection={"column"}>
            <FlexBox
                width={"100%"}
                height={"752px"}
                flexDirection={"column"}>
                <FlexBox /*product depth label*/
                    visible={!!productDetailItem && productDetailItem?.categoryList.length > 0}
                    alignItems={"center"}>
                    <NewP2
                        color={Colors.GRAY_6}>
                        Sourcing
                    </NewP2>
                    <Image
                        width={"24px"}
                        height={"24px"}
                        src={require("assets/images/ic-arrow-right-24.svg").default}
                        alt="sourcing-depth1-arrow"/>
                    <NewP2
                        color={Colors.GRAY_6}>
                        {productDetailItem?.categoryList.map((item) => item.categoryName).join(",")}
                    </NewP2>
                </FlexBox>
                <FlexBox
                    marginTop={"60px"}
                    height={"474px"}>
                    <FlexBox /*image vertical array*/
                        borderRadius={"12px"}
                        flexDirection={"column"}>
                        {productDetailItem?.itemImages.map((item, index) => {
                            return <FlexBox
                                onClick={() => {
                                    setSelectedProductImage(item.url)
                                }}
                                cursor={"pointer"}
                                borderRadius={"12px"}
                                border={`1.5px solid ${Colors.GRAY_3}`}
                                width={"82px"}
                                height={"82px"}
                                alignItems={"center"}
                                marginTop={index >= 1 ? "14px" : "0px"}
                                justifyContent={"center"}>
                                <Image /*selected product image*/
                                    width={"64px"}
                                    height={"64px"}
                                    src={item.url}
                                    alt={`product-image-${index}`}/>
                            </FlexBox>
                        })}
                    </FlexBox>
                    <FlexBox /*selected product image*/
                        alignSelf={"center"}
                        width={"474px"}
                        height={"474px"}
                        padding={"20px"}
                        alignItems={"center"}
                        borderRadius={"12px"}
                        marginLeft={"24px"}
                        cursor={"pointer"}
                        onClick={() => {
                            if (selectedProductImage) {
                                ImagePreview.renderImage(selectedProductImage)
                            }
                        }}
                        border={`1px solid ${Colors.GRAY_3}`}
                        justifyContent={"center"}>
                        <Image
                            width={"100%"}
                            height={"100%"}
                            src={selectedProductImage || require("assets/images/image-place-holder-48.png")}
                            alt="product-selected-image"/>
                    </FlexBox>
                    <FlexBox /*product info*/
                        marginLeft={"40px"}
                        flexDirection={"column"}>
                        <FlexBox /*country*/
                            visible={!!productDetailItem?.summary?.originCode}
                            marginTop={"30px"}
                            alignItems={"center"}>
                            <Flag
                                style={{
                                    borderRadius: "2px",
                                    clipPath: "border-box",
                                    marginRight: "10px"
                                }}
                                country={productDetailItem?.summary?.originCode}
                                size={32}/>
                            <NewH5
                                marginLeft={"10px"}
                                color={Colors.GRAY_6}>
                                {productDetailItem?.summary?.originName || ""}
                            </NewH5>
                        </FlexBox>
                        <NewH3
                            marginTop={"17px"}
                            textAlign={"left"}
                            color={Colors.GRAY_8}>
                            {productDetailItem?.info?.itemName || ""}
                        </NewH3>
                        <FlexBox
                            marginTop={"40px"}
                            alignItems={"center"}>
                            <NewH2
                                color={Colors.MAIN}>
                                US$ {productDetailItem?.info?.itemPrice || ""} ~
                            </NewH2>
                            <NewH3
                                marginLeft={"12px"}
                                color={Colors.GRAY_6}>
                                per {TextUtils.packagingUnitCodeToUiText(productDetailItem?.info?.itemPriceUnitCode)}
                            </NewH3>
                        </FlexBox>
                        <FlexBox
                            marginTop={"16px"}
                            alignItems={"center"}>
                            <NewH5
                                color={Colors.GRAY_8}>
                                Samples:
                                US${productDetailItem?.info?.samplePrice || ""}/{TextUtils.packagingUnitCodeToUiText(productDetailItem?.info?.samplePriceUnitCode)}
                            </NewH5>
                            <NewButton
                                marginLeft={"12px"}
                                width={"fit-content"}
                                height={"fit-content"}
                                padding={"6px 12px"}
                                fontSize={"12px"}
                                borderRadius={"8px"}
                                fontWeight={700}
                                colorType={5}>
                                Inquiry Samples
                            </NewButton>
                        </FlexBox>
                        <FlexBox
                            marginTop={"40px"}
                            flexDirection={"column"}>
                            <NewP2
                                color={Colors.GRAY_7}>
                                · Country of origin, {productDetailItem?.summary?.originName || ""}
                            </NewP2>
                            <NewP2
                                marginTop={"2px"}
                                color={Colors.GRAY_7}>
                                · Minimum of
                                quantity(MOQ), {productDetailItem?.summary?.moq || ""} {TextUtils.packagingUnitCodeToUiText(productDetailItem?.summary?.moqUnitCode)}
                            </NewP2>
                            <NewP2
                                marginTop={"2px"}
                                color={Colors.GRAY_7}>
                                · Packaging, {productDetailItem?.summary?.packaging || ""} per
                                a {TextUtils.packagingUnitCodeToUiText(productDetailItem?.summary?.packagingUnitCode)}
                            </NewP2>
                            <NewP2
                                marginTop={"2px"}
                                color={Colors.GRAY_7}>
                                · Lead time, {productDetailItem?.summary?.leadTime || ""}days after order confirmation
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            marginTop={"40px"}
                            alignItems={"center"}>
                            <NewButton
                                padding={"14px 16px"}
                                width={"240px"}
                                height={"fit-content"}
                                colorType={0}
                                fontSize={"16px"}
                                borderRadius={"12px"}>
                                Contact to Supplier
                            </NewButton>
                            <FlexBox
                                cursor={"pointer"}
                                marginLeft={"16px"}
                                borderRadius={"12px"}
                                border={`1px solid ${Colors.GRAY_3}`}
                                width={"52px"}
                                height={"52px"}
                                alignItems={"center"}
                                justifyContent={"center"}>
                                <Image
                                    width={"32px"}
                                    height={"32px"}
                                    src={require("assets/images/ic-bookmark-32.svg").default}
                                    alt="product-bookmark"/>
                            </FlexBox>
                            <FlexBox
                                cursor={"pointer"}
                                marginLeft={"16px"}
                                borderRadius={"12px"}
                                border={`1px solid ${Colors.GRAY_3}`}
                                width={"52px"}
                                height={"52px"}
                                alignItems={"center"}
                                justifyContent={"center"}>
                                <Image
                                    width={"32px"}
                                    height={"32px"}
                                    src={require("assets/images/ic-share-32.svg").default}
                                    alt="product-share"/>
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
                <FlexBox
                    height={"55px"}
                    padding={"14px 24px"}
                    borderRadius={"16px"}
                    alignItems={"center"}
                    background={"linear-gradient(89deg, #20D3FE 0.51%, #8E3BF0 88.34%, #FF3EEC 109.44%);"}
                    boxShadow={"0px 4px 8px 0px rgba(0, 0, 0, 0.08);"}
                    justifyContent={"space-between"}
                    marginTop={"52px"}>
                    <NewH6
                        color={Colors.WHITE}>
                        {t('check_report_introduce')}
                    </NewH6>
                    <Button
                        backgroundColor={Colors.WHITE}
                        color={"#8B3BF0"}
                        borderRadius={"8px"}
                        width={"fit-content"}
                        height={"fit-content"}
                        fontWeight={500}
                        fontSize={"14px"}
                        padding={"4px 14px 6px 14px"}>
                        {t('check_report')}
                    </Button>
                </FlexBox>

            </FlexBox>
        </FlexBox>
        <FlexBox
            width={"100%"}
            minHeight={"800px"}
            backgroundColor={Colors.GRAY_0}>
            <FlexBox /* content area */
                margin={"40px auto 80px auto"}
                width={"1200px"}
                flexDirection={"column"}>
                <FlexBox
                    height={"40px"}
                    marginBottom={"40px"}
                    borderBottom={`1px solid ${Colors.GRAY_4}`}>
                    {tabMenus.map((item, index) => {
                        return <FlexBox
                            cursor={"pointer"}
                            padding={"8px 24px"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderBottom={index === selectedTabMenuIndex ? `3px solid ${Colors.MAIN}` : undefined}
                            onClick={() => {
                                setSelectedTabMenuIndex(index)
                            }}>
                            <NewH7
                                fontWeight={index === selectedTabMenuIndex ? 700 : 400}
                                color={index === selectedTabMenuIndex ? Colors.GRAY_8 : Colors.GRAY_7}>
                                {item}
                            </NewH7>
                        </FlexBox>
                    })}
                </FlexBox>
                {getContentByTabMenu()}
            </FlexBox>
        </FlexBox>
        <FlexBox
            visible={selectedTabMenuIndex === 0}
            width={"1200px"}
            margin={"80px auto 160px auto"}
            flexDirection={"column"}>
            <Text
                color={Colors.GRAY_8}
                fontWeight={700}
                fontSize={"24px"}>
                {t('recommend_related_product')}
            </Text>
            <FlexBox
                marginTop={"32px"}
                justifyContent={"space-between"}
                flexWrap={"wrap"}>
                {sampleProducts.map((item, index) => {
                    return <FlexBox
                        cursor={"pointer"}
                        onClick={() => {
                            window.open("/sourcing-detail")
                        }}
                        marginTop={index >= 4 ? "40px" : "0px"}
                        flexDirection={"column"}
                        width={"288px"}
                        height={"502px"}
                        overflow={"hidden"}
                        borderRadius={"16px"}
                        boxShadow={"0px 4px 8px 0px #00000014;"}
                        border={`1px solid ${Colors.GRAY_3}`}>
                        <FlexBox
                            minHeight={"288px"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            background={Colors.GRAY_0}>
                            <Text
                                position={"absolute"}
                                top={"20px"}
                                left={"20px"}
                                fontWeight={700}
                                fontSize={"12px"}
                                borderRadius={"4px"}
                                padding={"2px 10px"}
                                color={Colors.WHITE}
                                backgroundColor={Colors.SECONDARY_06}>
                                NEW
                            </Text>
                            <Image
                                width={"193px"}
                                height={"288px"}
                                src={item.imagePath}
                                alignSelf={"center"}
                                alt={`image-sample+${index}`}/>
                        </FlexBox>
                        <FlexBox
                            padding={"20px"}
                            height={"100%"}
                            width={"100%"}
                            flexDirection={"column"}>
                            <FlexBox
                                alignItems={"center"}>
                                <Image
                                    width={"22px"}
                                    height={"16px"}
                                    src={require("assets/images/ic-sample-artwork-22-16.svg").default}
                                    alignSelf={"center"}
                                    alt={`image-sample+${index}`}/>
                                <NewP4
                                    marginLeft={'4px'}
                                    color={Colors.GRAY_6}>
                                    {item.source}
                                </NewP4>
                            </FlexBox>
                            <NewH7
                                marginTop={'8px'}
                                color={Colors.GRAY_8}>
                                {item.name}
                            </NewH7>
                            <FlexBox
                                marginTop={"16px"}
                                alignItems={"center"}>
                                <NewH5
                                    color={Colors.MAIN}>
                                    {item.price}
                                </NewH5>
                                <NewP1
                                    marginLeft={"8px"}
                                    color={Colors.GRAY_6}>
                                    {item.per}
                                </NewP1>
                            </FlexBox>
                            <NewButton
                                border={`1px solid ${Colors.SECONDARY_PRESSED}`}
                                colorType={5}
                                width={'100%'}
                                fontSize={"16px"}
                                fontWeight={500}
                                height={"44px"}
                                marginTop={"16px"}>
                                {t('get_quotation')}
                            </NewButton>
                        </FlexBox>
                    </FlexBox>
                })}
            </FlexBox>
        </FlexBox>
    </FlexBox>
}
export default SourcingDetailFragment;