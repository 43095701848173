import styled from "styled-components";
import Text from "../Text";
import {reactiveValueInContent} from "../../utils/StyleUtils";

const B3 = styled(Text)`
  font-size: ${({reactive}) => reactive ? reactiveValueInContent("20px", "12px") : "20px"};
  //font-size: min(calc(100vw * 0.0167), 2rem);
  font-weight: normal;
`;

export default B3;