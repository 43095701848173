import React from "react";
import {UiUtils} from "../utils/UiUtils";

interface RenderingByStateProps {
    items: Array<any> | null;
    children: React.ReactElement | null;
}

export const RenderingByState: React.FC<RenderingByStateProps> = ({
                                                                      items,
                                                                      children
                                                                  }) => {
    return (UiUtils.renderingByState(items, children))
}
