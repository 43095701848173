import {LocalStorageManager} from "../LocalStorageManager";

export class User {
    userId: string
    name: string
    phoneNumber: string
    company: string
    customsAuthNumber: string
    countryCode: string
    countryNumber: string
    impExpExperience: string
    createDt: string

    constructor(userId: string,
                name: string,
                phoneNumber: string,
                company: string,
                customsAuthNumber: string,
                countryCode: string,
                countryNumber: string,
                impExpExperience: string,
                createDt: string) {
        this.userId = userId;
        this.name = name;
        this.phoneNumber = phoneNumber;
        this.company = company;
        this.customsAuthNumber = customsAuthNumber;
        this.countryCode = countryCode;
        this.countryNumber = countryNumber;
        this.impExpExperience = impExpExperience;
        this.createDt = createDt;
    }

    static getEmptyUser(): User {
        return new User(LocalStorageManager.getUserId() || "",
            "", "", "", "", "", "", "", "")
    }
}