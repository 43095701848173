/**
 * @author jaeho.lee104 on 2023. 04. 20..
 */
import React, {ReactNode, useEffect, useRef, useState} from "react";
import FlexBox from "../styledcomponents/FlexBox";
import {ContentContainer} from "../styledcomponents/Containers";
import {Colors, Fonts} from "../styledcomponents/Styles";
import Button from "../styledcomponents/Button";
import Input from "../styledcomponents/Input";
import Image from "../styledcomponents/Image";
import {NewDashboardApi} from "../network/NewDashboardApi";
import {ResponseCodes} from "../network/ResponseCodes";
import {KeyboardUtils} from "../utils/KeyboardUtils";
import ReactGA from "react-ga4";
import Text from "../styledcomponents/Text";
import {FormatUtils} from "../utils/FormatUtils";
import IncomeTrendFragment from "./IncomeTrendFragment";
import SupplyAnalysisFragment from "./SupplyAnalysisFragment";
import {useReactToPrint} from "react-to-print";
import "./SolutionFragment.css";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {RootState} from "../modules";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Logger} from "../log/Logger";
import {useTranslation} from "react-i18next";
import {AppConfig} from "../AppConfig";
import PlanIntroDialog from "../dialog/PlanIntroDialog";
import PlanIntroBottomSheet from "../dialog/PlanIntroBottomSheet";
import PremiumPaymentDialog from "../dialog/PremiumPaymentDialog";
import PremiumPaymentBottomSheet from "../dialog/PremiumPaymentBottomSheet";
import {BillingAPI} from "../network/BillingAPI";
import * as Auths from "../modules/auths";
import {PaypleAPI} from "../network/PaypleAPI";
import {AxiosResponse} from "axios";
import {BillingService} from "../model/BillingService";
import {CardUtils} from "../utils/CardUtils";
import useTablet from "../uses/UseTablet";

type HsCodeUiModel = {
    key: string,
    hsCode: string,
    displayText: string,
    description: string
}

const SolutionFragment = () => {

    const freeHsCode = AppConfig.freeHsCode
    const componentName = "SolutionFragment"


    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedMenuIndex, setSelectedMenuIndex] = useState(Number(searchParams.get('index') || '0'))

    const [isSearchMenuOpen, setIsSearchMenuOpen] = useState(false)
    const [hsCodeUiModels, setHsCodeUiModels] = useState<Array<HsCodeUiModel>>([])
    const [searchedHsCode, setSearchedHsCode] = useState(freeHsCode);
    const [searchedHsCodeDescription, setSearchedHsCodeDescription] = useState("");
    const [selectedHsCodes, setSelectedHsCodes] = useState<Array<HsCodeUiModel>>([]);
    const [inputHsCode, setInputHsCode] = useState(FormatUtils.toHsCodeFormat(freeHsCode))
    const inputRef = useRef<HTMLInputElement>(null);
    const [billingServices, setBillingServices] = useState<Array<BillingService>>([])

    const [selectedTabIndex, setSelectedTabIndex] = useState(0)
    const [isPlanDialogOpen, setIsPlanDialogOpen] = useState(false)
    const [isPremiumPaymentDialogOpen, setIsPremiumPaymentDialogOpen] = useState(false)

    const {accessToken} = useSelector((state: RootState) => state.usersReducer, shallowEqual);
    const {premiumActivated} = useSelector((state: RootState) => state.auths, shallowEqual);
    const {language} = useSelector((state: RootState) => state.configurationsReducer, shallowEqual);
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [focusIndex, setFocusIndex] = useState(-1)
    const hsCodeItemsContainerRef = useRef<HTMLDivElement>(null)

    const isTablet = useTablet()

    const contentRef = useRef<HTMLDivElement>(null)
    const handlePrint = useReactToPrint({
        content: () => contentRef?.current,
    });
    const dispatch = useDispatch()

    useEffect(() => {
        let tabIndex = Number(searchParams.get('index') || String(selectedMenuIndex))
        if (tabIndex === 1) {
            if (premiumActivated || searchedHsCode === freeHsCode) {
                setSelectedTabIndex(1)
            } else {
                setIsPlanDialogOpen(true)
            }
        } else {
            setSelectedTabIndex(0)
        }
    }, [searchParams])

    useEffect(() => {
        if (selectedTabIndex !== 0) {
            contentRef?.current?.scrollIntoView()
        }
    }, [selectedTabIndex]);

    useEffect(() => {
        if (!accessToken) {
            return;
        }
        BillingAPI.getBillingServices()
            .then((res: AxiosResponse<BaseResponse<Array<BillingService>>>) => {
                const services = res.data.data || []
                setBillingServices(services)
            })

    }, [accessToken])

    useEffect(() => {
        if (searchedHsCode) {
            fetchHsCodeDescription(searchedHsCode);
        }
    }, [searchedHsCode, language])

    useEffect(() => {
        setIsPlanDialogOpen(!premiumActivated && selectedTabIndex !== 0 && searchedHsCode !== freeHsCode)
    }, [searchedHsCode, selectedTabIndex])

    useEffect(() => {
        if (focusIndex >= 0 && hsCodeUiModels.length > 0) {
            let hsCodeMenuItem = document.getElementById(`search-menu-item-${focusIndex}`)
            hsCodeMenuItem?.scrollIntoView({
                block: "end",
                inline: "end"
            })
        }
    }, [focusIndex, hsCodeUiModels])

    function handleInputKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            if (focusIndex === -1) {
                setIsSearchMenuOpen(false);
                setSelectedHsCodes([]);
                handleSearchClick()
                return;
            }
        }
        if (!hsCodeUiModels) {
            return
        }
        const length = hsCodeUiModels.length
        if (e.key === 'ArrowDown') {
            setFocusIndex((prev) => (prev + 1) % length)
        } else if (e.key === 'ArrowUp') {
            setFocusIndex((prev) => (prev - 1 + length) % length)
        } else if (e.key === 'Escape') {
            setFocusIndex(-1)
        } else if (e.key === 'Enter') {
            handleHsCodeItemClicked(hsCodeUiModels[focusIndex])
        }
    }

    function rawOrClearNumber(inputText: string) {
        return isNaN(Number(inputText)) ? inputText : KeyboardUtils.clearNumber(inputText)
    }

    function handleInputChanged(e: React.ChangeEvent<HTMLInputElement>) {
        if (searchedHsCodeDescription.length > 0) {
            setSearchedHsCodeDescription("");
        }
        const formatted = FormatUtils.toHsCodeFormat(e.target.value);
        setInputHsCode(formatted)
        openMenuAndSearchInput(e.target.value)
    }

    function openMenuAndSearchInput(inputText: string) {
        const inputParams = rawOrClearNumber(inputText)
        setSelectedHsCodes([]);
        if (inputText.length === 0) {
            searchByHsCode(inputParams);
        } else {
            searchByCondition(inputParams);
        }
        setIsSearchMenuOpen(true)
    }

    function searchByHsCode(hsCode: string) {
        NewDashboardApi.searchByHsCode(hsCode, language)
            .then((response) => response.data)
            .then((data) => {
                const responseCode = ResponseCodes.of(data.code)
                if (!responseCode.isSuccess() || data.data === null) {
                    alert(t('common_error_message'))
                    return
                }
                let hsCodeUiModels = data.data.map((it, index) =>
                    ({
                            key: hsCode + index,
                            hsCode: it.hsCode,
                            displayText: FormatUtils.toHsCodeFormat(it.hsCode),
                            description: it.hsCodeDesc,
                        } as HsCodeUiModel
                    ));
                setHsCodeUiModels(hsCodeUiModels)
            })
            .catch((e) => {
                alert(t('common_error_message'))
            })
    }

    function searchByCondition(inputText: string) {
        NewDashboardApi.searchByCondition(inputText, language)
            .then((response) => response.data)
            .then((data) => {
                const responseCode = ResponseCodes.of(data.code)
                if (!responseCode.isSuccess() || data.data === null) {
                    alert(t('common_error_message'))
                    return
                }
                let hsCodeUiModels = data.data.map((it, index) =>
                    ({
                            key: inputText + index,
                            hsCode: it.hsCode,
                            displayText: FormatUtils.toHsCodeFormat(it.hsCode),
                            description: it.hsCodeDesc,
                        } as HsCodeUiModel
                    ));
                setHsCodeUiModels(hsCodeUiModels)
            })
            .catch((e) => {
                alert(t('common_error_message'))
            })
    }

    function shouldKeepSearchMenu(clickId: string): boolean {
        return clickId === "search-input"
            || clickId === "search-down-arrow"
            || clickId === "search-close"
            || clickId.includes("search-menu-item")
            || clickId === "search-menu-item-hscode"
            || clickId === "search-menu-item-description"
            || clickId === "search-menu-header-description"
            || clickId === "search-menu-header-container"
            || clickId === "search-menu-header-hscode"
            || clickId === "search-menu-header-close"
            || clickId === "search-menu-header-back"

    }

    function handleSearchClick() {
        const inputParams = rawOrClearNumber(inputHsCode)
        if (!accessToken && inputParams !== freeHsCode) {
            alert(t('required_login'))
            navigate("/login")
            return;
        }
        if (inputParams.length !== 6 || isNaN(Number(inputParams))) {
            alert(t('required_input_hscode'))
            return;
        }
        ReactGA.event({
            category: "solution_dashboard",
            action: `search_hscode`
        });
        Logger.info(componentName, `search hscode ${inputParams}`, (scope) => {
            scope.setTag("hsCode", inputParams)
        })
        setSearchedHsCode(inputParams);
    }

    function fetchHsCodeDescription(hsCode: string) {
        NewDashboardApi.searchByCondition(hsCode, language)
            .then(response => response.data)
            .then(data => {
                const hsCodeItems = data.data
                if (hsCodeItems && hsCodeItems.length > 0) {
                    setSearchedHsCodeDescription(hsCodeItems[0].hsCodeDesc);
                } else {
                    setSearchedHsCodeDescription("");
                }
            });
    }

    function applySpan(text: string) {
        if (inputHsCode.length === 0) {
            return text;
        }
        const strRegExp = new RegExp(inputHsCode, "g");
        return text.replace(
            strRegExp,
            `<span style=\"color: ${Colors.KEY}; font-weight: 700\"
        }}>${inputHsCode}</span>`)
    }

    function handleHsCodeItemClicked(hsCodeItem: HsCodeUiModel) {
        setFocusIndex(-1)
        inputRef.current?.focus()
        const clearHsCode = rawOrClearNumber(inputHsCode)
        if (clearHsCode.length > 0) {
            if (hsCodeItem.hsCode.length === 6) {
                setIsSearchMenuOpen(false);
                setSearchedHsCodeDescription(hsCodeItem.description);
                setInputHsCode(hsCodeItem.displayText);
            } else {
                setInputHsCode(hsCodeItem.displayText);
                searchByCondition(hsCodeItem.hsCode);
            }
        } else {
            if (hsCodeItem.hsCode.length === 6) {
                setSelectedHsCodes([]);
                setHsCodeUiModels([]);
                setIsSearchMenuOpen(false);
                setSearchedHsCodeDescription(hsCodeItem.description);
                setInputHsCode(hsCodeItem.displayText);
            } else {
                selectedHsCodes.push(hsCodeItem);
                setSelectedHsCodes(selectedHsCodes);
                searchByHsCode(hsCodeItem.hsCode);
            }
        }
    }

    function handleSearchMenuCloseClicked() {
        setIsSearchMenuOpen(false);
        setSearchedHsCodeDescription("")
        setInputHsCode("");
        setSelectedHsCodes([]);
        setHsCodeUiModels([]);
    }

    function handleBackArrowClicked() {
        selectedHsCodes.pop();
        setSelectedHsCodes(selectedHsCodes);
        if (selectedHsCodes.length > 0) {
            searchByHsCode(selectedHsCodes[selectedHsCodes.length - 1].hsCode);
        } else {
            searchByHsCode("");
        }
    }

    function getContentFragmentBySelectedTab(): ReactNode {
        if (selectedTabIndex === 0) {
            return <IncomeTrendFragment
                key={selectedTabIndex}
                hsCode={searchedHsCode}
                onCompanyClick={(companyId) => {
                    if (premiumActivated || searchedHsCode === freeHsCode) {
                        window.open(
                            `company/${companyId}?hsCode=${searchedHsCode}`,
                            "_blank",
                        );
                    } else {
                        setIsPlanDialogOpen(true)
                    }
                }}
            />
        } else if (selectedTabIndex === 1) {
            return <SupplyAnalysisFragment
                key={selectedTabIndex}
                hsCode={searchedHsCode}
            />
        } else {
            return null
        }
    }

    function getSizeForScreenType(origin: string, fraction: number = 0.75): string {
        let value = Number(origin.replace("px", ""))
        return (isTablet ? value * fraction : value) + "px"
    }

    function getPremiumServicePrice(): string {
        return billingServices.length > 0 ? billingServices[0].itemPrice : "199000"
    }

    const doSubscribe = async () => {
        if (billingServices.length === 0) {
            alert("상품을 찾을 수 없습니다. 새로고침 후 이용해주세요.")
            navigate("/dashboard?index=0")
            return
        }
        const service = billingServices[0]
        const itemId = billingServices[0].itemId
        const cardInfo = await BillingAPI.getCardInfo()
            .then((response) => {
                let cardInfo = response.data.data;
                if (cardInfo && CardUtils.isCardInfoValid(cardInfo)) {
                    return cardInfo
                } else {
                    return null
                }
            })
            .catch((error) => {
                return null
            });

        if (cardInfo?.cardId) {
            BillingAPI.doSubscribe(itemId)
                .then((res) => {
                    const responseCode = ResponseCodes.of(res.data.code)
                    if (responseCode.isSuccess()) {
                        alert("결제 완료")
                        dispatch(Auths.refreshPaymentState())
                    } else {
                        alert(`결제 실패: [${res.data.code}] ${res.data.message}`)
                        navigate("/dashboard?index=0")
                    }
                })
                .catch((e) => {
                    alert(t('alert_a_temporary_error_has_occurred'))
                    navigate("/dashboard?index=0")
                })
        } else {
            PaypleAPI.getPaypleOrderId(itemId, "payment")
                .then((res) => {
                    const result = res.data.data
                    const orderId = result?.payOID
                    const payerNo = result?.payerNo
                    if (!orderId) {
                        alert("주문번호를 발급할 수 없습니다.")
                        navigate("/dashboard?index=0")
                        return
                    }
                    if (!payerNo) {
                        alert("회원번호가 확인되지 않습니다.")
                        navigate("/dashboard?index=0")
                        return
                    }
                    const options = 'top=10, left=10, width=380, height=1080, ' +
                        'status=no, ' +
                        'menubar=no,' +
                        ' toolbar=no,' +
                        ' resizable=no, scrollbar=yes';
                    window.open(`/payment/window?orderId=${orderId}&language=kr&type=PAY&payerNo=${payerNo}&product=${service?.itemName}&price=${Number(service?.itemPrice) + Number(service?.itemPriceVat)}`, "pay-window", options)
                })
                .catch((e) => {
                    alert(t('alert_a_temporary_error_has_occurred'))
                    navigate("/dashboard?index=0")
                })
        }
    }

    return (
        <FlexBox
            flexDirection={"column"}
            width={"100%"}
            minWidth={isTablet ? undefined : "1200px"}
            height={"fit-content"}
            minHeight={isTablet ? "1800px" : "900px"}
            alignItems={"center"}
            overflowX={"auto"}
            onMouseUp={(e: React.MouseEvent) => {
                if (isSearchMenuOpen) {
                    const element = e.target as Element
                    const clickId = element.id
                    if (shouldKeepSearchMenu(clickId)) {
                        return
                    }
                    setIsSearchMenuOpen(false)
                    if (inputHsCode.length === 0) {
                        searchByHsCode("");
                    } else {
                        searchByCondition(rawOrClearNumber(inputHsCode));
                    }
                }
            }}
            backgroundColor={Colors.BG_PAGE}>
            <ContentContainer
                width={isTablet ? "100%" : "1200px"}
                minWidth={isTablet ? "100%" : "1200px"}
                minHeight={"100%"}
                height={"100%"}
                paddingLeft={"20px"}
                paddingRight={"20px"}
                alignItems={"center"}
                paddingBottom={"100px"}
                overflow={"visible"}>
                <Text
                    fontWeight={700}
                    fontSize={getSizeForScreenType("32px")}
                    alignSelf={"flex-start"}
                    marginTop={getSizeForScreenType("120px")}>
                    {t('solution_title')}
                </Text>
                <Text
                    fontSize={getSizeForScreenType("20px")}
                    alignSelf={"flex-start"}
                    marginTop={getSizeForScreenType("20px")}>
                    {t('solution_subtitle')}
                </Text>

                <FlexBox
                    width={"100%"}
                    marginTop={getSizeForScreenType("60px")}
                    minHeight={"100%"}
                    height={"100%"}
                    flexDirection={isTablet ? "column" : "row"}>
                    <FlexBox minHeight={"60px"}
                             width={"100%"}
                             height={"fit-content"}
                             alignItems={"center"}>
                        <Input
                            autoComplete={"off"}
                            maxLength={7}
                            width={"100%"}
                            height={"60px"}
                            paddingRight={"60px"}
                            ref={inputRef}
                            id={"search-input"}
                            paddingLeft={isTablet ? "20px" : "30px"}
                            hintColor={Colors.GREY_50}
                            placeholder={`${t('search_by_hs_code_or_product')}`}
                            background={Colors.WHITE}
                            fontSize={"16px"}
                            fontWeight={searchedHsCodeDescription.length > 0 ? 700 : 400}
                            value={inputHsCode}
                            type={"text"}
                            onChange={handleInputChanged}
                            onFocus={(e) => {
                                if (!isSearchMenuOpen) {
                                    setIsSearchMenuOpen(true)
                                    openMenuAndSearchInput(inputHsCode);
                                }
                            }}
                            onMouseUp={(e: React.MouseEvent<HTMLInputElement>) => {
                                let element = e.target as HTMLInputElement
                                openMenuAndSearchInput(element.value);
                            }}
                            onKeyUp={handleInputKeyUp}
                            borderOnFocus={`1px solid ${Colors.KEY}`}
                        />
                        <Text
                            visible={inputHsCode.length > 0 && searchedHsCodeDescription.length > 0}
                            position={"absolute"}
                            fontSize={"16px"}
                            color={Colors.BLACK}
                            whiteSpace={"nowrap"}
                            maxWidth={"100%"}
                            overflow={"hidden"}
                            left={"0px"}
                            paddingRight={isTablet ? "40px" : "60px"}
                            paddingLeft={isTablet ? "90px" : "120px"}
                            textOverflow={"ellipsis"}
                            onClick={() => {
                                inputRef?.current?.focus();
                            }}>
                            {searchedHsCodeDescription}
                        </Text>
                        <Image
                            id={"search-down-arrow"}
                            cursor={"pointer"}
                            visible={!isSearchMenuOpen}
                            position={"absolute"}
                            right={isTablet ? "20px" : "24px"}
                            alt={"solution-search-down-arrow"}
                            src={require("assets/images/keyboard_arrow_down.svg").default}
                            onClick={(e) => {
                                openMenuAndSearchInput(inputHsCode);
                            }}
                            width={"16px"}/>
                        <Image
                            id={"search-close"}
                            cursor={"pointer"}
                            visible={isSearchMenuOpen}
                            position={"absolute"}
                            right={"24px"}
                            alt={"solution-search-close"}
                            src={require("assets/images/search-close.svg").default}
                            onClick={(e) => {
                                handleSearchMenuCloseClicked()
                            }}
                            width={"18px"}/>
                    </FlexBox>
                    <Button
                        width={isTablet ? "100%" : "180px"}
                        backgroundColor={Colors.KEY}
                        marginTop={isTablet ? "10px" : "0px"}
                        marginLeft={isTablet ? "0px" : "10px"}
                        borderRadius={"10px"}
                        color={Colors.WHITE}
                        fontSize={getSizeForScreenType("20px")}
                        fontWeight={Fonts.WEIGHT_700}
                        height={getSizeForScreenType("60px")}
                        onClick={handleSearchClick}>
                        {t('data_analysis')}
                    </Button>
                    <FlexBox
                        visible={isSearchMenuOpen}
                        width={isTablet ? "100%" : "1010px"}
                        height={getSizeForScreenType("524px")}
                        flexDirection={"column"}
                        borderRadius={"10px"}
                        marginTop={"4px"}
                        top={"62px"}
                        left={"0px"}
                        position={"absolute"}
                        overflowY={"hidden"}
                        zIndex={1}
                        border={`1px solid ${Colors.GRAY_350}`}
                        backgroundColor={Colors.WHITE}>

                        <FlexBox flexDirection={"column"}
                                 ref={hsCodeItemsContainerRef}
                                 width={"100%"}
                                 height={"100%"}
                                 overflowY={"auto"}
                                 paddingTop={"20px"}
                                 paddingBottom={"20px"}>

                            {selectedHsCodes.length > 0 &&
                                <FlexBox id={"search-menu-header-container"}
                                         alignItems={"center"}
                                         paddingBottom={getSizeForScreenType("20px")}
                                         paddingLeft={getSizeForScreenType("30px")}
                                         paddingRight={getSizeForScreenType("20px")}
                                         marginBottom={getSizeForScreenType("10px")}
                                         borderBottom={`1px solid ${Colors.GREY_40}`}>
                                    <Image
                                        id={"search-menu-header-back"}
                                        width={isTablet ? "14px" : "16px"}
                                        cursor={"pointer"}
                                        src={require("assets/images/search-back-arrow.svg").default}
                                        alt="search-back-arrow"
                                        onClick={() => handleBackArrowClicked()}/>
                                    <Text
                                        color={Colors.KEY}
                                        id={"search-menu-header-hscode"}
                                        fontSize={isTablet ? "14px" : "16px"}
                                        fontWeight={700}
                                        marginLeft={"20px"}>
                                        {selectedHsCodes[selectedHsCodes.length - 1].hsCode}
                                    </Text>
                                    <Text
                                        color={Colors.KEY}
                                        id={"search-menu-header-description"}
                                        fontSize={isTablet ? "14px" : "16px"}
                                        marginLeft={"20px"}
                                        marginRight={"40px"}>
                                        {selectedHsCodes[selectedHsCodes.length - 1].description}
                                    </Text>
                                    <Image
                                        id={"search-menu-header-close"}
                                        cursor={"pointer"}
                                        width={isTablet ? "20px" : "24px"}
                                        position={"absolute"}
                                        right={"20px"}
                                        src={require("assets/images/search-menu-header-close.svg").default}
                                        alt="search-close"
                                        onClick={() => handleSearchMenuCloseClicked()}/>
                                </FlexBox>}

                            {hsCodeUiModels.map((model, index) => {
                                return <FlexBox
                                    id={`search-menu-item-${index}`}
                                    alignItems={"center"}
                                    justifyContent={"flex-start"}
                                    key={model.key}
                                    width={"100%"}
                                    paddingRight={getSizeForScreenType("30px")}
                                    paddingLeft={getSizeForScreenType("30px")}
                                    paddingTop={getSizeForScreenType("10px")}
                                    paddingBottom={getSizeForScreenType("10px")}
                                    color={focusIndex === index ? Colors.KEY : Colors.BLACK}
                                    backgroundColor={focusIndex === index ? Colors.BG1_B : undefined}
                                    backgroundOnHover={focusIndex === -1 ? Colors.BG1_B : undefined}
                                    colorOnHover={focusIndex === -1 ? Colors.KEY : undefined}
                                    cursor={"pointer"}
                                    onMouseMove={() => {
                                        setFocusIndex(-1)
                                    }}
                                    onClick={() => {
                                        handleHsCodeItemClicked(model)
                                    }}>
                                    <Text
                                        id={"search-menu-item-hscode"}
                                        fontSize={isTablet ? "14px" : "16px"}
                                        dangerouslySetInnerHTML={{
                                            __html: applySpan(model.displayText)
                                        }}/>
                                    <Text
                                        id={"search-menu-item-description"}
                                        marginLeft={"30px"}
                                        fontSize={isTablet ? "14px" : "16px"}
                                        dangerouslySetInnerHTML={{
                                            __html: applySpan(model.description)
                                        }}/>
                                </FlexBox>
                            })}
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
                {/*------------------------------------------탭 레이아웃 + Content 컨테이너------------------------------------------*/}
                {searchedHsCode && <FlexBox
                    id={"tab-container"}
                    ref={contentRef}
                    width={"100%"}
                    maxWidth={"100%"}
                    border={`1px solid ${Colors.GREY_BORDER}`}
                    borderRadius={"10px"}
                    height={"fit-content"}
                    background={Colors.WHITE}
                    marginTop={getSizeForScreenType("60px")}
                    paddingTop={isTablet ? "20px" : "30px"}
                    paddingLeft={isTablet ? "20px" : "50px"}
                    paddingRight={isTablet ? "20px" : "50px"}
                    paddingBottom={isTablet ? "30px" : "50px"}
                    flexDirection={"column"}>
                    {/*------------------------------------------탭 레이아웃------------------------------------------*/}
                    <FlexBox
                        id={"tab_navigation_container"}
                        height={"80px"}
                        alignItems={"flex-end"}>
                        <FlexBox
                            width={"100%"}
                            height={"60px"}>
                            <Button
                                width={"100%"}
                                height={"60px"}
                                borderRadius={"0px"}
                                color={selectedTabIndex === 0 ? Colors.KEY : Colors.SUB_TXT2}
                                padding={"10px"}
                                fontSize={isTablet ? "12px" : "18px"}
                                fontWeight={selectedTabIndex === 0 ? 700 : "normal"}
                                borderLeft={selectedTabIndex === 0 ? `1px solid ${Colors.KEY}` : `1px solid ${Colors.GREY_BORDER}`}
                                borderTop={selectedTabIndex === 0 ? `1px solid ${Colors.KEY}` : `1px solid ${Colors.GREY_BORDER}`}
                                borderBottom={selectedTabIndex === 0 ? undefined : `1px solid ${Colors.KEY}`}
                                backgroundColor={selectedTabIndex === 0 ? Colors.WHITE : Colors.BG_GREY}
                                onClick={() => {
                                    ReactGA.event({
                                        category: "solution_dashboard",
                                        action: `income_trend_tab`
                                    });
                                    navigate("/dashboard?index=0")
                                }}>
                                {t('import_trends')}
                            </Button>
                        </FlexBox>
                        <FlexBox
                            width={"100%"}
                            height={"100%"}
                            alignItems={"center"}
                            flexDirection={"column-reverse"}>
                            <Button
                                width={"100%"}
                                height={"60px"}
                                borderRadius={"0px"}
                                color={selectedTabIndex === 1 ? Colors.KEY : Colors.SUB_TXT2}
                                padding={"10px"}
                                fontSize={isTablet ? "12px" : "18px"}
                                fontWeight={selectedTabIndex === 1 ? 700 : "normal"}
                                borderLeft={selectedTabIndex === 0 || selectedTabIndex === 1 ? `1px solid ${Colors.KEY}` : `1px solid ${Colors.GREY_BORDER}`}
                                borderTop={selectedTabIndex === 1 ? `1px solid ${Colors.KEY}` : `1px solid ${Colors.GREY_BORDER}`}
                                borderBottom={selectedTabIndex === 1 ? undefined : `1px solid ${Colors.KEY}`}
                                backgroundColor={selectedTabIndex === 1 ? Colors.WHITE : Colors.BG_GREY}
                                onClick={() => {
                                    ReactGA.event({
                                        category: "solution_dashboard",
                                        action: `supply_analysis_tab`
                                    });
                                    navigate("/dashboard?index=1")
                                }}>
                                {t('supply_analysis')}
                            </Button>
                            <Image
                                cursor={"pointer"}
                                position={"absolute"}
                                top={isTablet ? "4px" : "0px"}
                                width={isTablet ? "60px" : undefined}
                                alt={"solution-premium-icon"}
                                src={require("assets/images/solution-premium-icon.svg").default}
                                onClick={() => {
                                    ReactGA.event({
                                        category: "solution_dashboard",
                                        action: `supply_analysis_tab`
                                    });
                                    setSelectedTabIndex(1)
                                }}/>
                        </FlexBox>
                        <FlexBox
                            width={"100%"}
                            height={"100%"}
                            alignItems={"center"}
                            flexDirection={"column-reverse"}>
                            <Button
                                width={"100%"}
                                height={"60px"}
                                borderRadius={"0px"}
                                padding={"10px"}
                                fontSize={isTablet ? "12px" : "18px"}
                                color={selectedTabIndex === 2 ? Colors.KEY : Colors.SUB_TXT2}
                                fontWeight={selectedTabIndex === 2 ? 700 : "normal"}
                                borderLeft={selectedTabIndex === 1 || selectedTabIndex === 2 ? `1px solid ${Colors.KEY}` : `1px solid ${Colors.GREY_BORDER}`}
                                borderTop={selectedTabIndex === 2 ? `1px solid ${Colors.KEY}` : `1px solid ${Colors.GREY_BORDER}`}
                                borderBottom={selectedTabIndex === 2 ? undefined : `1px solid ${Colors.KEY}`}
                                backgroundColor={selectedTabIndex === 2 ? Colors.WHITE : Colors.BG_GREY}
                                onClick={() => {
                                    ReactGA.event({
                                        category: "solution_dashboard",
                                        action: `market_intelligence_tab`
                                    });
                                    alert(t('alert_coming_soon'))
                                }}>
                                {t('market_intelligence')}
                            </Button>
                            <Image
                                cursor={"pointer"}
                                position={"absolute"}
                                top={isTablet ? "4px" : "0px"}
                                width={isTablet ? "60px" : undefined}
                                alt={"solution-premium-icon"}
                                src={require("assets/images/solution-premium-icon.svg").default}
                                onClick={() => {
                                    ReactGA.event({
                                        category: "solution_dashboard",
                                        action: `market_intelligence_tab`
                                    });
                                    alert(t('alert_coming_soon'))
                                }}/>
                        </FlexBox>
                        <FlexBox
                            width={"100%"}
                            height={"100%"}
                            flexDirection={"column-reverse"}
                            alignItems={"center"}>
                            <Button
                                width={"100%"}
                                height={"60px"}
                                borderRadius={"0px"}
                                padding={"10px"}
                                fontSize={isTablet ? "12px" : "18px"}
                                color={selectedTabIndex === 3 ? Colors.KEY : Colors.SUB_TXT2}
                                fontWeight={selectedTabIndex === 3 ? 700 : "normal"}
                                borderLeft={selectedTabIndex === 2 || selectedTabIndex === 3 ? `1px solid ${Colors.KEY}` : `1px solid ${Colors.GREY_BORDER}`}
                                borderRight={selectedTabIndex === 3 ? `1px solid ${Colors.KEY}` : `1px solid ${Colors.GREY_BORDER}`}
                                borderTop={selectedTabIndex === 3 ? `1px solid ${Colors.KEY}` : `1px solid ${Colors.GREY_BORDER}`}
                                borderBottom={selectedTabIndex === 3 ? undefined : `1px solid ${Colors.KEY}`}
                                backgroundColor={selectedTabIndex === 3 ? Colors.WHITE : Colors.BG_GREY}
                                onClick={() => {
                                    ReactGA.event({
                                        category: "solution_dashboard",
                                        action: `market_research_tab`
                                    });
                                    alert(t('alert_coming_soon'))
                                }}>
                                {t('customized_market_report')}
                            </Button>
                            <Image
                                cursor={"pointer"}
                                position={"absolute"}
                                top={isTablet ? "4px" : "0px"}
                                width={isTablet ? "60px" : undefined}
                                alt={"solution-premium-icon"}
                                src={require("assets/images/solution-premium-icon.svg").default}
                                onClick={() => {
                                    ReactGA.event({
                                        category: "solution_dashboard",
                                        action: `market_research_tab`
                                    });
                                    alert(t('alert_coming_soon'))
                                }}/>
                        </FlexBox>
                    </FlexBox>
                    {/*------------------------------------------탭 Content 영역------------------------------------------*/}
                    <FlexBox>
                        {getContentFragmentBySelectedTab()}
                    </FlexBox>
                </FlexBox>}
                {!isTablet && searchedHsCode && <Button
                    id={"report-print-button"}
                    width={isTablet ? "100%" : "360px"}
                    height={getSizeForScreenType("72px")}
                    marginTop={getSizeForScreenType("60px")}
                    fontSize={getSizeForScreenType("24px")}
                    color={Colors.WHITE}
                    onClick={() => {
                        handlePrint()
                    }}>
                    {t('print_report')}
                </Button>}
            </ContentContainer>
            {!isTablet && <PlanIntroDialog dialogProps={{
                isOpen: isPlanDialogOpen,
                price: FormatUtils.toCurrencyFormat(getPremiumServicePrice()),
                premiumActivated: premiumActivated,
                onClose: (positive) => {
                    setIsPlanDialogOpen(false)
                    if (positive) {
                        setIsPremiumPaymentDialogOpen(true)
                    } else {
                        navigate("/dashboard?index=0")
                    }
                }
            }}
            />}
            {isTablet && <PlanIntroBottomSheet dialogProps={{
                isOpen: isPlanDialogOpen,
                premiumActivated: premiumActivated,
                price: FormatUtils.toCurrencyFormat(getPremiumServicePrice()),
                onClose: (positive) => {
                    setIsPlanDialogOpen(false)
                    navigate("/dashboard?index=0")
                    if (positive) {
                        setIsPremiumPaymentDialogOpen(true)
                    } else {
                        navigate("/dashboard?index=0")
                    }
                }
            }}/>}
            {!isTablet && <PremiumPaymentDialog dialogProps={{
                isOpen: isPremiumPaymentDialogOpen,
                price: FormatUtils.toCurrencyFormat(getPremiumServicePrice()),
                backTitle: `${t('dialog_back')}`,
                onClose: (positive) => {
                    setIsPremiumPaymentDialogOpen(false)
                    if (positive) {
                        // noinspection JSIgnoredPromiseFromCall
                        doSubscribe()
                    } else {
                        navigate("/dashboard?index=0")
                    }
                }
            }}
            />}
            {isTablet && <PremiumPaymentBottomSheet dialogProps={{
                isOpen: isPremiumPaymentDialogOpen,
                price: FormatUtils.toCurrencyFormat(getPremiumServicePrice()),
                backTitle: `${t('dialog_back')}`,
                onClose: (positive) => {
                    setIsPremiumPaymentDialogOpen(false)
                    if (positive) {
                        // noinspection JSIgnoredPromiseFromCall
                        doSubscribe()
                    } else {
                        navigate("/dashboard?index=0")
                    }
                }
            }}/>}
        </FlexBox>
    )
}
export default SolutionFragment;