import styled from "styled-components";
import Text from "../Text";
import {reactiveValueInContent} from "../../utils/StyleUtils";

const H4 = styled(Text)`
  font-size: ${({reactive}) => reactive ? reactiveValueInContent("24px", "12px") : "24px"};
  //font-size: min(calc(100vw * 0.02), 2.4rem);
  font-weight: 700;
`;

export default H4;