/**
 * @author jaeho.lee104 on 2023. 08. 24..
 */

import React, {useEffect, useState} from "react";
import {BottomSheet} from "react-spring-bottom-sheet";
import FlexBox from "../styledcomponents/FlexBox";
import NewH3 from "../styledcomponents/text/NewH3";
import {Colors} from "../styledcomponents/Styles";
import NewP2 from "../styledcomponents/text/NewP2";
import NewH4 from "../styledcomponents/text/NewH4";
import Image from "../styledcomponents/Image";
import NewButton from "../styledcomponents/NewButton";
import {useTranslation} from "react-i18next";

export interface PlanIntroBottomSheetProps {
    isOpen: boolean,
    premiumActivated: boolean,
    price: string,
    onClose: (positive: boolean) => void
}

const PlanIntroBottomSheet: React.FC<{
    dialogProps: PlanIntroBottomSheetProps
}> = ({dialogProps}) => {

    const [isOpen, setIsOpen] = useState(false);
    const {t} = useTranslation()

    useEffect(() => {
        setIsOpen(dialogProps.isOpen);
    }, [dialogProps.isOpen]);

    const handleOk = () => {
        if (!isOpen) {
            return;
        }
        dialogProps.onClose(true);
    };

    const handleCancel = () => {
        if (!isOpen) {
            return;
        }
        dialogProps.onClose(false);
    };

    return <BottomSheet
        open={isOpen}
        onDismiss={handleCancel}
        defaultSnap={({maxHeight}) => maxHeight * 0.8}
        snapPoints={({maxHeight}) => [
            maxHeight - 2 * maxHeight / 10,
            maxHeight / 4,
            maxHeight * 0.6,
        ]}>
        <FlexBox
            padding={"16px 20px"}
            flexDirection={"column"}>
            <NewH3
                textAlign={"left"}
                color={Colors.GRAY_8}>
                {t('plan_intro_title')}
            </NewH3>
            <NewP2
                textAlign={"left"}
                marginTop={"12px"}>
                {t('plan_intro_description')}
            </NewP2>
            <Image
                cursor={"pointer"}
                position={"absolute"}
                right={"20px"}
                top={"12px"}
                width={"24px"}
                height={"24px"}
                src={require("assets/images/ic-dialog-close.svg").default}
                onClick={() => {
                    dialogProps.onClose(false)
                }}
                alt="ic-dialog-close"/>
            <FlexBox
                border={`1px solid ${Colors.GRAY_2}`}
                width={"100%"}
                borderRadius={"16px"}
                marginBottom={"16px"}
                padding={"24px 20px"}
                marginTop={"24px"}
                flexDirection={"column"}>
                <FlexBox
                    flexDirection={"column"}>
                    <NewH3
                        color={Colors.GRAY_8}>
                        Standard
                    </NewH3>
                    <NewP2
                        color={Colors.GRAY_7}
                        marginTop={"4px"}>
                        {t('plan_standard_description')}
                    </NewP2>
                    <NewH4
                        color={Colors.MAIN}
                        marginTop={"24px"}>
                        {t('free_plan')}
                    </NewH4>
                    <NewH4
                        color={Colors.GRAY_8}
                        marginTop={"24px"}>
                        {t('plan_import_trends')}
                    </NewH4>
                    <FlexBox
                        backgroundColor={Colors.GRAY_0}
                        border={`1px solid ${Colors.GRAY_2}`}
                        borderRadius={"12px"}
                        flexDirection={"column"}
                        marginTop={"12px"}
                        padding={"12px 16px"}>
                        <FlexBox
                            alignItems={"center"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                {t('premium_trend_data_1')}
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                {t('premium_trend_data_2')}
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                {t('premium_trend_data_3')}
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                {t('premium_trend_data_4')}
                            </NewP2>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
                <NewButton
                    disabled={true}
                    marginTop={"24px"}>
                    {t('current_plan')}
                </NewButton>
            </FlexBox>
            <FlexBox
                border={`1px solid ${Colors.GRAY_2}`}
                width={"100%"}
                borderRadius={"16px"}
                padding={"24px 20px"}
                marginTop={"24px"}
                flexDirection={"column"}>
                <FlexBox
                    flexDirection={"column"}>
                    <FlexBox
                        height={"28px"}
                        alignItems={"flex-end"}>
                        <NewH3
                            color={Colors.GRAY_8}>
                            Premium
                        </NewH3>
                        <Image
                            alignSelf={"flex-start"}
                            marginLeft={"4px"}
                            width={"83px"}
                            height={"20px"}
                            src={require("assets/images/ic-most-popular-tag.svg").default}
                            alt="ic-most-popular-tag"/>
                    </FlexBox>
                    <NewP2
                        color={Colors.GRAY_7}
                        marginTop={"4px"}>
                        {t('plan_premium_description')}
                    </NewP2>
                    <FlexBox
                        alignItems={"flex-end"}
                        marginTop={"24px"}>
                        <NewH4
                            color={Colors.MAIN}>
                            {`${t('en_krw_unit')}${dialogProps.price}${t('kr_krw_unit')}`}
                        </NewH4>
                        <NewP2
                            color={Colors.GRAY_6}
                            marginLeft={"4px"}>
                            {`/${t('monthly')} ${t('additional_vat')}`}
                        </NewP2>
                    </FlexBox>
                    <NewH4
                        color={Colors.GRAY_8}
                        marginTop={"24px"}>
                        {t('plan_import_trends')}
                    </NewH4>
                    <FlexBox
                        backgroundColor={Colors.GRAY_0}
                        border={`1px solid ${Colors.GRAY_2}`}
                        borderRadius={"12px"}
                        flexDirection={"column"}
                        marginTop={"12px"}
                        padding={"12px 16px"}>
                        <FlexBox
                            alignItems={"center"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                {t('premium_trend_data_1')}
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                {t('premium_trend_data_2')}
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                {t('premium_trend_data_3')}
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                {t('premium_trend_data_4')}
                            </NewP2>
                        </FlexBox>
                    </FlexBox>
                    <NewH4
                        color={Colors.GRAY_8}
                        marginTop={"24px"}>
                        {t('plan_scm_data')}
                    </NewH4>
                    <FlexBox
                        backgroundColor={Colors.GRAY_0}
                        border={`1px solid ${Colors.GRAY_2}`}
                        borderRadius={"12px"}
                        flexDirection={"column"}
                        marginTop={"12px"}
                        padding={"12px 16px"}>
                        <FlexBox
                            alignItems={"center"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                {t('premium_scm_data_1')}
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                {t('premium_scm_data_2')}
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                {t('premium_scm_data_3')}
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                {t('premium_scm_data_4')}
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                {t('premium_scm_data_5')}
                            </NewP2>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
                <NewButton
                    disabled={dialogProps.premiumActivated}
                    marginTop={"24px"}
                    onClick={() => {
                        handleOk()
                    }}>
                    {dialogProps.premiumActivated ? `${t('current_plan')}` : `${t('do_upgrade')}`}
                </NewButton>
            </FlexBox>
        </FlexBox>
    </BottomSheet>
}

export default PlanIntroBottomSheet;