/**
 * @author jaeho.lee104 on 2023. 07. 12..
 */
import {AxiosResponse} from "axios";
import {NetworkCommon} from "./NetworkCommon";
import {PaypleOrderResult} from "../model/PaypleOrderResult";
import {PaypleAuthResult} from "../model/PaypleAuthResult";

const instance = NetworkCommon.createDefaultAxiosInstance()

export class PaypleAPI {

    static getAuthKey(): Promise<AxiosResponse<BaseResponse<PaypleAuthResult>>> {
        return instance.get(`/payple/auth`)
    }

    static getPaypleOrderId(serviceId: string, type: string): Promise<AxiosResponse<BaseResponse<PaypleOrderResult>>> {
        return instance.get(`/payple/order/${serviceId}`, {
            params: {
                type: type
            },
        })
    }

    static getPaymentResult(oid: string): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.get(`/payple/payment/${oid}`)
    }

}