import styled from "styled-components";
import Text from "../Text";
import {reactiveValueInContent} from "../../utils/StyleUtils";

const H1 = styled(Text)`
    font-size: ${({reactive}) => reactive ? reactiveValueInContent("60px", "12px") : "60px"};
    //font-size: min(calc(100vw * 0.05), 6rem);
    font-weight: 700;
`;

export default H1;