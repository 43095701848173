/**
 * @author jaeho.lee104 on 2023. 04. 07..
 */

import styled from "styled-components";
import {CommonProps} from "./CommonComponent";

interface ImageProps extends CommonProps {
    objectFit?: "fill" | "cover" | "contain" | "none"
}

const Image = styled.img<ImageProps>`
    object-fit: ${({objectFit}) => objectFit || "contain"};
    box-shadow: ${({boxShadow}) => boxShadow || undefined};
    opacity: ${({opacity}) => opacity || undefined};
    flex-grow: ${({flexGrow}) => flexGrow || undefined};
    position: ${({position}) => position || "relative"};
    border-radius: ${({borderRadius}) => borderRadius || undefined};
    float: ${({float}) => float || undefined};
    margin-left: ${({marginLeft}) => marginLeft || undefined};
    margin-right: ${({marginRight}) => marginRight || undefined};
    margin-bottom: ${({marginBottom}) => marginBottom || undefined};
    margin-top: ${({marginTop}) => marginTop || undefined};
    padding-left: ${({paddingLeft}) => paddingLeft || undefined};
    padding-right: ${({paddingRight}) => paddingRight || undefined};
    padding-bottom: ${({paddingBottom}) => paddingBottom || undefined};
    padding-top: ${({paddingTop}) => paddingTop || undefined};
    width: ${({width}) => width || undefined};
    height: ${({height}) => height || undefined};
    min-width: ${({minWidth}) => minWidth || undefined};
    min-height: ${({minHeight}) => minHeight || undefined};
    max-width: ${({maxWidth}) => maxWidth || undefined};
    max-height: ${({maxHeight}) => maxHeight || undefined};
    border: ${({border}) => border || undefined};
    border-right: ${({borderRight}) => borderRight || undefined};
    border-left: ${({borderLeft}) => borderLeft || undefined};
    border-top: ${({borderTop}) => borderTop || undefined};
    border-bottom: ${({borderBottom}) => borderBottom || undefined};
    color: ${({color}) => color || undefined};
    font-size: ${({fontSize}) => fontSize || undefined};
    font-weight: ${({fontWeight}) => fontWeight || undefined};
    display: ${({display, visible}) => visible ? (display || undefined) : "none"};
    align-content: ${({alignContent}) => alignContent || undefined};
    align-items: ${({alignItems}) => alignItems || undefined};
    flex-direction: ${({flexDirection}) => flexDirection || undefined};
    flex-wrap: ${({flexWrap}) => flexWrap || undefined};
    justify-content: ${({justifyContent}) => justifyContent || undefined};
    align-self: ${({alignSelf}) => alignSelf || undefined};
    background-color: ${({backgroundColor}) => backgroundColor || undefined};
    background: ${({background}) => background || undefined};
    cursor: ${({cursor}) => cursor || undefined};
    right: ${({right}) => right || undefined};
    left: ${({left}) => left || undefined};
    top: ${({top}) => top || undefined};
    bottom: ${({bottom}) => bottom || undefined};
    z-index: ${({zIndex}) => zIndex || undefined};
    transform: ${({transform}) => transform || undefined};;
`
Image.defaultProps = {
    visible: true
}

export default Image