import styled from "styled-components";
import Text from "../Text";
import {reactiveValueInContent} from "../../utils/StyleUtils";

const H3 = styled(Text)`
  font-size: ${({reactive}) => reactive ? reactiveValueInContent("32px", "12px") : "32px"};
  //font-size: min(calc(100vw * 0.027), 3.2rem);
  font-weight: 700;
`;

export default H3;