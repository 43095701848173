/**
 * @author jaeho.lee104 on 2023. 09. 17..
 */
import React, {useEffect, useState} from "react";
import useTablet from "../uses/UseTablet";
import {useTranslation} from "react-i18next";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Dialog} from "@mui/material";
import FlexBox from "../styledcomponents/FlexBox";
import Image from "../styledcomponents/Image";
import {Colors} from "../styledcomponents/Styles";
import NewH3 from "../styledcomponents/text/NewH3";
import NewP2 from "../styledcomponents/text/NewP2";
import NewH4 from "../styledcomponents/text/NewH4";
import NewButton from "../styledcomponents/NewButton";
import useMobile from "../uses/UseMobile";
import {BottomSheet} from "react-spring-bottom-sheet";

export interface AnugaPromotionDialogProps {
    isOpen: boolean,
    onClose: (positive: boolean) => void
}

const AnugaPromotionDialog: React.FC<{
    dialogProps: AnugaPromotionDialogProps
}> = ({dialogProps}) => {

    const [isOpen, setIsOpen] = useState(false);
    const isTablet = useTablet()
    const isMobile = useMobile()
    const {t} = useTranslation()
    const theme = createTheme({
        components: {
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        minWidth: "640px",
                        borderRadius: "24px"
                    }
                }
            }
        },
        palette: {
            background: {
                paper: '#fff',
            },
            text: {
                primary: '#000',
                secondary: '#46505A',
            },
            action: {
                active: '#001E3C',
            },
        },
    });

    useEffect(() => {
        setIsOpen(dialogProps.isOpen);
    }, [dialogProps.isOpen]);

    const handleOk = () => {
        if (!isOpen) {
            return;
        }
        dialogProps.onClose(true);
    };

    const handleCancel = () => {
        if (!isOpen) {
            return;
        }
        dialogProps.onClose(false);
    };
    return <>
        {!isMobile && <ThemeProvider theme={theme}>
            <Dialog
                open={isOpen}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleOk();
                    }
                }}>
                <FlexBox
                    width={"100%"}
                    padding={"32px"}
                    flexDirection={"column"}>
                    <Image
                        position={"absolute"}
                        cursor={"pointer"}
                        top={"32px"}
                        right={"32px"}
                        width={"24px"}
                        height={"24px"}
                        zIndex={10}
                        src={require("assets/images/ic-dialog-close.svg").default}
                        onClick={handleCancel}
                        alt="ic-dialog-close"/>
                    <FlexBox
                        // border={`1px solid ${Colors.GRAY_2}`}
                        borderRadius={"16px"}
                        padding={"24px"}>
                        <FlexBox
                            width={"100%"}
                            flexDirection={"column"}
                            alignItems={"center"}>
                            <FlexBox
                                flexDirection={"column"}
                                alignItems={"center"}
                                maxWidth={"360px"}>
                                <NewH3
                                    color={Colors.GRAY_8}>
                                    Welcome to THIRDWORKS in Anuga FoodTec 2023!
                                </NewH3>
                                <NewH4
                                    color={Colors.GRAY_8}
                                    marginTop={"24px"}>
                                    PROMOTION 🎉
                                </NewH4>
                                <FlexBox
                                    width={"100%"}
                                    maxWidth={"360px"}
                                    backgroundColor={Colors.GRAY_0}
                                    border={`1px solid ${Colors.GRAY_2}`}
                                    borderRadius={"12px"}
                                    flexDirection={"column"}
                                    marginTop={"16px"}
                                    padding={"12px 16px"}>
                                    <FlexBox
                                        alignItems={"center"}>
                                        <Image
                                            width={"16px"}
                                            height={"16px"}
                                            src={require("assets/images/ic-check-2.svg").default}
                                            alt="ic-check"/>
                                        <NewP2
                                            marginLeft={"8px"}
                                            color={Colors.GRAY_7}>
                                            Free 1 month data subscription
                                        </NewP2>
                                    </FlexBox>
                                    <FlexBox
                                        alignItems={"center"}
                                        marginTop={"12px"}>
                                        <Image
                                            width={"16px"}
                                            height={"16px"}
                                            src={require("assets/images/ic-check-2.svg").default}
                                            alt="ic-check"/>
                                        <NewP2
                                            marginLeft={"8px"}
                                            color={Colors.GRAY_7}>
                                            Free 3 multiple quotation
                                        </NewP2>
                                    </FlexBox>
                                    <FlexBox
                                        alignItems={"center"}
                                        marginTop={"12px"}>
                                        <Image
                                            width={"16px"}
                                            height={"16px"}
                                            src={require("assets/images/ic-check-2.svg").default}
                                            alt="ic-check"/>
                                        <NewP2
                                            marginLeft={"8px"}
                                            color={Colors.GRAY_7}>
                                            1 Target Market Analysis Report
                                        </NewP2>
                                    </FlexBox>
                                    <FlexBox
                                        alignItems={"center"}
                                        marginTop={"12px"}>
                                        <Image
                                            width={"16px"}
                                            height={"16px"}
                                            src={require("assets/images/ic-check-2.svg").default}
                                            alt="ic-check"/>
                                        <NewP2
                                            marginLeft={"8px"}
                                            color={Colors.GRAY_7}>
                                            Guide for Korea Import Procedure
                                        </NewP2>
                                    </FlexBox>
                                </FlexBox>
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                    <NewButton
                        alignSelf={"center"}
                        width={"100%"}
                        marginBottom={"24px"}
                        maxWidth={"360px"}
                        marginTop={"16px"}
                        onClick={handleOk}>
                        GET IT NOW FREE
                    </NewButton>
                </FlexBox>
            </Dialog>
        </ThemeProvider>}
        {isMobile && <BottomSheet
            open={isOpen}
            blocking={true}
            defaultSnap={({maxHeight}) => maxHeight * 0.8}
            snapPoints={({maxHeight}) => [
                maxHeight * 0.8,
                maxHeight * 0.4,
            ]}>
            <FlexBox
                width={"100%"}
                padding={"24px"}
                flexDirection={"column"}>
                <Image
                    position={"absolute"}
                    cursor={"pointer"}
                    top={"4px"}
                    right={"16px"}
                    width={"24px"}
                    height={"24px"}
                    zIndex={10}
                    src={require("assets/images/ic-dialog-close.svg").default}
                    onClick={handleCancel}
                    alt="ic-dialog-close"/>
                <FlexBox
                    // border={`1px solid ${Colors.GRAY_2}`}
                    borderRadius={"16px"}>
                    <FlexBox
                        width={"100%"}
                        flexDirection={"column"}
                        alignItems={"center"}>
                        <FlexBox
                            flexDirection={"column"}
                            alignItems={"center"}
                            marginTop={"4px"}
                            maxWidth={"360px"}>
                            <NewH3
                                color={Colors.GRAY_8}>
                                Welcome to THIRDWORKS in Anuga Foodtec 2023!
                            </NewH3>
                            <NewH4
                                color={Colors.GRAY_8}
                                marginTop={"24px"}>
                                PROMOTION 🎉
                            </NewH4>
                            <FlexBox
                                width={"100%"}
                                maxWidth={"360px"}
                                backgroundColor={Colors.GRAY_0}
                                border={`1px solid ${Colors.GRAY_2}`}
                                borderRadius={"12px"}
                                flexDirection={"column"}
                                marginTop={"16px"}
                                padding={"12px 16px"}>
                                <FlexBox
                                    alignItems={"center"}>
                                    <Image
                                        width={"16px"}
                                        height={"16px"}
                                        src={require("assets/images/ic-check-2.svg").default}
                                        alt="ic-check"/>
                                    <NewP2
                                        marginLeft={"8px"}
                                        color={Colors.GRAY_7}>
                                        Free 1 month data subscription
                                    </NewP2>
                                </FlexBox>
                                <FlexBox
                                    alignItems={"center"}
                                    marginTop={"12px"}>
                                    <Image
                                        width={"16px"}
                                        height={"16px"}
                                        src={require("assets/images/ic-check-2.svg").default}
                                        alt="ic-check"/>
                                    <NewP2
                                        marginLeft={"8px"}
                                        color={Colors.GRAY_7}>
                                        Free 3 multiple quotation
                                    </NewP2>
                                </FlexBox>
                                <FlexBox
                                    alignItems={"center"}
                                    marginTop={"12px"}>
                                    <Image
                                        width={"16px"}
                                        height={"16px"}
                                        src={require("assets/images/ic-check-2.svg").default}
                                        alt="ic-check"/>
                                    <NewP2
                                        marginLeft={"8px"}
                                        color={Colors.GRAY_7}>
                                        1 Target Market Analysis Report
                                    </NewP2>
                                </FlexBox>
                                <FlexBox
                                    alignItems={"center"}
                                    marginTop={"12px"}>
                                    <Image
                                        width={"16px"}
                                        height={"16px"}
                                        src={require("assets/images/ic-check-2.svg").default}
                                        alt="ic-check"/>
                                    <NewP2
                                        marginLeft={"8px"}
                                        color={Colors.GRAY_7}>
                                        Guide for Korea Import Procedure
                                    </NewP2>
                                </FlexBox>
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
                <NewButton
                    alignSelf={"center"}
                    width={"100%"}
                    marginBottom={"16px"}
                    maxWidth={"360px"}
                    marginTop={"20px"}
                    onClick={handleOk}>
                    GET IT NOW FREE
                </NewButton>
            </FlexBox>
        </BottomSheet>}
    </>
}
export default AnugaPromotionDialog;