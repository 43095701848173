import React, {ChangeEventHandler, FC, HTMLInputTypeAttribute, useEffect, useRef} from "react";
import {Colors} from "../styledcomponents/Styles";
import FlexBox from "../styledcomponents/FlexBox";
import Text from "../styledcomponents/Text";
import Input from "../styledcomponents/Input";
import {CommonProps} from "../styledcomponents/CommonComponent";

export interface TextFieldProps extends CommonProps {
    hint?: string;
    error?: boolean;
    errorText?: string;
    errorTextGap?: string;
    errorFontSize?: string;
    label?: string;
    labelGap?: string;
    labelFontSize?: string;
    width?: string;
    height?: string;
    labelColor?: string;
    labelFontWight?: number | "normal";
    labelVisible?: boolean;
    maxLength?: number;
    disabled?: boolean;
    isReadOnly?: boolean;
    isRequired?: boolean;

    // input
    type?: HTMLInputTypeAttribute | undefined;
    value?: string | ReadonlyArray<string> | number | undefined;
    onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
}

const TextField: FC<TextFieldProps> = ({
                                           isReadOnly = false,
                                           disabled = undefined,
                                           maxLength = undefined,
                                           labelVisible = true,
                                           label = "",
                                           error = false,
                                           errorText = "",
                                           errorTextGap = "12px",
                                           errorFontSize = "14px",
                                           labelGap = "8px",
                                           labelFontSize = "14px",
                                           isRequired = false,
                                           labelColor = Colors.GRAY_7,
                                           labelFontWight = 500,
                                           hint = "",
                                           ...props
                                       }) => {
    const inputRef = useRef<HTMLInputElement>(null)
    useEffect(() => {
        if (error) {
            inputRef?.current?.focus()
        }
    }, [error])

    return <FlexBox flexDirection={"column"}
                    alignItems={"flex-start"}
                    width={"100%"}
                    {...props}>
        {labelVisible
            && <Text fontSize={labelFontSize}
                     color={labelColor}
                     lineHeight={"150%"}
                // @ts-ignore
                     fontWeight={labelFontWight}>{label} {isRequired && <span style={{
                color: Colors.DESTRUCTIVE_HOVER
            }}>*</span>}</Text>}
        <Input
            isReadOnly={isReadOnly}
            autoComplete={"new-password"}
            disabled={isReadOnly || disabled}
            ref={inputRef}
            marginTop={labelVisible ? labelGap : undefined}
            width={"100%"}
            alignSelf={"center"}
            placeholder={hint}
            maxLength={maxLength}
            error={error}
            onChange={props.onChange}
            type={props.type}
            value={props.value}
        />
        {error && errorText && <Text
            color={Colors.INPUT_ERROR}
            marginTop={errorTextGap}
            fontSize={errorFontSize}>{errorText}</Text>}
    </FlexBox>
}

export default TextField