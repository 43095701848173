/**
 * @author jaeho.lee104 on 2023. 05. 09..
 */
import React, {useEffect, useState} from "react";
import {ResponsiveBar} from "@nivo/bar";
import FlexBox from "../../styledcomponents/FlexBox";
import {Colors} from "../../styledcomponents/Styles";
import Text from "../../styledcomponents/Text";
import Box from "../../styledcomponents/Box";
import {FormatUtils} from "../../utils/FormatUtils";
import {AxisProps} from "@nivo/axes";

export type MarketShareChartModel = {
    itemNm: string,
    [key: string]: number | string;
}

interface MarketShareChartProps {
    chartModels: Array<MarketShareChartModel>
    key1: string
    key2: string
    groupByKey: string
}

export const MarketShareChart: React.FC<MarketShareChartProps> = ({
                                                                      chartModels,
                                                                      key1,
                                                                      key2,
                                                                      groupByKey
                                                                  }) => {

    const colors = ["#68C9CF", "#017DFD"]
    const [maxLength, setMaxLength] = useState(4)
    const [maxValue, setMaxValue] = useState(0)
    useEffect(() => {
        let maxValue = 0
        chartModels.forEach((item) => {
            maxValue = Math.max(maxValue, Number(item[key1]))
            maxValue = Math.max(maxValue, Number(item[key2]))
        })
        setMaxLength(maxValue.toString().length)
        setMaxValue(maxValue)
    }, [chartModels])

    return <ResponsiveBar
        data={chartModels}
        // @ts-ignore
        keys={[key1, key2]}
        indexBy={groupByKey}
        padding={0.3}
        margin={{top: 67, right: 10, bottom: 30, left: maxLength * 10 + 10}}
        enableGridX={false}
        enableLabel={false}
        valueScale={{type: "symlog", constant: 100}}
        indexScale={{type: "band", round: true}}
        groupMode="grouped"
        colors={colors}
        tooltip={function (params) {
            return <FlexBox width={"fit-content"}
                            height={"fit-content"}
                            padding={"20px"}
                            flexDirection={"column"}
                            backgroundColor={Colors.WHITE}
                            borderRadius={"10px"}
                            alignItems={"flex-start"}
                            boxShadow={Colors.DEFAULT_BOX_SHADOW}
                            justifyContent={"center"}>
                <Text fontWeight={700}
                      fontSize={"18px"}>{params.data[groupByKey]}</Text>
                <FlexBox
                    marginTop={"10px"}
                    alignItems={"center"}
                    justifyContent={"space-between"}>
                    <Box width={"16px"}
                         height={"16px"}
                         backgroundColor={"#68C9CF"}
                         borderRadius={"5px"}/>
                    <Text
                        textOverflow={"ellipsis"}
                        whiteSpace={"nowrap"}
                        overflow={"hidden"}
                        maxWidth={"80px"}
                        fontSize={"18px"}
                        marginLeft={"10px"}>{key1}</Text>
                    <Text
                        fontSize={"18px"}
                        fontWeight={700}
                        marginLeft={"10px"}>{params.data[key1]}</Text>
                </FlexBox>
                <FlexBox
                    alignItems={"center"}
                    marginTop={"10px"}
                    justifyContent={"space-between"}>
                    <Box width={"16px"}
                         height={"16px"}
                         backgroundColor={"#017DFD"}
                         borderRadius={"5px"}/>
                    <Text
                        textOverflow={"ellipsis"}
                        whiteSpace={"nowrap"}
                        overflow={"hidden"}
                        maxWidth={"80px"}
                        fontSize={"18px"}
                        marginLeft={"10px"}>{key2}</Text>
                    <Text
                        fontWeight={700}
                        fontSize={"18px"}
                        marginLeft={"10px"}>{params.data[key2]}</Text>
                </FlexBox>
            </FlexBox>
        }}
        borderRadius={2}
        axisTop={null}
        axisRight={null}
        innerPadding={2}
        axisBottom={{
            orient: "bottom",
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            // format: (value: string) => {
            //     const standard = 36 / chartModels.length
            //     console.log(standard)
            //     const suffix = value.length > standard ? "..." : ""
            //     return `${value.substring(0, standard)}${suffix}`
            // },
            renderTick: ({
                             opacity,
                             textAnchor,
                             textBaseline,
                             textX,
                             textY,
                             value,
                             x,
                             y
                         }) => {
                const standard = 80 / chartModels.length
                const suffix = value.length > standard ? "..." : ""
                const renderText = value.substring(0, standard) + suffix
                return (
                    <g
                        transform={`translate(${x},12)`}
                        style={{opacity}}>
                        <text
                            textAnchor={textAnchor}
                            transform={`translate(${textX},${textY})`}
                            style={{
                                fontSize: "12px",
                                fontWeight: 500,
                                lineHeight: "14px"
                            }}>
                            {renderText}
                        </text>
                    </g>
                )
            }
        } as AxisProps}
        axisLeft={{
            tickValues: [0,
                Math.floor((maxValue / 5) / (Math.pow(10, Math.floor(Math.log10(maxValue / 5))))) * (Math.pow(10, Math.floor(Math.log10(maxValue / 5)))),
                Math.floor((maxValue * 2 / 5) / (Math.pow(10, Math.floor(Math.log10(maxValue * 2 / 5))))) * (Math.pow(10, Math.floor(Math.log10(maxValue * 2 / 5)))),
                Math.floor((maxValue * 3 / 5) / (Math.pow(10, Math.floor(Math.log10(maxValue * 3 / 5))))) * (Math.pow(10, Math.floor(Math.log10(maxValue * 3 / 5)))),
                Math.floor((maxValue * 4 / 5) / (Math.pow(10, Math.floor(Math.log10(maxValue * 4 / 5))))) * (Math.pow(10, Math.floor(Math.log10(maxValue * 4 / 5)))),
                maxValue],
            orient: "left",
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            format: value => `${FormatUtils.toCurrencyFormat(Number(value).toString())}`,
        } as AxisProps}
        animate={true}
        legends={[
            {
                dataFrom: "keys",
                anchor: "top-right",
                direction: "row",
                justify: false,
                translateX: -1 * (key1.length * 4),
                translateY: -28,
                itemsSpacing: key1.length * 6,
                itemDirection: "left-to-right",
                itemWidth: 70,
                itemHeight: 0,
                itemOpacity: 1,
                symbolSize: 14,
                symbolShape: "circle",
                itemTextColor: Colors.GRAY_800,
            },
        ]}
        theme={{
            fontFamily: "Noto Sans",
            legends: {
                text: {
                    fontWeight: 400,
                    fontSize: 14,
                    color: Colors.GRAY_800
                }
            },
            crosshair: {
                line: {
                    stroke: Colors.GRAY_900,
                    strokeWidth: 1,
                    strokeOpacity: 1,
                    strokeDasharray: '3 4'
                }
            },
            axis: {
                ticks: {
                    text: {
                        fontWeight: 400,
                        fontSize: 10,
                        color: Colors.GRAY_800
                    }
                }
            },
            grid: {
                line: {
                    stroke: Colors.GRAY_300,
                    strokeWidth: 1,
                },
            },
        }}
    />
}


