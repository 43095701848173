/**
 * @author jaeho.lee104 on 2024. 01. 26..
 */
import React from "react";
import FlexBox from "../styledcomponents/FlexBox";
import {Colors} from "../styledcomponents/Styles";
import NewH5 from "../styledcomponents/text/NewH5";
import NewH7 from "../styledcomponents/text/NewH7";
import NewH3 from "../styledcomponents/text/NewH3";
import Image from "../styledcomponents/Image";
import NewH2 from "../styledcomponents/text/NewH2";
import VerticalDivider from "../styledcomponents/VerticalDivider";
import NewH4 from "../styledcomponents/text/NewH4";
import Text from "../styledcomponents/Text";
import NewP2 from "../styledcomponents/text/NewP2";
import {CompanyDetailItem} from "../model/CompanyDetailItem";
import {TextUtils} from "../utils/TextUtils";
import Flag from "react-flagkit";
import {useTranslation} from "react-i18next";
import {FormatUtils} from "../utils/FormatUtils";
import {ImagePreview} from "../components/ImagePreview";


interface CompanyInfoFragmentProps {
    companyDetailItem: CompanyDetailItem | null;
}

const CompanyInfoFragment = (props: CompanyInfoFragmentProps) => {

    const {companyDetailItem} = props
    const overviewTitleWidth = "240px"
    const {t} = useTranslation()
    const overviewItems = [
        {
            "title": `${t('name')}`,
            "content": companyDetailItem?.companyName || ""
        },
        {
            "title": `${t('number_of_employees')}`,
            "content": companyDetailItem?.employeeCount || ""
        },
        {
            "title": `${t('country')}`,
            "content": companyDetailItem?.country || ""
        },
        {
            "title": `${t('sales')}`,
            "content": companyDetailItem?.annualRevenue || ""
        },
        {
            "title": `${t('address')}`,
            "content": companyDetailItem?.address || ""
        },
        {
            "title": `${t('main_category_product_label')}`,
            "content": companyDetailItem?.categoryList.map((item) => item.categoryName).join(", ") || ""
        },
        {
            "title": `${t('business_type')}`,
            "content": companyDetailItem?.businessTypeList.map((item) => item.name).join(" / ") || ""
        },
        {
            "title": `${t('trading_experience_yn')}`,
            "content": companyDetailItem?.exportExperience ? "YES" : "NO"
        },
        {
            "title": `${t('year_of_establishment')}`,
            "content": companyDetailItem?.establishedYear || ""
        },
        {
            "title": `${t('authenticated_trader_yn')}`,
            "content": companyDetailItem?.customAuthYn === 'Y' ? "YES" : "NO"
        },
    ]
    const tradeCapacityItems = [
        {
            "title": "Incoterms",
            "content": companyDetailItem?.incoterms || ""
        },
        {
            "title": "Terms of payment",
            "content": companyDetailItem?.termsOfPayment || ""
        },
        {
            "title": "Nearest port",
            "content": companyDetailItem?.nearestPort || ""
        },
        {
            "title": "Main markets",
            "content": companyDetailItem?.mainMarketList.map((item) => item.continentName).join(", ")
        },
    ]
    const productionCapacityItems = [
        {
            "title": "Factory name",
            "content": companyDetailItem?.productionFactoryNm || ""
        },
        {
            "title": "Annual Output capacity",
            "content": companyDetailItem?.annualOutputCapacity || ""
        },
        {
            "title": "Production capacity",
            "content": `${companyDetailItem?.productionCapacity || ""}/ ${TextUtils.productCapacityUnitCodeToUiText(companyDetailItem?.productionCapacityUnitCd)}`
        },
        {
            "title": "R&D capacity",
            "content": companyDetailItem?.rdCapacity || ""
        },
    ]
    return <FlexBox
        width={"1200px"}
        flexDirection={"column"}>
        <FlexBox
            backgroundColor={Colors.WHITE}
            border={`1px solid ${Colors.GRAY_3}`}
            padding={"40px 40px 56px 40px"}
            width={"100%"}
            borderRadius={"16px"}
            boxShadow={'0px 4px 8px 0px rgba(0, 0, 0, 0.08);'}
            flexDirection={"column"}
            minHeight={"400px"}>
            <FlexBox
                width={"100%"}
                alignItems={"center"}
                justifyContent={"space-between"}>
                <FlexBox>
                    <FlexBox
                        borderRadius={"100px"}
                        border={`1px solid ${Colors.GRAY_3}`}
                        width={"80px"}
                        height={"80px"}
                        alignItems={"center"}
                        justifyContent={"center"}>
                        <Image
                            width={"56px"}
                            height={"56px"}
                            src={companyDetailItem?.iconFile?.url ? companyDetailItem?.iconFile?.url : require("assets/images/image-place-holder-48.png")}
                            alt="company-icon"/>
                    </FlexBox>
                    <FlexBox
                        marginLeft={"24px"}
                        flexDirection={"column"}>
                        <NewH2
                            color={Colors.GRAY_8}>
                            {companyDetailItem?.companyName || ""}
                        </NewH2>
                        <FlexBox
                            marginTop={"10px"}
                            alignItems={"center"}>
                            {companyDetailItem?.country && <Flag
                                style={{
                                    borderRadius: "8px",
                                    clipPath: "border-box",
                                    marginRight: "12px"
                                }}
                                country={companyDetailItem?.country}
                                size={32}/>}
                            <NewH7
                                color={Colors.GRAY_6}>
                                {companyDetailItem?.countryName || ""}
                            </NewH7>
                            <FlexBox
                                alignItems={"center"}
                                flexDirection={"row"}>
                                <VerticalDivider
                                    height={"12px"}
                                    width={"1px"}
                                    backgroundColor={Colors.GRAY_6}
                                    margin={"0 16px"}
                                />
                                <NewH7
                                    color={Colors.GRAY_6}>
                                    {companyDetailItem?.businessTypeList ? companyDetailItem?.businessTypeList[0].name || "" : ""}
                                </NewH7>
                                {!!companyDetailItem?.businessTypeList && companyDetailItem?.businessTypeList.length > 1
                                    && <FlexBox alignItems={"center"}>
                                        <VerticalDivider
                                            height={"12px"}
                                            width={"1px"}
                                            backgroundColor={Colors.GRAY_6}
                                            margin={"0 16px"}
                                        />
                                        <NewH7
                                            color={Colors.GRAY_6}>
                                            {companyDetailItem.businessTypeList[1].name || ""}
                                        </NewH7>
                                    </FlexBox>}
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
                <FlexBox
                    width={"144px"}
                    height={"55px"}
                    borderRadius={"8px"}
                    background={"linear-gradient(135deg, #007D72 2.61%, #0ED9CD 100%);"}
                    alignItems={"center"}
                    justifyContent={"center"}>
                    <FlexBox
                        flexDirection={"column"}>
                        <Text
                            color={Colors.GRAY_4}
                            fontSize={"8px"}
                            fontWeight={400}
                            lineHeight={"12px"}>
                            Third Works
                        </Text>
                        <NewH4
                            color={Colors.WHITE}>
                            Verified
                        </NewH4>
                    </FlexBox>
                    <Image
                        width={"32px"}
                        height={"32px"}
                        marginLeft={"8px"}
                        src={require("assets/images/ic-verified-32px.svg").default}
                        alt="ic-verified"/>
                </FlexBox>
            </FlexBox>
            <FlexBox
                marginTop={"40px"}
                backgroundColor={Colors.GRAY_0}
                padding={"24px"}
                borderRadius={"12px"}
                flexWrap={"wrap"}
                justifyContent={"space-between"}
                border={`1px solid ${Colors.GRAY_2}`}>
                {overviewItems.map((item, index) => {
                    return <FlexBox
                        marginTop={index > 1 ? "16px" : "0px"}
                        marginLeft={index % 2 === 1 ? "4px" : "0px"}
                        width={"528px"}>
                        <NewH5
                            textAlign={"left"}
                            minWidth={overviewTitleWidth}
                            color={Colors.GRAY_8}>
                            {item.title}
                        </NewH5>
                        <NewH7
                            marginLeft={"16px"}
                            color={Colors.GRAY_7}>
                            {index === 3 ? FormatUtils.toCurrencyFormat(item.content.toString()) : item.content.toString()}
                        </NewH7>
                    </FlexBox>
                })}
            </FlexBox>
            <NewH3
                marginTop={"64px"}
                color={Colors.GRAY_8}>
                Company Overview
            </NewH3>
            <NewH7
                color={Colors.GRAY_7}
                marginTop={"32px"}>
                {companyDetailItem?.companyOverview}
            </NewH7>
        </FlexBox>
        <FlexBox
            backgroundColor={Colors.WHITE}
            border={`1px solid ${Colors.GRAY_3}`}
            padding={"40px 40px 48px 40px"}
            width={"100%"}
            marginTop={"40px"}
            borderRadius={"16px"}
            boxShadow={'0px 4px 8px 0px rgba(0, 0, 0, 0.08);'}
            flexDirection={"column"}>
            <NewH3
                color={Colors.GRAY_8}>
                Trade Capacity
            </NewH3>
            <FlexBox
                marginTop={"40px"}
                backgroundColor={Colors.GRAY_0}
                padding={"24px"}
                flexDirection={"column"}
                borderRadius={"12px"}
                border={`1px solid ${Colors.GRAY_2}`}>
                {tradeCapacityItems.map((item, index) => {
                    return <FlexBox
                        marginTop={index > 0 ? "16px" : "0px"}>
                        <NewH5
                            textAlign={"left"}
                            width={"256px"}
                            color={Colors.GRAY_8}>
                            {item.title}
                        </NewH5>
                        <NewH7
                            marginLeft={"16px"}
                            color={Colors.GRAY_7}>
                            {item.content}
                        </NewH7>
                    </FlexBox>
                })}
            </FlexBox>
        </FlexBox>
        <FlexBox
            backgroundColor={Colors.WHITE}
            border={`1px solid ${Colors.GRAY_3}`}
            padding={"40px 40px 48px 40px"}
            width={"100%"}
            marginTop={"40px"}
            borderRadius={"16px"}
            boxShadow={'0px 4px 8px 0px rgba(0, 0, 0, 0.08);'}
            flexDirection={"column"}>
            <NewH3
                color={Colors.GRAY_8}>
                Production Capacity
            </NewH3>
            <FlexBox
                marginTop={"40px"}
                backgroundColor={Colors.GRAY_0}
                padding={"24px"}
                flexDirection={"column"}
                borderRadius={"12px"}
                border={`1px solid ${Colors.GRAY_2}`}>
                {productionCapacityItems.map((item, index) => {
                    return <FlexBox
                        marginTop={index > 0 ? "16px" : "0px"}>
                        <NewH5
                            textAlign={"left"}
                            width={"256px"}
                            color={Colors.GRAY_8}>
                            {item.title}
                        </NewH5>
                        <NewH7
                            marginLeft={"16px"}
                            color={Colors.GRAY_7}>
                            {item.content}
                        </NewH7>
                    </FlexBox>
                })}
            </FlexBox>
        </FlexBox>
        <FlexBox
            backgroundColor={Colors.WHITE}
            border={`1px solid ${Colors.GRAY_3}`}
            padding={"40px 40px 48px 40px"}
            width={"100%"}
            marginTop={"40px"}
            borderRadius={"16px"}
            boxShadow={'0px 4px 8px 0px rgba(0, 0, 0, 0.08);'}
            flexDirection={"column"}>
            <NewH3
                color={Colors.GRAY_8}>
                Certificates
            </NewH3>
            <FlexBox
                marginTop={"32px"}>
                {companyDetailItem?.certificatesList.map((item, index) => {
                    return <FlexBox
                        cursor={"pointer"}
                        onClick={() => {
                            if (item.fileUrl) {
                                ImagePreview.renderImage(item.fileUrl)
                            }
                        }}
                        alignItems={"center"}
                        marginLeft={index > 0 ? "18px" : "0px"}
                        flexDirection={"column"}>
                        <Image
                            border={`1px solid ${Colors.GRAY_3}`}
                            width={"256px"}
                            height={"180px"}
                            borderRadius={"8px"}
                            src={item.fileUrl}
                            alt={`packing-image-${index}`}/>
                        <NewP2
                            marginTop={"12px"}
                            color={Colors.GRAY_7}>
                            {item.name}
                        </NewP2>
                    </FlexBox>
                })}
            </FlexBox>
        </FlexBox>
        <FlexBox
            backgroundColor={Colors.WHITE}
            border={`1px solid ${Colors.GRAY_3}`}
            padding={"40px 40px 48px 40px"}
            width={"100%"}
            marginTop={"40px"}
            borderRadius={"16px"}
            boxShadow={'0px 4px 8px 0px rgba(0, 0, 0, 0.08);'}
            flexDirection={"column"}>
            <NewH3
                color={Colors.GRAY_8}>
                Company Show
            </NewH3>
            <FlexBox
                marginTop={"32px"}>
                {companyDetailItem?.companyShowList.map((item, index) => {
                    return <FlexBox
                        cursor={"pointer"}
                        onClick={() => {
                            if (item.url) {
                                ImagePreview.renderImage(item.url)
                            }
                        }}
                        alignItems={"center"}
                        marginLeft={index > 0 ? "18px" : "0px"}
                        flexDirection={"column"}>
                        <Image
                            border={`1px solid ${Colors.GRAY_3}`}
                            width={"256px"}
                            height={"180px"}
                            borderRadius={"8px"}
                            src={item.url}
                            alt={`packing-image-${index}`}/>
                        <NewP2
                            marginTop={"12px"}
                            color={Colors.GRAY_7}>
                            {item.name}
                        </NewP2>
                    </FlexBox>
                })}
            </FlexBox>
        </FlexBox>
    </FlexBox>
}
export default CompanyInfoFragment;