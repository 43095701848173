export class UpdateUserInfoRequestBody {
    name: string;
    company: string;
    countryCode: string;
    phoneNumber: string;
    countryNumber: string;
    customsAuthNumber: string;

    constructor(name: string, company: string, countryCode: string, phoneNumber: string, countryNumber: string, customsAuthNumber: string) {
        this.name = name;
        this.company = company;
        this.countryCode = countryCode;
        this.phoneNumber = phoneNumber;
        this.countryNumber = countryNumber;
        this.customsAuthNumber = customsAuthNumber;
    }
}