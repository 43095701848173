export class SignUpRequestBody {

    userId: string;
    password: string;
    name: string;
    phoneNumber: string;
    company: string;
    countryCode: string;
    countryNumber: string;
    impExpExperience: string
    customsAuthNumber: string;

    constructor(userId: string,
                password: string,
                name: string,
                phoneNumber: string,
                company: string,
                countryCode: string,
                countryNumber: string,
                impExpExperience: string,
                customsAuthNumber: string) {
        this.userId = userId;
        this.password = password;
        this.name = name;
        this.phoneNumber = phoneNumber;
        this.company = company;
        this.countryCode = countryCode;
        this.countryNumber = countryNumber;
        this.impExpExperience = impExpExperience;
        this.customsAuthNumber = customsAuthNumber;
    }


}