/**
 * @author jaeho.lee104 on 2023. 04. 26..
 */
import React from "react";
import FlexBox from "../styledcomponents/FlexBox";
import {Colors} from "../styledcomponents/Styles";
import S1 from "../styledcomponents/text/S1";
import HorizontalDivider from "../styledcomponents/HorizontalDivider";
import Box from "../styledcomponents/Box";
import {RenderingByState} from "./RenderingByState";
import Text from "../styledcomponents/Text";
import Flag from "react-flagkit";
import {Fade} from "react-awesome-reveal";
import useMobile from "../uses/UseMobile";

interface CountryRankChartProps {
    title: string
    unitDescription: string
    rankItems: Array<CountryRankItem> | null
}

export type CountryRankItem = {
    countryName: string
    countryCode: string
    percent: string
}

const colorSet: Array<string> = [
    Colors.PREMIUM,
    Colors.KEY,
    Colors.KEY2,
    Colors.STANDARD,
    Colors.STANDARD2
]

const CountryRankChart: React.FC<CountryRankChartProps> = ({
                                                               title,
                                                               unitDescription,
                                                               rankItems
                                                           }) => {

    const isMobile = useMobile()

    return (
        <FlexBox flexDirection={"column"}
                 minWidth={isMobile ? "100%" : "400px"}
                 height={"466px"}
                 paddingTop={"25px"}
                 paddingLeft={"30px"}
                 paddingRight={"30px"}
                 paddingBottom={"40px"}>
            <FlexBox alignItems={"flex-end"}
                     justifyContent={"space-between"}>
                <Text
                    fontWeight={700}
                    fontSize={isMobile ? "18px" : "20px"}
                    lineHeight={"20px"}>
                    {title}
                </Text>
            </FlexBox>
            <HorizontalDivider
                paddingTop={"1px"}
                marginTop={"20px"}
                backgroundColor={Colors.KEY}/>
            <S1
                marginTop={"14px"}
                alignSelf={"flex-end"}
                lineHeight={"14px"}
                color={Colors.SUB_TXT}>
                {unitDescription}
            </S1>
            <RenderingByState items={rankItems}>
                <Fade duration={300}
                      triggerOnce={true}>
                    <FlexBox flexDirection={"column"}>
                        <Box marginTop={"0px"}/>
                        {rankItems && rankItems.map((item, index) => {
                            return <FlexBox flexDirection={"column"}
                                            marginTop={"20px"}
                                            key={`${item.countryCode}`}>
                                <FlexBox
                                    alignItems={"center"}
                                    justifyContent={"space-between"}>
                                    <FlexBox
                                        alignItems={"center"}>
                                        <Flag
                                            style={{
                                                borderRadius: "2px",
                                                clipPath: "border-box",
                                                marginRight: "10px"
                                            }}
                                            country={item.countryCode}
                                            size={32}/>
                                        <Text
                                            fontSize={"14px"}
                                            lineHeight={"14px"}
                                            fontWeight={700}
                                            color={Colors.BLACK}>
                                            {item.countryName}
                                        </Text>
                                    </FlexBox>
                                    <Text
                                        fontSize={isMobile ? "16px" : "18px"}
                                        lineHeight={"20px"}
                                        color={index <= 2 ? Colors.KEY : Colors.SUB_TXT}
                                        fontWeight={index === 0 ? 700 : index === 1 || index === 2 ? 500 : "normal"}>
                                        {item.percent}%
                                    </Text>
                                </FlexBox>
                                <FlexBox width={"100%"}
                                         marginTop={"4px"}
                                         height={"10px"}
                                         borderRadius={"20px"}
                                         backgroundColor={Colors.GREY_30}>
                                    <FlexBox
                                        backgroundColor={colorSet[index % 5]}
                                        width={`calc(${item.percent}%)`}
                                        height={"10px"}
                                        borderRadius={"20px"}/>
                                </FlexBox>
                            </FlexBox>
                        })}
                    </FlexBox>
                </Fade>
            </RenderingByState>
        </FlexBox>
    )
}
export default CountryRankChart;