import {NetworkCommon} from "./NetworkCommon";
import {AxiosResponse} from "axios";
import {SourcingListItem} from "../model/SourcingListItem";
import {ProductDetailItem} from "../model/ProductDetailItem";
import {CompanyDetailItem} from "../model/CompanyDetailItem";


const instance = NetworkCommon.createDefaultAxiosInstance()


export class SourcingAPI {
    static getSourcingItems(): Promise<AxiosResponse<BaseResponse<Array<SourcingListItem>>>> {
        return instance.get("/solution/sourcing/items");
    }

    static getProductDetail(itemId: string): Promise<AxiosResponse<BaseResponse<ProductDetailItem>>> {
        return instance.get(`solution/sourcing/item/${itemId}`)
    }

    static getCompanyDetail(companyId: string): Promise<AxiosResponse<BaseResponse<CompanyDetailItem>>> {
        return instance.get(`solution/sourcing/company/${companyId}`)
    }

}