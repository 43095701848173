/**
 * @author jaeho.lee104 on 2023. 08. 10..
 */
import {ContentContainer, RootContainer} from "../styledcomponents/Containers";
import FlexBox from "../styledcomponents/FlexBox";
import UserFragment from "./UserFragment";
import Image from "../styledcomponents/Image";
import React, {useEffect, useState} from "react";
import {Colors} from "../styledcomponents/Styles";
import NewH4 from "../styledcomponents/text/NewH4";
import NewP2 from "../styledcomponents/text/NewP2";
import NewH5 from "../styledcomponents/text/NewH5";
import NewP1 from "../styledcomponents/text/NewP1";
import NewH6 from "../styledcomponents/text/NewH6";
import HorizontalDivider from "../styledcomponents/HorizontalDivider";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../modules";
import useTablet from "../uses/UseTablet";
import PaymentManagerFragment from "./PaymentManagerFragment";
import {useTranslation} from "react-i18next";
import {useNavigate, useSearchParams} from "react-router-dom";

const menuIconAccountCircleNormal = require(`assets/images/ic-account-circle-normal.svg`).default
const menuIconAccountCircleSelected = require(`assets/images/ic-account-circle-selected.svg`).default
const menuIconPaymentNormal = require(`assets/images/ic-payment-normal.svg`).default
const menuIconPaymentSelected = require(`assets/images/ic-payment-selected.svg`).default
const menuIconSettingsNormal = require(`assets/images/ic-settings-normal.svg`).default
const menuIconSettingsSelected = require(`assets/images/ic-settings-selected.svg`).default

const NewMyFragment = () => {

    const {signedIn, accessToken} = useSelector((state: RootState) => state.usersReducer, shallowEqual);
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedMenuIndex, setSelectedMenuIndex] = useState(Number(searchParams.get('index') || '0'))
    const {user} = useSelector(
        (state: RootState) => state.usersReducer, shallowEqual,
    );
    const isTablet = useTablet()
    const navigate = useNavigate()
    const {t} = useTranslation()

    useEffect(() => {
        setSelectedMenuIndex(Number(searchParams.get('index') || String(selectedMenuIndex)))
    }, [searchParams])

    useEffect(() => {
        if (!signedIn) {
            alert(t('required_login'))
            navigate("/login")
        }
    }, [signedIn])

    return (<RootContainer
        backgroundColor={Colors.GRAY_0}
        borderBottom={`1px solid ${Colors.GRAY_3}`}>
        <ContentContainer
            width={"100%"}
            paddingTop={"64px"}
            paddingBottom={"64px"}
            flexDirection={"row"}>
            {!isTablet && <FlexBox
                height={"100%"}
                flexDirection={"column"}
                width={"240px"}
                minWidth={"240px"}
                marginRight={"40px"}>

                <FlexBox
                    borderRadius={"16px"}
                    padding={"16px"}
                    border={`1px solid ${Colors.GRAY_1}`}
                    backgroundColor={Colors.WHITE}
                    boxShadow={"0px 2px 16px 0px rgba(0, 0, 0, 0.05)"}>
                    <Image
                        width={"48px"}
                        height={"48px"}
                        marginRight={"16px"}
                        src={require("assets/images/avatars.svg").default}
                        alt="avatar"/>
                    <FlexBox
                        flexDirection={"column"}>
                        <NewH4
                            color={Colors.GRAY_8}>{user?.name}</NewH4>
                        <NewP2
                            maxWidth={"144px"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                            textOverflow={"ellipsis"}
                            color={Colors.GRAY_7}
                            marginTop={"4px"}>{user?.createDt ? `${t('user_account_start', {startDate: (user?.createDt || "")})}` : user?.userId} </NewP2>
                        <FlexBox
                            marginTop={"4px"}
                            alignItems={"center"}>
                            <Image
                                width={"10px"}
                                height={"10px"}
                                color={Colors.GRAY_8}
                                src={require("assets/images/ic-active-user.svg").default}
                                alt="icon-active-user"/>
                            <NewH5
                                marginLeft={"8px"}>{t('active_user')}</NewH5>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
                <NewP1
                    color={Colors.GRAY_8}
                    marginTop={"32px"}>{t('my_manager')}</NewP1>
                <FlexBox
                    height={"54px"}
                    marginTop={"12px"}
                    borderRadius={"12px"}
                    paddingLeft={"20px"}
                    alignItems={"center"}
                    border={selectedMenuIndex === 0 ? undefined : `1px solid ${Colors.GRAY_3}`}
                    cursor={"pointer"}
                    backgroundColor={selectedMenuIndex === 0 ? Colors.MAIN : Colors.WHITE}
                    onClick={() => {
                        navigate("/my?index=0")
                    }}
                    boxShadow={"0px 2px 16px 0px rgba(0, 0, 0, 0.05)"}>
                    <Image
                        width={"24px"}
                        height={"24px"}
                        src={selectedMenuIndex === 0 ? menuIconAccountCircleSelected : menuIconAccountCircleNormal}
                        alt="menu-icon-account"/>
                    <NewH6
                        marginLeft={"12px"}
                        color={selectedMenuIndex === 0 ? Colors.WHITE : Colors.GRAY_6}>
                        {t('user_account')}
                    </NewH6>
                </FlexBox>
                <FlexBox
                    height={"54px"}
                    marginTop={"12px"}
                    borderRadius={"12px"}
                    paddingLeft={"20px"}
                    alignItems={"center"}
                    border={selectedMenuIndex === 1 ? undefined : `1px solid ${Colors.GRAY_3}`}
                    cursor={"pointer"}
                    backgroundColor={selectedMenuIndex === 1 ? Colors.MAIN : Colors.WHITE}
                    onClick={() => {
                        navigate("/my?index=1")
                    }}
                    boxShadow={"0px 2px 16px 0px rgba(0, 0, 0, 0.05)"}>
                    <Image
                        width={"24px"}
                        height={"24px"}
                        src={selectedMenuIndex === 1 ? menuIconPaymentSelected : menuIconPaymentNormal}
                        alt="menu-icon-payment"/>
                    <NewH6
                        marginLeft={"12px"}
                        color={selectedMenuIndex === 1 ? Colors.WHITE : Colors.GRAY_6}>
                        {t('payment_manage')}
                    </NewH6>
                </FlexBox>
                <FlexBox
                    height={"54px"}
                    visible={false}
                    marginTop={"12px"}
                    borderRadius={"12px"}
                    paddingLeft={"20px"}
                    alignItems={"center"}
                    border={selectedMenuIndex === 2 ? undefined : `1px solid ${Colors.GRAY_3}`}
                    cursor={"pointer"}
                    backgroundColor={selectedMenuIndex === 2 ? Colors.MAIN : Colors.WHITE}
                    onClick={() => {
                        alert(t('alert_coming_soon'))
                    }}
                    boxShadow={"0px 2px 16px 0px rgba(0, 0, 0, 0.05)"}>
                    <Image
                        width={"24px"}
                        height={"24px"}
                        src={selectedMenuIndex === 2 ? menuIconSettingsSelected : menuIconSettingsNormal}
                        alt="menu-icon-settings"/>
                    <NewH6
                        marginLeft={"12px"}
                        color={selectedMenuIndex === 2 ? Colors.WHITE : Colors.GRAY_6}>
                        {t('setting')}
                    </NewH6>
                </FlexBox>

                <HorizontalDivider
                    marginTop={"32px"}
                    backgroundColor={Colors.GRAY_3}/>

                <FlexBox
                    alignItems={"center"}
                    marginTop={"32px"}
                    paddingLeft={"20px"}
                    cursor={"pointer"}
                    onClick={() => {
                        alert(t('alert_coming_soon'))
                    }}>
                    <Image
                        width={"20px"}
                        height={"20px"}
                        src={require(`assets/images/icon-text-sms.svg`).default}
                        alt="icon-text-sms-send"/>
                    <NewP1
                        marginLeft={"8px"}
                        color={Colors.GRAY_6}>
                        {t('send_comment')}
                    </NewP1>
                </FlexBox>

            </FlexBox>}
            <FlexBox
                width={"100%"}
                height={"100%"}
                justifyContent={"center"}>
                {selectedMenuIndex === 0 ? <UserFragment/> : selectedMenuIndex === 1 ? <PaymentManagerFragment/> : <></>}
            </FlexBox>
        </ContentContainer>
    </RootContainer>)
}

export default NewMyFragment;