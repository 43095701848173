import React from "react";
import {useNavigate} from "react-router-dom";
import "./PreparePage.css";
import Button from "../styledcomponents/Button";
import FlexBox from "../styledcomponents/FlexBox";

const PreparePage = () => {
    const navigate = useNavigate();

    const onHomeButtonClicked = () => {
        navigate("/");
    };

    return (
        <div className="PreparePage-container">
            <FlexBox
                flexDirection={"column"}
                alignItems={"center"}
                paddingTop={"120px"}>
                <div className="PreparePage-title">서비스 준비중입니다.</div>
                <div className="PreparePage-subtitle">
                    보다 나은 서비스 제공을 위하여 페이지 준비중에 있습니다.
                    <br/>
                    빠른시일내에 준비하여 찾아뵙겠습니다.
                </div>
                <Button onClick={onHomeButtonClicked}
                        padding={"24px"}
                        marginTop={"40px"}>
                    Home으로 돌아가기
                </Button>
            </FlexBox>
        </div>
    );
};

export default PreparePage;
