import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useScript} from "../uses/UseScript";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../modules";

const PaymentWindow = () => {

    const [searchParams, setSearchParams] = useSearchParams()
    const {user} = useSelector(
        (state: RootState) => state.usersReducer, shallowEqual,
    );

    //국내 테스트 환경: https://democpay.payple.kr/js/v1/payment.js
    //국내 리얼 환경: https://cpay.payple.kr/js/v1/payment.js
    //해외 테스트 환경: https://demo-gpay.payple.kr/common/js/gpay-1.0.0.js
    //해외 리얼 환경: https://gpay.payple.kr/common/js/gpay-1.0.1.js

    const axiosScriptStatus = useScript('https://unpkg.com/axios/dist/axios.min.js')
    const paypleKrScriptStatus = useScript('https://cpay.payple.kr/js/v1/payment.js')
    const paypleEnScriptStatus = useScript('https://demo-gpay.payple.kr/common/js/gpay-1.0.0.js')
    const [isScriptsAllLoaded, setIsScriptsAllLoaded] = useState(false)

    useEffect(() => {
        setIsScriptsAllLoaded(paypleKrScriptStatus === 'ready'
            && paypleEnScriptStatus === 'ready'
            && axiosScriptStatus === 'ready')
    }, [paypleKrScriptStatus, paypleEnScriptStatus, axiosScriptStatus])

    //-------------- 국내결제 및 카드등록 ----------------
    useEffect(() => {
        const orderId = searchParams.get('orderId')
        const language = searchParams.get('language')
        const type = searchParams.get('type')
        const payerNo = searchParams.get('payerNo')
        const price = searchParams.get('price')
        const product = searchParams.get('product')
        if (!isScriptsAllLoaded || language !== 'kr' || !type || !user || !user.name || !orderId || !payerNo) {
            console.log(`language: ${language}, type: ${type}, username : ${user?.name}, orderId: ${orderId}, payerNo: ${payerNo})`)
            return
        }
        const isCardChanged = type === 'AUTH'
        if (!isCardChanged) {
            if (!price) {
                alert("invalid price")
                return;
            }
            if (!product) {
                alert("invalid product")
                return;
            }
        }
        const redirectUrl = isCardChanged ?
            `https://apis.thirdworks.co.kr/payple/payment/domestic/card/${orderId}` : "https://apis.thirdworks.co.kr/payple/payment/domestic"

        const obj = {
            PCD_PAY_OID: orderId,
            PCD_PAYER_NO: payerNo,
            PCD_PAY_TYPE: 'card',
            PCD_PAY_WORK: type,
            PCD_CARD_VER: '01',
            PCD_PAY_GOODS: isCardChanged ? "" : product,
            PCD_PAY_TOTAL: isCardChanged ? "" : price,
            PCD_PAYER_NAME: user?.name || "",
            PCD_PAYER_EMAIL: user?.userId || "",
            PCD_PAYER_HP: user?.phoneNumber || "",
            PCD_RST_URL: redirectUrl,
            clientKey: process.env.REACT_APP_PAYPLE_REAL_CLIENT_KEY,
        };
        // @ts-ignore
        PaypleCpayAuthCheck(obj);

    }, [searchParams, isScriptsAllLoaded, user])

    //-------------- 해외결제 ----------------
    useEffect(() => {
        const orderId = searchParams.get('orderId')
        const language = searchParams.get('language')
        const accessToken = searchParams.get('accessToken')
        const type = searchParams.get('type')
        const payCls = searchParams.get('payCls')
        if (!isScriptsAllLoaded || !orderId || language !== 'en' || !accessToken || !user || !user.userId) {
            return
        }
        const isCardChanged = type === 'CARDCHANGE'
        const obj = {
            Authorization: accessToken,
            service_id: process.env.REACT_APP_PAYPLE_REAL_CUST_ID,
            service_oid: orderId,
            comments: isCardChanged ? "ADD CARD" : "REAL PRODUCT",
            email: user?.userId,
            totalAmount: "1",
            currency: "USD",
            resultUrl: "https://apis.thirdworks.co.kr/payple/payment/oversea",
            isDirect: "Y",
            serviceDefine: type,
            payCls: payCls //TEST 시 해당 필드 넘겨야 함
        };
        // @ts-ignore
        paypleGpayPaymentRequest(obj);

    }, [searchParams, isScriptsAllLoaded, user])

    return (
        <></>
    );
};

export default PaymentWindow;

