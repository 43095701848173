import {Reveal, RevealProps} from "react-awesome-reveal";
import {keyframes} from "@emotion/react";
import React from "react";

/**
 * @author jaeho.lee104 on 2023. 04. 18..
 */

export interface SimpleFadeProps extends RevealProps {
    translationY?: string
}

export const SimpleFade: React.FC<SimpleFadeProps> = ({
                                                          children,
                                                          duration = 1500,
                                                          translationY,
                                                          fraction = 0.6,
                                                          ...props
                                                      }) => {

    const customAnimation = keyframes`
      from {
        opacity: 0;
        transform: translate3d(0, ${translationY ? translationY : "200px"}, 0);
        transition-timing-function: cubic-bezier(0.15, 0, 0.2, 0);
      }

      to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition-timing-function: cubic-bezier(0.15, 0, 0.2, 0);
      }
    `;
    return <Reveal
        triggerOnce={true}
        duration={duration}
        fraction={fraction}
        {...props}
        keyframes={customAnimation}>{children}</Reveal>;
}