import React, {useEffect, useState} from "react";
import FlexBox from "../styledcomponents/FlexBox";
import NewH4 from "../styledcomponents/text/NewH4";
import NewH7 from "../styledcomponents/text/NewH7";
import {useParams} from "react-router-dom";
import NewH3 from "../styledcomponents/text/NewH3";
import {ImportNotice} from "../model/ImportNotice";
import {ImportApplyAPI} from "../network/ImportApplyAPI";
import {ResponseCodes} from "../network/ResponseCodes";
import {useTranslation} from "react-i18next";

const InquiryDetailFragment = () => {

    const {postSequence} = useParams();
    const [notice, setNotice] = useState<ImportNotice | null>(null)
    const {t} = useTranslation()

    useEffect(() => {
        if (!postSequence) {
            return
        }
        ImportApplyAPI.getNotice(postSequence)
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                if (responseCode.isSuccess()) {
                    setNotice(response.data.data)
                } else {
                    alert(t('alert_a_temporary_error_has_occurred'))
                }
            })
    }, [postSequence]);

    if (!postSequence) {
        alert(t("alert_invalid_access"))
        return
    }

    if (!notice) {
        return <></>
    }

    return <FlexBox
        width={"100%"}
        minWidth={"1200px"}
        justifyContent={"center"}
        height={"100%"}>
        <FlexBox
            marginTop={"60px"}
            width={"640px"}
            flexDirection={"column"}
            marginBottom={"120px"}>
            <FlexBox
                alignSelf={"center"}>
                <NewH3>
                    {notice.subject}
                </NewH3>
            </FlexBox>
            <NewH4
                marginTop={"34px"}>
                Writer
            </NewH4>
            <NewH7
                marginTop={"8px"}>
                {t('manager')}
            </NewH7>
            <NewH4
                marginTop={"34px"}>
                Date
            </NewH4>
            <NewH7
                marginTop={"8px"}>
                {notice.createDate}
            </NewH7>
            <NewH4
                marginTop={"34px"}>
                Content
            </NewH4>
            <NewH7
                marginTop={"8px"}>
                {notice.contents}
            </NewH7>
        </FlexBox>
    </FlexBox>;
}
export default InquiryDetailFragment;