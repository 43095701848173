import React, {useEffect, useState} from "react";
import {
    AppBar,
    Drawer,
    FormControl,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Shadows,
    ThemeProvider,
    Toolbar
} from "@mui/material";
import {HideOnScroll} from "./HodeOnScroll";
import {Colors, Fonts} from "../styledcomponents/Styles";
import Image from "../styledcomponents/Image";
import {useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";
import CommonComponent from "../styledcomponents/CommonComponent";
import ReactGA from "react-ga4";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {RootState} from "../modules";
import FlexBox from "../styledcomponents/FlexBox";
import HorizontalDivider from "../styledcomponents/HorizontalDivider";
import {Logger} from "../log/Logger";
import {LocalStorageManager} from "../LocalStorageManager";
import {Users} from "../modules/Users";
import LanguageIcon from "@mui/icons-material/Language";
import {useTranslation} from "react-i18next";
import {Configurations} from "../modules/Configurations";
import NewH6 from "../styledcomponents/text/NewH6";
import NewButton from "../styledcomponents/NewButton";
import {ExpandMoreOutlined} from "@mui/icons-material";
import {createTheme} from "@mui/material/styles";

interface NavigationItemProps {
    selected: boolean
}

const theme = createTheme({
    shadows: Array(25).fill("0px 4px 16px 0px rgba(0, 0, 0, 0.08);") as Shadows,
    components: {
        MuiPaper: {
            defaultProps: {
                sx: {
                    background: Colors.WHITE,
                    padding: "4px",
                    marginTop: "4px",
                    borderRadius: "12px",
                    boxShadow: "0px 2px 40px 0px rgba(0, 0, 0, 0.05);",
                    border: `1px solid ${Colors.GRAY_3}`
                }
            }
        },
        MuiList: {
            defaultProps: {
                sx: {
                    padding: "0px",
                }
            }
        },
    },
    palette: {
        action: {
            selected: Colors.MAIN,
        }
    }
});

const NavigationItem = styled(CommonComponent)<NavigationItemProps>`
    font-weight: ${({selected}) => selected ? Fonts.WEIGHT_700 : Fonts.WEIGHT_500};
    color: ${({selected}) => selected ? Colors.MAIN : Colors.GRAY_8};
    font-size: 16px;
    width: 100%;
    text-align: left;
    cursor: pointer;
    padding-top: 12px;
    padding-bottom: 12px;
    line-height: 150%;

    &:hover {
        color: ${Colors.MAIN_LIGHT}
    }
`;


const MobileAppBar = () => {
    const componentName = "MobileAppBar"
    const navigate = useNavigate()
    const dispatch = useDispatch<any>()
    const location = useLocation();
    const {signedIn} = useSelector((state: RootState) => state.usersReducer, shallowEqual);
    const [selectedMenuIndex, setSelectedMenuIndex] = useState(0)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const {t} = useTranslation()
    const {language} = useSelector((state: RootState) => state.configurationsReducer, shallowEqual);
    const drawerWidth = "288px";
    const isMyPage = location.pathname.includes("my")
    const navItems = [{
        name: `${t('home')}`,
        stat: "home",
        path: "/"
    }, {
        name: `${t('dashboard')}`,
        stat: "dashboard",
        path: "/dashboard"
    }, {
        name: `${t('sourcing_inquiry')}`,
        stat: "sourcing_inquiry",
        path: "/sourcing-inquiry"
    }, {
        name: `FAQ`,
        stat: "Faq",
        path: "/faq"
    }];
    const handleDrawerToggle = () => {
        setDrawerOpen((prevState) => !prevState);
    };


    const LanguageMenu: React.FC = () => {
        return <FlexBox
            alignItems={"center"}
            height={"100%"}
            marginTop={"16px"}
            width={"100%"}>
            <ThemeProvider theme={theme}>
                <FormControl sx={{width: "100%"}}>
                    <Select
                        IconComponent={(props) => (<ExpandMoreOutlined {...props}/>)}
                        input={<OutlinedInput
                            style={{
                                height: "40px"
                            }}/>}
                        value={language}
                        renderValue={(value) => {
                            return <FlexBox
                                alignItems={"center"}>
                                <LanguageIcon
                                    sx={{
                                        marginRight: "4px",
                                        width: "24px",
                                        height: "24px",
                                        color: Colors.GRAY_8
                                    }}
                                />
                                <NewH6
                                    textOverflow={"ellipsis"}
                                    whiteSpace={"nowrap"}
                                    overflow={"hidden"}
                                    color={Colors.GRAY_8}
                                    marginLeft={"4px"}>
                                    {t('lang_' + value)}
                                </NewH6>
                            </FlexBox>
                        }}
                        onChange={(e: SelectChangeEvent) => {
                            const selectedLanguage = e.target.value
                            if (language !== selectedLanguage) {
                                dispatch(Configurations.setLanguage(selectedLanguage))
                            }
                        }}
                        sx={{
                            color: Colors.GRAY_8,
                            fontSize: "14px",
                            borderRadius: "5px",
                            "&& fieldset": {
                                border: `1px solid ${Colors.GRAY_3}`,
                            },
                            "&:hover": {
                                "&& fieldset": {
                                    border: `1px solid ${Colors.CTA_HOVER}`
                                }
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                border: `2px solid ${Colors.MAIN}`
                            },

                        }}>
                        <MenuItem
                            sx={{
                                margin: "4px",
                                fontSize: "14px",
                                borderRadius: "8px"
                            }}
                            value={"kr"}>{t('lang_kr')}</MenuItem>
                        <MenuItem
                            sx={{
                                margin: "4px",
                                fontSize: "14px",
                                borderRadius: "8px"
                            }}
                            value={"en"}>{t('lang_en')}</MenuItem>
                    </Select>
                </FormControl>
            </ThemeProvider>
        </FlexBox>
    }

    const container = window !== undefined ? () => window.document.body : undefined;
    const drawer = (
        <FlexBox
            onClick={handleDrawerToggle}
            marginTop={"30px"}
            width={"100%"}
            flexDirection={"column"}
            paddingLeft={"20px"}
            paddingRight={"20px"}
            alignItems={"center"}>
            <FlexBox
                width={"100%"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                paddingTop={"12px"}>
                {navItems.map((item, index) => {
                    return <NavigationItem
                        key={item.name}
                        selected={selectedMenuIndex === index}
                        onClick={() => {
                            ReactGA.event({
                                category: "app_bar",
                                action: `${item.stat}_click`,
                            });
                            navigate(item.path)
                        }}>{item.name}</NavigationItem>
                })}
            </FlexBox>
            <HorizontalDivider
                width={"100%"}
                marginTop={"16px"}
                backgroundColor={Colors.GRAY_3}
            />
            <FlexBox
                alignSelf={"flex-start"}
                flexDirection={"column"}
                marginTop={"16px"}>
                <NewH6
                    cursor={"pointer"}
                    visible={!isMyPage}
                    color={Colors.GRAY_8}
                    borderRadius={'8px'}
                    colorOnHover={Colors.MAIN}
                    padding={"8px 8px 8px 0px"}
                    onClick={() => {
                        if (signedIn) {
                            navigate("/my?index=0")
                        } else {
                            alert(t('required_login'))
                            navigate("/login")
                        }
                    }}>
                    {"My Page"}
                </NewH6>
                <FlexBox
                    height={"48px"}
                    marginTop={"4px"}
                    paddingTop={"12px"}
                    paddingBottom={"12px"}
                    borderRadius={"12px"}
                    alignItems={"center"}
                    cursor={"pointer"}
                    onClick={() => {
                        navigate("/my?index=0")
                    }}>
                    <Image
                        width={"24px"}
                        height={"24px"}
                        src={require(`assets/images/ic-account-circle-normal.svg`).default}
                        alt="menu-icon-account"/>
                    <NewH6
                        marginLeft={"12px"}
                        color={Colors.GRAY_7}>
                        {t('user_account')}
                    </NewH6>
                </FlexBox>
                <FlexBox
                    height={"48px"}
                    marginTop={"4px"}
                    paddingTop={"12px"}
                    paddingBottom={"12px"}
                    borderRadius={"12px"}
                    alignItems={"center"}
                    cursor={"pointer"}
                    colorOnHover={Colors.MAIN}
                    onClick={() => {
                        navigate("/my?index=1")
                    }}>
                    <Image
                        width={"24px"}
                        height={"24px"}
                        src={require(`assets/images/ic-payment-normal.svg`).default}
                        alt="menu-icon-account"/>
                    <NewH6
                        marginLeft={"12px"}
                        color={Colors.GRAY_7}>
                        {t('payment_manage')}
                    </NewH6>
                </FlexBox>
                <FlexBox
                    visible={false}
                    height={"48px"}
                    marginTop={"4px"}
                    paddingTop={"12px"}
                    paddingBottom={"12px"}
                    borderRadius={"12px"}
                    alignItems={"center"}
                    cursor={"pointer"}
                    onClick={() => {
                        alert(t('alert_coming_soon'))
                    }}>
                    <Image
                        width={"24px"}
                        height={"24px"}
                        src={require(`assets/images/ic-settings-normal.svg`).default}
                        alt="menu-icon-account"/>
                    <NewH6
                        marginLeft={"12px"}
                        color={Colors.GRAY_7}>
                        설정
                    </NewH6>
                </FlexBox>

            </FlexBox>
            <NewButton
                colorType={0}
                marginTop={"22px"}
                borderRadius={"8px"}
                minHeight={"44px"}
                alignSelf={"center"}
                onClick={handleLoginOrLogout}>
                {signedIn ? t("logout") : t("login")}
            </NewButton>
            <LanguageMenu/>
        </FlexBox>
    );

    useEffect(() => {
        let pathname = location.pathname
        if (pathname === "/") {
            setSelectedMenuIndex(0)
        } else if (pathname === "/dashboard") {
            setSelectedMenuIndex(1)
        } else if (pathname === "/sourcing-inquiry") {
            setSelectedMenuIndex(2)
        } else {
            setSelectedMenuIndex(999)
        }
    }, [location])

    function handleLoginOrLogout() {
        if (signedIn) {
            ReactGA.event({
                category: "app_bar",
                action: `logout`
            });
            Logger.info(componentName, `logout. userId: ${LocalStorageManager.getUserId()}`)
            dispatch(Users.logout());
            navigate("/");

        } else {
            ReactGA.event({
                category: "app_bar",
                action: `login`
            });
            navigate("/login");
        }
    }

    return (
        <>
            <HideOnScroll>
                <AppBar
                    elevation={0}
                    style={{
                        height: "64px",
                        backgroundColor: Colors.WHITE,
                        borderBottom: `1px solid ${Colors.GRAY_3}`
                    }}>
                    <Toolbar
                        style={{
                            display: "flex"
                        }}>
                        <Image
                            cursor={"pointer"}
                            width={"32px"}
                            height={"32px"}
                            src={require("assets/images/ic-mobile-app-bar-menu.svg").default}
                            position={"absolute"}
                            onClick={handleDrawerToggle}
                            alt="app-bar-menu"/>
                        <Image
                            width={"205px"}
                            height={"20px"}
                            cursor={"pointer"}
                            src={require("assets/images/app-bar-logo.svg").default}
                            style={{
                                margin: "0 auto"
                            }}
                            onClick={() => {
                                navigate("/")
                            }}
                            alt="app-bar-logo"/>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Toolbar id="back-to-top-anchor"/>
            <Drawer
                container={container}
                variant="temporary"
                open={drawerOpen}
                onClose={() => handleDrawerToggle()}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                }}>
                {drawer}
            </Drawer>
        </>
    )
}
export default MobileAppBar;