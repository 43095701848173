/**
 * @author jaeho.lee104 on 2023. 04. 13..
 */
import {Sizes} from "../styledcomponents/Styles";

export const reactiveValueInContent = (valueWithUnit: string, minValue?: string) => {
    const pureValue = StyleUtils.unitToValue(valueWithUnit)
    const pureContentWidth = StyleUtils.unitToValue(Sizes.CONTENT_WIDTH)
    const result = `min(calc(100vw * ${pureValue}/${pureContentWidth}), ${valueWithUnit})`
    if (minValue) {
        return `max(${minValue}, ${result})`
    } else {
        return result
    }
}
export const reactiveValueInFHD = (valueWithUnit: string, minValue?: string) => {
    const pureValue = StyleUtils.unitToValue(valueWithUnit)
    const pureContentWidth = StyleUtils.unitToValue(Sizes.FHD_WIDTH)
    const result = `min(calc(100vw * ${pureValue}/${pureContentWidth}), ${valueWithUnit})`
    if (minValue) {
        return `max(${minValue}, ${result})`
    } else {
        return result
    }
}
export const reactiveValue = (valueWithUnit: string, ratio: number) => {
    const pureValue = StyleUtils.unitToValue(valueWithUnit)
    return `min(calc(100vw * ${ratio}), ${valueWithUnit})`
}

export class StyleUtils {
    static unitToValue = (valueWithUnit: string) => {
        if (valueWithUnit.includes("rem", 0)) {
            return valueWithUnit.replace("rem", "0")
        } else if (valueWithUnit.includes("px", 0)) {
            return valueWithUnit.replace("px", "")
        } else {
            return valueWithUnit
        }
    }

}