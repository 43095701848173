import React, {useEffect, useRef, useState} from "react";
import FlexBox from "../styledcomponents/FlexBox";
import NewH4 from "../styledcomponents/text/NewH4";
import {useNavigate, useParams} from "react-router-dom";
import NewH3 from "../styledcomponents/text/NewH3";
import NewButton from "../styledcomponents/NewButton";
import {useTranslation} from "react-i18next";
import {ImportApplyDetailItem} from "../model/ImportApplyDetailItem";
import TextField from "../components/TextField";
import {ImportApplyAPI} from "../network/ImportApplyAPI";
import {ResponseCodes} from "../network/ResponseCodes";
import SimpleDialog, {ButtonType} from "../dialog/SimpleDialog";
import {Comment} from "../model/Comment";
import NewP2 from "../styledcomponents/text/NewP2";
import {Colors} from "../styledcomponents/Styles";
import HorizontalDivider from "../styledcomponents/HorizontalDivider";
import Image from "../styledcomponents/Image";
import CheckIcon from "@mui/icons-material/Check";
import useTablet from "../uses/UseTablet";
import useMobile from "../uses/UseMobile";
import TextArea from "antd/lib/input/TextArea";
import {Tag} from "antd";
import styled from "styled-components";
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import TextEditorViewer from "../components/TextEditorViewer";

const CommentTextArea = styled(TextArea)`
    .ant-input::placeholder {
        color: ${Colors.GRAY_6};
    }

    border-color: transparent !important; /* 호버 시 보더 제거 */
    box-shadow: none !important; /* 호버 시 그림자 제거 */
`;

const InquiryDetailFragment = () => {

    const {postSequence} = useParams();

    const [detailItem, setDetailItem] = useState<ImportApplyDetailItem | null>(null)
    const [password, setPassword] = useState("")

    const {t} = useTranslation()
    const navigate = useNavigate()
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [isCommentDeleteDialogOpen, setIsCommentDeleteDialogOpen] = useState(false)
    const [comments, setComments] = useState<Array<Comment>>([])
    const [newComment, setNewComment] = useState("")
    const [refresh, setRefresh] = useState(0)
    const isTablet = useTablet()
    const isMobile = useMobile()
    const [pendingDeleteComment, setPendingDeleteComment] = useState<Comment | null>(null)

    const commentInputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (!postSequence) {
            return
        }
        ImportApplyAPI.getPost(`${postSequence}`, password)
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                if (responseCode.isSuccess()) {
                    setDetailItem(response.data.data)
                }
            })
    }, [postSequence]);

    useEffect(() => {
        const commentKey = detailItem?.commentKey
        if (!detailItem || !postSequence || !commentKey) {
            return
        }
        ImportApplyAPI.getComments(`${postSequence}`, commentKey)
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                if (responseCode.isSuccess()) {
                    setComments(response.data.data || [])
                } else {
                    alert(t('alert_a_temporary_error_has_occurred'))
                }
            })
    }, [detailItem, refresh])

    function addComment() {
        const commentKey = detailItem?.commentKey
        if (!postSequence || !commentKey) {
            return
        }
        ImportApplyAPI.addComment(`${postSequence}`, commentKey, newComment)
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                if (responseCode.isSuccess()) {
                    setNewComment("")
                    setRefresh(refresh + 1)
                } else {
                    alert(t('alert_a_temporary_error_has_occurred'))
                }
            })
    }

    function deleteComment(comment: Comment) {
        const commentKey = detailItem?.commentKey
        if (!postSequence || !commentKey) {
            return
        }
        ImportApplyAPI.deleteComment(`${postSequence}`, `${commentKey}`, comment.commentSequence)
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                if (responseCode.isSuccess()) {
                    setRefresh(refresh + 1)
                } else if (responseCode.isNotFoundInformation() || responseCode.isIncorrectInformation()) {
                    alert(t(`alert_invalid_access`))
                } else {
                    alert(t('alert_a_temporary_error_has_occurred'))
                }
            })
    }

    function deleteItem() {
        ImportApplyAPI.deletePost(`${postSequence}`, password)
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                if (responseCode.isSuccess()) {
                    navigate("/sourcing-inquiry")
                } else {
                    alert(t('alert_a_temporary_error_has_occurred'))
                }
            })
    }

    function fetchItem() {
        if (!postSequence) {
            alert(t("alert_invalid_access"))
            return;
        }
        if (!password) {
            alert(t("please_enter_password"))
            return
        }
        ImportApplyAPI.getPost(`${postSequence}`, password)
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                if (responseCode.isSuccess()) {
                    setDetailItem(response.data.data)
                } else if (responseCode.isIncorrectInformation()) {
                    alert(t('invalid_password'))
                } else {
                    alert(t('alert_a_temporary_error_has_occurred'))
                }
            })
    }

    if (!postSequence) {
        alert(t("alert_invalid_access"))
        return
    }

    if (detailItem == null) {
        return <FlexBox
            width={"100%"}
            maxWidth={"400px"}
            flexDirection={"column"}
            padding={"40px"}
            alignItems={"center"}
            justifyContent={"center"}
            margin={"0 auto"}
            height={"100%"}>
            <TextField
                width={"100%"}
                label={'Password'}
                labelFontWight={700}
                labelFontSize={"16px"}
                marginTop={"34px"}
                value={password}
                onChange={(e) => {
                    setPassword(e.target.value)
                }}
                type={"password"}
            />
            <NewButton
                width={"100%"}
                marginTop={"20px"}
                onClick={() => {
                    fetchItem()
                }}>
                {t('confirm')}
            </NewButton>
        </FlexBox>
    }

    return <FlexBox
        alignItems={"center"}
        width={"100%"}
        minWidth={isTablet ? "100%" : "1200px"}
        justifyContent={"center"}
        backgroundColor={Colors.BG_PAGE}
        flexDirection={"column"}
        padding={"60px 20px 80px 20px"}
        height={"100%"}>
        <FlexBox
            flexDirection={"column"}
            width={"100%"}
            maxWidth={"640px"}>
            <FlexBox
                backgroundColor={Colors.WHITE}
                width={"100%"}
                maxWidth={"640px"}
                border={`1px solid ${Colors.GRAY_3}`}
                borderRadius={"12px"}
                padding={isMobile ? "24px" : "40px"}
                flexDirection={"column"}
                alignItems={"center"}>
                <Image
                    visible={!isTablet}
                    width={"132px"}
                    position={"absolute"}
                    top={"24px"}
                    right={"24px"}
                    opacity={0.8}
                    src={require("assets/images/app-bar-logo.svg").default}
                    alignSelf={"center"}
                    alt="form-logo"/>
                <FlexBox
                    marginTop={isTablet ? "0px" : "20px"}
                    alignItems={"center"}
                    justifyContent={"space-between"}>
                    <NewH3>
                        {t('import_apply_form')}
                    </NewH3>
                </FlexBox>
                <TextField
                    width={"100%"}
                    label={`${t('inquiry_subject')}`}
                    labelFontWight={700}
                    labelFontSize={"16px"}
                    marginTop={"34px"}
                    value={detailItem.subject}
                    isReadOnly={true}
                    onChange={(e) => {
                    }}
                    type={"text"}
                />
                <TextField
                    width={"100%"}
                    label={`${t('company')}`}
                    labelFontWight={700}
                    labelFontSize={"16px"}
                    marginTop={"34px"}
                    value={detailItem.companyName}
                    isReadOnly={true}
                    onChange={(e) => {
                    }}
                    type={"text"}
                />
                <FlexBox
                    marginTop={isTablet ? "0px" : "24px"}
                    width={'100%'}
                    flexDirection={isTablet ? "column" : "row"}>
                    <TextField
                        width={"100%"}
                        marginTop={isTablet ? "24px" : "0px"}
                        isReadOnly={true}
                        label={`${t('email')}`}
                        labelFontWight={700}
                        labelFontSize={"16px"}
                        value={detailItem.email}
                        onChange={(e) => {
                        }}
                        type={"text"}
                    />
                    <TextField
                        marginLeft={isTablet ? "0px" : "20px"}
                        marginTop={isTablet ? "24px" : "0px"}
                        width={"100%"}
                        label={`${t('writer')}`}
                        isReadOnly={true}
                        labelFontWight={700}
                        labelFontSize={"16px"}
                        value={detailItem.writer}
                        onChange={(e) => {
                        }}
                        type={"text"}
                    />
                </FlexBox>
                <FlexBox
                    alignItems={"center"}
                    marginTop={isTablet ? "0px" : "24px"}
                    width={'100%'}
                    flexDirection={isTablet ? "column" : "row"}>
                    <TextField
                        width={"100%"}
                        isReadOnly={true}
                        label={`${t('phone')}`}
                        marginTop={isTablet ? "24px" : "0px"}
                        labelFontWight={700}
                        labelFontSize={"16px"}
                        value={detailItem.phone}
                        errorText={`${t('alert_please_enter_your_phone_number2')}`}
                        onChange={(e) => {
                        }}
                        type={"text"}
                    />
                </FlexBox>
                <HorizontalDivider margin={"34px"}/>
                <FlexBox
                    alignSelf={"flex-start"}
                    alignItems={"center"}>
                    <CheckIcon sx={{color: Colors.GRAY_7}}/>
                    <NewH4 fontSize={'16px'}
                           marginLeft={"8px"}
                           color={Colors.GRAY_7}
                           lineHeight={"150%"}
                           alignSelf={"flex-start"}
                           fontWeight={700}>{t('sourcing_content')}</NewH4>
                </FlexBox>
                <FlexBox
                    width={"100%"}
                    minHeight={"200px"}
                    borderRadius={"16px"}
                    marginTop={"24px"}
                    padding={"20px"}
                    border={`1px solid ${Colors.GRAY_3}`}>
                    <TextEditorViewer content={detailItem.contents}/>
                </FlexBox>
                <FlexBox
                    marginTop={"80px"}
                    alignSelf={"flex-end"}>
                    < NewButton
                        width={"120px"}
                        colorType={6}
                        onClick={() => {
                            if (!detailItem) {
                                return
                            }
                            navigate(`/sourcing-inquiry/modify/${postSequence}`, {
                                state: {
                                    subject: detailItem.subject,
                                    companyName: detailItem.companyName,
                                    phone: detailItem.phone,
                                    writer: detailItem.writer,
                                    email: detailItem.email,
                                    contents: detailItem.contents
                                }
                            })
                        }}>
                        {t('modify')}
                    </NewButton>
                    <NewButton
                        marginLeft={"16px"}
                        width={"120px"}
                        colorType={3}
                        onClick={() => {
                            setIsDeleteDialogOpen(true)
                        }}>
                        {t('delete')}
                    </NewButton>
                </FlexBox>
            </FlexBox>
            <HorizontalDivider
                margin={"40px 0px"}/>
            <FlexBox
                width={"100%"}
                alignSelf={"center"}
                flexDirection={"column"}>
                <NewH4
                    alignSelf={"flex-start"}>
                    {t('comments')}
                </NewH4>
                {comments.map((item, index) => {
                    return <FlexBox
                        border={`1px solid ${Colors.GRAY_5}`}
                        borderRadius={"16px"}
                        flexDirection={"column"}
                        backgroundColor={Colors.WHITE}
                        marginTop={"20px"}
                        width={"100%"}
                        padding={"28px"}>
                        <FlexBox
                            justifyContent={"space-between"}
                            alignItems={"center"}>
                            <Tag
                                color={item.commentUser ? Colors.MAIN : Colors.GRAY_7}
                                key={'index'}>
                                {item.commentUser || detailItem.writer || "User"}
                            </Tag>
                            <FlexBox
                                alignItems={"center"}>
                                <NewP2>
                                    {item.createDate}
                                </NewP2>
                                {item.editable && <DeleteIcon fontSize={"small"}
                                                              onClick={() => {
                                                                  setPendingDeleteComment(item)
                                                                  setIsCommentDeleteDialogOpen(true)
                                                              }}
                                                              sx={{
                                                                  cursor: "pointer",
                                                                  marginLeft: "12px",
                                                                  color: Colors.GRAY_6
                                                              }}/>}
                            </FlexBox>
                        </FlexBox>
                        <NewP2
                            marginTop={"20px"}
                            maxLines={20}
                            textOverflow={"ellipsis"}
                            overflowWrap={"anywhere"}>
                            {item.comment}
                        </NewP2>
                    </FlexBox>
                })}
                <FlexBox
                    flexDirection={"column"}
                    onClick={() => {
                        commentInputRef.current?.focus()
                    }}
                    alignItems={"center"}
                    cursor={"text"}
                    width={"100%"}
                    marginTop={"20px"}
                    borderRadius={"16px"}
                    padding={"20px"}
                    borderOnHover={`1px solid ${Colors.GRAY_7}`}
                    backgroundColor={Colors.WHITE}
                    boxShadow={"0px 4px 8px 0px #00000014;"}
                    border={`1px solid ${Colors.GRAY_3}`}>
                    <CommentTextArea
                        ref={commentInputRef}
                        value={newComment}
                        onChange={(e) => {
                            setNewComment(e.target.value)
                        }}
                        showCount
                        placeholder={`${t('add_a_comment')}`}
                        maxLength={200}
                        style={{
                            background: undefined,
                            border: "none",
                            height: "120px",
                            resize: 'none',
                        }}
                    />
                    <HorizontalDivider
                        marginTop={"40px"}
                        backgroundColor={Colors.GRAY_3}
                        width={"100%"}/>
                    <FlexBox
                        alignSelf={"flex-end"}>
                        <NewButton
                            marginTop={"20px"}
                            width={"100px"}
                            fontSize={"16px"}
                            colorType={6}
                            onClick={() => {
                                addComment()
                            }}>
                            {t('add')}
                        </NewButton>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </FlexBox>
        <SimpleDialog dialogProps={{
            isOpen: isDeleteDialogOpen,
            title: t('delete_dialog_title'),
            description: "",
            positiveText: `${t('delete')}`,
            positiveColorType: 3,
            buttonType: ButtonType.BOTH,
            onClose: (positive) => {
                setIsDeleteDialogOpen(false)
                if (positive) {
                    deleteItem()
                }
            }
        }}/>
        <SimpleDialog dialogProps={{
            isOpen: isCommentDeleteDialogOpen,
            title: t('delete_comment'),
            description: "",
            positiveText: `${t('delete')}`,
            positiveColorType: 3,
            buttonType: ButtonType.BOTH,
            onClose: (positive) => {
                setIsCommentDeleteDialogOpen(false)
                if (positive && pendingDeleteComment) {
                    deleteComment(pendingDeleteComment)
                }
                setPendingDeleteComment(null)
            }
        }}/>
    </FlexBox>;
}
export default InquiryDetailFragment;