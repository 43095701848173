import React from "react";
import Image from "../styledcomponents/Image";
import {Colors} from "../styledcomponents/Styles";
import {useLocation, useNavigate} from "react-router-dom";
import NewButton from "../styledcomponents/NewButton";
import FlexBox from "../styledcomponents/FlexBox";
import NewH3 from "styledcomponents/text/NewH3";
import NewP2 from "../styledcomponents/text/NewP2";

/**
 * @author jaeho.lee104 on 2023. 11. 06..
 */
const CompanySignUpDoneFragment = () => {
    const {state} = useLocation();
    const navigate = useNavigate()
    return (
        <FlexBox
            margin={"110px auto"}
            alignSelf={"center"}
            border={`1px solid ${Colors.GRAY_2}`}
            borderRadius={'12px'}
            backgroundColor={Colors.WHITE}
            boxShadow={`0px 4px 16px 0px rgba(0, 0, 0, 0.08);`}
            flexDirection={"column"}
            width={"100%"}
            alignItems={"center"}
            padding={"32px"}
            maxWidth={"480px"}>
            <Image
                width={"193px"}
                height={"147px"}
                src={require("assets/images/img-signup-done.svg").default}
                alt="sign-up-done"
                marginTop={"48px"}/>
            <NewH3
                marginTop={"40px"}
                color={Colors.GRAY_9}>
                회원가입이 완료되었습니다.
            </NewH3>
            <NewP2
                textAlign={"center"}
                marginTop={"16px"}
                color={Colors.GRAY_7}>
                {`신청정보를 기준으로 약 1~2일 정도 정보 확인이 진행되며,\n확인이 완료되면 고객님의 전담 매니저가 메일로 안내해드립니다.`}
            </NewP2>
            <NewButton
                borderRadius={"12px"}
                margin={"32px"}
                fontSize={"16px"}
                onClick={() => {
                    navigate("/")
                }}>
                홈으로 돌아가기
            </NewButton>

        </FlexBox>
    )
}
export default CompanySignUpDoneFragment;