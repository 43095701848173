/**
 * @author jaeho.lee104 on 2023. 08. 25..
 */
import React, {useEffect, useState} from "react";
import {Dialog} from "@mui/material";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import useMobile from "../uses/UseMobile";
import {BottomSheet} from "react-spring-bottom-sheet";
import FlexBox from "../styledcomponents/FlexBox";
import NewH3 from "../styledcomponents/text/NewH3";
import NewP2 from "../styledcomponents/text/NewP2";
import Image from "../styledcomponents/Image";
import NewButton from "../styledcomponents/NewButton";
import {useTranslation} from "react-i18next";

const theme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    minWidth: "480px",
                    borderRadius: "16px"
                }
            }
        }
    },
    palette: {
        background: {
            paper: '#fff',
        },
        text: {
            primary: '#000',
            secondary: '#46505A',
        },
        action: {
            active: '#001E3C',
        },
    },
});

export enum ButtonType {
    ONLY_POSITIVE,
    BOTH
}

export interface SimpleDialogProps {
    isOpen: boolean
    title: string
    description: string
    positiveText?: string
    negativeText?: string
    positiveColorType?: number
    buttonType: ButtonType
    onClose: (positive: boolean) => void
}

const SimpleDialog: React.FC<{
    dialogProps: SimpleDialogProps
}> = ({dialogProps}) => {

    const [isOpen, setIsOpen] = useState(false);
    const isMobile = useMobile()
    const {t} = useTranslation()

    useEffect(() => {
        setIsOpen(dialogProps.isOpen);
    }, [dialogProps.isOpen]);

    const handleOk = () => {
        if (!isOpen) {
            return;
        }
        dialogProps.onClose(true);
    };

    const handleCancel = () => {
        if (!isOpen) {
            return;
        }
        dialogProps.onClose(false);
    };

    return <>{!isMobile &&
        <ThemeProvider theme={theme}>
            <Dialog
                open={isOpen}
                onClose={handleCancel}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleOk();
                    }
                }}>
                <FlexBox
                    flexDirection={"column"}
                    padding={"32px"}>
                    <FlexBox
                        alignItems={"center"}
                        justifyContent={"space-between"}>
                        <NewH3>
                            {dialogProps.title}
                        </NewH3>
                        <Image
                            cursor={"pointer"}
                            onClick={handleCancel}
                            width={"20px"}
                            height={"20px"}
                            src={require("assets/images/ic-close-20.svg").default}
                            alt="ic-close"/>
                    </FlexBox>
                    <NewP2
                        marginTop={"16px"}>
                        {dialogProps.description}
                    </NewP2>
                    <FlexBox
                        height={"52px"}
                        alignItems={"center"}
                        marginTop={"32px"}>
                        <NewButton
                            height={"52px"}
                            colorType={2}
                            width={"100%"}
                            fontSize={"16px"}
                            fontWeight={500}
                            borderRadius={"12px"}
                            onClick={handleCancel}>
                            {dialogProps.negativeText || t('cancel')}
                        </NewButton>
                        <NewButton
                            height={"52px"}
                            width={"100%"}
                            marginLeft={"16px"}
                            colorType={dialogProps.positiveColorType || 0}
                            fontSize={"16px"}
                            fontWeight={700}
                            onClick={handleOk}
                            borderRadius={"12px"}>
                            {dialogProps.positiveText || t('ok')}
                        </NewButton>
                    </FlexBox>
                </FlexBox>
            </Dialog>
        </ThemeProvider>}
        {isMobile &&
            <BottomSheet
                open={isOpen}
                onDismiss={handleCancel}
                defaultSnap={({maxHeight}) => maxHeight / 4}
                snapPoints={({maxHeight}) => [
                    maxHeight - 2 * maxHeight / 10,
                    maxHeight / 4,
                    maxHeight * 0.6,
                ]}>
                <FlexBox
                    flexDirection={"column"}
                    padding={"16px 20px"}>
                    <FlexBox
                        alignItems={"center"}
                        justifyContent={"space-between"}>
                        <NewH3>
                            {dialogProps.title}
                        </NewH3>
                        <Image
                            cursor={"pointer"}
                            onClick={handleCancel}
                            width={"20px"}
                            height={"20px"}
                            src={require("assets/images/ic-close-20.svg").default}
                            alt="ic-close"/>
                    </FlexBox>
                    <NewP2
                        marginTop={"12px"}>
                        {dialogProps.description}
                    </NewP2>
                    <FlexBox
                        height={"52px"}
                        alignItems={"center"}
                        marginTop={"28px"}
                        marginBottom={"30px"}>
                        <NewButton
                            colorType={2}
                            visible={dialogProps.buttonType === ButtonType.BOTH}
                            height={"52px"}
                            width={"100%"}
                            fontSize={"16px"}
                            fontWeight={500}
                            borderRadius={"12px"}
                            onClick={handleCancel}>
                            {dialogProps.negativeText || t('cancel')}
                        </NewButton>
                        <NewButton
                            height={"52px"}
                            width={"100%"}
                            marginLeft={dialogProps.buttonType === ButtonType.BOTH ? "16px" : "0px"}
                            colorType={dialogProps.positiveColorType || 0}
                            fontSize={"16px"}
                            fontWeight={700}
                            onClick={handleOk}
                            borderRadius={"12px"}>
                            {dialogProps.positiveText || t('ok')}
                        </NewButton>
                    </FlexBox>
                </FlexBox>
            </BottomSheet>}
    </>
}

export default SimpleDialog