/**
 * @author jaeho.lee104 on 2023. 04. 27..
 */
import React, {useEffect, useRef} from "react";

import Chart from 'chart.js/auto';
import {TreemapController, TreemapElement} from 'chartjs-chart-treemap';

export interface TreemapDataItem {
    id: string;
    what: string;
    value: number;
    color: string;
}

interface TreemapChartProps {
    items: Array<TreemapDataItem>
    onItemClick: (item: TreemapDataItem) => void
}

const TreemapChart: React.FC<TreemapChartProps> = ({items, onItemClick}) => {

    Chart.register(TreemapController, TreemapElement);

    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    useEffect(() => {
        const context = canvasRef?.current?.getContext("2d")
        if (!context || !items) {
            return
        }
        const chart = new Chart(context, {
            type: 'treemap',
            data: {
                datasets: [
                    {
                        // @ts-ignore
                        tree: items,
                        key: 'value',
                        borderWidth: 0,
                        // @ts-ignore
                        borderRadius: 4,
                        spacing: 1,
                        backgroundColor(ctx) {
                            if (ctx.type !== 'data') {
                                return 'transparent';
                            }
                            // @ts-ignore
                            return ctx.raw._data.color;
                        },
                        labels: {
                            overflow: 'fit',
                            align: 'left',
                            padding: 5,
                            display: true,
                            formatter(ctx) {
                                if (ctx.type !== 'data') {
                                    return;
                                }
                                // @ts-ignore
                                const title: string = ctx.raw._data.what
                                const width = Number(ctx.raw.w)
                                let uppercaseCount = 0
                                for (let i = 0; i < title.length; i++) {
                                    const ch = title[i];
                                    if (ch === ch.toLowerCase()) {
                                    } else {
                                        uppercaseCount++
                                    }
                                }
                                const lowercaseCount = title.length - uppercaseCount
                                const delta = (lowercaseCount * 6 + uppercaseCount * 12) / title.length
                                const toLength = Math.round(width / delta)
                                const suffix = title.length > toLength ? "..." : ""

                                // @ts-ignore
                                return [ctx.raw.v + '%', (title).substring(0, toLength) + suffix];
                            },
                            color: ['white'],
                            font: [{size: 28, weight: 'bold'}, {size: 12}],
                            hoverFont: [{size: 30, weight: 'bold'}, {size: 12}],
                            hoverColor: '#fff',
                            position: 'bottom'
                        }
                    }
                ],
            },
            options: {
                onClick(event, elements, chart) {
                    onItemClick(items[elements[0].index])
                },
                onHover(event, elements, chart) {
                    // @ts-ignore
                    event.native.target.style.cursor = 'pointer';
                },
                plugins: {
                    title: {
                        display: false
                    },
                    legend: {
                        display: false
                    },
                    tooltip: {
                        callbacks: {
                            title(items) {
                                // @ts-ignore
                                return items[0].raw._data.what;
                            },
                            label(item) {
                                const dataItem = item.raw;
                                // @ts-ignore
                                const obj = dataItem._data;
                                return ' ' + obj.value + '%';
                            }
                        }
                    }
                }
            }
        })
        return () => chart.destroy();
    }, [canvasRef, items]);

    return (
        <canvas ref={canvasRef} style={{
            width: "340px",
            maxWidth: "340px",
            maxHeight: "220px",
            height: "220px"
        }}></canvas>
    )
}
export default TreemapChart;