import styled from "styled-components";
import Text from "../Text";
import {reactiveValueInContent} from "../../utils/StyleUtils";

const H5 = styled(Text)`
  font-size: ${({reactive}) => reactive ? reactiveValueInContent("20px", "12px") : "20px"};
  //font-size: min(calc(100vw * 0.017), 2rem);
  font-weight: 700;
`;

export default H5;