import styled from "styled-components";
import Text from "../Text";
import {reactiveValueInContent} from "../../utils/StyleUtils";

const B1 = styled(Text)`
  font-size: ${({reactive}) => reactive ? reactiveValueInContent("28px", "12px") : "28px"};
  font-weight: 500;
  line-height: ${({lineHeight}) => lineHeight || "160%"};
`;

export default B1;