import React from "react";
import FlexBox from "../styledcomponents/FlexBox";
import {useLocation} from "react-router-dom";

/**
 * @author jaeho.lee104 on 2024. 01. 16..
 */

interface RootFragmentProps {
    children: React.ReactElement | any;
}

const RootFragment = (props: RootFragmentProps) => {
    const {children} = props;
    const location = useLocation();

    function getMinWidth(path: string): string | undefined {
        switch (path) {
            case "/sourcing":
                return "1240px"
            case "/sourcing-detail":
                return "1240px"
            default:
                return undefined
        }
    }

    return <FlexBox
        flexDirection={"column"}
        width={"100%"}
        minWidth={getMinWidth(location.pathname)}
        height={"100vh"}
        minHeight={"100vh"}
        textAlign={"center"}
        margin={"0 auto"}
        position={"relative"}
        style={{
            fontFamily: "Noto Sans, sans-serif"
        }}>
        {children}
    </FlexBox>
}
export default RootFragment;